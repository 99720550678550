import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { TextStrings } from "../../../core/constants/text-strings";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { ModeEnum } from '../../../core/constants/enums';
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { IMapper } from "../../../models/i-mapper";
import { EquipmentTypePostMapper } from "../../../mappers/equipment-type-mapper";
import { UtilsService } from "../../../core/app-utils/utils.service";

@Component({
  selector: 'app-admin-panel-create-modify-equipment-type',
  templateUrl: './admin-panel-create-modify-equipment-type.component.html',
  styleUrl: './admin-panel-create-modify-equipment-type.component.scss'
})
export class AdminPanelCreateModifyEquipmentTypeComponent {

  loading = false;
  formLabels: any = {};
  formMode = '';

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    metadataSchema: new FormControl('', [Validators.required, UtilsService.jsonValidator]),
  });

  createModifyEquipmentTypeUrl = environment.cognito.apiEndpoint + Constants.equipmentTypesApiPath;

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyEquipmentTypeComponent>
  ) {
    this.formLabels = {
      name: TextStrings.EQ_TYPE_NAME,
      metadataSchema: TextStrings.EQ_TYPE_JSONSCHEMA,
      messages: {
        nameMessage: TextStrings.EQ_TYPE_MESSAGES_NAME_PLACEHOLDER,
        descriptionMessage: TextStrings.EQ_TYPE_MESSAGES_JSONSCHEMA_PLACEHOLDER,
      },
    };
    this.formMode = this.data.mode;
    if (this.data.mode === ModeEnum.MODIFY) {
      this.fillFormControls();
    }
  }

  async sendRequest(): Promise<void> {
    if (this.data.mode === ModeEnum.CREATE) {
      await this.sendCreateRequest();
    } else {
      await this.sendModifyRequest();
    }
  }

  async sendCreateRequest(): Promise<void> {
    this.loading = true;
    const responseMapper = new SimpleMapper();
    const requestMapper = new EquipmentTypePostMapper();
    this.fillRequestBody(requestMapper);
    this.providerService.networkService
      .post(this.createModifyEquipmentTypeUrl, requestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  async sendModifyRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new EquipmentTypePostMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .put(`${this.createModifyEquipmentTypeUrl}/${this.data.data.id}`, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
      metadataSchema: this.form.controls.metadataSchema.value,
    });
  }

  fillFormControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
    this.form.controls.metadataSchema.setValue(this.data.data.metadataSchema);
  }

  protected readonly ModeEnum = ModeEnum;
}
