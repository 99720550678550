import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IListMapper, IMapper } from '../../../models/i-mapper';
import { WelfarePostMapper, WelfareReasonListMapper, WelfareTypeListMapper } from "../../../mappers/welfare.mapper";
import { BeSharperModel } from '../../../models/be-sharper.model';
import { WelfareReasonModel, WelfareTypeModel } from "../../../models/welfare.model";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";

@Component({
  selector: 'app-create-modify-welfare',
  templateUrl: './create-modify-welfare.component.html',
  styleUrl: './create-modify-welfare.component.scss',
})
export class CreateModifyWelfareComponent implements OnInit {
  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  listMode = false;
  welfare?: any;
  backendUrlWelfare = environment.cognito.apiEndpoint + Constants.welfareApiPath;
  backendUrlWelfareTypes = environment.cognito.apiEndpoint + Constants.welfareTypesApiPath;
  backendUrlWelfareReasons = environment.cognito.apiEndpoint + Constants.welfareReasonApiPath;
  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;

  welfareTypes: WelfareTypeModel[] = [];
  welfareReasons: WelfareReasonModel[] = [];
  beSharperId?: string;
  eTextStrings = TextStrings;

  filteredBesharpers: BeSharperModel[] = [];
  selectedBesharper?: BeSharperModel;
  besharpers: BeSharperModel[] = [];

  public form = new FormGroup({
    welfareType: new FormControl('', [Validators.required]),
    value: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*((.|,)[0-9]{1,2})?$')]),
    supplyDate: new FormControl(new Date(), [Validators.required]),
    reason: new FormControl('', [Validators.required]),
    besharper: new FormControl('', [])
  });

  constructor(
    public dialogRef: MatDialogRef<CreateModifyWelfareComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
      this.bootstrap(data);
  }

  private bootstrap(data : any) {
    if (data['mode'] === ModeEnum.MODIFY.valueOf()) {
      this.mode = ModeEnum.MODIFY;
    } else if (data['mode'] === ModeEnum.CREATE.valueOf()) {
      this.mode = ModeEnum.CREATE;
      this.listMode = data['listMode'];
      if(this.listMode) {
        this.form.controls.besharper.addValidators(Validators.required);
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.getWelfareTypes();
    await this.getWelfareReasons();
    this.beSharperId = this.data['beSharperId'];
    if (this.data['mode'] === ModeEnum.MODIFY.valueOf() && this.data['welfare'] !== undefined) {
      this.mode = ModeEnum.MODIFY;
      this.welfare = this.data['welfare'];
      this.populateFormForModify();
    }
    else if (this.data['mode'] === ModeEnum.CREATE.valueOf()) {
      this.mode = ModeEnum.CREATE;
    }
    await this.getBesharpers();
    this.loading = false;
  }

  async getBesharpers(): Promise<void> {
    const mapper: IListMapper = new BeSharperListMapper();

    try {
      await this.providerService.networkService.get(this.backendUrlBesharper, mapper, { limit: '99999', offset: '0', orderBy: 'surname' })
      this.besharpers = mapper.elements;
    }
    catch(e) {
      this.providerService.utilService.showMessage(e as string, LogLevel.error);
      this.dialogRef.close();
    }
  }

  displaySelectedBesharper(id: string): string {
    const selectedBesharper = this.besharpers.find((besharper: { id: string; }) => besharper.id === id);
    return selectedBesharper ? selectedBesharper?.name + ' ' + selectedBesharper?.surname : '';
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  onBesharperSelected(besharper: BeSharperModel): void {
    this.selectedBesharper = besharper;
  }

  formValid(): boolean {
    return this.form.valid;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  async createWelfare(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const url = environment.cognito.apiEndpoint + Constants.welfareApiPath;
    const postMapper = new WelfarePostMapper();
    const responseMapper = new SimpleMapper();

    try {
      this.fillMapper(postMapper);
      await this.providerService.networkService.post(url, postMapper, responseMapper);
      this.providerService.utilService.showMessage(TextStrings.WELFARE_CREATE_SUCCESS, LogLevel.success);
      this.loading = false;
      this.form.reset();
      if (!addAnother) {
        this.dialogRef.close();
      }
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  async modifyWelfare(): Promise<void> {
    this.loading = true;
    const postMapper = new WelfarePostMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(postMapper);
      await this.providerService.networkService.put(this.backendUrlWelfare + '/' + this.welfare['id'], postMapper, mapper);
      this.providerService.utilService.showMessage(TextStrings.WELFARE_MODIFY_SUCCESS, LogLevel.success);
      this.dialogRef.close();
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  private populateFormForModify() {
    this.form.controls.welfareType.setValue(this.welfare['welfareTypeId']);
    const valueString = Math.abs(this.welfare['value']).toString()
    const formattedValue = Number(valueString).toLocaleString("it-IT", {minimumFractionDigits: 2});
    this.form.controls.value.setValue(formattedValue);
    this.form.controls.supplyDate.setValue(new Date(this.welfare['supplyDate']));

    const reason = this.welfare['reason'] ? this.welfare['reason']['id'] : null;
    this.form.controls.reason.setValue(reason);
  }

  fillMapper(mapper: IMapper): void {
    const riaccreditoId = this.welfareTypes.find(wt => wt.name == 'riaccredito')?.id ?? '';

    //console.log(this.form.controls);
    //console.log(mapper);
    mapper.fillFromJson({
      besharperId: this.beSharperId || this.form.controls.besharper.value,
      welfareTypeId: this.form.controls.welfareType.value,
      value: (parseFloat(this.form.controls.value.value?.replace(',', '.') || '0')) * (this.form.controls.welfareType?.value === riaccreditoId ? -1 : 1),
      supplyDate: this.providerService.utilService.formatDate(this.form.controls.supplyDate.value ?? new Date()),
      reasonId: this.form.controls.reason.value,
    });
  }

  private async getWelfareTypes() {
    const mapper: IListMapper = new WelfareTypeListMapper();
    try {
      await this.providerService.networkService.get(this.backendUrlWelfareTypes, mapper, { limit: '999999', offset: '0' });
      this.welfareTypes = mapper.elements;
    }
    catch(e) {
      this.providerService.utilService.showMessage(e as string, LogLevel.error);
      this.dialogRef.close();
    }
  }

  private async getWelfareReasons() {
    const mapper: IListMapper = new WelfareReasonListMapper();
    try {
      await this.providerService.networkService.get(this.backendUrlWelfareReasons, mapper, { limit: '999999', offset: '0' });
      this.welfareReasons = mapper.elements;
    }
    catch(e) {
      this.providerService.utilService.showMessage(e as string, LogLevel.error);
      this.dialogRef.close();
    }
  }
}
