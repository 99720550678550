import { Component, OnDestroy } from "@angular/core";
import { BehaviorSubject, debounceTime, distinctUntilChanged, skip, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { IAuthService } from "./i-auth-service";
import { UtilsService } from "../app-utils/utils.service";
import moment, { Moment } from "moment/moment";
import { IUser } from "../../models/i-user";
import RoleEnum, { stringToRole } from "../../models/role.enum";
import { BesharperState } from "../constants/enums";

@Component({ template: '' })
export default abstract class IBasePage implements OnDestroy {
  debounceSubject = new BehaviorSubject<string>('');
  debounceDaysSubject = new BehaviorSubject<string>('');
  debounceActiveCeasedStatusSubject = new BehaviorSubject<string>('');
  debounceMobileWindowSubject = new BehaviorSubject<boolean>(true);
  debounceOnlyMeSubject = new BehaviorSubject<boolean>(false);

  lastDebounceValue = '';
  lastDebounceWeekValue = '00000';
  lastDebounceActiveCeasedState = BesharperState.ALL;
  lastDebounceMobileWindow = true;
  lastDebounceOnlyMe = false;

  filterObservable = new BehaviorSubject<string>('');
  daysOfTheWeekObservable = new BehaviorSubject<string>('');
  activeCeasedStatusObservable = new BehaviorSubject<string>('');
  mobileWindowObservable = new BehaviorSubject<boolean>(true);
  onlyMeObservable = new BehaviorSubject<boolean>(false);

  private subscriptions = new Subscription();

  protected constructor(protected router: Router, protected authService: IAuthService, protected utilService: UtilsService) {
    this.subscriptions.add(
      this.debounceSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.filterObservable.next(value);
      })
    );
    this.subscriptions.add(
      this.debounceDaysSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.daysOfTheWeekObservable.next(value);
      })
    );

    this.subscriptions.add(
      this.debounceActiveCeasedStatusSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.activeCeasedStatusObservable.next(value);
      })
    );

    this.subscriptions.add(
      this.debounceMobileWindowSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.mobileWindowObservable.next(value);
      })
    );

    this.subscriptions.add(
      this.debounceOnlyMeSubject.pipe(debounceTime(500), distinctUntilChanged(), skip(1)).subscribe((value) => {
        this.onlyMeObservable.next(value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  get userRole(): RoleEnum {
    return stringToRole(this.authService.getUser().role);
  }

  get currentUser(): IUser {
    return this.authService.getUser();
  }

  formatDate(date: Date | Moment | string | undefined, onlyDate = false): string {
    if (date) {
      date = moment(date).toDate();
      return this.utilService.toItalianDateFormat(date, onlyDate);
    } else return "";
  }

  formatMoney(value: number | string | undefined): string {
    if (value) {
      return "€ " + Number(value).toLocaleString("it-IT", { minimumFractionDigits: 2 })
    } else {
      return "0.00 €"
    }
  }

  formatBytes(bytes:number, decimals: number = 2): string {
    return this.utilService.formatBytes(bytes, decimals);
  }

  refreshTable(): void {
    this.filterObservable.next(this.lastDebounceValue);
    this.daysOfTheWeekObservable.next(this.lastDebounceWeekValue);
    this.activeCeasedStatusObservable.next(this.lastDebounceActiveCeasedState);
    this.mobileWindowObservable.next(this.lastDebounceMobileWindow);
    this.onlyMeObservable.next(this.lastDebounceOnlyMe);
  }

  humanizeString(s: string | null | undefined) {
    if (!s)
      return '';
    return s?.replace(/([A-Z])/g, ' $1');
  }
}
