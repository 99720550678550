import { Component } from '@angular/core';
import { ContractsEnums } from '../../../../core/constants/enums';
import { Constants } from '../../../../core/constants/constants';
import { ContractRoleMapper } from '../../../../mappers/contract.mapper';
import { ContractLevelMapper } from '../../../../mappers/contract.mapper';
import { ContractTypeMapper } from '../../../../mappers/contract.mapper';
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { ProviderService } from "../../../../core/provider.service";
import { AdminPanelCreateModifyContractComponent } from "../../../dialogs/admin-panel-create-modify-contract/admin-panel-create-modify-contract.component";
import { ModeEnum } from "../../../../core/constants/enums";
import {ContractTypeModel} from "../../../../models/contract-type.model";
import {ContractLevelModel} from "../../../../models/contract-level.model";
import {ContractRoleModel} from "../../../../models/contract-role.model";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { Router } from "@angular/router";
@Component({
  selector: 'app-set-contract-dropdown-menus',
  templateUrl: './set-contract-dropdown-menus.component.html',
  styleUrl: './set-contract-dropdown-menus.component.scss',
})
export class SetContractDropdownMenusComponent extends IFilteredTablePage {
  loading = false;
  basePath = environment.cognito.apiEndpoint;

  contractLevelDataSource: TableDataSource<ContractLevelModel> = new TableDataSource<ContractLevelModel>();
  contractTypeDataSource: TableDataSource<ContractTypeModel> = new TableDataSource<ContractTypeModel>();
  contractRoleDataSource: TableDataSource<ContractRoleModel> = new TableDataSource<ContractRoleModel>();

  contractLevelCollapsed = true;
  contractTypeCollapsed = true;
  contractRoleCollapsed = true;

  contractLevelsBackendUrl = this.basePath + Constants.contractLevelsApiPath;
  contractTypesBackendUrl = this.basePath + Constants.contractTypesApiPath;
  contractRolesBackendUrl = this.basePath + Constants.contractRolesApiPath;

  contractLevelsMapper: ContractLevelMapper = new ContractLevelMapper();
  contractTypesMapper: ContractTypeMapper = new ContractTypeMapper();
  contractRolesMapper: ContractRoleMapper = new ContractRoleMapper();

  contractLevelTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'description', title: 'Descrizione', sortable: true },
  ];
  contractTypesTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'description', title: 'Descrizione', sortable: true },
  ];
  contractRolesTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'description', title: 'Descrizione', sortable: true },
  ];

  contractLevelActionsDefinitions = [
    {
      icon: 'fa-solid fa-pencil',
      text: 'Modifica',
      styleClass: 'table-action-success',
      callback: (item: any) => {
        this.openModifyDialog(item, ContractsEnums.CONTRACT_LEVEL);
      },
    },
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: ContractLevelModel) => {
        this.deleteItem(ContractsEnums.CONTRACT_LEVEL, item.id);
      },
    },
  ];
  contractTypeActionsDefinitions = [
    {
      icon: 'fa-solid fa-pencil',
      text: 'Modifica',
      styleClass: 'table-action-success',
      callback: (item: any) => {
        this.openModifyDialog(item, ContractsEnums.CONTRACT_TYPE);
      },
    },
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: ContractTypeModel) => {
        this.deleteItem(ContractsEnums.CONTRACT_TYPE, item.id);
      },
    },
  ];
  contractRoleActionsDefinitions = [
    {
      icon: 'fa-solid fa-pencil',
      text: 'Modifica',
      styleClass: 'table-action-success',
      callback: (item: any) => {
        this.openModifyDialog(item, ContractsEnums.CONTRACT_ROLE);
      },
    },
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: ContractRoleModel) => {
        this.deleteItem(ContractsEnums.CONTRACT_ROLE, item.id);
      },
    },
  ];

  eTextStrings = TextStrings;
  eContractsEnums = ContractsEnums;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(formToToggle: string): void {
    switch (formToToggle) {
      case ContractsEnums.CONTRACT_LEVEL:
        this.contractLevelCollapsed = !this.contractLevelCollapsed;
        break;
      case ContractsEnums.CONTRACT_TYPE:
        this.contractTypeCollapsed = !this.contractTypeCollapsed;
        break;
      case ContractsEnums.CONTRACT_ROLE:
        this.contractRoleCollapsed = !this.contractRoleCollapsed;
        break;
    }
  }

  openCreateContractDialog(dropDown: string): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyContractComponent,
      data: {
        mode: ModeEnum.CREATE,
        dropDown: dropDown,
      }
    });
  }

  deleteItem(formName: string, id: string): void {
    let apiUrl;
    if (formName === ContractsEnums.CONTRACT_LEVEL) {
      apiUrl = `${this.contractLevelsBackendUrl}/${id}`;
    } else if (formName === ContractsEnums.CONTRACT_TYPE) {
      apiUrl = `${this.contractTypesBackendUrl}/${id}`;
    } else if (formName === ContractsEnums.CONTRACT_ROLE) {
      apiUrl = `${this.contractRolesBackendUrl}/${id}`;
    }

    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: apiUrl!,
    });
  }

  openModifyDialog(row: any, dropDown: string): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyContractComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
        dropDown: dropDown,
      }
    });
  }
}
