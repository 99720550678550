import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-saml-error',
  templateUrl: './saml-error.component.html',
  styleUrl: './saml-error.component.scss',
})
export class SamlErrorComponent implements OnInit {
  public errorMessage : string = "";

  public constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async (params) => {
      this.errorMessage = params.get("error") ?? "Unknown error";
    })
  }

  redirectToHome(event : any) {
    event.preventDefault();
    this.router.navigate(["/"]).then();
  }
}
