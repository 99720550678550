<div class="navbar">
  <div class="logo">
    <img src="/assets/images/logo-beSharp-high-res.png" alt="logo" title="logo" />
  </div>
  <div class="title">
    <h2>{{eConstants.title}}</h2>
  </div>
  <div class="spacer"></div>
  <div class="user">
    <span>Benvenuto, {{user.email}} <b class="bolder">({{user.role}})</b></span>
    <a (click)="signOut()" matTooltip="Sign-out"><i class="fa-regular fa-right-from-bracket"></i></a>
  </div>
  <div class="user-mobile">
    <a class="hamburger-menu" mat-icon-button [matMenuTriggerFor]="mobileMenu"><i class="fa-solid fa-bars"></i></a>
    <mat-menu #mobileMenu="matMenu">
      <span mat-menu-item disabled>Welcome, {{user.email}}</span>
      <mat-divider></mat-divider>
      <a (click)="signOut()" mat-menu-item><i class="fa-regular fa-right-from-bracket"></i></a>
    </mat-menu>
  </div>
</div>
