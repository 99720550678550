import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Constants} from "../../../core/constants/constants";
import {CreateUpdateBesharpItemMapper} from "../../../mappers/items.mapper";
import {IMapper} from "../../../models/i-mapper";
import {ProviderService} from "../../../core/provider.service";
import {SimpleMapper} from "../../../mappers/simple.mapper";
import {LogLevel} from "../../../models/log-level";
import {S3Service} from "../../../core/auth-utils/s3.service";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { FormTypeEnum, StatusesEnum } from "../../../core/constants/enums";

@Component({
  selector: 'app-create-modify-besharp-item',
  templateUrl: './create-modify-besharp-item.component.html',
  styleUrl: './create-modify-besharp-item.component.scss',
})

export class CreateModifyBesharpItemComponent implements OnInit {
  protected readonly formTypeEnum = FormTypeEnum;
  protected readonly statusesEnum = StatusesEnum;

  loading = false;

  createUrl = environment.cognito.apiEndpoint + Constants.besharpItemsPath;
  updateUrl = environment.cognito.apiEndpoint + Constants.besharpItemsPath;

  users: string[] = [];

  formType = FormTypeEnum.CREATE.valueOf();

  form = new FormGroup({
    description: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    total: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]*$"),]),
    createdAt: new FormControl(new Date, Validators.required),
    status: new FormControl('', Validators.required),
    note: new FormControl(''),
    assignee: new FormControl(''),
    position: new FormControl('', Validators.required),
    serialNumber: new FormControl(''),
    photo: new FormControl('')
  });

  file?: File;
  imageSrc = '';

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CreateModifyBesharpItemComponent>,
    private providerService: ProviderService,
    private s3Service: S3Service,
  ) {}

  ngOnInit(): void {
    if(this.data.mode === FormTypeEnum.MODIFY.valueOf()) {
      this.formType = FormTypeEnum.MODIFY.valueOf();
      this.populateModifyForm();
    }
  }

  isCreateMode(): boolean {
    return this.formType === FormTypeEnum.CREATE.valueOf();
  }

  formValid(): boolean {
    return this.form.valid;
  }

  populateModifyForm(): void {
    this.form.controls.description.setValue(this.data.item.description);
    this.form.controls.code.setValue(this.data.item.code);
    this.form.controls.type.setValue(this.data.item.type);
    this.form.controls.total.setValue(this.data.item.total);
    this.form.controls.createdAt.setValue(new Date(this.data.item.acquisitionDate));
    this.form.controls.status.setValue(this.data.item.status);
    this.form.controls.note.setValue(this.data.item.note);
    this.form.controls.assignee.setValue(this.data.item.assignee);
    this.form.controls.position.setValue(this.data.item.position);
    this.form.controls.serialNumber.setValue(this.data.item.serialNumber);
  }

  async sendRequest(addAnother?:boolean): Promise<void> {
    if(this.formType === FormTypeEnum.CREATE.valueOf()) {
      this.loading = true;
      let imageKey = '';
      const createRequestMapper = new CreateUpdateBesharpItemMapper();
      const createResponseMapper = new SimpleMapper();
      if(this.file) {
        try {
          imageKey = await this.s3Service.uploadImage(this.file);
        }
        catch (e: any) {
          this.providerService.utilService.showMessage(TextStrings.WAREHOUSE_UPLOAD_FAIL, LogLevel.error);
        }
      }
      imageKey.length === 0 ? this.fillBody(createRequestMapper) : this.fillBody(createRequestMapper, imageKey);
      try {
        this.providerService.networkService.post(this.createUrl, createRequestMapper, createResponseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.WAREHOUSE.CREATE_SUCCESS, LogLevel.success);
            this.loading = false;
            this.form.reset();
            if(!addAnother) {
              this.dialogRef.close();
            }
          })
          .catch((e) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
            this.loading = false;
          })
      }
      catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      }
    } else {
      let imageKey = '';
      const updateRequestMapper = new CreateUpdateBesharpItemMapper();
      const updateResponseMapper = new SimpleMapper();
      this.loading = true;
      if(this.file) {
        try {
          imageKey = await this.s3Service.uploadImage(this.file);
        }
        catch (e: any) {
          this.providerService.utilService.showMessage(TextStrings.WAREHOUSE_UPLOAD_FAIL, LogLevel.error);
        }
      }
      imageKey.length === 0 ? this.fillBody(updateRequestMapper) : this.fillBody(updateRequestMapper, imageKey);
      try {
        this.providerService.networkService.put(`${this.updateUrl}${this.data.item.id}`, updateRequestMapper, updateResponseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.WAREHOUSE_CREATE_SUCCESS, LogLevel.success);
            this.loading = false;
            this.dialogRef.close();
          })
          .catch((e) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
            this.loading = false;
          })
      }
      catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      }
    }
  }

  handleFileInputChange(fileList: FileList | null): void {
    if(fileList !== null) {
      this.file = fileList[0];
      const reader = new FileReader();
      if (this.file) {
        reader.readAsDataURL(this.file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
        }
        this.form.controls.photo.patchValue(`${this.file.name}`);
      } else {
        this.form.controls.photo.patchValue("");
      }
    }
  }

  fillBody(mapper: IMapper, imageKey?: string): void {
    mapper.fillFromJson({
      serialNumber: this.form.controls.serialNumber.value,
      note: this.form.controls.note.value,
      description: this.form.controls.description.value,
      code: this.form.controls.code.value,
      status: this.form.controls.status.value,
      type: this.form.controls.type.value,
      position: this.form.controls.position.value,
      assignee: this.form.controls.assignee.value,
      total: this.form.controls.total.value,
      imageUrl: imageKey,
      createdAt: this.providerService.utilService.formatDate(this.form.controls.createdAt.value!),
    })
  }
}
