import { BehaviorSubject } from 'rxjs';
import { IUser } from '../../models/i-user';

export abstract class IAuthService {
  protected authenticationSubject: BehaviorSubject<any>;

  protected constructor() {
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  abstract signUp(user: IUser): Promise<any>;

  abstract confirmSignUp(user: IUser): Promise<any>;

  abstract signIn(user: IUser): Promise<any>;

  abstract signOut(): Promise<any>;

  abstract isAuthenticated(): Promise<boolean>;

  abstract getUser(): IUser;

  abstract setUser(user: IUser): void;

  abstract updateUser(user: IUser): Promise<any>;

  abstract get userSubject() : BehaviorSubject<IUser>;
}
