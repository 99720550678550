import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../../core/constants/constants';
import { ActivatedRoute, Router } from "@angular/router";
import { ProviderService } from '../../../../core/provider.service';
import { WelfareModel } from '../../../../models/welfare.model';
import { WelfareDetailMapper } from '../../../../mappers/welfare.mapper';
import { CreateModifyWelfareComponent } from '../../../dialogs/create-modify-welfare/create-modify-welfare.component';
import { ModeEnum } from '../../../../core/constants/enums';
import { RoleEnum } from "../../../../models/role.enum";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import IBasePage from '../../../../core/interfaces/i-base-page';

@Component({
  selector: 'app-welfare-detail',
  templateUrl: './welfare-detail.component.html',
  styleUrl: './welfare-detail.component.scss',
})
export class WelfareDetailComponent extends IBasePage implements OnInit {
  loading = false;
  id = '';
  welfareBackendUrl = environment.cognito.apiEndpoint;
  welfare?: WelfareModel;
  mapper = new WelfareDetailMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;
  sensitiveDataVisible = false;

  constructor(private activatedRoute: ActivatedRoute, protected override router: Router, private providerService: ProviderService) {
    super(router, providerService.authService, providerService.utilService);
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        this.id = params['id'];
        this.welfareBackendUrl += `${Constants.welfareApiPath}/${this.id}`;
        await this.getWelfare();
      })
      .unsubscribe();
  }

  async getWelfare() {
    this.loading = true;
    await this.providerService.networkService.get(this.welfareBackendUrl, this.mapper)
    this.welfare = this.mapper.welfare;
    this.loading = false;
  }

  modifyWelfare(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyWelfareComponent,
      data: {
        mode: ModeEnum.MODIFY,
        beSharperId: this.welfare?.besharperId,
        welfare: this.welfare,
      },
      closeCallback: () => this.getWelfare()
    });
  }

  deleteWelfare(): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.welfareBackendUrl,
      closeCallback: () => this.providerService.utilService.navigateBack()
    });
  }
}
