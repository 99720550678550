import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutOutAppComponent } from './components/layout/layout-out-app/layout-out-app.component';
import { LayoutInAppComponent } from './components/layout/layout-in-app/layout-in-app.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { SignInComponent } from './components/pages/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/auth/sign-up/sign-up.component';
import { AuthGuardService } from './core/auth-utils/auth-guard.service';
import { WelfareDetailComponent } from './components/pages/detail-pages/welfare-detail/welfare-detail.component';
import { BonusDetailComponent } from './components/pages/detail-pages/bonus-detail/bonus-detail.component';
import { BeSharpersComponent } from './components/pages/be-sharpers/be-sharpers.component';
import { WelfareComponent } from './components/pages/welfare/welfare.component';
import { ContractsComponent } from './components/pages/contracts/contracts.component';
import { BonusComponent } from './components/pages/bonus/bonus.component';
import { BesharperDetailComponent } from './components/pages/detail-pages/besharper-detail/besharper-detail.component';
import { ContractDetailComponent } from './components/pages/detail-pages/contract-detail/contract-detail.component';
import { OutOfOfficeComponent } from './components/pages/out-of-office/out-of-office.component'
import { SamlCallbackComponent } from './components/pages/auth/saml-callback/saml-callback.component';
import { BesharpItemsComponent } from './components/pages/to-be-refactored/besharp-items/besharp-items.component';
import { BesharperEquipmentComponent } from './components/pages/to-be-refactored/besharper-equipment/besharper-equipment.component';
import { EquipmentDetailComponent } from './components/pages/detail-pages/equipment-detail/equipment-detail.component';
import { AdminPanelComponent } from './components/pages/admin-panel/admin-panel.component';
import { SetWelfareDropdownMenusComponent } from './components/pages/admin-panel/set-welfare-dropdown-menus/set-welfare-dropdown-menus.component';
import { SetBonusDropdownMenusComponent } from './components/pages/admin-panel/set-bonus-dropdown-menus/set-bonus-dropdown-menus.component';
import { SetBesharpDropdownMenusComponent } from './components/pages/admin-panel/set-besharp-dropdown-menus/set-besharp-dropdown-menus.component';
import { SetContractDropdownMenusComponent } from './components/pages/admin-panel/set-contract-dropdown-menus/set-contract-dropdown-menus.component';
import { OutOfOfficeDetailsComponent } from './components/pages/detail-pages/out-of-office-details/out-of-office-details.component';
import { SamlErrorComponent } from './components/pages/auth/saml-error/saml-error.component';
import { SetBusinessUnitDropdownMenusComponent } from "./components/pages/admin-panel/set-business-unit-dropdown-menus/set-business-unit-dropdown-menus.component";
import { SetWelfareReasonMenusComponent } from "./components/pages/admin-panel/set-welfare-reason-menus/set-welfare-reason-menus.component";
import { CronjobComponent } from './components/pages/cronjobs/cronjob.component';
import { SetWelfareMaximalsMenusComponent } from "./components/pages/admin-panel/set-welfare-maximals-menus/set-welfare-maximals-menus.component";
import { RecurrentSmartWorkingComponent } from "./components/pages/recurrent-smart-working/recurrent-smart-working.component";
import { SetEquipmentTypesDropdownMenusComponent } from "./components/pages/admin-panel/set-equipment-types-dropdown-menus/set-equipment-types-dropdown-menus.component";
import { WarehouseComponent } from "./components/pages/warehouse/warehouse.component";
import { ConsumableDetailComponent } from "./components/pages/detail-pages/consumable-detail/consumable-detail.component";
import { SetOutOfOfficeTypesDropdownMenusComponent } from "./components/pages/admin-panel/set-out-of-office-types-dropdown-menus/set-out-of-office-types-dropdown-menus.component";
import { SetSmartWorkingTypesDropdownMenuComponent } from "./components/pages/admin-panel/set-smart-working-types-dropdown-menu/set-smart-working-types-dropdown-menu.component";
import { SetSettingsComponent } from "./components/pages/admin-panel/set-settings/set-settings.component";
import { MissingRequiredInformationComponent } from "./components/pages/missing-required-information/missing-required-information.component";
import { SetUserContractRoleComponent } from "./components/pages/admin-panel/set-user-contract-role/set-user-contract-role.component";
import { SetCustomHolidaysComponent } from './components/pages/admin-panel/set-custom-holidays/set-custom-holidays.component';

const routes: Routes = [
  {
    path: 'auth',
    component: LayoutOutAppComponent,
    children: [
      { path: 'sign-in', component: SignInComponent },
      { path: 'sign-up', component: SignUpComponent },
      { path: 'saml-callback', component: SamlCallbackComponent },
      { path: 'saml-error', component: SamlErrorComponent },
    ],
  },
  {
    path: 'app',
    component: LayoutInAppComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'admin-panel', component: AdminPanelComponent },
      { path: 'admin-panel/set-welfare-dropdown-menus', component: SetWelfareDropdownMenusComponent },
      { path: 'admin-panel/set-bonus-dropdown-menus', component: SetBonusDropdownMenusComponent },
      { path: 'admin-panel/set-besharper-dropdown-menus', component: SetBesharpDropdownMenusComponent },
      { path: 'admin-panel/set-contracts-dropdown-menus', component: SetContractDropdownMenusComponent },
      { path: 'admin-panel/set-business-units-dropdown-menus', component: SetBusinessUnitDropdownMenusComponent },
      { path: 'admin-panel/set-welfare-reason-menus', component: SetWelfareReasonMenusComponent },
      { path: 'admin-panel/set-welfare-maximals-menus', component: SetWelfareMaximalsMenusComponent },
      { path: 'admin-panel/set-equipment-types-dropdown-menus', component: SetEquipmentTypesDropdownMenusComponent },
      { path: 'admin-panel/set-out-of-office-types-dropdown-menus', component: SetOutOfOfficeTypesDropdownMenusComponent },
      { path: 'admin-panel/set-smart-working-types-dropdown-menus', component: SetSmartWorkingTypesDropdownMenuComponent },
      { path: 'admin-panel/set-settings', component: SetSettingsComponent },
      { path: 'admin-panel/set-user-contract-role', component: SetUserContractRoleComponent },
      { path: 'admin-panel/set-custom-holidays', component: SetCustomHolidaysComponent },

      { path: 'besharpers', component: BeSharpersComponent },
      { path: 'welfare', component: WelfareComponent },
      { path: 'bonus', component: BonusComponent },
      { path: 'contracts', component: ContractsComponent },
      { path: 'items', component: BesharpItemsComponent },
      { path: 'equipment', component: BesharperEquipmentComponent },
      { path: 'out-of-office', component: OutOfOfficeComponent },
      { path: 'recurrent-smart-working', component: RecurrentSmartWorkingComponent },
      { path: 'cronjob', component: CronjobComponent },
      // { path: 'warehouse', component: WarehouseComponent },

      { path: 'besharper-detail/:id', component: BesharperDetailComponent },
      { path: 'contract-detail/:id', component: ContractDetailComponent },
      { path: 'welfare-detail/:id', component: WelfareDetailComponent },
      { path: 'bonus-detail/:id', component: BonusDetailComponent },
      // { path: 'equipment-detail/:id', component: EquipmentDetailComponent },
      // { path: 'consumable-detail/:id', component: ConsumableDetailComponent },
      { path: 'out-of-office-detail/:id', component: OutOfOfficeDetailsComponent },
    ],
  },
  { path: 'app', component: LayoutInAppComponent, children: [{ path: 'missing-required-information', component: MissingRequiredInformationComponent }] },
  { path: '', redirectTo: '/app/out-of-office', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
