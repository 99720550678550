import { Injectable } from '@angular/core';
import { ProviderService } from '../../provider.service';
import { OrderEnum } from '../../../models/filter.model';
import { IListMapper, IMapper } from '../../../models/i-mapper';
import { BesharperState } from "../../constants/enums";

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private totalCount = 0;
  constructor(private providerService: ProviderService) { }

  getTotalCount(): number {
    return this.totalCount;
  }

  setTotalCount(total: number): void {
    this.totalCount = total;
  }

  async find(
    url: string,
    filter = '',
    daysOfTheWeek = '00000',
    activeCeasedStatus = BesharperState.ALL,
    mobileWindow = false,
    onlyMe = false,
    sortItem = '',
    sortOrder: OrderEnum = OrderEnum.asc,
    pageNumber = 0,
    pageSize = 3,
    mapper: IMapper,
    role?: string
  ): Promise<IListMapper> {
    const params: {
      activeCeasedStatus?: BesharperState,
      mobileWindow?: string;
      onlyMe: string;
      daysOfTheWeek?: string;
      filter?: string;
      offset: string;
      limit: string;
      orderBy?: string;
      order: string;
      role?: string;
    } = {
      offset: pageNumber?.toString(),
      limit: pageSize?.toString(),
      daysOfTheWeek: daysOfTheWeek?.toString(),
      activeCeasedStatus: activeCeasedStatus,
      mobileWindow: mobileWindow?.toString(),
      onlyMe: onlyMe?.toString(),
      order: sortOrder?.toString(),
      filter: filter,
      orderBy: sortItem,
      role: role,
    };

    if (filter === '') {
      delete params['filter'];
    }
    if (sortItem === '') {
      delete params['orderBy'];
    }

    try {
      const result = await this.providerService.networkService.get(url, mapper, params);
      this.totalCount = result.count;
      return result;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
}
