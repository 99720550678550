import { Attribute, Component, EventEmitter, Input, OnInit, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimePickerComponent),
    multi: true
  }]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {
  @Input()
  readonly: boolean = false;

  @Input()
  label: string = 'timepicker';

  @Input()
  minuteStep?: number;

  @Input()
  set time(value: string | null) {
    this.setTime(value);
  }

  @Output()
  timeChange = new EventEmitter<string | null>();

  hours: string | null = null;
  minutes: string | null = null;

  hourArray: string[] = [];
  minuteArray: string[] = [];

  private onChange: (value: string | null) => void = () => { };
  private onTouched: () => void = () => { };

  ngOnInit() {
    this.hourArray = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    this.minuteArray = Array.from({ length: 60 }, (_, i) => i)
      .filter(i => i % (this.minuteStep || 1) === 0)
      .map(i => i.toString().padStart(2, '0'));
  }

  setTime(time: string | null) {
    if (time === null) {
      this.minutes = null;
      this.hours = null;
    }
    else {
      const hours = time.split(':')[0].padStart(2, '0');
      const minutes = time.split(':')[1].padStart(2, '0');

      const hoursAreInRange = this.hourArray.includes(hours);
      const minutesAreInRange = this.minuteArray.includes(minutes);

      if (hoursAreInRange && minutesAreInRange) {
        this.hours = hours;
        this.minutes = minutes;
      }
    }
  }

  emitTime() {
    console.log("Emitting time")
    if (this.hours !== null && this.minutes !== null) {
      const value = `${this.hours}:${this.minutes}`;
      this.timeChange.emit(value);
      this.onChange(value);
    }
    else {
      this.timeChange.emit(null);
      this.onChange(null);
    }
  }

  writeValue(value: string | null): void {
    this.setTime(value);
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  forceChange() {
    this.emitTime();
    this.onTouched();
  }
}
