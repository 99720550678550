<div class="align-right">
  <button mat-raised-button color="primary" (click)="addContract();"
    *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <i class="fa-solid fa-file-contract"></i>
    &nbsp;
    <span>{{eTextStrings.CONTRACT_CREATE}}</span>
  </button>
</div>

<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-solid fa-file-contract"></i>
    <h1>{{eTextStrings.PAGES_CONTRACTS}}</h1>
  </div>

  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.CONTRACT_SEARCH}}</mat-label>
      <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
      <mat-hint>
        <!--                {{'AREAS.TYPE_YOUR_FULL_TEXT_QUERY'}}-->
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<div class="v-centered-row justify-end">
  <app-eye-toggle [(visible)]="sensitiveDataVisible" />
</div>

<div *ngIf="loading">
  <app-loader></app-loader>
</div>

<app-table-ui *ngIf="dataSource" [datasource]="dataSource" [backendUrl]="backendUrl" [defaultActive]="'contractEndDate'"
  [defaultDirection]="'desc'" [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
  [pageSize]="10" [mapper]="listContractMapper" [pageSizeOptions]="[10,20,30]" [filterObservable]="filterObservable"
  [hasRowAction]=true (rowClicked)="rowClicked($event);">
</app-table-ui>