import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { TextStrings } from "../../constants/text-strings";
import { MatButton } from "@angular/material/button"

@Component({
  selector: 'app-eye-toggle',
  templateUrl: './eye-toggle.component.html',
  styleUrls: ['./eye-toggle.component.scss']
})
export class EyeToggleComponent implements OnChanges {
  protected eTextStrings = TextStrings

  @Input()
  visible: boolean = false;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visible']) {
      this.visible = changes['visible'].currentValue;
    }
  }

  toggleVisibility(): void {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }
}