import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { ConsumableModel } from '../models/consumable.model';

export class ListConsumableMapper implements IListMapper {
  elements: ConsumableModel[] = [];
  count = 0;

  fillFromJson(json: any): PagedModel<ConsumableModel> {
    this.elements = [];
    json['items'].forEach((item: any) => {
      this.elements.push(this.mapItem(item));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return '';
  }

  toString(): string {
    return '';
  }

  private mapItem(item: any): ConsumableModel {
    return {
      alarm: item['alarm'],
      alarmMinQty: item['alarmMinQty'],
      name: item['name'],
      description: item['description'],
      customAlarmEmail: item['customAlarmEmail'],
      qty: item['qty'],
      id: item['id']
    };
  }
}

export class ConsumableDetailMapper implements IMapper {
  consumable?: ConsumableModel;
  fillFromJson(item: any): ConsumableModel {
    this.consumable = {
      alarm: item['alarm'],
      alarmMinQty: item['alarmMinQty'],
      name: item['name'],
      description: item['description'],
      customAlarmEmail: item['customAlarmEmail'],
      qty: item['qty'],
      id: item['id']
    };

    return this.consumable;
  }

  fillToJson(): string {
    return '';
  }
}

export class CreateConsumableMapper implements IMapper {
  body = {};

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }

  toString(): string {
    return '';
  }
}
