import { Component, OnInit } from "@angular/core";
import { TextStrings } from "../../../../core/constants/text-strings";
import { ProviderService } from "../../../../core/provider.service";
import { SettingModel } from "../../../../models/setting-model";
import { Constants } from "../../../../core/constants/constants";
import { SettingPostMapper, SettingsListMapper } from "../../../../mappers/settings.mapper";
import { SimpleMapper } from "../../../../mappers/simple.mapper";
import { LogLevel } from "../../../../models/log-level";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";

/*
TODO: @alexg at the moment I prepared a simple check for settings value here, if the settings table become bigger,
 I would suggest moving the type value in the table itself, similar to the equipment metadata.
 Now for simplicity is here because we only have one parameter.
 */
export const settingsValueTypeMap: { [key: string] : string } = {
 "max_consecutive_smart_working_days_for_warning": "number"
}

@Component({
  selector: 'app-set-settings',
  templateUrl: './set-settings.component.html',
  styleUrl: './set-settings.component.scss'
})
export class SetSettingsComponent implements OnInit {

  private settingsListUrl = environment.cognito.apiEndpoint +  Constants.settingsApiPath;
  protected readonly eTextStrings = TextStrings;
  protected settings: SettingModel[] = [];
  protected loading = false;
  form = new FormGroup({});

  constructor(private providerService: ProviderService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.settings = (await this.providerService.networkService.get(this.settingsListUrl, new SettingsListMapper())).elements;
    console.log(this.settings);
    for(const setting of this.settings) {
      (this.form.controls as any)[setting.name] = new FormControl(setting.value, Validators.required);
    }
    this.form.markAllAsTouched();
    this.loading = false;
  }

  async modifySetting(settingId: string, settingName: string): Promise<void> {
    const formField = ((this.form.controls as {[key:string]: FormControl})[settingName] as FormControl);
    let validFormat = true;
    if(settingsValueTypeMap[settingName] === "number" && formField && isNaN(formField.value)) {
      validFormat = false;
    }
    if(settingsValueTypeMap[settingName] === "number" && formField && formField.value < 0) {
      validFormat = false;
    }
    if(formField && formField.valid && validFormat) {
      try {
        const postMapper = new SettingPostMapper();
        postMapper.fillFromJson({
          id: settingId,
          name: settingName,
          value: formField.value
        });

        await this.providerService.networkService.put(this.settingsListUrl + "/" + settingId, postMapper, new SimpleMapper());
        this.providerService.utilService.showMessage("Settaggio modificato correttamente", LogLevel.success);
      } catch (error) {
        throw error;
      }
    } else {
      this.providerService.utilService.showMessage("Il parametro inserito per il campo: " + settingName + " non è valido. Per favore ricontrolla.", LogLevel.warning);
    }
  }
}
