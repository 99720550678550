import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../../environments/environment";
import { ProviderService } from "../../../../core/provider.service";
import { AuthUtilsService } from "../../../../core/auth-utils/auth-utils.service";
import { LogLevel } from "../../../../models/log-level";
import RoleEnum from "../../../../models/role.enum";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent {
  loading: boolean;
  showPassword = false;

  constructor(
    private router: Router,
    private providerService: ProviderService,
    private authUtilsService: AuthUtilsService,
  ) {
    this.loading = false;
  }

  async login(): Promise<void> {
    if (environment.local) {
      // @ts-ignore
      const developerCredentials = await import("../../../../../../developer-credentials.json");
      const credentials = await this.authUtilsService.getLocalCredentials(environment.cognito.principalId, developerCredentials);
      if (credentials) {
        await this.authUtilsService.saveCredentialsInCookies(credentials!);
        await this.authUtilsService.setLocalMockedUser("NOT_USED", "a8cadcd6-3e21-44e4-ac92-61f89b9671b1");

        const userRole = this.providerService.authService.getUser().role;
        const userIsAdminOrHr = [RoleEnum.HR, RoleEnum.HR_REPORTS, RoleEnum.ADMIN].includes(userRole);

        if (userIsAdminOrHr) {
          await this.router.navigate(["/", "app", "besharpers"]).then();
        }
        else {
          await this.router.navigate(["/", "app", "out-of-office"]).then();
        }
      } else {
        this.providerService.utilService.showMessage("Missing local credentials for development; please verify your environment.", LogLevel.error);
      }
    } else {
      this.changeHref(environment.cognito.ssoUrl);
    }
  }

  private changeHref(href: string) {
    window.location.href = href;
  }
}
