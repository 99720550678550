<div class="align-right">
  <button mat-raised-button color="primary" (click)="createBesharper();" *ngIf="userRole === eRoleEnum.HR || userRole === eRoleEnum.ADMIN">
    <i class="fa-solid fa-user"></i>
    &nbsp;
    <span>{{eTextStrings.BESHARPER_CREATE}}</span>
  </button>
</div>

<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-solid fa-user"></i>
    <h1>{{eTextStrings.BESHARPER_EMPTY}}</h1>
  </div>

  <div class="filters">
    <div class="active-ceased-container">
      <mat-form-field appearance="fill">
        <mat-label>Filtra per stato beSharper</mat-label>
        <mat-select (selectionChange)="sendActiveCeasedStatusQuery($event)" [(ngModel)]="selectedBesharperStatusFilter">
          <mat-option [value]="s.id" *ngFor="let s of beSharperStateI18nMap">{{s.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="table-search-form-container">
      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.BESHARPER_SEARCH}}</mat-label>
        <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
        <i class="fa-regular fa-magnifying-glass" matSuffix></i>
        <mat-hint>
          <!--                {{'AREAS.TYPE_YOUR_FULL_TEXT_QUERY'}}-->
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</div>

<app-table-ui
  [backendUrl]="backendUrl"
  [datasource]="dataSource"
  [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
  [pageSize]="10"
  [pageSizeOptions]="[10,20,30]"
  [filterObservable]="filterObservable"
  [activeCeasedStatusObservable]="activeCeasedStatusObservable"
  [mapper]="besharperMapper"
  [hasRowAction]=true
  (rowClicked)="rowClicked($event);"
>

</app-table-ui>
