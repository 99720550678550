import { Component } from '@angular/core';
import {IFilteredTablePage} from "../../../../core/interfaces/i-filtered-table-page";
import {environment} from "../../../../../environments/environment";
import {Constants} from "../../../../core/constants/constants";
import {ListConsumableMapper} from "../../../../mappers/consumable.mapper";
import {TableDataSource} from "../../../../core/form-utils/table/table.datasource";

import {TextStrings} from "../../../../core/constants/text-strings";
import RoleEnum from "../../../../models/role.enum";
import {ProviderService} from "../../../../core/provider.service";
import {Router} from "@angular/router";
import {ModeEnum} from "../../../../core/constants/enums";
import {CreateModifyBesharperConsumableComponent} from "../../../dialogs/create-modify-besharper-consumable/create-modify-besharper-consumable.component";
import {ConsumableModel} from "../../../../models/consumable.model";

@Component({
  selector: 'app-consumable',
  templateUrl: './consumable.component.html',
  styleUrl: './consumable.component.scss'
})
export class ConsumableComponent extends IFilteredTablePage {
  consumableBackendUrl = environment.cognito.apiEndpoint + Constants.consumableApiPath;
  consumableMapper = new ListConsumableMapper();

  consumableDataSource: TableDataSource<ConsumableModel> = new TableDataSource<ConsumableModel>();
  loading = false;
  showTable = false;

  consumableTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'description', title: 'Descrizione', sortable: true },
    { def: 'qty', title: 'Q.tà', sortable: true },
    { def: 'alarm', title: 'Allarme Q.tà minima attivo', sortable: true },
    { def: 'alarmMinQty', title: 'Q.tà minima', sortable: true },
    { def: 'customAlarmEmail', title: 'Email Custom per gli avvisi', sortable: true },
  ];

  consumableActionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: TextStrings.MODIFY,
      styleClass: 'table-action-modify',
      callback: (consumableData: ConsumableModel) => {
        this.openConsumableModifyDialog(consumableData);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.DELETE,
      styleClass: 'table-action-delete',
      callback: (row: any) => {
        this.deleteConsumableRequest(row);
      },
    }
  ];

  eTextStrings = TextStrings;
  eRoleEnum = RoleEnum;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);

    this.detailPage = 'consumable-detail';
    this.showTable = true;
  }


  createConsumable() {
    this.providerService.utilService
      .openCrudDialog({
        data: {
          mode: ModeEnum.CREATE,
        },
        template: CreateModifyBesharperConsumableComponent,
        page: this,
        closeCallback: () => {}
      });
  }

  private openConsumableModifyDialog(consumableData: ConsumableModel) {
    this.providerService.utilService.openCrudDialog({
      data: {
        mode: ModeEnum.MODIFY,
        consumable: consumableData,
      },
      template: CreateModifyBesharperConsumableComponent,
      page: this,
    });
  }

  private deleteConsumableRequest(consumable: ConsumableModel) {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: environment.cognito.apiEndpoint + Constants.consumableApiPath + `/${consumable.id}`,
    });
  }
}
