<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title" *ngIf="isModifyMode()">
      {{eTextStrings.MODIFY}}{{' '}}{{eTextStrings.WELFARE_MAXIMALS_TITLE}}
    </h1>
    <h1 class="create-modify-title" *ngIf="!isModifyMode()">
      {{eTextStrings.CREATE}}{{' '}}{{eTextStrings.WELFARE_MAXIMALS_TITLE}}
    </h1>
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.WELFARE_MAXIMALS_VALUE}}</mat-label>
        <input matInput placeholder="{{eTextStrings.WELFARE_MAXIMALS_VALUE_PLACEHOLDER}}" formControlName="value" type="number">
        <app-validator-ui [form]="form" [name]="'value'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.REQUIRED}}"></app-validator-ui>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.WELFARE_MAXIMALS_FAMILY}}</mat-label>
        <mat-select formControlName="family">
          <mat-option [value]="w.value" *ngFor="let w of [{value: true, name: 'TRUE'}, {value: false, name: 'FALSE'}]">{{w.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="create-modify-button">
        <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" disabled="{{form.invalid || loading}}" mat-raised-button (click)="sendRequest()">{{eTextStrings.CONFIRM}}</button>
        <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
      </div>
    </form>
  </div>
</div>
