// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const environment = {
  local: false,
  cognito: {
    ssoUrl: 'https://accounts.google.com/o/saml2/initsso?idpid=C03eqis8s&spid=506174109973&forceauthn=false',
    samlProvider: 'arn:aws:iam::767397776443:saml-provider/dev-besharp-intranet-backend-google-saml-provider',
    cognitoRegion: 'eu-west-1',
    apiEndpoint: 'https://api.dev.intranet.besharp.it/',
    identityPoolId: 'eu-west-1:06c4da04-b759-44d3-84df-75300d41d8c5',

    principalId: 'NOT-USED',
    developerProvider: 'NOT-USED',
    developerId: 'NOT-USED',
    customRoleArn: 'NOT-USED',
    cognitoIdentityUrl: 'NOT-USED'
  },
  imageBucket: {
    name: 'dev-besharp-intranet-backend-items-photos',
    region: 'eu-west-1',
  }
};

export { environment };
