<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <div>
      <h1>{{eTextStrings.NEW_SETUP_TITLE}}</h1>
      <h3>{{eTextStrings.NEW_SETUP_SUBTITLE}}</h3>
    </div>

  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse()" (closed)="toggleCollapse()" [expanded]="true">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.SETUP_HEADER_TITLE}}
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="userContractRoleDataModel"
        [backendUrl]="userContractRoleBackendUrl"
        [tableDefinitions]="userContractRoleTableDefinitions"
        [actionDefinitions]="userContractRoleActionsDefinitions"
        [pageSize]="10"
        [pageSizeOptions]="[10, 20, 30]"
        [filterObservable]="filterObservable"
        [mapper]="userContractRoleMapper"
        [showPaginator]=true
        [defaultActive]="'createdAt'"
        (rowClicked)="openModifyDialog($event)"
        [hasRowAction]=true>
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>




