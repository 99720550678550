import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IMapper } from '../../../models/i-mapper';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import { SmartWhyMapper } from "../../../mappers/smart-why.mapper";

@Component({
  selector: 'app-admin-panel-create-modify-smart-working-type',
  templateUrl: './admin-panel-create-modify-smart-working-type.component.html',
  styleUrl: './admin-panel-create-modify-smart-working-type.component.scss'
})
export class AdminPanelCreateModifySmartWorkingTypeComponent {
  loading = false;
  formMode = ModeEnum.CREATE;
  dropDownName = '';

  modifySmartWorkingTypeUrl = environment.cognito.apiEndpoint + Constants.smartWorkingTypeApiPath;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  eTextStrings = TextStrings;
  protected readonly ModeEnum = ModeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifySmartWorkingTypeComponent>
  ) {
    this.formMode = this.data.mode;
    if (data.mode === ModeEnum.MODIFY) {
      this.fillControls();
    }
  }

  async sendRequest(): Promise<void> {
    if (this.formMode === ModeEnum.MODIFY) {
      await this.sendModifyRequest();
    } else {
      await this.sendCreateRequest();
    }
  }

  async deleteItem(): Promise<void> {
    this.providerService.utilService.openDialog(ConfirmDeleteComponent, { url: `${this.modifySmartWorkingTypeUrl}/${this.data.data.id}`})
      .afterClosed()
      .subscribe(() => {
        document.location.reload();
      });
  }

  async sendCreateRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();
    this.loading = true;
    const createRequestMapper = new SmartWhyMapper();
    this.fillRequestBody(createRequestMapper);
    this.providerService.networkService
      .post(this.modifySmartWorkingTypeUrl, createRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });

  }

  async sendModifyRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();

    this.loading = true;
    const updateRequestMapper = new SmartWhyMapper();
    this.fillRequestBody(updateRequestMapper);
    this.providerService.networkService
      .put(`${this.modifySmartWorkingTypeUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });
  }
  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
    });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
  }
}

