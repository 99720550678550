import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ValidatorUiComponent } from './form-utils/validators/validator-ui.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TableUiComponent } from './form-utils/table/table-ui/table-ui.component';
import { HttpClientModule } from '@angular/common/http';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TimePickerComponent } from "./form-utils/time-picker/time-picker.component";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { EyeToggleComponent } from './form-utils/eye-toggle/eye-toggle.component';

@NgModule({
  declarations: [
    ValidatorUiComponent,
    TableUiComponent,
    TimePickerComponent,
    EyeToggleComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatTooltipModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgOptimizedImage,
    NgxJsonViewerModule
  ],
  exports: [ValidatorUiComponent, TableUiComponent, TimePickerComponent, EyeToggleComponent],
})
export class CoreModule { }
