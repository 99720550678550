<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title" *ngIf="isModifyMode()">
      {{eTextStrings.MODIFY}}{{' '}} Business Unit
    </h1>
    <h1 class="create-modify-title" *ngIf="!isModifyMode()">
      {{eTextStrings.CREATE}}{{' '}} Business Unit
    </h1>
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="inserisci il nome della business unit" formControlName="name">
      </mat-form-field>

      <div class="create-modify-button">
        <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" disabled="{{form.invalid || loading}}" mat-raised-button (click)="sendRequest()">{{eTextStrings.CONFIRM}}</button>
        <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
      </div>
    </form>
  </div>
</div>
