import { NgModule } from '@angular/core';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { SignInComponent } from './pages/auth/sign-in/sign-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SignUpComponent } from './pages/auth/sign-up/sign-up.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OutOfOfficeDetailsComponent } from './pages/detail-pages/out-of-office-details/out-of-office-details.component';
import { EquipmentDetailComponent } from './pages/detail-pages/equipment-detail/equipment-detail.component';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CoreModule } from '../core/core.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio'
import { MatChipsModule } from '@angular/material/chips'

import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangePicker
} from "@angular/material/datepicker";
import { BonusComponent } from './pages/bonus/bonus.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { BeSharpersComponent } from './pages/be-sharpers/be-sharpers.component';
import { WelfareComponent } from './pages/welfare/welfare.component';
import { CronjobComponent } from './pages/cronjobs/cronjob.component';
import { RouterLink } from '@angular/router';
import { BesharperDetailComponent } from './pages/detail-pages/besharper-detail/besharper-detail.component';
import { ContractDetailComponent } from './pages/detail-pages/contract-detail/contract-detail.component';
import { CreateModifyBesharperComponent } from './dialogs/create-modify-besharper/create-modify-besharper.component';
import { CreateModifyContractComponent } from './dialogs/create-modify-contract/create-modify-contract.component';
import { BesharperEquipmentComponent } from './pages/to-be-refactored/besharper-equipment/besharper-equipment.component';
import { CreateModifyBesharperEquipmentComponent } from './dialogs/create-modify-besharper-equipment/create-modify-besharper-equipment.component';
import { LoaderComponent } from './loader/loader.component';
import { CreateModifyOutOfOfficeComponent } from './dialogs/create-modify-out-of-office/create-modify-out-of-office.component';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatNativeDateModule } from "@angular/material/core";
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardModule } from '@angular/material/card';
import { CreateModifyBesharpItemComponent } from './dialogs/create-modify-besharp-item/create-modify-besharp-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateModifyWelfareComponent } from './dialogs/create-modify-welfare/create-modify-welfare.component';
import { CustomSnackBarComponent } from '../core/form-utils/custom-snack-bar/custom-snack-bar.component';
import { CreateModifyBonusComponent } from './dialogs/create-modify-bonus/create-modify-bonus.component';
import { WelfareDetailComponent } from './pages/detail-pages/welfare-detail/welfare-detail.component';
import { BonusDetailComponent } from './pages/detail-pages/bonus-detail/bonus-detail.component';
import { SamlCallbackComponent } from './pages/auth/saml-callback/saml-callback.component';
import { ConfirmDeleteComponent } from './dialogs/confirm-delete/confirm-delete.component';
import { BesharpItemsComponent } from './pages/to-be-refactored/besharp-items/besharp-items.component';
import { SetContractDropdownMenusComponent } from './pages/admin-panel/set-contract-dropdown-menus/set-contract-dropdown-menus.component';
import { SetBonusDropdownMenusComponent } from './pages/admin-panel/set-bonus-dropdown-menus/set-bonus-dropdown-menus.component';
import { SetWelfareDropdownMenusComponent } from './pages/admin-panel/set-welfare-dropdown-menus/set-welfare-dropdown-menus.component';
import { SetBesharpDropdownMenusComponent } from './pages/admin-panel/set-besharp-dropdown-menus/set-besharp-dropdown-menus.component';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AdminPanelComponent } from './pages/admin-panel/admin-panel.component';
import { AdminPanelCreateModifyContractComponent } from './dialogs/admin-panel-create-modify-contract/admin-panel-create-modify-contract.component';
import { AdminPanelCreateModifyWelfareComponent } from './dialogs/admin-panel-create-modify-welfare/admin-panel-create-modify-welfare.component';
import { AdminPanelCreateModifyBesharperComponent } from './dialogs/admin-panel-create-modify-besharper/admin-panel-create-modify-besharper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { OutOfOfficeComponent } from "./pages/out-of-office/out-of-office.component";
import { SamlErrorComponent } from './pages/auth/saml-error/saml-error.component';
import { OutOfOfficeReportComponent } from "./dialogs/out-of-office-report/out-of-office-report.component";
import { SetBusinessUnitDropdownMenusComponent } from "./pages/admin-panel/set-business-unit-dropdown-menus/set-business-unit-dropdown-menus.component";
import { AdminPanelCreateModifyBusinessUnitComponent } from "./dialogs/admin-panel-create-modify-business-unit/admin-panel-create-modify-business-unit.component";
import { WelfareReportComponent } from "./dialogs/welfare-report/welfare-report.component";
import { SetWelfareReasonMenusComponent } from "./pages/admin-panel/set-welfare-reason-menus/set-welfare-reason-menus.component";
import { AdminPanelCreateModifyWelfareReasonComponent } from "./dialogs/admin-panel-create-modify-welfare-reason/admin-panel-create-modify-welfare-reason.component";
import { CreateModifyCronjobComponent } from './dialogs/create-modify-cronjob/create-modify-cronjob.component';
import { LogMessageArgsFormComponent } from './dialogs/create-modify-cronjob/args-forms/log-message-args-form/log-message-args-form.component';
import { SendEmailArgsFormComponent } from './dialogs/create-modify-cronjob/args-forms/send-email-args-form/send-email-args-form.component';
import { RemindOooArgsFormComponent } from './dialogs/create-modify-cronjob/args-forms/remind-ooo-args-form/remind-ooo-args-form.component';
import { NotifyFailedIntegrationsArgsFormComponent } from './dialogs/create-modify-cronjob/args-forms/notify-failed-integration-args-form/notify-failed-integrations-args-form.component';
import { EmailChipsComponent } from './dialogs/create-modify-cronjob/utils/email-chips/email-chips.component';
import { CustomMaximalComponent } from "./dialogs/custom-maximal/custom-maximal.component";
import { SetWelfareMaximalsMenusComponent } from "./pages/admin-panel/set-welfare-maximals-menus/set-welfare-maximals-menus.component";
import { AdminPanelCreateModifyWelfareMaximalsComponent } from "./dialogs/admin-panel-create-modify-welfare-maximals/admin-panel-create-modify-welfare-maximals.component";
import { CreateModifyRecurrentOooComponent } from "./dialogs/create-modify-recurrent-ooo/create-modify-recurrent-ooo.component";
import { RecurrentSmartWorkingComponent } from "./pages/recurrent-smart-working/recurrent-smart-working.component";
import { SetEquipmentTypesDropdownMenusComponent } from "./pages/admin-panel/set-equipment-types-dropdown-menus/set-equipment-types-dropdown-menus.component";
import { AdminPanelCreateModifyEquipmentTypeComponent } from "./dialogs/admin-panel-create-modify-equipmen-type/admin-panel-create-modify-equipment-type.component";
import { WarehouseComponent } from "./pages/warehouse/warehouse.component";
import { EquipmentComponent } from "./pages/warehouse/equipment/equipment.component";
import { ConsumableComponent } from "./pages/warehouse/consumable/consumable.component";
import { CreateModifyBesharperConsumableComponent } from "./dialogs/create-modify-besharper-consumable/create-modify-besharper-consumable.component";
import { NgxJsonViewerModule } from "ngx-json-viewer";
import { ConsumableDetailComponent } from "./pages/detail-pages/consumable-detail/consumable-detail.component";
import { NotifyExpiringConsumablesArgsFormComponent } from './dialogs/create-modify-cronjob/args-forms/notify-expiring-consumables-args-form/notify-expiring-consumables-args-form.component';
import { SetOutOfOfficeTypesDropdownMenusComponent } from "./pages/admin-panel/set-out-of-office-types-dropdown-menus/set-out-of-office-types-dropdown-menus.component";
import { AdminPanelCreateModifyOutOfOfficeTypeComponent } from "./dialogs/admin-panel-create-modify-out-of-office-type/admin-panel-create-modify-out-of-office-type.component";
import { SetSmartWorkingTypesDropdownMenuComponent } from "./pages/admin-panel/set-smart-working-types-dropdown-menu/set-smart-working-types-dropdown-menu.component";
import { AdminPanelCreateModifySmartWorkingTypeComponent } from "./dialogs/admin-panel-create-modify-smart-working-type/admin-panel-create-modify-smart-working-type.component";
import { SetSettingsComponent } from "./pages/admin-panel/set-settings/set-settings.component";
import { MissingRequiredInformationComponent } from "./pages/missing-required-information/missing-required-information.component";
import { SetUserContractRoleComponent } from "./pages/admin-panel/set-user-contract-role/set-user-contract-role.component";
import { AdminPanelModifyUserContractRoleComponent } from "./dialogs/admin-panel-modify-user-contract-role/admin-panel-modify-user-contract-role.component";
import { DialogBoxComponent } from "./dialogs/dialog-box/dialog-box.component";
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { DropzoneMaterialModule } from "@ngx-dropzone/material";
import { DropzoneCdkModule } from "@ngx-dropzone/cdk";
import { SetCustomHolidaysComponent } from './pages/admin-panel/set-custom-holidays/set-custom-holidays.component';
import { AdminPanelCreateCustomHolidayComponent } from './dialogs/admin-panel-create-modify-custom-holiday/admin-panel-create-custom-holiday.component';

@NgModule({
  declarations: [
    AdminPanelCreateModifyBusinessUnitComponent,
    AdminPanelCreateModifyWelfareReasonComponent,
    AdminPanelCreateModifyWelfareMaximalsComponent,
    SetBusinessUnitDropdownMenusComponent,
    NotFoundComponent,
    SignInComponent,
    SignUpComponent,
    CustomSnackBarComponent,
    NavbarComponent,
    EquipmentDetailComponent,
    SetBesharpDropdownMenusComponent,
    SetWelfareMaximalsMenusComponent,
    OutOfOfficeDetailsComponent,
    SetWelfareDropdownMenusComponent,
    SetBonusDropdownMenusComponent,
    SetContractDropdownMenusComponent,
    SetCustomHolidaysComponent,
    SetWelfareReasonMenusComponent,
    SidebarComponent,
    BonusComponent,
    ContractsComponent,
    BeSharpersComponent,
    WelfareComponent,
    BesharperDetailComponent,
    ContractDetailComponent,
    AdminPanelComponent,
    WelfareDetailComponent,
    CreateModifyBesharpItemComponent,
    AdminPanelCreateModifyWelfareComponent,
    AdminPanelCreateModifyBesharperComponent,
    AdminPanelCreateModifyContractComponent,
    BesharperEquipmentComponent,
    BonusDetailComponent,
    CreateModifyBesharperEquipmentComponent,
    LoaderComponent,
    CreateModifyBesharperComponent,
    CreateModifyContractComponent,
    CreateModifyOutOfOfficeComponent,
    CreateModifyWelfareComponent,
    BesharpItemsComponent,
    CreateModifyBonusComponent,
    OutOfOfficeComponent,
    SamlCallbackComponent,
    SamlErrorComponent,
    ConfirmDeleteComponent,
    OutOfOfficeReportComponent,
    AdminPanelCreateModifyContractComponent,
    WelfareReportComponent,
    CronjobComponent,
    CreateModifyCronjobComponent,
    LogMessageArgsFormComponent,
    SendEmailArgsFormComponent,
    RemindOooArgsFormComponent,
    NotifyFailedIntegrationsArgsFormComponent,
    NotifyExpiringConsumablesArgsFormComponent,
    EmailChipsComponent,
    CustomMaximalComponent,
    CreateModifyRecurrentOooComponent,
    RecurrentSmartWorkingComponent,
    SetEquipmentTypesDropdownMenusComponent,
    AdminPanelCreateModifyEquipmentTypeComponent,
    WarehouseComponent,
    EquipmentComponent,
    ConsumableComponent,
    CreateModifyBesharperConsumableComponent,
    ConsumableDetailComponent,
    SetOutOfOfficeTypesDropdownMenusComponent,
    AdminPanelCreateModifyOutOfOfficeTypeComponent,
    SetSmartWorkingTypesDropdownMenuComponent,
    AdminPanelCreateModifySmartWorkingTypeComponent,
    SetSettingsComponent,
    MissingRequiredInformationComponent,
    SetUserContractRoleComponent,
    AdminPanelModifyUserContractRoleComponent,
    DialogBoxComponent,
    AdminPanelCreateCustomHolidayComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatLabel,
    CoreModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    RouterLink,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatOption,
    MatSelect,
    MatCard,
    MatCardModule,
    MatCardContent,
    MatCheckboxModule,
    MatAutocomplete,
    MatAutocompleteTrigger,
    MatCardHeader,
    MatCardActions,
    MatExpansionModule,
    MatRadioModule,
    MatChipsModule,
    NgxJsonViewerModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    DropzoneCdkModule,
    DropzoneMaterialModule,
  ],
  providers: [MatDatepickerModule, MatDateRangePicker],
  exports: [NavbarComponent, SidebarComponent, LoaderComponent],
})
export class ComponentsModule { }
