import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TimePickerComponent } from "../../../core/form-utils/time-picker/time-picker.component";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TextStrings } from "../../../core/constants/text-strings";
import { BeSharperModel } from "../../../models/be-sharper.model";
import { OutOfOfficeModel } from "../../../models/out-of-office.model";
import moment from "moment/moment";
import IBasePage from "../../../core/interfaces/i-base-page";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { Router } from "@angular/router";
import { RequestStatus } from "../../../models/out-of-office.enums";
import RoleEnum from "../../../models/role.enum";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";
import { OutOfOfficeRequestMapper, OutOfOfficeTypeListMapper } from "../../../mappers/out-of-office.mapper";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { OutOfOfficeTypeModel } from "../../../models/out-of-office-type.model";

@Component({
  selector: 'app-create-modify-recurrent-ooo',
  templateUrl: './create-modify-recurrent-ooo.component.html',
  styleUrl: './create-modify-recurrent-ooo.component.scss'
})
export class CreateModifyRecurrentOooComponent extends IBasePage implements OnInit {
  @ViewChild('timePickerStart')
  timePickerStart!: TimePickerComponent;

  @ViewChild('timePickerEnd')
  timePickerEnd!: TimePickerComponent;

  backendUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;

  form = new FormGroup({
    id: new FormControl(''),
    besharper: new FormControl('', Validators.required),
    startingAtDate: new FormControl(new Date(), Validators.required),
    endingAtDate: new FormControl(new Date(), Validators.required),
    reason: new FormControl('', Validators.required)
  });

  startingAtTime: string | null = null;
  endingAtTime: string | null = null;

  loading = false;
  editingExistingItem = false;
  dialogWasOpenedFromDetailsPage = false;

  besharpers: BeSharperModel[] = [];
  outOfOfficeData: OutOfOfficeModel;
  filteredBesharpers: BeSharperModel[] = [];
  outOfOfficeTypes: OutOfOfficeTypeModel[] = [];

  daysOfTheWeek = "00000";

  eTextStrings = TextStrings;
  eMoment = moment;
  smartWorkingType: OutOfOfficeTypeModel | undefined;
  correlationId: string | null;

  constructor(
    public dialogRef: MatDialogRef<CreateModifyRecurrentOooComponent>,
    @Inject(MAT_DIALOG_DATA) data: {
      outOfOfficeData: OutOfOfficeModel | undefined,
      openedFromDetailsPage?: boolean
    },
    public providerService: ProviderService,
    protected override router: Router) {

    super(router, providerService.authService, providerService.utilService);
    this.dialogWasOpenedFromDetailsPage = data.openedFromDetailsPage || false;

    const start = new Date();
    start.setMinutes(30);
    start.setHours(9);
    const end = new Date(new Date().getFullYear(), 11, 31);
    end.setMinutes(30);
    end.setHours(18);

    if (data.outOfOfficeData) {
      this.outOfOfficeData = data.outOfOfficeData;
      // Extra check for null dates (it usually can't happen but in rare occasion this must be addressed)
      this.outOfOfficeData.startDate ||= start.toISOString();
      this.outOfOfficeData.endDate ||= end.toISOString();

      this.editingExistingItem = true;
      this.correlationId = this.outOfOfficeData.correlationId;
      this.daysOfTheWeek = this.outOfOfficeData.daysOfTheWeek;
    } else {
      this.outOfOfficeData = {
        besharperId: providerService.authService.getUser().id,
        createdAt: new Date(),
        updatedAt: new Date(),
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        status: RequestStatus.ApprovedByLineManager,
        id: '',
        description: '',
        besharperName: '',
        besharperSurname: '',
        typeName: '',
        typeId: '',
        smartWorkingReason: '',
        isRecurrent: true,
        correlationId: '',
        daysOfTheWeek: this.daysOfTheWeek,
        approvers: {
          lineManager: null,
          tpm: null,
          supervisor: null
        },
        allowedActions: []
      };
      this.correlationId = null;
    }

  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.besharpers = await this.getBesharpers();
    this.outOfOfficeTypes = await this.getOutOfOfficeTypes();
    this.filteredBesharpers = this.besharpers;

    if (this.outOfOfficeTypes) {
      this.smartWorkingType = this.outOfOfficeTypes.find(oot => oot.name === "Remote Working" || oot.name === "Smart Working");
      console.log(this.smartWorkingType);
    }

    this.populateForm();
    this.loading = false;
  }

  async getOutOfOfficeTypes(): Promise<OutOfOfficeTypeModel[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.outOfOfficeTypeApiPath,
      new OutOfOfficeTypeListMapper(),
      {
        limit: '9999'
      }
    )
    return response.elements;
  }

  checkAndSetSingleDay() {
    console.log("CLOSE: ", this.form.controls.endingAtDate.value?.toString());
    if (
      this.form.controls.endingAtDate.value?.toString() === 'Invalid Date' ||
      !this.form.controls.endingAtDate.value
    ) {
      const startingAtDate = this.form.controls.startingAtDate.value;
      const timestamp = startingAtDate?.valueOf();
      const newEndingAtDate = new Date(timestamp!);
      newEndingAtDate?.setMonth(11);
      newEndingAtDate?.setDate(31);
      this.form.controls.endingAtDate.setValue(newEndingAtDate);
    }
  }

  formValid(): boolean {
    return this.form.valid
      && this.startingAtTime !== null
      && this.endingAtTime !== null
      && this.daysOfTheWeek.includes("1");
  }

  getDisplayNameForBesharper(besharperId: string) {
    const besharper: BeSharperModel = this.filteredBesharpers?.find(b => b.id === besharperId)!;
    return `${besharper?.name ?? ''} ${besharper?.surname ?? ''}`
  }

  isCurrentUserHr(): boolean {
    const role = this.providerService.authService.getUser().role;
    return RoleEnum.HR === role || RoleEnum.ADMIN === role;
  }

  async getBesharpers(): Promise<BeSharperModel[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.besharperApiPath,
      new BeSharperListMapper(),
      { limit: '999999', orderBy: 'surname' }
    )
    response.elements = (this.userRole === RoleEnum.EMPLOYEE) ?
      response.elements.filter((user: BeSharperModel) => user.id === this.currentUser.id) : response.elements;
    return response.elements;
  }

  async submit() {
    if (this.formValid()) {
      this.editingExistingItem ? await this.modifyOutOfOffice() : await this.createOutOfOffice(true)
    }
  }

  async createOutOfOffice(continueEditing?: boolean): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();
    try {
      this.fillRequestBody(requestMapper);
      console.log("Create - RequestMapper: ", requestMapper);
      this.providerService.networkService
        .post(`${this.backendUrl}`, requestMapper, responseMapper)
        .then((response) => {
          if (response.body.googleCalendar) {
            this.providerService.utilService.showMessage('Remote working strutturato creato, ma qualcosa è andato storto nella sincronizzazione: ' + response.body.warning, LogLevel.warning);
          } else {
            this.providerService.utilService.showMessage('Remote working strutturato creato con successo', LogLevel.success);
          }
          this.loading = false;
          this.clearForm();

          if (!continueEditing) {
            this.dialogRef.close();
          }
        })
        .catch((e) => {
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  async modifyOutOfOffice(): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();
    try {
      this.fillRequestBody(requestMapper);
      console.log("Modify - RequestMapper: ", requestMapper);
      this.providerService.networkService
        .put(`${this.backendUrl}/${this.form.controls['id'].value}`, requestMapper, responseMapper)
        .then((response) => {
          if (response.body.googleCalendar) {
            this.providerService.utilService.showMessage('Permesso modificato, ma qualcosa è andato storto nella sincronizzazione: ' + response.body.warning, LogLevel.warning);
          } else {
            this.providerService.utilService.showMessage('Permesso modificato con successo', LogLevel.success);
          }
          this.loading = false;
          this.dialogRef.close();
        })
        .catch((e) => {
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  fillRequestBody(mapper: OutOfOfficeRequestMapper): void {
    const startTime = this.startingAtTime;
    const endTime = this.endingAtTime;

    console.log("Time 1: ", startTime);
    console.log("Time 2: ", endTime);
    console.log("Date 1: ", moment(this.form.controls.startingAtDate.value).toDate());
    console.log("Date 2: ", moment(this.form.controls.endingAtDate.value).toDate());

    const localStartingDate = moment(this.form.controls.startingAtDate.value);
    localStartingDate?.hours(parseInt(startTime!.split(':')[0]));
    localStartingDate?.minutes(parseInt(startTime!.split(':')[1]));

    const localEndingDate = moment(this.form.controls.endingAtDate.value);
    localEndingDate?.hours(parseInt(endTime!.split(':')[0]));
    localEndingDate?.minutes(parseInt(endTime!.split(':')[1]));

    localStartingDate.seconds(0);
    localStartingDate.milliseconds(0);

    localEndingDate.seconds(0);
    localEndingDate.milliseconds(0);

    // Check if dates must be inverted for single day requests
    let finalStartDate = localStartingDate.toISOString(true);
    let finalEndDate = localEndingDate.toISOString(true);

    if (this.checkIfDatesAreTheSame(finalStartDate, finalEndDate) && localStartingDate > localEndingDate) {
      const temp = finalStartDate;
      finalStartDate = finalEndDate;
      finalEndDate = temp;
    }

    const reason = this.form.controls.reason.value;

    mapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      startDate: finalStartDate,
      endDate: finalEndDate,
      smartWorkingReason: reason,
      status: '',
      description: '',
      daysOfTheWeek: this.daysOfTheWeek,
      typeId: this.smartWorkingType!.id,
      isRecurrent: true,
      correlationId: this.correlationId || undefined,
    });
  }

  toggleDay(number: number) {
    const days = this.daysOfTheWeek.split("");
    days[number] = days[number] === "1" ? "0" : "1";
    this.daysOfTheWeek = days.join("");
  }

  checkTimePattern(time: string): string {
    const regex = /^(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (regex.test(time)) {
      return time;
    } else {
      return time + ':00';
    }
  }

  populateForm(): void {
    this.form.controls['id'].setValue(this.outOfOfficeData?.id ?? null);
    this.form.controls['besharper'].setValue(this.outOfOfficeData?.besharperId ?? null);

    const localStartingAt = moment(this.outOfOfficeData.startDate || new Date().toISOString());
    if (localStartingAt.minutes() !== 0 && localStartingAt.minutes() !== 30) {
      localStartingAt.minutes(0);
    }
    console.log(localStartingAt.format("DD/MM/YYYY"));
    console.log(localStartingAt.format("HH:mm"));


    this.form.controls['startingAtDate'].setValue(localStartingAt.toDate());

    this.startingAtTime = localStartingAt.format("HH:mm") ?? '';

    const localEndingAt = moment(this.outOfOfficeData.endDate || new Date().toISOString());
    if (localEndingAt.minutes() !== 0 && localEndingAt.minutes() !== 30) {
      localEndingAt.minutes(0);
    }
    console.log(localEndingAt.format("DD/MM/YYYY"));
    console.log(localEndingAt.format("HH:mm"));

    this.form.controls['endingAtDate'].setValue(localEndingAt.toDate());
    this.endingAtTime = (localEndingAt.format("HH:mm") ?? '');
    this.form.controls['reason'].setValue(this.outOfOfficeData.smartWorkingReason);
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  private clearForm() {
    if (this.isCurrentUserHr()) {
      this.form.controls.besharper.reset();
    }

    this.form.controls.startingAtDate.reset();
    this.startingAtTime = null;
    this.form.controls.endingAtDate.reset();
    this.endingAtTime = null;

    this.timePickerStart.setTime(null);
    this.timePickerEnd.setTime(null);
  }

  private checkIfDatesAreTheSame(finalStartDate: string, finalEndDate: string) {
    return finalStartDate.substring(0, 10) === finalEndDate.substring(0, 10);
  }
}
