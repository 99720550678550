import { Component } from '@angular/core';
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { OutOfOfficeListMapper } from "../../../mappers/out-of-office.mapper";
import { TableDataSource } from "../../../core/form-utils/table/table.datasource";
import { OutOfOfficeModel } from "../../../models/out-of-office.model";
import { TextStrings } from "../../../core/constants/text-strings";
import RoleEnum from "../../../models/role.enum";
import { ProviderService } from "../../../core/provider.service";
import { Router } from "@angular/router";
import { RequestStatus } from "../../../models/out-of-office.enums";
import { OutOfOfficeReportComponent } from "../../dialogs/out-of-office-report/out-of-office-report.component";
import { CreateModifyOutOfOfficeComponent } from "../../dialogs/create-modify-out-of-office/create-modify-out-of-office.component";
import { CreateModifyRecurrentOooComponent } from "../../dialogs/create-modify-recurrent-ooo/create-modify-recurrent-ooo.component";
import { ConfirmDeleteComponent } from "../../dialogs/confirm-delete/confirm-delete.component";
import { LogLevel } from "../../../models/log-level";
import { IFilteredTablePage } from "../../../core/interfaces/i-filtered-table-page";

@Component({
  selector: 'app-recurrent-smart-working',
  templateUrl: './recurrent-smart-working.component.html',
  styleUrl: './recurrent-smart-working.component.scss'
})
export class RecurrentSmartWorkingComponent extends IFilteredTablePage {
  outOfOfficeBackendUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;
  outOfOfficeListUrl = this.outOfOfficeBackendUrl + '/recurrent';
  mapper = new OutOfOfficeListMapper();

  holidaysDataSource: TableDataSource<OutOfOfficeModel> = new TableDataSource<OutOfOfficeModel>();
  loading = false;
  showTable = false;
  daysOfTheWeek = "00000";

  tableDefinitions = [
    { def: 'besharperName', title: TextStrings.NAME, sortable: false },
    { def: 'besharperSurname', title: TextStrings.LASTNAME, sortable: false },
    { def: 'typeName', title: TextStrings.OUT_OF_OFFICE_CREATE_TYPE, sortable: false },
    { def: 'createdAt', title: TextStrings.OUT_OF_OFFICE_CREATED_AT, sortable: true, transformer: (value : string) => this.formatDate(value) },
    { def: 'startDate', title: TextStrings.OUT_OF_OFFICE_CREATE_STARTDATE, sortable: true, transformer: (value : string) => this.formatDate(value).split(" ")[0] },
    { def: 'endDate', title: TextStrings.OUT_OF_OFFICE_CREATE_ENDDATE, sortable: true, transformer: (value : string) => this.formatDate(value).split(" ")[0] },

    { def: 'startTime', title: "Ora inizio", sortable: false, virtual: (cell: any) => this.formatDate(cell.startDate).split(" ")[1] },
    { def: 'endTime', title: "Ora fine", sortable: false, virtual: (cell: any) => this.formatDate(cell.endDate).split(" ")[1] },

    { def: 'daysOfTheWeek', title: TextStrings.DAYS_OF_THE_WEEK, sortable: false, transformer: (value : string) => this.formatDaysOfTheWeek(value) },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pencil-alt',
      text: TextStrings.MODIFY,
      styleClass: 'table-action-modify',
      callback: (row: any) => {
        this.openModifyDialog(row);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.DELETE,
      styleClass: 'table-action-delete',
      callback: (row: any) => {
        this.deleteRequest(row).then();
      },
    }
  ];

  eTextStrings = TextStrings;
  eRoleEnum = RoleEnum;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);

    this.detailPage = 'out-of-office-detail';

    if (this.currentUser.role === RoleEnum.LINE_MANAGER) {
      this.actionDefinitions.splice(2, 1);
      this.actionDefinitions.splice(3, 1);
    }
    if (this.currentUser.role === RoleEnum.HR) {
      this.actionDefinitions.splice(2, 1);
    }
    if (this.currentUser.role === RoleEnum.EMPLOYEE) {
      this.actionDefinitions.splice(0, 2);
      this.actionDefinitions.splice(2, 1);
    }
    if (this.currentUser.role === RoleEnum.HR_REPORTS) {
      this.actionDefinitions.splice(0, 4);
    }

    this.showTable = true;
  }

  enableDisableActionButton(row: any): boolean {
    const userIsHr =
      this.providerService?.authService.getUser().role === RoleEnum.HR ||
      this.providerService?.authService.getUser().role === RoleEnum.HR_REPORTS ||
      this.providerService?.authService.getUser().role === RoleEnum.ADMIN;
    return userIsHr ||
      (
        !userIsHr &&
        (row.status !== RequestStatus.ApprovedByLineManager && row.status !== RequestStatus.DeniedByLineManager) &&
        row.beSharperId === this.providerService?.authService.getUser().id
      );
  }

  async exportTable() {
    if(this.loading) {
      return;
    }
    this.providerService.utilService
      .openDialog(OutOfOfficeReportComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.filterObservable.next(this.lastDebounceValue);
      });
  }

  createOutOfOffice() {
    this.providerService.utilService
      .openCrudDialog({
        data: {},
        template: CreateModifyOutOfOfficeComponent,
        page: this,
        closeCallback: () => {}
      });
  }

  createRecurrentOutOfOffice() {
    this.providerService.utilService
      .openCrudDialog({
        data: {},
        template: CreateModifyRecurrentOooComponent,
        page: this,
        closeCallback: () => {}
      });
  }

  async deleteRequest(ooo: any): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService
        .openDialog(ConfirmDeleteComponent, {
          url: `${this.outOfOfficeBackendUrl}/${ooo['id']}`,
          returnPath: Constants.recurrentSmartPath,
        })
        .afterClosed()
        .subscribe(() => {
          this.filterObservable.next(this.lastDebounceValue);
        });
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  openModifyDialog(outOfOfficeData: OutOfOfficeModel): void {
    this.providerService.utilService.openCrudDialog({
      data: { outOfOfficeData },
      template: CreateModifyRecurrentOooComponent,
      page: this,
    });
  }

  formatDaysOfTheWeek(daysBits: string): string {
    if(daysBits) {
      const map = ["LUN", "MAR", "MER", "GIO", "VEN"];
      const daysArray = daysBits.split("");
      let result = "";
      for(let i = 0; i < daysArray.length; i++) {
        if(daysArray[i] === "1") {
          result += `<span class="days-of-the-week">${map[i]}</span>`;
        }
      }
      return result;
    } else return "-";
  }

  toggleDay(number: number) {
    const days = this.daysOfTheWeek.split("");
    days[number] = days[number] === "1" ? "0"  : "1";
    this.daysOfTheWeek = days.join("");
    this.sendDaysOfWeekQuery(this.daysOfTheWeek);
  }
}
