import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ProviderService } from '../../../core/provider.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IListMapper, IMapper } from '../../../models/i-mapper';
import { LogLevel } from '../../../models/log-level';
import { BeSharperListMapper } from '../../../mappers/besharper.mapper';
import { ContractLevelMapper } from '../../../mappers/contract.mapper';
import { BeSharperModel } from '../../../models/be-sharper.model';
import { Constants } from '../../../core/constants/constants';
import { ContractTypeMapper } from '../../../mappers/contract.mapper';
import { ContractRoleMapper } from '../../../mappers/contract.mapper';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { ModeEnum } from '../../../core/constants/enums';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ContractTypeModel } from "../../../models/contract-type.model";
import { ContractLevelModel } from "../../../models/contract-level.model";
import { ContractRoleModel } from "../../../models/contract-role.model";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { QualificationModel } from "../../../models/qualification.model";
import { BusinessUnitModel } from "../../../models/business-unit.model";
import { ListQualificationMapper } from "../../../mappers/qualification.mapper";
import { BusinessUnitListMapper } from "../../../mappers/business-unit.mapper";

@Component({
  selector: 'app-create-modify-contract',
  templateUrl: './create-modify-contract.component.html',
  styleUrl: './create-modify-contract.component.scss',
})
export class CreateModifyContractComponent implements OnInit, OnDestroy {
  loading = false;
  updating = false;
  mode: ModeEnum = ModeEnum.CREATE;
  contract?: any;
  checked = false;

  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  backendUrlContract = environment.cognito.apiEndpoint + Constants.contractApiPath;
  backendUrlContractTypes = environment.cognito.apiEndpoint + Constants.contractTypesApiPath;
  backendUrlContractLevels = environment.cognito.apiEndpoint + Constants.contractLevelsApiPath;
  backendUrlContractRoles = environment.cognito.apiEndpoint + Constants.contractRolesApiPath;
  backendUrlQualifications = environment.cognito.apiEndpoint + Constants.qualificationsApiPath;
  backendUrlBusinessUnits = environment.cognito.apiEndpoint + Constants.businessUnitsApiPath;

  filteredBesharpers: BeSharperModel[] = [];
  filteredTpmBesharpers: BeSharperModel[] = [];
  filteredLinemanagerBesharpers: BeSharperModel[] = [];
  filteredSupervisorBesharpers: BeSharperModel[] = [];

  selectedBesharper?: BeSharperModel;
  selectedTpmBesharper?: BeSharperModel;
  selectedLinemanagerBesharper?: BeSharperModel;
  selectedSupervisorBesharper?: BeSharperModel;


  besharpers: BeSharperModel[] = [];
  contractTypes: ContractTypeModel[] = [];
  contractLevels: ContractLevelModel[] = [];
  contractPositions: ContractRoleModel[] = [];
  qualifications: QualificationModel[] = [];
  businessUnits: BusinessUnitModel[] = [];

  readonlyBesharperSubject = new BehaviorSubject(false);
  readonlyBesharperSubscription!: Subscription;
  userIsReadonly: boolean = false;

  eTextStrings = TextStrings;

  public form = new FormGroup({
    besharper: new FormControl('', [Validators.required]),
    contractType: new FormControl('', [Validators.required]),
    contractLevel: new FormControl('', [Validators.required]),
    contractStartDate: new FormControl(new Date(), [Validators.required]),
    contractEndDate: new FormControl(new Date()),
    partTime: new FormControl('40', [
      Validators.required,
      Validators.pattern('[0-9]*'),
      Validators.max(40),
      Validators.min(0),
    ]),
    ral: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
    contractRole: new FormControl('', [Validators.required]),
    contractLink: new FormControl(''),
    qualification: new FormControl('', [Validators.required]),
    businessUnit: new FormControl('', [Validators.required]),
    isLineManager: new FormControl(false, [Validators.required]),
    isTpm: new FormControl(false, [Validators.required]),
    isSupervisor: new FormControl(false, [Validators.required]),
    lineManager: new FormControl(''),
    tpm: new FormControl(''),
    supervisor: new FormControl(''),
  });

  isCurrent = false;

  constructor(
    public dialogRef: MatDialogRef<CreateModifyContractComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.bootstrap(data);
  }

  bootstrap(data: any) {
    this.data = data;

    if (this.data['mode'] === ModeEnum.MODIFY.valueOf()) {
      this.mode = ModeEnum.MODIFY;
    } else if (this.data['mode'] === ModeEnum.CREATE.valueOf()) {
      this.mode = ModeEnum.CREATE;
    }
  }

  ngOnDestroy(): void {
    this.readonlyBesharperSubscription?.unsubscribe();
  }

  async getQualifications(): Promise<void> {
    try {
      const mapper: IListMapper = new ListQualificationMapper();
      await this.providerService.networkService.get(this.backendUrlQualifications, mapper, { limit: '999999', offset: '0' })
      this.qualifications = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  async getBusinessUnits(): Promise<void> {
    try {
      const mapper: IListMapper = new BusinessUnitListMapper();
      await this.providerService.networkService.get(this.backendUrlBusinessUnits, mapper, { limit: '999999', offset: '0' })
      this.businessUnits = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    const dataFetchPromises: Promise<void>[] = [
      this.getBesharpers(),
      this.getContractTypes(),
      this.getContractLevels(),
      this.getContractRoles(),
      this.getBusinessUnits(),
      this.getQualifications()
    ]
    await Promise.all(dataFetchPromises);

    this.filteredBesharpers = this.besharpers;
    this.filteredTpmBesharpers = this.besharpers;
    this.filteredLinemanagerBesharpers = this.besharpers;
    this.filteredSupervisorBesharpers = this.besharpers;

    if (this.data['mode'] === 'modify' && this.data['contract'] !== undefined) {
      this.mode = ModeEnum.MODIFY;
      this.contract = this.data['contract'];
      this.populateFormForModify(this.contract);
    } else {
      this.mode = ModeEnum.CREATE;
    }

    this.readonlyBesharperSubscription = this.readonlyBesharperSubject.subscribe((value: boolean) => {
      this.userIsReadonly = value;
    });

    if (this.data.selectedBesharper) {
      this.form.controls.besharper.setValue(this.data.selectedBesharper);
      this.readonlyBesharperSubject.next(true);
    } else {
      this.readonlyBesharperSubject.next(false);
    }
    if (this.data.tpm) {
      this.form.controls.tpm.setValue(this.data.tpm.id);
    }
    if (this.data.lineManager) {
      this.form.controls.lineManager.setValue(this.data.lineManager.id);
    }
    if (this.data.supervisor) {
      this.form.controls.supervisor.setValue(this.data.supervisor.id);
    }

    this.loading = false;
  }

  isReadonlyBesharper(): boolean {
    return !!this.data.selectedBesharper;
  }

  formValid() {
    return this.form.valid;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  displaySelectedBesharper(id: string): string {
    const selectedBesharper = this.besharpers.find((_) => _.id === id);
    return selectedBesharper ? selectedBesharper?.name + ' ' + selectedBesharper?.surname : '';
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  filterTpm(): void {
    if (this.form.controls.tpm.value) {
      const userInput = this.form.controls.tpm?.value?.toLowerCase();
      this.filteredTpmBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredTpmBesharpers = this.besharpers;
    }
  }

  filterLm(): void {
    if (this.form.controls.lineManager.value) {
      const userInput = this.form.controls.lineManager?.value?.toLowerCase();
      this.filteredLinemanagerBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredLinemanagerBesharpers = this.besharpers;
    }
  }

  filterSupervisor(): void {
    if (this.form.controls.supervisor.value) {
      const userInput = this.form.controls.supervisor?.value?.toLowerCase();
      this.filteredSupervisorBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredSupervisorBesharpers = this.besharpers;
    }
  }

  onBesharperSelected(besharper: BeSharperModel): void {
    this.selectedBesharper = besharper;
  }
  onBesharperTpmSelected(besharper: BeSharperModel) {
    this.selectedTpmBesharper = besharper;
  }
  onBesharperLineManagerSelected(besharper: BeSharperModel) {
    this.selectedLinemanagerBesharper = besharper;
  }
  onBesharperSupervisorSelected(besharper: BeSharperModel) {
    this.selectedSupervisorBesharper = besharper;
  }

  async addContract(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const url = environment.cognito.apiEndpoint + Constants.contractApiPath;
    const mapper = new SimpleMapper();
    const postMapper = new SimpleMapper();

    this.fillMapper(postMapper);

    if (this.form.controls.contractStartDate.value && new Date(this.form.controls.contractStartDate.value)?.getTime() > Date.now()) {
      this.providerService.utilService.showMessage(TextStrings.CONTRACT_IN_THE_FUTURE, LogLevel.warning);
      this.loading = false;
      this.updating = false;
      return;
    }

    try {
      await this.providerService.networkService.post(url, postMapper, mapper)

      this.providerService.utilService.showMessage(TextStrings.CONTRACT_CREATE_SUCCESS, LogLevel.success);
      this.loading = false;
      this.form.reset();
      this.isCurrent = false;
      if (!addAnother) {
        this.dialogRef.close();
      } else {
        this.form.controls.besharper.setValue(this.data.selectedBesharper);
        this.form.controls.partTime.setValue('40');
        this.nullifyDateIfCurrent();
      }
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      console.log("Error in creating contract: ", e);
      this.loading = false;
    }
  }

  async getBesharpers(): Promise<void> {
    try {
      const mapper: IListMapper = new BeSharperListMapper();
      await this.providerService.networkService.get(this.backendUrlBesharper, mapper, { limit: '999999', offset: '0', orderBy: 'name' });
      this.besharpers = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  async getContractTypes(): Promise<void> {
    try {
      const mapper: IListMapper = new ContractTypeMapper();
      await this.providerService.networkService.get(this.backendUrlContractTypes, mapper, { limit: '999999', offset: '0' })
      this.contractTypes = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  async getContractLevels(): Promise<void> {
    try {
      const mapper: IListMapper = new ContractLevelMapper();
      await this.providerService.networkService.get(this.backendUrlContractLevels, mapper, { limit: '999999', offset: '0' })
      this.contractLevels = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  async getContractRoles(): Promise<void> {
    try {
      const mapper: IListMapper = new ContractRoleMapper();
      await this.providerService.networkService.get(this.backendUrlContractRoles, mapper, { limit: '999999', offset: '0' })
      this.contractPositions = mapper.elements;
    }
    catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
      this.dialogRef.close();
    }
  }

  fillMapper(mapper: IMapper): void {
    let lineManagerId = this.form.controls.lineManager.value !== "" ? this.form.controls.lineManager.value : null;
    lineManagerId = this.form.controls.isLineManager.value ? null : lineManagerId;

    let tpmId = this.form.controls.tpm.value !== "" ? this.form.controls.tpm.value : null;
    tpmId = this.form.controls.isTpm.value ? null : tpmId;

    let supervisorId = this.form.controls.supervisor.value !== "" ? this.form.controls.supervisor.value : null;
    supervisorId = this.form.controls.isSupervisor.value ? null : supervisorId;



    mapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      contractTypeId: this.form.controls.contractType.value,
      contractLevelId: this.form.controls.contractLevel.value,
      contractStartDate: this.providerService.utilService.formatDate(this.form.controls.contractStartDate.value!),
      contractEndDate: this.providerService.utilService.formatDate(this.form.controls.contractEndDate.value!),
      partTime: parseInt(this.form.controls.partTime.value!),
      ral: parseInt(this.form.controls.ral.value!),
      contractRoleId: this.form.controls.contractRole.value,
      contractLink: this.form.controls.contractLink.value,
      qualificationId: this.form.controls.qualification.value,
      businessUnitId: this.form.controls.businessUnit.value,
      isLineManager: this.form.controls.isLineManager.value,
      isTpm: this.form.controls.isTpm.value,
      lineManagerId: lineManagerId,
      tpmId: tpmId,
      isSupervisor: this.form.controls.isSupervisor.value,
      supervisorId: supervisorId
    });
  }

  async modifyContract(): Promise<void> {
    this.loading = true;
    this.updating = true;

    const putMapper = new SimpleMapper();
    const mapper = new SimpleMapper();

    try {
      this.fillMapper(putMapper);

      if (this.form.controls.contractStartDate.value && new Date(this.form.controls.contractStartDate.value)?.getTime() > Date.now()) {
        this.providerService.utilService.showMessage(TextStrings.CONTRACT_IN_THE_FUTURE, LogLevel.warning);
        this.loading = false;
        this.updating = false;
        return;
      }

      await this.providerService.networkService.put(this.backendUrlContract + '/' + this.contract['id'], putMapper, mapper)
      this.providerService.utilService.showMessage('Contratto modificato con successo', LogLevel.success);
      this.dialogRef.close();
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    } finally {
      this.updating = false;
      this.loading = false;
    }
  }

  private populateFormForModify(contract: any) {
    this.form.controls.besharper.setValue(contract['besharper']['id']);
    this.form.controls.contractType.setValue(contract['contractType']['id']);
    this.form.controls.contractRole.setValue(contract['contractRole']['id']);
    this.form.controls.contractLevel.setValue(contract['contractLevel']['id']);
    this.form.controls.contractStartDate.setValue(new Date(contract['contractStartDate']));

    const endDate: string | null = contract['contractEndDate'];
    this.form.controls.contractEndDate.setValue(endDate === null ? null : new Date(endDate));

    if (endDate === null) {
      this.isCurrent = true;
    }

    this.form.controls.ral.setValue(contract['ral']);
    this.form.controls.partTime.setValue(contract['partTime']);
    this.form.controls.contractLink.setValue(contract['contractLink']);

    this.form.controls.qualification.setValue(contract['qualification']['id']);
    this.form.controls.businessUnit.setValue(contract['businessUnit']['id']);
    this.form.controls.isLineManager.setValue(contract['isLineManager']);
    this.form.controls.isTpm.setValue(contract['isTpm']);
    this.form.controls.lineManager.setValue(contract['lineManager']?.id);
    this.form.controls.tpm.setValue(contract['tpm']?.id);

    this.form.controls.isSupervisor.setValue(contract['isSupervisor']);
    this.form.controls.supervisor.setValue(contract['supervisor']?.id);
  }

  nullifyDateIfCurrent() {
    this.isCurrent = !this.isCurrent;

    if (this.isCurrent) {
      this.form.controls.contractEndDate.setValue(null);
    } else {
      this.form.controls.contractEndDate.setValue(new Date());
    }
  }

  showTpm(): boolean {
    const showTpm = this.form.controls.isLineManager.value === false && this.form.controls.isTpm.value === false;
    if (!showTpm) {
      this.form.controls.tpm.setValue(null);
    }
    return showTpm;
  }

  showLineManager(): boolean {
    const showLineManager = this.form.controls.isLineManager.value === false;
    if (!showLineManager) {
      this.form.controls.lineManager.setValue(null);
    }
    return showLineManager;
  }

  showSupervisor(): boolean {
    const showSupervisor = this.form.controls.isLineManager.value === true;
    if (!showSupervisor) {
      this.form.controls.supervisor.setValue(null);
    }
    return showSupervisor;
  }
}
