<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>{{eTextStrings.BESHARPER_SET_DROPDOWNS}}</h1>
  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse()"
                         (closed)="toggleCollapse()">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.BESHARPER_QUALIFICATION_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateBesharperDialog()">{{eTextStrings.CREATE}} {{' '}} {{eTextStrings.BESHARPER_QUALIFICATION_TITLE}}</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="besharperQualificationDataSource"
        [backendUrl]="besharperQualificationBackendUrl"
        [tableDefinitions]="besharperQualificationTableDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="besharperQualificationMapper"
        [actionDefinitions]="besharperActionDefinitions"
        [showPaginator]=false
        [hasRowAction]=true
        (rowClicked)="openModifyBesharperDialog($event)">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>




