<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{(isCreateMode() ? eTextStrings.CONTRACT_CREATE : eTextStrings.CONTRACT_MODIFY)}}</h1>

    <form spellcheck="false" [formGroup]="form">
      <div class="col-2">
        <div class="sided">
          <ng-container *ngIf="userIsReadonly">
            <mat-form-field appearance="fill">
            <input type="text" matInput value="{{displaySelectedBesharper(data.selectedBesharper)}}" class="disabled" readonly/>
            </mat-form-field>
            <input type="hidden" value="{{data.selectedBesharper}}" formControlName="besharper" />
          </ng-container>
          <mat-form-field *ngIf="!userIsReadonly" appearance="fill">
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   [readonly] = isReadonlyBesharper()
                   (input)="filter()"
                   formControlName="besharper"
                   [matAutocomplete]="auto"
            />

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperSelected(besharper)" *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>
        </div>
        <div class="sided">
        <mat-form-field appearance="fill">
          <mat-label>{{eTextStrings.CONTRACT_TYPE_TITLE}}</mat-label>
          <mat-select [disabled]="contractTypes.length === 0" formControlName="contractType">
            <mat-option [value]="ct.id" *ngFor="let ct of contractTypes">{{ct.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'contractType'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_MESSAGES_TYPE}}"></app-validator-ui>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Business Unit</mat-label>
            <mat-select [disabled]="businessUnits.length === 0" formControlName="businessUnit">
              <mat-option [value]="b.id" *ngFor="let b of businessUnits">{{b.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Ruolo</mat-label>
            <mat-select [disabled]="qualifications.length === 0" formControlName="qualification">
              <mat-option [value]="q.id" *ngFor="let q of qualifications">{{q.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Sei TPM?</mat-label>
            <mat-select formControlName="isTpm">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Sei Line manager?</mat-label>
            <mat-select formControlName="isLineManager">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="false">
          <mat-form-field appearance="fill">
            <mat-label>Sei Supervisor?</mat-label>
            <mat-select formControlName="isSupervisor">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="{{(!this.form.controls.isTpm.value && !this.form.controls.isLineManager.value) ? 'col-2': ''}}">
        <div class="sided" *ngIf="showTpm()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo TPM</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterTpm()"
                   formControlName="tpm"
                   [matAutocomplete]="autoTpm"
            />

            <mat-autocomplete #autoTpm="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperTpmSelected(besharper)" *ngFor="let besharper of filteredTpmBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="showLineManager()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo Line Manager</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterLm()"
                   formControlName="lineManager"
                   [matAutocomplete]="autoLinemanager"
            />

            <mat-autocomplete #autoLinemanager="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperLineManagerSelected(besharper)" *ngFor="let besharper of filteredLinemanagerBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="showSupervisor()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo Supervisor</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterSupervisor()"
                   formControlName="supervisor"
                   [matAutocomplete]="autoSupervisor"
            />

            <mat-autocomplete #autoSupervisor="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperSupervisorSelected(besharper)" *ngFor="let besharper of filteredSupervisorBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>


      <div class="col-2">
        <div class="sided">
        <mat-form-field appearance="fill">
          <mat-label>Posizione</mat-label>
          <mat-select [disabled]="contractPositions.length === 0" formControlName="contractRole">
            <mat-option [value]="cr.id" *ngFor="let cr of contractPositions">{{cr.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'contractRole'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_ROLE_MESSAGES_ROLE}}"></app-validator-ui>
        </div>
        <div class="sided">
        <mat-form-field appearance="fill">
          <mat-label>{{eTextStrings.CONTRACT_LEVEL_TITLE}}</mat-label>
          <mat-select [disabled]="contractLevels.length === 0" formControlName="contractLevel">
            <mat-option [value]="cl.id" *ngFor="let cl of contractLevels">{{cl.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'contractLevel'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_ROLE_MESSAGES_ROLE}}"></app-validator-ui>
        </div>
      </div>

      <mat-form-field appearance="fill" (focus)="datepicker1.open()" (click)="datepicker1.open()">
        <mat-label>{{eTextStrings.CONTRACT_STARTDATE}}</mat-label>
        <input matInput [matDatepicker]="datepicker1" id="contractStartDate" name="contractStartDate" formControlName="contractStartDate" (focus)="datepicker1.open()" (click)="datepicker1.open()">
        <mat-datepicker-toggle matIconSuffix [for]="datepicker1"></mat-datepicker-toggle>
        <mat-datepicker #datepicker1></mat-datepicker>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'contractStartDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_STARTDATE_MESSAGE}}"></app-validator-ui>

      <mat-checkbox (change)="nullifyDateIfCurrent()" [checked]="isCurrent">Corrente?</mat-checkbox>

      <mat-form-field *ngIf="!isCurrent" appearance="fill" (focus)="datepicker2.open()" (click)="datepicker2.open()">
        <mat-label>{{eTextStrings.CONTRACT_ENDDATE}}</mat-label>
        <input matInput [matDatepicker]="datepicker2" id="contractEndDate" name="contractEndDate" formControlName="contractEndDate" (focus)="datepicker2.open()" (click)="datepicker2.open()">
        <mat-datepicker-toggle matIconSuffix [for]="datepicker2"></mat-datepicker-toggle>
        <mat-datepicker #datepicker2></mat-datepicker>
      </mat-form-field>
      <app-validator-ui *ngIf="!isCurrent" [form]="form" [name]="'contractEndDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_ENDDATE_MESSAGE}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.CONTRACT_WEEKHOURS}}</mat-label>
        <input matInput type="text" id="partTime" name="partTime" formControlName="partTime" placeholder="Part Time" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'partTime'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_WEEKHOURS_MESSAGE}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>RAL</mat-label>
        <input matInput type="text" id="ral" name="ral" formControlName="ral" placeholder="RAL" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'ral'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_RAL_MESSAGE}}"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>Link contratto</mat-label>
        <input matInput type="text" id="contractLink" name="contractLink" formControlName="contractLink" placeholder="Link Contratto" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'contractLink'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_LINK}}"></app-validator-ui>
    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>{{eTextStrings.BUTTONS_BACK}}</span>
    </button>
    <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
            (click)="isCreateMode() ? addContract() : modifyContract();" [disabled]="!formValid() || loading">
      {{ (isCreateMode() ? eTextStrings.CONTRACT_CREATE : eTextStrings.CONTRACT_MODIFY) }}
      <i *ngIf="updating" class="fa-solid fa-loader"></i>
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button" (click)="addContract(true);" [disabled]="!formValid() || loading">{{ eTextStrings.CONTRACT_CREATE_AND_ADD_ANOTHER }}<i *ngIf="updating" class="fa-solid fa-loader"></i></button>
  </div>
</div>

