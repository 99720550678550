import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { WelfareModel, WelfareReasonModel, WelfareSummaryModel, WelfareTypeModel } from "../models/welfare.model";
import { WelfareStatusModel } from "../models/welfare-status.model";
import { WelfareMaximalModel } from "../models/welfare-maximal.model";

export class WelfareListMapper implements IListMapper {
  count = 0;
  elements: WelfareSummaryModel[] = [];

  fillFromJson(json: any): PagedModel<WelfareSummaryModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapWelfare(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  public mapWelfare(value: any): WelfareSummaryModel {
    return {
      besharperId: value['besharper']['id'],
      beSharperName: value['besharper']['name'],
      beSharperSurname: value['besharper']['surname'],
      beSharperCompleteName: value['besharper']['name'] + ' ' + value['besharper']['surname'],
      id: value['id'],
      supplyDate: value['supplyDate'],
      value: value['value'],
      reason: value['reason'],
      welfareTypeId: value['welfareType']['id'],
      welfareTypeName: value['welfareType']['name'],
    };
  }
}

export class WelfarePostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class WelfareTypeListMapper implements IListMapper {
  count = 0;
  elements: WelfareTypeModel[] = [];

  fillFromJson(json: any): PagedModel<WelfareTypeModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        name: value['name'],
        description: value['description'],
        decreaseTotal: value['decreaseTotal'],
        createdAt: value['createdAt'],
        updatedAt: value['updatedAt'],
      });
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }
}

export class WelfareReasonListMapper implements IListMapper {
  count = 0;
  elements: WelfareReasonModel[] = [];
  fillFromJson(json: any): PagedModel<WelfareReasonModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        name: value['name'],
      });
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }
}

export class WelfareMaximalListMapper implements IListMapper {
  count = 0;
  elements: WelfareMaximalModel[] = [];

  fillFromJson(json: any): PagedModel<WelfareMaximalModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapWelfare(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  public mapWelfare(value: any): WelfareMaximalModel {
    return {
      id: value['id'],
      family: value['family'],
      value: value['value'],
    };
  }
}

export class WelfareMaximalsPostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class WelfareDetailMapper implements IMapper {
  welfare?: WelfareModel;
  fillFromJson(json: any): WelfareModel {
    this.welfare = {
      id: json['id'],
      beSharperName: json['besharper']['name'],
      besharperAddress: json['besharper']['address'],
      besharperBirthDate: json['besharper']['birthDate'],
      besharperCreatedAt: json['besharper']['createdAt'],
      besharperEmail: json['besharper']['email'],
      besharperId: json['besharper']['id'],
      besharperPhotoUrl: json['besharper']['photoUrl'],
      besharperQualificationCreatedAt: json['besharper']['qualificationCreatedAt'],
      besharperQualificationDescription: json['besharper']['qualificationDescription'],
      besharperQualificationId: json['besharper']['qualificationId'],
      besharperQualificationName: json['besharper']['qualificationName'],
      besharperQualificationUpdatedAt: json['besharper']['qualificationUpdatedAt'],
      besharperSchool: json['besharper']['school'],
      besharperSurname: json['besharper']['surname'],
      besharperUpdatedAt: json['besharper']['updatedAt'],
      createdAt: json['createdAt'],
      reason: json['reason'],
      supplyDate: json['supplyDate'],
      updatedAt: json['updatedAt'],
      value: json['value'],
      welfareTypeCreatedAt: json['welfareType']['createdAt'],
      welfareTypeDescription: json['welfareType']['description'],
      welfareTypeId: json['welfareType']['id'],
      welfareTypeName: json['welfareType']['name'],
      welfareTypeUpdatedAt: json['welfareType']['updatedAt'],
    };

    return this.welfare;
  }

  fillToJson(): string {
    return '';
  }
}

export class WelfareStatusMapper implements IMapper {
  welfareStatus: any;

  fillFromJson(json: any): WelfareStatusModel {
    this.welfareStatus = {
      totalCredit: json['totalCredit'],
      spentCredit: json['spentCredit'],
      residualCredit: json['residualCredit']
    };

    return this.welfareStatus;
  }

  fillToJson(): string {
    return '';
  }
}
