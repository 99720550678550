import {IListMapper, IMapper} from '../models/i-mapper';
import { QualificationModel } from '../models/qualification.model';
import {PagedModel} from "../models/paged.model";

export class ListQualificationMapper implements IListMapper {
  count: number = 0;
  elements: QualificationModel[] = [];
  fillFromJson(json: any): PagedModel<QualificationModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push({
        id: value['id'],
        name: value['name'],
        description: value['description'],
        createdAt: value['createdAt'],
        updatedAt: value['updatedAt'],
      });
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return '';
  }
}

export class QualificationPostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
