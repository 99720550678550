<div class="time-picker {{readonly ? 'readonly': ''}}">
  <label>{{label}}</label>
  <select [(ngModel)]="hours" name="hoursSelect" (change)="emitTime()" [disabled]="readonly">
    <option [ngValue]="null" selected disabled>--</option>
    <ng-container *ngFor="let hourValue of hourArray">
      <option [ngValue]="hourValue" *ngIf="hourValue === hours" selected>{{hourValue}}</option>
      <option [ngValue]="hourValue" *ngIf="hourValue !== hours">{{hourValue}}</option>
    </ng-container>
  </select>
  :
  <select [(ngModel)]="minutes" name="minutesSelect" (change)="emitTime()" [disabled]="readonly">
    <option [ngValue]="null" selected disabled>--</option>
    <ng-container *ngFor="let minuteValue of minuteArray">
      <option [ngValue]="minuteValue" *ngIf="minuteValue === minutes" selected>{{minuteValue}}</option>
      <option [ngValue]="minuteValue" *ngIf="minuteValue !== minutes">{{minuteValue}}</option>
    </ng-container>
  </select>
</div>