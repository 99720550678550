<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <form [formGroup]="form">
      <h1 class="create-modify-title" *ngIf="isModifyMode()">{{eTextStrings.MODIFY}}{{' '}}{{eTextStrings.BESHARPER_QUALIFICATION_TITLE}}</h1>
      <h1 class="create-modify-title" *ngIf="!isModifyMode()">{{eTextStrings.CREATE}}{{' '}}{{eTextStrings.BESHARPER_QUALIFICATION_TITLE}}</h1>
      <mat-form-field appearance="fill">
        <mat-label>{{formLabels.name}}</mat-label>
        <input matInput placeholder="{{formLabels.messages.nameMessage}}" formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{formLabels.description}}</mat-label>
        <input matInput placeholder="{{formLabels.messages.descriptionMessage}}" formControlName="description">
      </mat-form-field>
      <div class="create-modify-button">
          <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" disabled="{{form.invalid || loading}}" mat-raised-button (click)="sendRequest()">{{eTextStrings.CONFIRM}}</button>
          <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
        </div>
    </form>
  </div>
</div>

