import { Component } from "@angular/core";
import { TextStrings } from "../../../../core/constants/text-strings";
import { ProviderService } from "../../../../core/provider.service";
import { SimpleListMapper } from "../../../../mappers/simple.mapper";
import { CustomHoliday as CustomHolidayModel } from "src/app/models/custom-holiday.model";
import { IFilteredTablePage } from "src/app/core/interfaces/i-filtered-table-page";
import { Router } from "@angular/router";
import { TableDataSource } from "src/app/core/form-utils/table/table.datasource";
import { AdminPanelCreateCustomHolidayComponent } from "src/app/components/dialogs/admin-panel-create-modify-custom-holiday/admin-panel-create-custom-holiday.component";
import { Constants } from "src/app/core/constants/constants";
import { environment } from "src/environments/environment";
import moment from "moment";

@Component({
  selector: 'app-set-custom-holidays',
  templateUrl: './set-custom-holidays.component.html',
  styleUrl: './set-custom-holidays.component.scss'
})
export class SetCustomHolidaysComponent extends IFilteredTablePage {

  protected tableDataSource = new TableDataSource<CustomHolidayModel>();

  protected customHolidaysListUrl = environment.cognito.apiEndpoint + Constants.customHolidaysApiPath;
  protected readonly eTextStrings = TextStrings;
  protected loading = false;
  protected simpleMapper = new SimpleListMapper();

  protected panelExpanded = true;

  protected customHolidaysTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    {
      def: 'date', title: 'Data', sortable: true, transformer: (date: string) => {
        return moment(date, 'MM-DD').locale('it').format('DD MMMM');
      }
    }
  ];

  protected customHolidaysActionDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: CustomHolidayModel) => {
        this.providerService.utilService.deleteCrudDialog({
          page: this,
          apiUrl: `${this.customHolidaysListUrl}/${item.id}`
        })
      },
    },
  ];

  constructor(private providerService: ProviderService, router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  async createCustomHoliday(): Promise<void> {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateCustomHolidayComponent,
      data: {}
    });
  }
}
