import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IMapper } from '../../../models/i-mapper';
import { CronjobModel } from '../../../models/cronjob.model';
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-create-modify-cronjob',
  templateUrl: './create-modify-cronjob.component.html',
  styleUrl: './create-modify-cronjob.component.scss',
})
export class CreateModifyCronjobComponent implements OnInit {
  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  listMode = false;
  cronjob?: any;
  backendUrlCronjob = environment.cognito.apiEndpoint + Constants.cronjobApiPath;
  eTextStrings = TextStrings;

  public args : any = null;

  public form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    action: new FormControl('SendEmail', [Validators.required]),
    schedule: new FormControl('0 * * * *', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateModifyCronjobComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.bootstrap(data);
  }

  private bootstrap(data: any) {
    if (data["mode"] === "modify") {
      this.mode = ModeEnum.MODIFY;
    } else if (data["mode"] === "create") {
      this.mode = ModeEnum.CREATE;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.data['mode'] == ModeEnum.MODIFY && this.data.cronjob !== undefined) {
      this.mode = ModeEnum.MODIFY;
      this.cronjob = this.data.cronjob;
      this.populateFormForModify(this.cronjob);
    }
    else if(this.data['mode'] == ModeEnum.CREATE) {
      this.mode = ModeEnum.CREATE;
    }

    this.loading = false;
  }

  onArgsChange(args : any | null) {
    this.args = args;
    console.log(args);
  }

  formValid(): boolean {
    return this.form.valid && this.args !== null;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  async createCronjob(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const url = environment.cognito.apiEndpoint + Constants.cronjobApiPath;
    const postMapper = new SimpleMapper();
    const responseMapper = new SimpleMapper();

    try {
      this.fillMapper(postMapper);

      await this.providerService.networkService.post(url, postMapper, responseMapper);

      this.providerService.utilService.showMessage(TextStrings.CRONJOB_CREATE_SUCCESS, LogLevel.success);
      this.loading = false;
      this.form.reset();
      if(!addAnother) {
        this.dialogRef.close();
      }
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  async modifyCronjob(): Promise<void> {
    this.loading = true;
    const putMapper = new SimpleMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(putMapper);
      await this.providerService.networkService.put(this.backendUrlCronjob + '/' + this.cronjob['id'], putMapper, mapper)
      this.providerService.utilService.showMessage(TextStrings.CRONJOB_MODIFY_SUCCESS, LogLevel.success);
      this.dialogRef.close();
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  private populateFormForModify(data: CronjobModel) {
    this.form.controls.name.setValue(data.name)
    this.form.controls.action.setValue(data.action?.toString() ?? '');
    this.form.controls.schedule.setValue(data.schedule ?? '');
    this.args = data.args;
  }

  fillMapper(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
      action: this.form.controls.action.value,
      args: this.args,
      schedule: this.form.controls.schedule.value,
    });
  }
}
