export class Constants {
  // App configuration
  static readonly title: string = 'beSharp - Intranet';
  static readonly appPrefix: string = 'BESHARP_INTRANET.';
  static readonly snackbarDuration: number = 6000;
  static readonly snackbarDurationLong: number = 15000;
  static readonly dateFormat: string = 'DD/MM/YYYY';
  static readonly invalidDate: string = "Invalid Date";

  // Out of office configuration
  static readonly morningStartingHour = '09:30';
  static readonly morningEndingHour = '13:00';
  static readonly afternoonStartingHour = '14:00';
  static readonly afternoonEndingHour = '18:30';

  // Navigation Path
  static readonly basePath: string = '/app';
  static readonly besharpersPath = this.basePath + '/besharpers';
  static readonly contractPath = this.basePath + '/contracts';
  static readonly welfarePath = this.basePath + '/welfare';
  static readonly bonusPath = this.basePath + '/bonus';
  static readonly inventoryPath = this.basePath + '/besharp-item';
  static readonly adminContractsPath = this.basePath + 'admin-panel/set-contracts-dropdown-menus';
  static readonly equipmentPath = this.basePath + '/equipment';
  static readonly homePath = this.basePath + '/home';
  static readonly outOfOfficePath = this.basePath + '/out-of-office';
  static readonly outOfOfficeDetailPath: string = this.basePath + '/out-of-office-detail';
  static readonly besharperDetailPath: string = this.basePath + '/besharper-detail';
  static readonly authPath: string = '/auth';
  static readonly signInPath: string = this.authPath + '/sign-in';
  static readonly recurrentSmartPath: string = this.basePath + '/recurrent-smart-working';

  // Api paths (they are equal between prod and dev environment, so we can leave them here)
  static readonly besharperApiPath: string = 'besharper';
  static readonly besharpItemsPath = 'besharp-item';
  static readonly besharperEquipmentPath = 'besharper-equipment';
  static readonly qualificationsApiPath: string = 'qualification';
  static readonly contractTypesApiPath: string = 'contract-type';
  static readonly contractLevelsApiPath: string = 'contract-level';
  static readonly contractRolesApiPath: string = 'contract-role';
  static readonly contractApiPath: string = 'contract';
  static readonly customHolidaysApiPath: string = 'custom-holidays';
  static readonly welfareApiPath: string = 'welfare';
  static readonly equipmentApiPath: string = 'equipment';
  static readonly consumableApiPath: string = 'consumable';
  static readonly bonusApiPath: string = 'bonus';
  static readonly outOfOfficeApiPath: string = 'out-of-office';
  static readonly outOfOfficeApproversPath = (officeId: string) => 'out-of-office/' + officeId + '/approvers';
  static readonly outOfOfficeTypeApiPath: string = 'out-of-office-type';
  static readonly outOfOfficeReportApiPath: string = 'report/out-of-office';
  static readonly profileImageApiPath: string = 'profile-image';
  static readonly welfareTypesApiPath: string = 'welfare-type';
  static readonly welfareReasonApiPath: string = 'welfare-reason';
  static readonly welfareMaximalsApiPath: string = 'welfare-maximals';
  static readonly welfareReportApiPath: string = 'report/welfare';
  static readonly businessUnitsApiPath: string = 'business-unit';
  static readonly cronjobApiPath: string = 'cronjob';
  static readonly equipmentTypesApiPath: string = 'equipment-type';
  static readonly smartWorkingTypeApiPath: string = 'smart-working-reason';
  static readonly settingsApiPath: string = 'settings';
  static readonly backOfficeApiPath: string = 'backoffice';

  // Cookies
  static readonly accessKeyCookie: string = this.appPrefix + 'accessKey';
  static readonly secretKeyCookie: string = this.appPrefix + 'secretKey';
  static readonly sessionTokenCookie: string = this.appPrefix + 'sessionToken';
  static readonly expirationCookie: string = this.appPrefix + 'expiration';

  // Local storage items
  static readonly samlResponseItem: string = this.appPrefix + 'samlResponse';
  static readonly currentNavigation: string = this.appPrefix + 'currentNavigation';
  static readonly userInfo: string = this.appPrefix + 'userInfo';
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MONTHS = [
  { name: "Gennaio", value: 1 },
  { name: "Febbraio", value: 2 },
  { name: "Marzo", value: 3 },
  { name: "Aprile", value: 4 },
  { name: "Maggio", value: 5 },
  { name: "Giugno", value: 6 },
  { name: "Luglio", value: 7 },
  { name: "Agosto", value: 8 },
  { name: "Settembre", value: 9 },
  { name: "Ottobre", value: 10 },
  { name: "Novembre", value: 11 },
  { name: "Dicembre", value: 12 },
];

export const COUNTRIES = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

