<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="header">
    <h1>{{isCreateMode() ? 'Crea nuovo oggetto' : "Modifica l'oggetto"}}</h1>
  </div>
  <form [formGroup]="form">
    <mat-form-field style="width: 100%;" appearance="fill">
      <mat-label>Scegli foto oggetto</mat-label>
      <button mat-icon-button matPrefix (click)="f_input.click()">
        <mat-icon>attach_file</mat-icon>
      </button>
      <input type="text" readonly matInput formControlName="photo" />
      <input
        type="file"
        hidden
        #f_input
        (change)="handleFileInputChange(f_input.files)"
      />
      <mat-error>this field is required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Descrizione</mat-label>
      <input matInput type="text" id="description" name="description" formControlName="description">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'description'" [icon]="'fa-regular fa-circle-exclamation'" message="Descrizione è obbligatorio"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>Tipo</mat-label>
      <input matInput type="text" id="type" name="type" formControlName="type">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'type'" [icon]="'fa-regular fa-circle-exclamation'" message="Tipo è obbligatorio"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>Codice</mat-label>
      <input matInput type="text" id="code" name="code" formControlName="code">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'code'" [icon]="'fa-regular fa-circle-exclamation'" message="Codice è obbligatorio"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>Numero seriale</mat-label>
      <input matInput type="text" id="serialNumber" name="serialNumber" formControlName="serialNumber">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Posizione</mat-label>
      <input matInput type="text" id="position" name="position" formControlName="position">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'position'" [icon]="'fa-regular fa-circle-exclamation'" message="Posizione è obbligatorio"></app-validator-ui>


    <mat-form-field appearance="fill">
      <mat-label>Assegnato a</mat-label>
      <mat-select formControlName="assignee">
        <mat-option [value]="user" *ngFor="let user of users">{{user}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option [value]="statusesEnum.OK">{{statusesEnum.OK}}</mat-option>
        <mat-option [value]="statusesEnum.IN_ASSISTENZA">{{statusesEnum.IN_ASSISTENZA}}</mat-option>
        <mat-option [value]="statusesEnum.SMARRITO">{{statusesEnum.SMARRITO}}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'status'" [icon]="'fa-regular fa-circle-exclamation'" message="Status è obbligatorio"></app-validator-ui>

    <mat-form-field appearance="fill">
      <mat-label>Note</mat-label>
      <input matInput type="text" id="note" name="note" formControlName="note">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Totale</mat-label>
      <input matInput type="text" id="total" name="total" formControlName="total">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'total'" [icon]="'fa-regular fa-circle-exclamation'" message="Totale è obbligatorio"></app-validator-ui>

    <mat-form-field appearance="fill" (focus)="picker1.open()" (click)="picker1.open()">
      <mat-label>Data acquisto</mat-label>
      <input matInput type="text" id="createdAt" name="createdAt" formControlName="createdAt" [matDatepicker]="picker1" (focus)="picker1.open()" (click)="picker1.open()">
      <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1 startView="year"></mat-datepicker>
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'createdAt'" [icon]="'fa-regular fa-circle-exclamation'" message="Data acquisto è obbligatorio"></app-validator-ui>
  </form>

  <div class="button-container">
    <button color="primary" mat-raised-button (click)="dialogRef.close()">Indietro</button>
    <button color="{{formValid() ? 'primary' : 'disabled'}}" disabled="{{!formValid() || loading}}" (click)="sendRequest()" mat-raised-button>Crea</button>
    <button color="{{formValid() ? 'primary' : 'disabled'}}" disabled="{{!formValid() || loading}}" (click)="sendRequest(true)" mat-raised-button>Crea e continua ad inserire</button>
  </div>
</div>

