<div class="container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="card-detail">
    <div class="v-centered-row space-between">
      <h3>Dettagli Contratto</h3>
      <app-eye-toggle [(visible)]="sensitiveDataVisible"></app-eye-toggle>
    </div>
    <p><i class="fa-regular fa-id-card"></i><b>BESHARPER</b>: {{contract?.besharper?.name}}
      {{contract?.besharper?.surname}}</p>
    <p><i class="fa-regular fa-id-card"></i><b>BUSINESS UNIT</b>: {{contract?.businessUnit?.name}}</p>
    <p><i class="fa-regular fa-id-card"></i><b>RUOLO BESHARPER</b>: {{contract?.qualification?.name}}</p>
    <p><i class="fa-regular fa-id-card"></i><b>TIPO CONTRATTO</b>: {{contract?.contractType?.name}}</p>
    <p><i class="fa-regular fa-id-card"></i><b>LIVELLO CCNL</b>: {{contract?.contractLevel?.name}}</p>
    <p><i class="fa-regular fa-id-card"></i><b>LIVELLO BESHARP</b>: {{contract?.contractRole?.name}}</p>
    <p><i class="fa-regular fa-money-bill"></i><b>RAL</b>: {{sensitiveDataVisible ? formatMoney(contract?.ral) : "€
      •••••"}}</p>
    <p><i class="fa-regular fa-clock"></i><b>ORE</b>: {{contract?.partTime}}</p>
    <p><i class="fa-regular fa-user"></i><b>E' LINE MANAGER?</b>: {{contract?.isLineManager ? 'SI' : 'NO'}}</p>
    <p><i class="fa-regular fa-user"></i><b>E' TPM?</b>: {{contract?.isTpm ? 'SI' : 'NO'}}</p>

    <p><i class="fa-regular fa-user"></i><b>IL TUO LINE MANAGER</b>:
      <ng-container
        *ngIf="contract?.lineManager && !contract?.isLineManager && !contract?.isSupervisor">&nbsp;{{getFullname(contract?.lineManager)}}</ng-container>
      <ng-container *ngIf="!isLineManagerRequired">&nbsp;-</ng-container>
      <ng-container *ngIf="!contract?.lineManager && !contract?.isLineManager && !contract?.isSupervisor">&nbsp;<span
          class="red">DA AGGIORNARE!</span></ng-container>
    </p>
    <p><i class="fa-regular fa-user"></i><b>IL TUO TPM</b>:
      <ng-container
        *ngIf="contract?.tpm && !contract?.isTpm && !contract?.isLineManager && !contract?.isSupervisor">&nbsp;{{getFullname(contract?.tpm)}}</ng-container>
      <ng-container *ngIf="!isTpmRequired">&nbsp;-</ng-container>
      <ng-container
        *ngIf="!contract?.tpm && !contract?.isTpm && !contract?.isLineManager && !contract?.isSupervisor">&nbsp;<span
          class="red">DA
          AGGIORNARE!</span></ng-container>
    </p>
    <p><i class="fa-regular fa-user"></i><b>IL TUO SUPERVISOR</b>:
      <ng-container
        *ngIf="contract?.supervisor && contract?.isLineManager">&nbsp;{{getFullname(contract?.supervisor)}}</ng-container>
      <ng-container *ngIf="!isSupervisorRequired">&nbsp;-</ng-container>
      <ng-container *ngIf="!contract?.supervisor && contract?.isLineManager">&nbsp;<span class="red">DA
          AGGIORNARE!</span></ng-container>
    </p>

    <p><i class="fa-solid fa-play"></i><b>INIZIO CONTRATTO</b>: {{ getContractStartLabel() }}</p>
    <p><i class="fa-solid fa-stop"></i><b>FINE CONTRATTO</b>: {{ getContractEndLabel() }}</p>
    <p><i class="fa-solid fa-file"></i><b>LINK CONTRATTO</b>:
      <ng-container *ngIf="contract?.contractLink">
        <a href="{{ contract?.contractLink }}" target="_blank">vai al contratto</a>
      </ng-container>
      <ng-container *ngIf="!contract?.contractLink">nessun contratto caricato</ng-container>
    </p>
  </div>
  <mat-divider></mat-divider>

  <div class="button-container" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="modifyContractModalDialog()">
        <i class="fa-solid fa-pen"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="deleteContractModalDialog()">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
  </div>
</div>