import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { EquipmentModel } from '../models/equipment.model';

export class ListEquipmentMapper implements IListMapper {
  elements: EquipmentModel[] = [];
  count = 0;

  fillFromJson(json: any): PagedModel<EquipmentModel> {
    this.elements = [];
    json['items'].forEach((item: any) => {
      this.elements.push(this.mapItem(item));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return '';
  }

  toString(): string {
    return '';
  }

  private mapItem(item: any): EquipmentModel {
    return {
      id: item['id'],
      idDevice: item['idDevice'],
      serialNumber: item['serialNumber'],
      besharperId: item['besharperId'],
      equipmentTypeId: item['equipmentTypeId'],
      brand: item['brand'],
      model: item['model'],
      buyingDate: new Date(item['buyingDate']),
      warrant: item['warrant'],
      invoice: item['invoice'],
      metadata: item['metadata'],

      endOfSupport: new Date(item['endOfSupport']),
      warrantLink: item['warrantLink'],
      invoiceLink: item['invoiceLink'],
      personal: item['personal'],
      mixedUse: item['mixedUse'],
      workUse: item['workUse'],
      insurance: item['insurance'],

      note: item['note'],
      invoiceDate: item['invoiceDate'] ? new Date(item['invoiceDate']) : undefined,
      invoiceCompany: item['invoiceCompany'],
      status: item['status'],

      besharper: item['besharper'],
      equipmentType: item['equipmentType']
    };
  }
}

export class EquipmentDetailMapper implements IMapper {
  equipment?: EquipmentModel;
  fillFromJson(item: any): EquipmentModel {
    this.equipment = {
      id: item['id'],
      idDevice: item['idDevice'],
      serialNumber: item['serialNumber'],
      besharperId: item['besharperId'],
      equipmentTypeId: item['equipmentTypeId'],
      brand: item['brand'],
      model: item['model'],
      buyingDate: new Date(item['buyingDate']),
      warrant: item['warrant'],
      invoice: item['invoice'],
      metadata: item['metadata'],

      endOfSupport: new Date(item['endOfSupport']),
      warrantLink: item['warrantLink'],
      invoiceLink: item['invoiceLink'],
      personal: item['personal'],
      mixedUse: item['mixedUse'],
      workUse: item['workUse'],
      insurance: item['insurance'],

      note: item['note'],
      invoiceDate: item['invoiceDate'] ? new Date(item['invoiceDate']) : undefined,
      invoiceCompany: item['invoiceCompany'],
      status: item['status'],
      besharper: item['besharper'],
      equipmentType: item['equipmentType'],
    };

    return this.equipment;
  }

  fillToJson(): string {
    return '';
  }
}

export class CreateEquipmentMapper implements IMapper {
  body = {};

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }

  toString(): string {
    return '';
  }
}
