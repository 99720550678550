<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{isCreateMode() ? "Crea Consumabile" : "Modifica Consumabile"}}</h1>

    <form spellcheck="false" [formGroup]="form">

      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput id="name" name="name" formControlName="name" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'name'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire nome"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>Descrizione</mat-label>
        <input matInput id="description" name="description" formControlName="description" value="">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Q.tà</mat-label>
        <input matInput type="number" id="qty" name="qty" formControlName="qty" placeholder="Valore..." value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'qty'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire valore quantità"></app-validator-ui>
      <app-validator-ui [form]="form" [name]="'qty'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="Solo valori numerici"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>Q.tà minima allarme</mat-label>
        <input matInput type="number" id="alarmMinQty" name="alarmMinQty" formControlName="alarmMinQty" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'alarmMinQty'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire valore quantità minima allarme"></app-validator-ui>
      <app-validator-ui [form]="form" [name]="'alarmMinQty'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="Solo valori numerici"></app-validator-ui>

      <mat-checkbox formControlName="alarm" id="alarm" name="alarm" [checked]="form.controls.alarm.value">Allarme attivo</mat-checkbox>

      <mat-form-field appearance="fill">
        <mat-label>Email Custom per Allarme</mat-label>
        <input matInput id="customAlarmEmail" name="customAlarmEmail" formControlName="customAlarmEmail" value="">
      </mat-form-field>


    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>{{eTextStrings.BUTTONS_BACK}}</span>
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
            type="button" (click)="createConsumable()" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_CREATE}}
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
            type="button" (click)="createConsumable(true)" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_CREATE_AND_CONTINUE}}
    </button>
    <button *ngIf="isModifyMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
            type="button" (click)="modifyConsumable();" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_MODIFY}}
    </button>
  </div>
</div>
