<div class="container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="button-container" *ngIf="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER)">
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="modifyBonus();">
        <i class="fa-solid fa-pen"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="deleteBonus();">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
  </div>
  <div class="card-detail">
    <div class="v-centered-row space-between">
      <h3>Dettagli Bonus</h3>
      <app-eye-toggle [(visible)]="sensitiveDataVisible" />
    </div>
    <mat-card>
      <mat-card-content>
        <p><i class="fa-solid fa-id-card"></i>{{bonus?.beSharperName}} {{bonus?.besharperSurname}}</p>
        <p><i class="fa-solid fa-euro-sign"></i>{{sensitiveDataVisible ? formatMoney(bonus?.value) : "€ •••••"}}</p>
        <p><i class="fa-solid fa-calendar-days"></i>{{formatDate(bonus?.supplyDate, true)}}</p>
        <p><i class="fa-regular fa-money-check"></i>{{bonus?.reason}}</p>
      </mat-card-content>
    </mat-card>
  </div>

</div>