<div class="container">
  <div id="top" class="row">
    <div class="content">
      <app-navbar></app-navbar>
    </div>
  </div>
  <div id="bottom" class="row">
    <div id="left" class="column">
      <div class="content">
        <app-sidebar></app-sidebar>
      </div>
    </div>
    <div id="right" class="column">
      <div class="app-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
