<div class="container create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="header">
    <h1>{{eTextStrings.OUT_OF_OFFICE_REPORT}}</h1>
  </div>

  <mat-radio-group aria-label="Seleziona il tipo di report" (change)="changeReportType($event.value)">
    <mat-radio-button value="monthly" checked>Report mensile</mat-radio-button>
    <mat-radio-button value="custom">Report custom</mat-radio-button>
  </mat-radio-group>

  <form [formGroup]="montlyReportForm">
    <ng-container *ngIf="!customRange">
      <mat-form-field appearance="fill">
        <mat-label>Mese di riferimento ({{currentYear}})</mat-label>
        <mat-select [disabled]="customRange" formControlName="referenceMonth">
          <mat-option [value]="month.value" *ngFor="let month of months">{{month.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </form>

  <form [formGroup]="customReportForm">
    <ng-container  *ngIf="customRange">
      <mat-form-field appearance="fill">
        <mat-label>Periodo</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate type="text" formControlName="startDate">
          <input matEndDate type="text" formControlName="endDate">
        </mat-date-range-input>
        <mat-date-range-picker #picker/>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <app-validator-ui [form]="customReportForm" [name]="'startDate'" [icon]="'fa-regular fa-circle-exclamation'" message="La data di inizio è obbligatoria"></app-validator-ui>
        <app-validator-ui [form]="customReportForm" [name]="'endDate'" [icon]="'fa-regular fa-circle-exclamation'" message="La data di fine è obbligatoria"></app-validator-ui>
      </mat-form-field>

    </ng-container>
  </form>

  <div class="button-row">
    <button color="primary" mat-raised-button (click)="close()">Indietro</button>
    <button color="{{formValid() ? 'primary' : 'disabled'}}" disabled="{{!formValid() || loading}}" (click)="generateReport()" mat-raised-button>Genera Report</button>
  </div>
</div>

