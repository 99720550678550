<div class="container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="button-container" *ngIf="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER)">
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="modifyConsumable();">
        <i class="fa-solid fa-pen"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="deleteConsumable();">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
  </div>
  <div class="card-detail">
    <h3>Dettagli Consumabile</h3>
    <mat-card>
      <mat-card-content>
        <p><i class="fa-solid fa-id-card"></i>Nome: {{consumable?.name}}</p>
        <p><i class="fa-solid fa-id-card"></i>Descrizione: {{consumable?.description}}</p>

        <p><i class="fa-solid fa-id-card"></i>Q.tà: {{consumable?.qty}}</p>
        <p><i class="fa-solid fa-id-card"></i>Q.tà Minima: {{consumable?.alarmMinQty}}</p>

        <p><i class="fa-solid fa-id-card"></i>Allarme q.tà minima: {{consumable?.alarm}}</p>
        <p><i class="fa-solid fa-id-card"></i>Email Custom per l'invio? (Default francesco.perri[at]besharp.it): {{consumable?.customAlarmEmail}}</p>
      </mat-card-content>
    </mat-card>
  </div>

</div>
