<div class="container create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <div class="header">
    <h1>{{editingExistingItem ? eTextStrings.OUT_OF_OFFICE_MODIFY : eTextStrings.OUT_OF_OFFICE_CREATE}}</h1>
  </div>
  <div class="form-row">
    <div class="hint info">
      <span>- Le richieste su singola giornata permettono di editare ora di inizio e ora di fine.</span><br>
      <span>- Le richieste su più giorni calcolano in automatico le ore previste dal contratto.</span><br>
    </div>

    <form spellcheck="false" [formGroup]="form">
      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>BeSharper</mat-label>
          <input type="text" placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}" aria-label="name"
            matInput formControlName="besharper" [matAutocomplete]="besharperAuto" (input)="filter()"
            [readonly]="!isCurrentUserHr() || editingExistingItem" />

          <mat-autocomplete #besharperAuto="matAutocomplete" [displayWith]="getDisplayNameForBesharper.bind(this)">
            <mat-option *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' +
              besharper.surname }}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'"
          message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>
      </ng-container>

      <div class="col-2">
        <div class="sided">
          <ng-container>
            <mat-form-field appearance="fill">
              <mat-label>Tipo</mat-label>
              <input type="text" placeholder="{{eTextStrings.OUT_OF_OFFICE_CREATE_TYPE}}" aria-label="type" matInput
                formControlName="type" [matAutocomplete]="typesAuto" (input)="filterType()" />

              <mat-autocomplete #typesAuto="matAutocomplete" [displayWith]="getDisplayNameForType.bind(this)">
                <mat-option *ngFor="let outOfOfficeType of filteredTypes"
                  [value]="outOfOfficeType.id">{{outOfOfficeType.name}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'type'" [icon]="'fa-regular fa-circle-exclamation'"
              message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>
          </ng-container>
        </div>
        <div class="sided">
          <ng-container>
            <mat-form-field appearance="fill" (focus)="picker.open()" (click)="picker.open()">
              <mat-label>Seleziona uno o più giorni</mat-label>
              <mat-date-range-input [rangePicker]="picker" (focus)="picker.open()" (click)="picker.open()">
                <input matStartDate id="startingAtDate" name="startingAtDate" formControlName="startingAtDate" readonly
                  (dateChange)="startDatePicker.next($event)">
                <input matEndDate id="endingAtDate" name="endingAtDate" formControlName="endingAtDate" readonly
                  (dateChange)="endDatePicker.next($event)">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker (closed)="checkAndSetSingleDay();"></mat-date-range-picker>
            </mat-form-field>
          </ng-container>
        </div>
      </div>

      <ng-container>
        <div class="col-2">
          <div class="sided">
            <app-time-picker #timePickerStart [(time)]="startingAtTime" [minuteStep]="30"
              [label]="eTextStrings.OUT_OF_OFFICE_CREATE_STARTTIME" />
          </div>

          <div class="sided">
            <app-time-picker #timePickerEnd [(time)]="endingAtTime" [minuteStep]="30"
              [label]="eTextStrings.OUT_OF_OFFICE_CREATE_ENDTIME" />
          </div>
        </div>

        <div>
          <ng-container *ngIf="checkIfOOOIsSmartWorking">
            <mat-form-field appearance="fill">
              <mat-label>Motivo Remote Working</mat-label>
              <input type="text" placeholder="{{eTextStrings.OUT_OF_OFFICE_SMART_WHY}}" aria-label="name" matInput
                formControlName="smartWhy" [matAutocomplete]="smartWhyAuto" (input)="smartWhyType()" />

              <mat-autocomplete #smartWhyAuto="matAutocomplete" [displayWith]="getDisplayNameForSmartWhy.bind(this)"
                (optionSelected)="setSmartWhy($event)">
                <mat-option *ngFor="let outOfOfficeSmartWhy of filteredSmartWhy"
                  [value]="outOfOfficeSmartWhy.id">{{outOfOfficeSmartWhy.name}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'smartWhy'" [icon]="'fa-regular fa-circle-exclamation'"
              message="Campo obbligatorio"></app-validator-ui>
          </ng-container>
        </div>
        <div *ngIf="showOtherWhyInput">
          <mat-form-field appearance="fill">
            <mat-label>Altra Motivazione</mat-label>
            <input matInput type="text" id="otherWhy" name="otherWhy" formControlName="otherWhy">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'otherWhy'" [icon]="'fa-regular fa-circle-exclamation'"
            message="Altra motivazione è obbligatoria se la categoria 'altro' è selezionata"
            *ngIf="showOtherWhyInput"></app-validator-ui>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="button-row">
    <button color="primary" (click)="dialogRef.close()" mat-raised-button>{{eTextStrings.BUTTONS_BACK}}</button>
    <button mat-raised-button (click)="submit()" [disabled]="!formValid() || loading"
      color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
      *ngIf="!dialogWasOpenedFromDetailsPage">{{eTextStrings.CONFIRM_AND_CONTINUE}}</button>
    <button mat-raised-button (click)="editingExistingItem ? modifyOutOfOffice() : createOutOfOffice()"
      [disabled]="!formValid() || loading" color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button">{{eTextStrings.CONFIRM}}</button>
  </div>
</div>
