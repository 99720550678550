import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { BusinessUnitModel } from "../models/business-unit.model";

export class BusinessUnitListMapper implements IListMapper {
  count = 0;
  elements: BusinessUnitModel[] = [];

  fillFromJson(json: any): PagedModel<BusinessUnitModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapBusinessUnit(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  mapBusinessUnit(value: any): BusinessUnitModel {
    return {
      id: value['id'],
      name: value['name'],
    };
  }
}

export class BusinessUnitMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
