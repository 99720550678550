import { Injectable } from "@angular/core";
import { IAuthenticator } from "../../models/i-authenticator";
import { HttpVerb } from "../../models/http-verb";
import { Sha256 } from "@aws-crypto/sha256-browser";
import { Constants } from "../constants/constants";
import { SignatureV4 } from "@smithy/signature-v4";
import { CookieService } from "ngx-cookie-service";
import { UtilsService } from "../app-utils/utils.service";
import { LogLevel } from "../../models/log-level";
import { SimpleMapper } from "../../mappers/simple.mapper";
import { TextStrings } from "../constants/text-strings";

@Injectable({
  providedIn: 'root',
})
export class IamAuthenticatorService implements IAuthenticator {
  // How to solve proper signing: https://github.com/aws/aws-sdk-js-v3/issues/3590

  constructor(private cookieService: CookieService, private utilService: UtilsService) { }

  async getRequest(
    path: string,
    method: HttpVerb,
    headers: { [key: string]: string },
    body?: string,
    params?: { [key: string]: string }
  ): Promise<any> {
    const apiUrl = new URL(path);

    headers['host'] = apiUrl.hostname;
    const sigV4 = await this.prepareSigV4();

    const requestToSign = {
      method: method.toUpperCase(),
      hostname: apiUrl.host,
      path: apiUrl.pathname,
      protocol: apiUrl.protocol,
      body,
      headers,
      query: params,
    };
    if (sigV4 === undefined) {
      return Promise.reject(new Error('Error in signature v4'));
    }

    const signedRequest = await sigV4.sign(requestToSign, { signingDate: new Date() });

    if (headers['x-intranet-user-id'] !== undefined && headers['x-intranet-user-role']) {
      signedRequest.headers['x-intranet-user-id'] = headers['x-intranet-user-id'];
      signedRequest.headers['x-intranet-user-role'] = headers['x-intranet-user-role'];
    }

    return signedRequest;
  }

  async prepareSigV4(): Promise<SignatureV4> {
    const credentials = {
      accessKeyId: this.cookieService.get(Constants.accessKeyCookie),
      secretAccessKey: this.cookieService.get(Constants.secretKeyCookie),
      sessionToken: this.cookieService.get(Constants.sessionTokenCookie),
    };
    return new SignatureV4({
      service: 'execute-api',
      region: 'eu-west-1',
      credentials,
      sha256: Sha256,
    });
  }

  isAuthenticationError(statusCode: string | number): boolean {
    return statusCode.toString() === "403";
  }

  manageError(response: any): Promise<any> {
    console.log("TEST: ", response, response?.data?.message);
    if (response.data?.message?.includes("is not authorized to perform")) {
      return Promise.reject(new Error(TextStrings.FORBIDDEN));
    } else if (response.data?.message?.includes("The security token included in the request is invalid.")) {
      return Promise.reject(new Error(TextStrings.TOKEN_EXPIRED));
    }
    return Promise.reject(new Error(`Status code: ${response.status} - error: ${response?.data?.message}`));
  }
}
