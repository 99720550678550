<div class="align-right">
  <button (click)="openCreateOrModifyBesharperEquipmentDialog('create')" mat-raised-button color="primary" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <i class="fa-sharp fa-solid fa-toolbox"></i>
    &nbsp;
    <span>{{eTextStrings.EQUIPMENT_CREATE}}</span>
  </button>
</div>
<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-sharp fa-solid fa-toolbox"></i>
    <h1>{{eTextStrings.PAGES_EQUIPMENT}}</h1>
  </div>
  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.EQUIPMENT_SEARCH}}</mat-label>
      <input matInput (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
    </mat-form-field>
  </div>
</div>

<app-table-ui
  [backendUrl]="backendUrl"
  [datasource]="dataSource"
  [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
  [pageSize]="10"
  [pageSizeOptions]="[10,20,30]"
  [filterObservable]="filterObservable"
  [mapper]="tableMapper"
>
</app-table-ui>
