<div>
  <div class="align-right">
    <button mat-raised-button color="primary" (click)="exportTable();"
      *ngIf="currentUser.role === eRoleEnum.ADMIN || currentUser.role === eRoleEnum.HR || currentUser.role === eRoleEnum.HR_REPORTS">
      <i class="fa-solid fa-file-excel"></i>&nbsp;
      <span>{{eTextStrings.EXPORT}}</span>
    </button>
    <button mat-raised-button color="primary" (click)="createWelfare();"
      *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
      <i class="fa-solid fa-file-contract"></i>
      &nbsp;
      <span>{{eTextStrings.WELFARE_CREATE}}</span>
    </button>
  </div>
</div>

<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa-solid fa-umbrella-beach"></i>
    <h1>{{eTextStrings.PAGES_WELFARE}}</h1>
  </div>

  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.WELFARE.SEARCH}}</mat-label>
      <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
      <mat-hint>
        <!--                {{'AREAS.TYPE_YOUR_FULL_TEXT_QUERY'}}-->
      </mat-hint>
    </mat-form-field>
  </div>
</div>

<div class="v-centered-row justify-end">
  <app-eye-toggle [(visible)]="sensitiveDataVisible" />
</div>

<div *ngIf="loading">
  <app-loader></app-loader>
</div>

<app-table-ui [backendUrl]="backendUrl" [datasource]="dataSource" [defaultActive]="'supplyDate'"
  [defaultDirection]="'desc'" [tableDefinitions]="tableDefinitions"
  [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
  [pageSize]="10" [pageSizeOptions]="[10,20,30]" [filterObservable]="filterObservable" [mapper]="welfareMapper"
  [hasRowAction]=true (rowClicked)="rowClicked($event);">

</app-table-ui>