<div class="{{data.type}} snackbar-container">
  <div class="left-panel"></div>
  <div class="body-panel">
    <span>{{data.message}}</span>
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <i class="fa-solid fa-x fa-sm"></i>
    </button>
  </div>
</div>

