<div class="sidebar {{ssToggle ? 'set-away' : ''}}">

  <div class="sidebar-container">
    <div>
      <ng-container class="nav-menu">
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge"
            [routerLink]="['/app/besharpers']">
            <i class="far fa-user"></i>
            <span>{{eTextStrings.PAGES_BESHARPERS}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/welfare']">
            <i class="far fa-umbrella-beach"></i>
            <span>{{eTextStrings.PAGES_WELFARE}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/bonus']">
            <i class="far fa-gift"></i>
            <span>{{eTextStrings.PAGES_BONUS}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge"
            [routerLink]="['/app/contracts']">
            <i class="far fa-file-contract"></i>
            <span>{{eTextStrings.PAGES_CONTRACTS}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container>
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge"
            [routerLink]="['/app/out-of-office']">
            <div class="nav-item">
              <i class="far fa-champagne-glasses"></i>
              <span [innerHTML]="eTextStrings.PAGES_OUT_OF_OFFICE"></span>
            </div>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge"
            [routerLink]="['/app/recurrent-smart-working']">
            <i class="far fa-laptop-house"></i>
            <span>{{eTextStrings.RECURRENT_SW}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge"
            [routerLink]="['/app/warehouse']">
            <i class="fa-sharp fa-solid fa-toolbox"></i>
            <span>{{eTextStrings.PAGES_WAREHOUSE}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['/app/cronjob']">
            <i class="far fa-clock"></i>
            <span>{{eTextStrings.PAGES_CRONJOB}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
        <ng-container *ngIf="[RoleEnum.ADMIN, RoleEnum.HR_REPORTS, RoleEnum.HR].includes(userRole)">
          <button mat-button type="button" class="nav-item" queryParamsHandling="merge" [routerLink]="['admin-panel']">
            <i class="fa-solid fa-user"></i>
            <span>{{eTextStrings.PAGES_ADMIN_PANEL}}</span>
          </button>
          <mat-divider></mat-divider>
        </ng-container>
      </ng-container>
    </div>

    <div class="copyright-version">
      <p>
        <span>&copy;beSharp 2024. All right reserved.</span>
        <br>
        <small>{{version}}</small>
      </p>
    </div>

  </div>

  <div class="small-screen-toggle">
    <a (click)="ssToggle = !ssToggle;"><i class="fa-solid fa-chevrons-{{ssToggle ? 'right' : 'left'}}"></i></a>
  </div>
</div>