import { Component } from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { TableDataSource } from '../../../core/form-utils/table/table.datasource';
import { ProviderService } from '../../../core/provider.service';
import { Router } from '@angular/router';
import { BonusModel } from '../../../models/bonus.model';
import { BonusMapper } from '../../../mappers/bonus.mapper';
import { IFilteredTablePage } from '../../../core/interfaces/i-filtered-table-page';
import { RoleEnum } from "../../../models/role.enum";
import { CreateModifyBonusComponent } from '../../dialogs/create-modify-bonus/create-modify-bonus.component';
import { ModeEnum } from '../../../core/constants/enums';
import { ConfirmDeleteComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import { CoreModule } from "../../../core/core.module";
import { EyeToggleComponent } from "../../../core/form-utils/eye-toggle/eye-toggle.component";

@Component({
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrl: './bonus.component.scss',
})
export class BonusComponent extends IFilteredTablePage {
  backendUrl = environment.cognito.apiEndpoint + Constants.bonusApiPath;
  dataSource: TableDataSource<BonusModel> = new TableDataSource<BonusModel>();
  bonusMapper: BonusMapper = new BonusMapper();
  eRoleEnum = RoleEnum;
  sensitiveDataVisible = false;

  tableDefinitions = [
    { def: 'beSharperCompleteName', title: 'beSharper', sortable: true },
    { def: 'supplyDate', title: 'Data', sortable: true, transformer: (value: string) => this.formatDate(new Date(value), true) },
    { def: 'reason', title: 'Causale', sortable: false },
    { def: 'value', title: 'Valore', sortable: true, transformer: (value: string) => this.sensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: 'Modifica bonus',
      styleClass: 'table-action-modify',
      callback: (bonus: any): void => {
        this.modifyBonus(bonus);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: 'Elimina bonus',
      styleClass: 'table-action-delete',
      callback: (bonus: any): void => {
        this.deleteBonus(bonus['id'] as string).then();
      },
    },
  ];

  eTextStrings = TextStrings;

  constructor(protected providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
    this.detailPage = 'bonus-detail';
  }

  createBonus() {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBonusComponent,
      data: {
        mode: ModeEnum.CREATE,
        listMode: true,
      }
    });
  }

  modifyBonus(bonus: any) {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBonusComponent,
      data: {
        mode: ModeEnum.MODIFY,
        bonus: bonus
      }
    });
  }

  async deleteBonus(bonusId: any) {
    const url = environment.cognito.apiEndpoint + `${Constants.bonusApiPath}/${bonusId}`;

    this.providerService.utilService.openDialog(ConfirmDeleteComponent, {
      url: url,
    })
      .afterClosed()
      .subscribe(() => {
        this.filterObservable.next(this.lastDebounceValue)
      });
  }
}
