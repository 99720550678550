import { Component } from '@angular/core';
import { TextStrings } from "../../../../core/constants/text-strings";
import {environment} from "../../../../../environments/environment";
import {Constants} from "../../../../core/constants/constants";
import {TableDataSource} from "../../../../core/form-utils/table/table.datasource";
import RoleEnum from "../../../../models/role.enum";
import {ListEquipmentMapper} from "../../../../mappers/equipment.mapper";
import {EquipmentModel} from "../../../../models/equipment.model";
import {IFilteredTablePage} from "../../../../core/interfaces/i-filtered-table-page";
import {ProviderService} from "../../../../core/provider.service";
import {Router} from "@angular/router";
import {CreateModifyBesharperEquipmentComponent} from "../../../dialogs/create-modify-besharper-equipment/create-modify-besharper-equipment.component";
import {ModeEnum} from "../../../../core/constants/enums";

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrl: './equipment.component.scss'
})
export class EquipmentComponent extends IFilteredTablePage {
  equipmentBackendUrl = environment.cognito.apiEndpoint + Constants.equipmentApiPath;
  equipmentMapper = new ListEquipmentMapper();

  equipmentDataSource: TableDataSource<EquipmentModel> = new TableDataSource<EquipmentModel>();
  loading = false;
  showTable = false;

  equipmentTableDefinitions = [
    { def: 'idDevice', title: 'ID Dispositivo', sortable: true },
    { def: 'serialNumber', title: 'Codice Seriale', sortable: true },
    { def: 'besharper', title: 'Assegnatario', sortable: false, virtual: (row: any) => (row.besharper ? (row.besharper.name + ' ' + row.besharper.surname) : 'NON ASSEGNATO' ) },
    { def: 'equipmentType', title: 'Tipologia', sortable: true, transformer: (value : any) => (value.name) },
    { def: 'brand', title: 'Marca', sortable: true },
    { def: 'model', title: 'Modello', sortable: true },
    { def: 'personal', title: 'Personale', sortable: true, transformer: (value: any) => `<span class="${ value ? 'green': 'red'}"><i class="fa fa-${ value ? 'check' : 'times'}"></i></span>` },
    { def: 'mixedUse', title: 'Uso promisquo', sortable: true, transformer: (value: any) => `<span class="${ value ? 'green': 'red'}"><i class="fa fa-${ value ? 'check' : 'times'}"></i></span>`  },
    { def: 'workUse', title: 'Uso lavorativo', sortable: true, transformer: (value: any) => `<span class="${ value ? 'green': 'red'}"><i class="fa fa-${ value ? 'check' : 'times'}"></i></span>`  },
    { def: 'status', title: 'Stato', sortable: true },
  ];

  equipmentActionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: TextStrings.MODIFY,
      styleClass: 'table-action-modify',
      callback: (equipmentData: EquipmentModel) => {
        this.openEquipmentModifyDialog(equipmentData);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.DELETE,
      styleClass: 'table-action-delete',
      callback: (row: any) => {
        this.deleteEquipmentRequest(row);
      },
    }
  ];

  eTextStrings = TextStrings;
  eRoleEnum = RoleEnum;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);

    this.detailPage = 'equipment-detail';
    this.showTable = true;
  }


  createEquipment() {
    this.providerService.utilService
      .openCrudDialog({
        data: {
          mode: ModeEnum.CREATE,
        },
        template: CreateModifyBesharperEquipmentComponent,
        page: this,
        closeCallback: () => {}
      });
  }

  private openEquipmentModifyDialog(equipmentData: EquipmentModel) {
    this.providerService.utilService.openCrudDialog({
      data: {
        mode: ModeEnum.MODIFY,
        beSharperId: equipmentData.besharperId,
        equipment: equipmentData,
      },
      template: CreateModifyBesharperEquipmentComponent,
      page: this,
    });
  }

  private deleteEquipmentRequest(equipment: EquipmentModel) {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: environment.cognito.apiEndpoint + Constants.equipmentApiPath + `/${equipment.id}`,
    });
  }
}

