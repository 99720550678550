<div class="sign-in-container">
  <img class="logo" src="assets/images/logo-beSharp-high-res.png" title="logo" alt="logo" />

  <h1>beSharp - Intranet</h1>

  <div class="auth-form">

    <button (click)="login()">
      <div class="login-button">
        <i class="fa-brands fa-google"></i>
        Login with Google
      </div>
    </button>

  </div>
</div>

