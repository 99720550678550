import { Component } from '@angular/core';
import { IFilteredTablePage } from '../../../../core/interfaces/i-filtered-table-page';
import { ProviderService } from '../../../../core/provider.service';
import { AdminPanelCreateModifyWelfareComponent } from '../../../dialogs/admin-panel-create-modify-welfare/admin-panel-create-modify-welfare.component';
import { ModeEnum } from '../../../../core/constants/enums';
import { WelfareTypeModel } from '../../../../models/welfare.model';
import { Constants } from '../../../../core/constants/constants';
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { WelfareTypeListMapper } from '../../../../mappers/welfare.mapper';
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { Router } from "@angular/router";

@Component({
  selector: 'app-set-welfare-dropdown-menus',
  templateUrl: './set-welfare-dropdown-menus.component.html',
  styleUrl: './set-welfare-dropdown-menus.component.scss',
})
export class SetWelfareDropdownMenusComponent extends IFilteredTablePage {
  welfareTypeCollapsed = true;

  welfareTypeDataSource: TableDataSource<WelfareTypeModel> = new TableDataSource<WelfareTypeModel>();
  welfareTypeBackendUrl = environment.cognito.apiEndpoint + Constants.welfareTypesApiPath;
  welfareTypeTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'description', title: 'Descrizione', sortable: false },
    { def: 'decreaseTotal', title: 'Decrementa Totale?', sortable: false },
  ];
  welfareTypeMapper = new WelfareTypeListMapper();
  welfareActionDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: WelfareTypeModel) => {
        this.deleteItem(item.id);
      },
    },
  ];

  eTextStrings = TextStrings;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.welfareTypeCollapsed = !this.welfareTypeCollapsed;
  }

  deleteItem(id: string): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.welfareTypeBackendUrl}/${id}`,
    });
  }

  openCreateWelfareDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyWelfareComponent,
      data: { mode: ModeEnum.CREATE }
    });
  }

  openModifyWelfareDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyWelfareComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
      }
    });
  }
}
