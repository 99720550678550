<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}"
          class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>Tipi di Remote Working</h1>
  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse()" (closed)="toggleCollapse()">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          Tipi di Remote Working
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary"
              (click)="openCreatesmartWorkingTypesDialog()">{{eTextStrings.CREATE}} un Remote Working Type</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui [datasource]="smartWorkingTypesDataModel" [backendUrl]="smartWorkingTypesBackendUrl"
        [tableDefinitions]="smartWorkingTypesTableDefinitions" [actionDefinitions]="smartWorkingTypesActionsDefinitions"
        [pageSize]="9999" [pageSizeOptions]="[9999]" [filterObservable]="filterObservable"
        [mapper]="smartWorkingTypesMapper" [showPaginator]=false (rowClicked)="openModifyDialog($event)"
        [hasRowAction]=true>
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>