import { Injectable } from '@angular/core';
import {
  CognitoIdentityClient,
  Credentials,
  GetCredentialsForIdentityCommand,
  GetOpenIdTokenForDeveloperIdentityCommand
} from "@aws-sdk/client-cognito-identity";
import { environment } from "../../../environments/environment";
import * as xml2js from "xml2js"
import { Constants } from "../constants/constants";
import { CookieService } from "ngx-cookie-service";
import { RoleEnum, stringToRole } from "../../models/role.enum";
import { NetworkService } from "../net-utils/network.service";
import { AuthService } from "./auth.service";
import { BeSharperMapper } from "../../mappers/besharper.mapper";
import { IUser } from 'src/app/models/i-user';
import { SimpleMapper } from 'src/app/mappers/simple.mapper';

@Injectable({
  providedIn: 'root',
})
export class AuthUtilsService {
  identityPoolId = environment.cognito.identityPoolId;
  cognitoClient = new CognitoIdentityClient({ region: environment.cognito.cognitoRegion });

  constructor(private cookieService: CookieService, private networkService: NetworkService, private authService: AuthService) { }

  async saveCredentialsInCookies(credentials: Credentials): Promise<void> {
    const accessKey = credentials["AccessKeyId"] || "";
    const secretKey = credentials["SecretKey"] || "";
    const sessionToken = credentials["SessionToken"] || "";
    const expiration = credentials["Expiration"] || undefined;
    const domain = environment.local ? undefined : "intranet.besharp.it";
    this.cookieService.set(Constants.accessKeyCookie, accessKey.toString(), expiration, "/", domain, true);
    this.cookieService.set(Constants.secretKeyCookie, secretKey.toString(), expiration, "/", domain, true);
    this.cookieService.set(Constants.sessionTokenCookie, sessionToken.toString(), expiration, "/", domain, true);
    this.cookieService.set(Constants.expirationCookie, expiration!.toString(), expiration, "/", domain, true);
  }

  async getCredentialsWithSamlResponse(samlResponse: string, identityId: string): Promise<Credentials | undefined> {
    return await this.generateSTSCredentials(identityId, samlResponse);
  }

  async getLocalCredentials(identityId: string, localCredentials: any): Promise<Credentials | undefined> {
    if (identityId !== undefined) {
      const cognitoTemporaryClient = new CognitoIdentityClient({
        region: environment.cognito.cognitoRegion,
        credentials: { accessKeyId: localCredentials.accessKey, secretAccessKey: localCredentials.secretKey }
      });

      const input = {
        IdentityPoolId: environment.cognito.identityPoolId,
        IdentityId: environment.cognito.principalId,
        Logins: {
          [environment.cognito.developerProvider]: environment.cognito.developerId,
        }
      };
      const command = new GetOpenIdTokenForDeveloperIdentityCommand(input);
      const response = await cognitoTemporaryClient.send(command);

      const getCredentialsParams = { Logins: { [environment.cognito.cognitoIdentityUrl]: '' }, IdentityId: '' };
      getCredentialsParams['IdentityId'] = identityId;
      getCredentialsParams['Logins'][environment.cognito.cognitoIdentityUrl] = response.Token!;

      const getCredentialsData = await this.cognitoClient.send(new GetCredentialsForIdentityCommand(getCredentialsParams));
      return getCredentialsData.Credentials;
    } else {
      return undefined;
    }
  }

  async getMatchingRoleFromSAMLResponse(samlResponse: string, samlProviderId: string): Promise<string> {
    const decodedSamlResponse = atob(samlResponse);
    const dom = await xml2js.parseStringPromise(decodedSamlResponse);
    const attributes = dom["saml2p:Response"]["saml2:Assertion"][0]["saml2:AttributeStatement"][0]["saml2:Attribute"];
    const roleAttributes = attributes.find((a: any) => a["$"]["Name"] == 'https://aws.amazon.com/SAML/Attributes/Role')["saml2:AttributeValue"]

    for (let roleAttribute of roleAttributes) {
      const roleValue: string = roleAttribute['_'];
      const roleAttributesParts = roleValue.split(',');
      const roleSamlProvider = roleAttributesParts[0];
      const roleArn = roleAttributesParts[1];

      if (roleSamlProvider === samlProviderId) {
        console.log("Role matches SAML provider");
        return roleArn;
      }
    }

    throw new Error(`No roles found matching SAML provider: ${samlProviderId}`);
  }

  async setLocalUserInformation(originalSamlResponse: string, beSharperId?: string): Promise<IUser> {
    localStorage.setItem(Constants.samlResponseItem, originalSamlResponse);

    const getBesharperUrl = `${environment.cognito.apiEndpoint}${Constants.besharperApiPath}/${beSharperId}`;
    const besharperGetResult = await this.networkService.get(getBesharperUrl, new BeSharperMapper());

    const besharper = besharperGetResult.beSharper;

    const userToSet = {
      name: besharper?.name ?? "",
      surname: besharper?.surname ?? "",
      email: besharper?.email ?? "",
      lineManager: besharper?.lineManager ?? "",
      role: stringToRole(besharper?.role ?? ""),
      id: besharper?.id ?? "",
      photoUrl: besharper?.photoUrl ?? null
    };

    this.authService.setUser(userToSet);
    localStorage.setItem(Constants.userInfo, JSON.stringify(userToSet));

    return userToSet as IUser
  }

  async setLocalMockedUser(originalSamlResponse: string, beSharperId: string): Promise<void> {
    localStorage.setItem(Constants.samlResponseItem, originalSamlResponse);
    const userToSet = {
      name: "Local",
      surname: "User",
      email: "local.user@gmail.com",
      lineManager: "True",
      role: RoleEnum.HR,
      id: beSharperId,
      photoUrl: null
    };

    this.authService.setUser(userToSet);
    localStorage.setItem(Constants.userInfo, JSON.stringify(userToSet));
  }

  private async generateSTSCredentials(identityId: string, samlResponse: string) {
    if (identityId !== undefined) {
      const getCredentialsParams = { Logins: { [environment.cognito.samlProvider]: '' }, IdentityId: '', CustomRoleArn: '' };
      getCredentialsParams['IdentityId'] = identityId;
      getCredentialsParams['Logins'][environment.cognito.samlProvider] = samlResponse;
      getCredentialsParams['CustomRoleArn'] = await this.getMatchingRoleFromSAMLResponse(samlResponse, environment.cognito.samlProvider);

      const getCredentialsData = await this.cognitoClient.send(new GetCredentialsForIdentityCommand(getCredentialsParams));
      return getCredentialsData.Credentials;
    } else {
      return undefined;
    }
  }
}
