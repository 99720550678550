import { ErrorHandler, Injectable } from "@angular/core";
import { ProviderService } from "../provider.service";
import { LogLevel } from "../../models/log-level";
import { TextStrings } from "../constants/text-strings";
import { Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private providerService: ProviderService, private router: Router) { }

  handleError(error: any): void {
    if (error) {
      console.log("Unhandled Error. Message: ", error.stack);
      this.providerService.utilService.setLoader(false);
      if (error?.toString().includes(TextStrings.FORBIDDEN)) {
        this.providerService.utilService.showMessage(error.message, LogLevel.warning);
      } else if (error?.toString().includes(TextStrings.FORBIDDEN)) {
        this.providerService.utilService.showMessage(error.message, LogLevel.info);
        setTimeout(() => {
          this.router.navigate(['/', 'auth', 'sign-in']).then();
        }, 2000);
      } else {
        this.handleSpecificError(error);
      }
    }
  }

  handleSpecificError(error: any) {
    if (error.toString().includes("404")) {
      this.router.navigate(['/', 'not-found']).then();
    } else if (error.toString().includes("[403-tk]")) {
      this.providerService.utilService.showMessage(TextStrings.TOKEN_EXPIRED.replace("[403-tk]", ""), LogLevel.info);
      this.router.navigate(['/', 'auth', 'sign-in']).then();
    } else if (error.toString().includes("403")) {
      this.providerService.utilService.showMessage("You are not authorized to access this page.", LogLevel.warning);
    } else {
      this.providerService.utilService.showMessage(`Ops! Something went wrong. Don't worry, refresh the page and retry, or contact the development Team.\n${error}`, LogLevel.error);
    }
  }
}
