<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{isCreateMode() ? eTextStrings.CRONJOB_CREATE : eTextStrings.CRONJOB_MODIFY}}</h1>

    <form spellcheck="false" [formGroup]="form">
      <!--Name-->
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput type="text" id="name" name="name" formControlName="name" placeholder="Nome" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'name'" [errorCode]="'required'"
        [icon]="'fa-regular fa-circle-exclamation'" message="Inserire un nome"></app-validator-ui>

      <!--Action-->
      <mat-form-field appearance="fill">
        <mat-label>Azione</mat-label>

        <mat-select id="action" name="action" formControlName="action" [(ngModel)]="form.controls.action.value" (selectionChange)="args = null">
          <mat-option value="SendEmail">Invia Email</mat-option>
          <mat-option value="RemindOutOfOffice">Invia notifica per out of office in attesa</mat-option>
          <mat-option value="NotifyFailedIntegration">Notifica integrazioni calendar fallite</mat-option>
          <mat-option value="NotifyFailedIntegration">Notifica integrazioni calendar fallite</mat-option>
          <mat-option value="NotifyExpiringConsumables">Notifica consumabili in esaurimento</mat-option>
          <mat-option value="LogMessage" disabled="true">Stampa Messaggio su Console</mat-option>
        </mat-select>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'action'" [errorCode]="'required'"
        [icon]="'fa-regular fa-circle-exclamation'" message="Inserire azione"></app-validator-ui>


      <ng-container [ngSwitch]="form.controls.action.value">
        <!--Args-->
        <app-log-message-args-form *ngSwitchCase="'LogMessage'" [args]="args" (argsChange)="onArgsChange($event)"/>
        <app-send-email-args-form *ngSwitchCase="'SendEmail'" [args]="args" (argsChange)="onArgsChange($event)"/>
        <app-remind-ooo-args-form *ngSwitchCase="'RemindOutOfOffice'" [args]="args" (argsChange)="onArgsChange($event)"/>
        <app-notify-failed-integrations-args-form *ngSwitchCase="'NotifyFailedIntegration'" [args]="args" (argsChange)="onArgsChange($event)"/>
        <app-notify-expiring-consumables-args-form *ngSwitchCase="'NotifyExpiringConsumables'" (argsChange)="onArgsChange($event)"/>
      </ng-container>


      <!--Schedule-->
      <mat-form-field appearance="fill">
        <mat-label>Crontab</mat-label>
        <input matInput type="text" id="schedule" name="schedule" formControlName="schedule" value="0 0 * * *">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'schedule'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire Crontab"></app-validator-ui>
    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>{{eTextStrings.BUTTONS_BACK}}</span>
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button" (click)="createCronjob()" [disabled]="!formValid() || loading">
      {{eTextStrings.CRONJOB_CREATE}}
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
            type="button" (click)="createCronjob(true)" [disabled]="!formValid() || loading">
      {{eTextStrings.CRONJOB_CREATE_AND_CONTINUE}}
    </button>
    <button *ngIf="isModifyMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button" (click)="modifyCronjob();" [disabled]="!formValid() || loading">
      {{eTextStrings.CRONJOB_MODIFY}}
    </button>
  </div>
</div>
