import { Injectable } from '@angular/core';
import { S3 } from '@aws-sdk/client-s3';
import { environment } from '../../../environments/environment';
import { Constants } from '../constants/constants';
import { v4 } from 'uuid';
import { CookieService } from "ngx-cookie-service";
@Injectable({
  providedIn: 'root',
})
export class S3Service {
  constructor(private cookieService: CookieService) {}
  async uploadImage(file: File): Promise<string> {
    const s3Client = this.prepareS3Client();
    return new Promise((resolve, reject) => {
      const fileNameOnS3 = v4() + '-' + file.name;
      const params = {
        Bucket: environment.imageBucket.name,
        Key: fileNameOnS3,
        Body: file,
        ContentType: file.type,
      };
      s3Client.putObject(params, (err: Error, _data: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(fileNameOnS3);
        }
      });
    });
  }
  prepareS3Client(): S3 {
    const credentials = {
      accessKeyId: this.cookieService.get(Constants.accessKeyCookie),
      secretAccessKey: this.cookieService.get(Constants.secretKeyCookie),
      sessionToken: this.cookieService.get(Constants.sessionTokenCookie),
    };
    return new S3({
      region: environment.imageBucket.region,
      credentials: {
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      },
    });
  }
}
