import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProviderService } from '../../../../core/provider.service';
import { EquipmentDetailMapper } from '../../../../mappers/equipment.mapper';
import { EquipmentModel } from '../../../../models/equipment.model';
import { ModeEnum } from '../../../../core/constants/enums';
import { RoleEnum } from "../../../../models/role.enum";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import IBasePage from "../../../../core/interfaces/i-base-page";
import {CreateModifyBesharperEquipmentComponent} from "../../../dialogs/create-modify-besharper-equipment/create-modify-besharper-equipment.component";

@Component({
  selector: 'app-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrl: './equipment-detail.component.scss',
})
export class EquipmentDetailComponent extends IBasePage implements OnInit {
  equipment?: EquipmentModel;
  id = '';
  equipmentBackendUrl = environment.cognito.apiEndpoint;
  loading = false;
  mapper = new EquipmentDetailMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;

  constructor(private activatedRoute: ActivatedRoute, protected override router: Router, private providerService: ProviderService) {
    super(router, providerService.authService, providerService.utilService);
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        this.id = params['id'];
        this.equipmentBackendUrl += `equipment/${this.id}`;
        this.getEquipment();
      })
      .unsubscribe();
  }

  modifyEquipment(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperEquipmentComponent,
      data: {
        mode: ModeEnum.MODIFY,
        beSharperId: this.equipment?.besharperId,
        equipment: this.equipment,
      },
      closeCallback: () => this.getEquipment()
    });
  }

  async deleteEquipment(): Promise<void> {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.equipmentBackendUrl,
      closeCallback: () => this.providerService.utilService.navigateBack(),
    });
  }

  private getEquipment() {
    this.loading = true;
    this.providerService.networkService
      .get(this.equipmentBackendUrl, this.mapper)
      .then(() => {
        this.equipment = this.mapper.equipment;
      }).finally(() => {
        this.loading = false;
      });
  }

  protected readonly JSON = JSON;
}
