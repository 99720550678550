<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>Settaggi Globali</h1>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>
<ng-container [formGroup]="form" *ngIf="!loading">
  <br>
  <ul class="setting-list">
    <li *ngFor="let setting of settings">
      <mat-form-field appearance="fill">
        <mat-label>{{setting.name}}</mat-label>
        <input matInput placeholder="" formControlName="{{setting.name}}">
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="modifySetting(setting.id, setting.name)"><i class="fa fa-pencil" aria-hidden="true"></i> Salva</button>
    </li>
  </ul>
</ng-container>
