import { BeSharperModel } from "./be-sharper.model";
import { IUser } from "./i-user";
import { RequestStatus } from "./out-of-office.enums";
import RoleEnum from "./role.enum";
import { OutOfOfficeAttachment } from "./out-of-office-attachment";

export type OutOfOfficeModel = {
  id: string;
  besharperId: string;
  besharperName: string;
  besharperSurname: string;
  typeId: string,
  typeName: string,
  startDate: string;
  endDate: string;
  status: RequestStatus;
  description: string;
  smartWorkingReason: string | null;
  isRecurrent: boolean;
  correlationId: string | null;
  daysOfTheWeek: string;
  createdAt: Date;
  updatedAt: Date | null;
  approvers: {
    lineManager: BeSharperModel | null,
    tpm: BeSharperModel | null,
    supervisor: BeSharperModel | null,
  }
  allowedActions: string[];
  approverNotes?:string;
  attachments?: OutOfOfficeAttachment[];
  noteFieldValue?: string;
}

export function canBesharperPerformActionOnOutOfOffice(besharper: IUser, action: string, outOfOffice: OutOfOfficeModel,): boolean {
  const isHr = [RoleEnum.HR, RoleEnum.HR_REPORTS, RoleEnum.ADMIN].includes(besharper.role);
  const isSupervisor = outOfOffice.approvers.supervisor?.id === besharper.id;
  const isLineManager = outOfOffice.approvers.lineManager?.id === besharper.id;
  const isTpm = outOfOffice.approvers.tpm?.id === besharper.id;
  const isOwner = outOfOffice.besharperId === besharper.id;

  if (isHr && outOfOffice.allowedActions.includes(`hr:${action}`)) {
    return true;
  }

  if (isOwner && outOfOffice.allowedActions.includes(`owner:${action}`)) {
    return true;
  }

  if (isSupervisor && outOfOffice.allowedActions.includes(`supervisor:${action}`)) {
    return true;
  }

  if (isLineManager && outOfOffice.allowedActions.includes(`lm:${action}`)) {
    return true;
  }

  if (isTpm && outOfOffice.allowedActions.includes(`tpm:${action}`)) {
    return true;
  }

  return false;
}
