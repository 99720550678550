<div>
  <div class="align-right">
    <button mat-raised-button color="primary" (click)="createRecurrentOutOfOffice();" *ngIf="currentUser.role === eRoleEnum.HR_REPORTS || currentUser.role === eRoleEnum.HR || currentUser.role === eRoleEnum.ADMIN">
      <i class="fa-solid fa-user"></i>&nbsp;
      &nbsp;
      <span>{{eTextStrings.OUT_OF_OFFICE_CREATE_RECURRENT}}</span>
    </button>
  </div>
</div>

<div class="search-row">
  <h1>{{eTextStrings.RECURRENT_SW}}</h1>
  <div class="search-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.OUT_OF_OFFICE_CREATE_SEARCH}} Per nome</mat-label>
      <input matInput (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
    </mat-form-field>
  </div>
  <br>
  <div class="days-query-filter">
    <mat-label>Cerca per giorni della ricorrenza</mat-label>
    <ul>
      <li><mat-checkbox (change)="toggleDay(0)" [checked]="daysOfTheWeek[0] === '1'">LUN</mat-checkbox></li>
      <li><mat-checkbox (change)="toggleDay(1)" [checked]="daysOfTheWeek[1] === '1'">MAR</mat-checkbox></li>
      <li><mat-checkbox (change)="toggleDay(2)" [checked]="daysOfTheWeek[2] === '1'">MER</mat-checkbox></li>
      <li><mat-checkbox (change)="toggleDay(3)" [checked]="daysOfTheWeek[3] === '1'">GIO</mat-checkbox></li>
      <li><mat-checkbox (change)="toggleDay(4)" [checked]="daysOfTheWeek[4] === '1'">VEN</mat-checkbox></li>
    </ul>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<app-table-ui *ngIf="showTable"
              [backendUrl]="outOfOfficeListUrl"
              [datasource]="holidaysDataSource"
              [tableDefinitions]="tableDefinitions"
              [actionDefinitions]="(userRole === eRoleEnum.HR || userRole === eRoleEnum.HR_REPORTS || userRole === eRoleEnum.ADMIN) ? actionDefinitions : undefined"
              [defaultActive]="'startDate'"
              [defaultDirection]="'desc'"
              [mapper]="mapper"
              [role]="currentUser.role"
              (rowClicked)="rowClicked($event);"
              [hasRowAction]=true
              [pageSize]="10"
              [pageSizeOptions]="[10,20,30]"
              [daysOfTheWeekObservable]="daysOfTheWeekObservable"
              [filterObservable]="filterObservable">
</app-table-ui>
