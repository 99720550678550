<div class="loader" *ngIf="providerService.utilService.isLoaderEnabled()">
  <div class="loader-element">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <img src="assets/images/logo-beSharp-high-res.png" alt="logo" title="logo">
  </div>
</div>
