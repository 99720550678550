import { Component, Inject, OnInit } from "@angular/core";
import { TextStrings } from "../../../core/constants/text-strings";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { ModeEnum } from "../../../core/constants/enums";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { IListMapper, IMapper } from "../../../models/i-mapper";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";
import { BeSharperModel } from "../../../models/be-sharper.model";
import RoleEnum from "../../../models/role.enum";
import { ContractModel } from "../../../models/contract.model";

@Component({
  selector: 'app-admin-panel-modify-user-contract-role',
  templateUrl: './admin-panel-modify-user-contract-role.component.html',
  styleUrl: './admin-panel-modify-user-contract-role.component.scss'
})
export class AdminPanelModifyUserContractRoleComponent implements OnInit {
  loading = false;

  form = new FormGroup({
    besharper: new FormControl('', Validators.required),
    contract: new FormControl('', Validators.required),
    role: new FormControl('', Validators.required),

    isTpm: new FormControl(false),
    isLineManager: new FormControl(false),

    tpm: new FormControl(''),
    lineManager: new FormControl(''),
    supervisor: new FormControl(''),
  });

  modifyUserContractRoleUrl = environment.cognito.apiEndpoint + Constants.backOfficeApiPath;

  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  backendUrlContract = environment.cognito.apiEndpoint + Constants.contractApiPath;

  filteredBesharpers: BeSharperModel[] = [];
  filteredTpmBesharpers: BeSharperModel[] = [];
  filteredLinemanagerBesharpers: BeSharperModel[] = [];
  filteredSupervisorBesharpers: BeSharperModel[] = [];

  selectedBesharper?: BeSharperModel;
  selectedTpmBesharper?: BeSharperModel;
  selectedLinemanagerBesharper?: BeSharperModel;
  selectedSupervisorBesharper?: BeSharperModel;


  besharpers: BeSharperModel[] = [];
  roles = Object.keys(RoleEnum).map(key => ({ id: (RoleEnum as any)[key], name: key }));

  protected readonly eTextStrings = TextStrings;
  protected readonly ModeEnum = ModeEnum;
  protected selectedContract?: ContractModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelModifyUserContractRoleComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;

    const dataFetchPromises: Promise<void>[] = [
      this.getBesharpers(),
    ]
    await Promise.all(dataFetchPromises);

    this.filteredBesharpers = this.besharpers;
    this.filteredTpmBesharpers = this.besharpers;
    this.filteredLinemanagerBesharpers = this.besharpers;
    this.filteredSupervisorBesharpers = this.besharpers;

    if (this.data.data) {
      await this.fillFormControls();
    }

    if (this.data.selectedBesharper) {
      this.form.controls.besharper.setValue(this.data.selectedBesharper);
    }

    if (this.data.tpm) {
      this.form.controls.tpm.setValue(this.data.tpm.id);
    }
    if (this.data.lineManager) {
      this.form.controls.lineManager.setValue(this.data.lineManager.id);
    }
    if (this.data.supervisor) {
      this.form.controls.supervisor.setValue(this.data.supervisor.id);
    }

    this.loading = false;
  }

  async sendRequest(): Promise<void> {
    await this.sendModifyRequest();
  }

  async sendModifyRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new SimpleMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .put(`${this.modifyUserContractRoleUrl}/setup/besharper`, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      besharperId:this.selectedBesharper?.id,
      contractId: this.selectedContract?.id,
      role: this.form.controls.role.value,
      isTpm: this.form.controls.isTpm.value,
      isLineManager: this.form.controls.isLineManager.value,
      isSupervisor: !!this.form.controls.supervisor.value,
      tpmId: this.form.controls.tpm.value,
      lineManagerId: this.form.controls.lineManager.value,
      supervisorId: this.form.controls.supervisor.value,
    });
  }

  async fillFormControls(): Promise<void> {
    this.form.controls.besharper.setValue(this.data.data.besharper.id);
    this.form.controls.role.setValue(this.data.data.role);

    this.form.controls.isTpm.setValue(this.data.data.isTpm);
    this.form.controls.isLineManager.setValue(this.data.data.isLineManager);

    this.form.controls.tpm.setValue(this.data.data.tpm?.id);
    this.form.controls.lineManager.setValue(this.data.data.lineManager?.id);
    this.form.controls.supervisor.setValue(this.data.data.supervisor?.id);

    this.selectedBesharper = this.data.data.besharper;

    await this.setCurrentContract();
  }
  displaySelectedBesharper(id: string): string {
    const selectedBesharper = this.besharpers.find((_) => _.id === id);
    return selectedBesharper ? selectedBesharper?.name + ' ' + selectedBesharper?.surname : '';
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  filterTpm(): void {
    if (this.form.controls.tpm.value) {
      const userInput = this.form.controls.tpm?.value?.toLowerCase();
      this.filteredTpmBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredTpmBesharpers = this.besharpers;
    }
  }

  filterLm(): void {
    if (this.form.controls.lineManager.value) {
      const userInput = this.form.controls.lineManager?.value?.toLowerCase();
      this.filteredLinemanagerBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredLinemanagerBesharpers = this.besharpers;
    }
  }

  filterSupervisor(): void {
    if (this.form.controls.supervisor.value) {
      const userInput = this.form.controls.supervisor?.value?.toLowerCase();
      this.filteredSupervisorBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput || "") || b.surname.toLowerCase().includes(userInput || "")
      );
    } else {
      this.filteredSupervisorBesharpers = this.besharpers;
    }
  }

  async onBesharperSelected(besharper: BeSharperModel): Promise<void> {
    this.selectedBesharper = besharper;
    try {
      await this.setCurrentContract();
    } catch (error) {}
  }
  onBesharperTpmSelected(besharper: BeSharperModel) {
    this.selectedTpmBesharper = besharper;
  }
  onBesharperLineManagerSelected(besharper: BeSharperModel) {
    this.selectedLinemanagerBesharper = besharper;
  }
  onBesharperSupervisorSelected(besharper: BeSharperModel) {
    this.selectedSupervisorBesharper = besharper;
  }

  async getBesharpers(): Promise<void> {
    try {
      const mapper: IListMapper = new BeSharperListMapper();
      await this.providerService.networkService.get(this.backendUrlBesharper, mapper, { limit: '999999', offset: '0', orderBy: 'name' });
      this.besharpers = mapper.elements;
    } catch (e) {
      this.providerService.utilService.showMessage(`${e}`, LogLevel.error);
    }
  }

  showTpm(): boolean {
    const showTpm = this.form.controls.isLineManager.value === false && this.form.controls.isTpm.value === false;
    if(!showTpm) {
      this.form.controls.tpm.setValue(null);
    }
    return showTpm;
  }

  showLineManager(): boolean {
    const showLineManager = this.form.controls.isLineManager.value === false;
    if(!showLineManager) {
      this.form.controls.lineManager.setValue(null);
    }
    return showLineManager;
  }

  showSupervisor(): boolean {
    const showSupervisor =  this.form.controls.isLineManager.value === true;
    if(!showSupervisor) {
      this.form.controls.supervisor.setValue(null);
    }
    return showSupervisor;
  }

  async setCurrentContract() {
    if(this.selectedBesharper) {
      try {
        this.form.controls.contract.setValue(null);
        const getContractUrl = environment.cognito.apiEndpoint + Constants.besharperApiPath + `/${this.selectedBesharper?.id}/contract/current`;
        const currentContract = (await this.providerService.networkService.get(getContractUrl, new SimpleMapper()));
        this.selectedContract = currentContract?.body;
        const stringToShow = this.selectedContract?.id || '';
        this.form.controls.contract.setValue(stringToShow);
      } catch (error) {
        this.providerService.utilService.showMessage("Non ho trovato un contratto corrente valido, crealo a partire dal link a destra!", LogLevel.info);
      }
    } else {
      this.providerService.utilService.showMessage("Seleziona o crea prima un beSharper!", LogLevel.info);
    }
  }

  protected readonly JSON = JSON;
}
