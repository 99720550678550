<div class="admin-panel-container">
  <button mat-raised-button color="primary" [routerLink]="['set-besharper-dropdown-menus']">
    <i class="fa-solid fa-user"></i>
    <span>{{eTextStrings.BESHARPER_ROLE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-welfare-dropdown-menus']">
    <i class="far fa-umbrella-beach"></i>
    <span>{{eTextStrings.WELFARE_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-welfare-reason-menus']">
    <i class="far fa-umbrella-beach"></i>
    <span>{{eTextStrings.WELFARE_REASON_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-welfare-maximals-menus']">
    <i class="far fa-umbrella-beach"></i>
    <span>{{eTextStrings.WELFARE_MAXIMALS_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-contracts-dropdown-menus']">
    <i class="far fa-file-contract"></i>
    <span>{{eTextStrings.CONTRACT_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-business-units-dropdown-menus']">
    <i class="far fa-users"></i>
    <span>{{eTextStrings.BU_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-equipment-types-dropdown-menus']">
    <i class="far fa-cog"></i>
    <span>{{eTextStrings.EQ_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-out-of-office-types-dropdown-menus']">
    <i class="far fa-file-contract"></i>
    <span>{{eTextStrings.OOO_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-smart-working-types-dropdown-menus']">
    <i class="far fa-lightbulb"></i>
    <span>{{eTextStrings.SMART_WORKING_TYPE_CREATE_MODIFY}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-settings']">
    <i class="far fa-gear"></i>
    <span>{{eTextStrings.SET_SETTINGS}}</span>
  </button>
  <button mat-raised-button color="primary" [routerLink]="['set-user-contract-role']">
    <i class="far fa-user"></i>
    <span>{{eTextStrings.SET_USER_CONTRACT_ROLE}}</span>
  </button>
</div>
