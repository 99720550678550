import { Component } from '@angular/core';
import { CoreModule } from "../../../../core/core.module";
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader, MatExpansionPanelTitle
} from "@angular/material/expansion";
import { MatButton } from "@angular/material/button";
import { MatTooltip } from "@angular/material/tooltip";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { TextStrings } from "../../../../core/constants/text-strings";
import { environment } from "../../../../../environments/environment";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { Constants } from "../../../../core/constants/constants";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { ModeEnum } from "../../../../core/constants/enums";
import { OutOfOfficeTypeModel } from "../../../../models/out-of-office-type.model";
import { OutOfOfficeTypeListMapper } from "../../../../mappers/out-of-office.mapper";
import { AdminPanelCreateModifyOutOfOfficeTypeComponent } from "../../../dialogs/admin-panel-create-modify-out-of-office-type/admin-panel-create-modify-out-of-office-type.component";

@Component({
  selector: 'app-set-out-of-office-types-dropdown-menus',
  templateUrl: './set-out-of-office-types-dropdown-menus.component.html',
  styleUrl: './set-out-of-office-types-dropdown-menus.component.scss'
})
export class SetOutOfOfficeTypesDropdownMenusComponent extends IFilteredTablePage {
  eTextStrings = TextStrings;
  loading = false;
  basePath = environment.cognito.apiEndpoint;

  outOfOfficeTypesDataModel: TableDataSource<OutOfOfficeTypeModel> = new TableDataSource<OutOfOfficeTypeModel>();
  outOfOfficeTypesCollapsed = true;
  outOfOfficeTypesBackendUrl = this.basePath + Constants.outOfOfficeTypeApiPath;
  outOfOfficeTypesMapper: OutOfOfficeTypeListMapper = new OutOfOfficeTypeListMapper();

  outOfOfficeTypesTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'code', title: 'Codice OOO', sortable: true },
    { def: 'offsetDays', title: 'N° Giorni entro cui fare richiesta', sortable: false, transformer: (value: any)  => value ?? 1 },
  ];
  outOfOfficeTypesActionsDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: OutOfOfficeTypeModel) => {
        this.deleteItem(item.id);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.outOfOfficeTypesCollapsed = !this.outOfOfficeTypesCollapsed;
  }

  openCreateoutOfOfficeTypesDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyOutOfOfficeTypeComponent,
      data: {
        mode: ModeEnum.CREATE
      }
    });
  }

  deleteItem(id: string): void {
    const apiUrl = `${this.outOfOfficeTypesBackendUrl}/${id}`;

    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: apiUrl!,
    });
  }

  openModifyDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyOutOfOfficeTypeComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row
      }
    });
  }
}
