import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { BonusModel, BonusSummaryModel } from '../models/bonus.model';
import { SettingModel } from "../models/setting-model";

export class SettingsListMapper implements IListMapper {
  count = 0;
  elements: SettingModel[] = [];

  fillFromJson(json: any): PagedModel<SettingModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapSetting(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  mapSetting(value: any): SettingModel {
    return {
      id: value['id'],
      name: value['name'],
      value: value['value'],
    };
  }
}

export class SettingPostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class BonusDetailMapper implements IMapper {
  bonus?: BonusModel;

  fillFromJson(json: any): BonusModel {
    //console.log(json);
    this.bonus = {
      id: json['id'],
      beSharperName: json['besharper']['name'],
      besharperAddress: json['besharper']['address'],
      besharperBirthDate: json['besharper']['birthDate'],
      besharperCreatedAt: json['besharper']['createdAt'],
      besharperEmail: json['besharper']['email'],
      besharperId: json['besharper']['id'],
      besharperPhotoUrl: json['besharper']['photoUrl'],
      besharperSchool: json['besharper']['school'],
      besharperSurname: json['besharper']['surname'],
      besharperUpdatedAt: json['besharper']['updatedAt'],
      createdAt: json['createdAt'],
      reason: json['reason'],
      supplyDate: json['supplyDate'],
      updatedAt: json['updatedAt'],
      value: json['value'],
    };

    return this.bonus;
  }

  fillToJson(): string {
    return '';
  }
}
