import { Component } from '@angular/core';

@Component({
  selector: 'app-set-bonus-dropdown-menus',
  templateUrl: './set-bonus-dropdown-menus.component.html',
  styleUrl: './set-bonus-dropdown-menus.component.scss'
})
export class SetBonusDropdownMenusComponent {

}
