import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IMapper } from '../../../models/i-mapper';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import { BusinessUnitMapper } from "../../../mappers/business-unit.mapper";

@Component({
  selector: 'app-admin-panel-create-modify-business-unit',
  templateUrl: './admin-panel-create-modify-business-unit.component.html',
  styleUrl: './admin-panel-create-modify-business-unit.component.scss'
})
export class AdminPanelCreateModifyBusinessUnitComponent {
  loading = false;
  formMode = ModeEnum.CREATE;
  dropDownName = '';

  modifyBusinessUnitUrl = environment.cognito.apiEndpoint + Constants.businessUnitsApiPath;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  eTextStrings = TextStrings;
  protected readonly ModeEnum = ModeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyBusinessUnitComponent>
  ) {
    this.formMode = this.data.mode;
    if (data.mode === ModeEnum.MODIFY) {
      this.fillControls();
    }
  }

  async sendRequest(): Promise<void> {
    if (this.formMode === ModeEnum.MODIFY) {
      await this.sendModifyRequest();
    } else {
      await this.sendCreateRequest();
    }
  }

  async deleteItem(): Promise<void> {
    this.providerService.utilService.openDialog(ConfirmDeleteComponent, { url: `${this.modifyBusinessUnitUrl}/${this.data.data.id}`})
    .afterClosed()
    .subscribe(() => {
      document.location.reload();
    });
  }

  async sendCreateRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();
    this.loading = true;
    const createRequestMapper = new BusinessUnitMapper();
    this.fillRequestBody(createRequestMapper);
    this.providerService.networkService
      .post(this.modifyBusinessUnitUrl, createRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });

  }

  async sendModifyRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();

    this.loading = true;
    const updateRequestMapper = new BusinessUnitMapper();
    this.fillRequestBody(updateRequestMapper);
    this.providerService.networkService
      .put(`${this.modifyBusinessUnitUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });
  }
  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({ name: this.form.controls.name.value });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
  }
}
