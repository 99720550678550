import { AfterContentInit, Component, OnInit } from "@angular/core";
import { ProviderService } from "../../../../core/provider.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Constants } from "../../../../core/constants/constants";
import { OutOfOfficeDetailsMapper, OutOfOfficeListMapper } from "../../../../mappers/out-of-office.mapper";
import { LogLevel } from "../../../../models/log-level";
import { RoleEnum } from "../../../../models/role.enum";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { canBesharperPerformActionOnOutOfOffice, OutOfOfficeModel } from "../../../../models/out-of-office.model";
import { CreateModifyOutOfOfficeComponent } from "../../../dialogs/create-modify-out-of-office/create-modify-out-of-office.component";
import { SimpleMapper } from "../../../../mappers/simple.mapper";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { ConfirmDeleteComponent } from "../../../dialogs/confirm-delete/confirm-delete.component";
import { CreateModifyRecurrentOooComponent } from "../../../dialogs/create-modify-recurrent-ooo/create-modify-recurrent-ooo.component";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { DialogBoxComponent } from "../../../dialogs/dialog-box/dialog-box.component";


@Component({
  selector: 'app-out-of-office-details',
  templateUrl: './out-of-office-details.component.html',
  styleUrl: './out-of-office-details.component.scss',
})
export class OutOfOfficeDetailsComponent extends IFilteredTablePage implements AfterContentInit, OnInit {
  outOfOfficeBEUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;

  outOfOfficeId = '';
  outOfOfficeLineManagerId!: string;
  outOfOfficeTpmId!: string;
  requestStatus: string = "";

  outOfOffice: OutOfOfficeModel = undefined!;
  loading = false;

  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;

  daysOfTheWeek = "00000";

  childrenUrl = "";
  mapper = new OutOfOfficeListMapper();

  childrenDataSource: TableDataSource<OutOfOfficeModel> = new TableDataSource<OutOfOfficeModel>();
  tableDefinitions = [
    { def: 'besharperName', title: TextStrings.NAME, sortable: false },
    { def: 'besharperSurname', title: TextStrings.LASTNAME, sortable: false },
    { def: 'typeName', title: TextStrings.OUT_OF_OFFICE_CREATE_TYPE, sortable: false },
    { def: 'createdAt', title: TextStrings.OUT_OF_OFFICE_CREATED_AT, sortable: false, transformer: (value: string) => this.formatDate(value) },
    { def: 'startDate', title: TextStrings.OUT_OF_OFFICE_CREATE_STARTDATE, sortable: true, transformer: (value: string) => this.formatDate(value).split(" ")[0] },
    { def: 'endDate', title: TextStrings.OUT_OF_OFFICE_CREATE_ENDDATE, sortable: true, transformer: (value: string) => this.formatDate(value).split(" ")[0] },

    { def: 'startTime', title: "Ora inizio", sortable: false, virtual: (cell: any) => this.formatDate(cell.startDate).split(" ")[1] },
    { def: 'endTime', title: "Ora fine", sortable: false, virtual: (cell: any) => this.formatDate(cell.endDate).split(" ")[1] },

    { def: 'daysOfTheWeek', title: TextStrings.DAYS_OF_THE_WEEK, sortable: false, transformer: (value: string) => this.formatDaysOfTheWeek(value) },
  ];
  actionDefinitions = [
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.DELETE,
      styleClass: 'table-action-delete',
      callback: (row: any) => {
        this.deleteChildRequest(row).then();
      },
    }
  ];

  mobileWindowFilter = true;

  constructor(
    protected override router: Router,
    private providerService: ProviderService,
    private activeRoute: ActivatedRoute
  ) {
    super(router, providerService.authService, providerService.utilService);
  }

  async ngOnInit() {
    this.activeRoute.params.subscribe(async (params: any) => {
      this.outOfOfficeId = params['id'];
      this.childrenUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath + "/" + this.outOfOfficeId + "/children";
    }).unsubscribe();
  }

  async ngAfterContentInit(): Promise<void> {
    this.loading = true;
    this.outOfOffice = await this.getOutOfOfficeInfo(this.outOfOfficeId);
    const officeApproversUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApproversPath(this.outOfOfficeId);
    console.log(officeApproversUrl)
    const response = await this.providerService.networkService.get(officeApproversUrl, new SimpleMapper());
    console.log(response);
    this.outOfOfficeLineManagerId = response.body?.lineManager?.id;
    this.outOfOfficeTpmId = response.body?.tpm?.id;

    this.requestStatus = TextStrings['OUT_OF_OFFICE_STATUS_' + this.outOfOffice?.status?.toString()?.toUpperCase()];

    if(this.currentUser.role === RoleEnum.EMPLOYEE && (this.outOfOffice?.status?.toString()?.toUpperCase() === "APPROVEDBYTPM" || this.outOfOffice?.status?.toString()?.toUpperCase() === "DENIEDBYTPM")) {
      this.requestStatus = TextStrings['OUT_OF_OFFICE_STATUS_WAITINGFORAPPROVAL'];
    }

    this.loading = false;
  }

  async getOutOfOfficeInfo(id: string): Promise<any> {
    const requestMapper = new OutOfOfficeDetailsMapper();
    const apiResponse = await this.providerService.networkService.get(`${this.outOfOfficeBEUrl}/${id}`, requestMapper);
    return apiResponse.response;
  }

  async denyRequest(): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService.openDialog(DialogBoxComponent, {
        title: "Rifiuta la richiesta",
        description: "aggiungi una motivazione per rifiutare questa richiesta",
        url: `${this.outOfOfficeBEUrl}/${this.outOfOffice.id}/denial`,
        returnPath: null,
        method: "post",
        callback: () => {
          this.loading = false;
          this.providerService.utilService.navigateTo(Constants.outOfOfficePath).then()
        },
        schema: {
          type: 'object',
          properties: {
            notes: {
              title: "Motivazione",
              type: "string"
            } },
          required: ["notes"]
        }
      });
    } catch (e: any) {
      this.providerService.utilService.showMessage(TextStrings.DENY_PERMISSION_ERROR + e.toString(), LogLevel.error);
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  async approveRequest(): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService.openDialog(DialogBoxComponent, {
        title: "Approva la richiesta",
        description: "aggiungi una motivazione (opzionale) per approvare questa richiesta",
        url: `${this.outOfOfficeBEUrl}/${this.outOfOffice.id}/approval`,
        returnPath: null,
        method: "post",
        callback: () => {
          this.loading = false;
          this.providerService.utilService.navigateTo(Constants.outOfOfficePath).then()
        },
        schema: {
          type: 'object',
          properties: {
            notes: {
              title: "Motivazione",
              type: "string"
            } },
          required: []
        }
      });
    } catch (e: any) {
      this.providerService.utilService.showMessage(TextStrings.APPROVE_PERMISSION_ERROR, LogLevel.error);
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }

  get dateRange(): string {
    if (this.outOfOffice === undefined) {
      return '-';
    }

    return `${this.formatDate(this.outOfOffice.startDate)} - ${this.formatDate(this.outOfOffice.endDate)}`;
  }

  canPerformAction(action: string): boolean {
    if (!this.outOfOffice) {
      return false;
    }

    return canBesharperPerformActionOnOutOfOffice(this.currentUser, action, this.outOfOffice);
  }

  openDeleteDialog(): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.outOfOfficeBEUrl}/${this.outOfOfficeId}`,
      description: "Eliminare la richiesta?",
      closeCallback: (result) => {
        if (result) {
          this.providerService.utilService.navigateBack();
        }
      }
    })
  }

  openModifyDialog(): void {
    const oooData: OutOfOfficeModel = this.getOooData();
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyOutOfOfficeComponent,
      data: { outOfOfficeData: oooData, openedFromDetailsPage: true },
      closeCallback: () => this.providerService.utilService.refreshPage()
    });
  }

  formatDaysOfTheWeek(daysBits: string): string {
    if (daysBits) {
      const map = ["LUN", "MAR", "MER", "GIO", "VEN"];
      const daysArray = daysBits.split("");
      let result = "";
      for (let i = 0; i < daysArray.length; i++) {
        if (daysArray[i] === "1") {
          result += `<span class="days-of-the-week">${map[i]}</span>`;
        }
      }
      return result;
    } else return "-";
  }

  async deleteChildRequest(ooo: any): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService
        .openDialog(ConfirmDeleteComponent, {
          url: `${this.outOfOfficeBEUrl}/${ooo['id']}`,
          returnPath: Constants.outOfOfficeDetailPath + "/" + this.outOfOfficeId
        })
        .afterClosed()
        .subscribe(() => {
          this.filterObservable.next(this.lastDebounceValue);
        });
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  openModifyDialogRecurrent() {
    const oooData: OutOfOfficeModel = this.getOooData();
    console.log('OOO DATA RECURRENT: ', oooData);
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyRecurrentOooComponent,
      data: { outOfOfficeData: oooData, openedFromDetailsPage: true },
      closeCallback: () => this.providerService.utilService.refreshPage()
    });
  }

  toggleDay(number: number) {
    const days = this.daysOfTheWeek.split("");
    days[number] = days[number] === "1" ? "0" : "1";
    this.daysOfTheWeek = days.join("");
    this.sendDaysOfWeekQuery(this.daysOfTheWeek);
  }

  toggleMobileWindow() {
    this.mobileWindowFilter = !this.mobileWindowFilter;
    this.sendMobileWindowQuery(this.mobileWindowFilter);
  }


  private getOooData() {
    return {
      id: this.outOfOffice.id,
      besharperId: this.outOfOffice.besharperId,
      besharperName: this.outOfOffice.besharperName,
      besharperSurname: this.outOfOffice.besharperSurname,
      typeId: this.outOfOffice.typeId,
      typeName: this.outOfOffice.typeName,
      status: this.outOfOffice.status,
      startDate: this.outOfOffice.startDate,
      endDate: this.outOfOffice.endDate,
      smartWorkingReason: this.outOfOffice.smartWorkingReason,
      description: "",
      createdAt: this.outOfOffice.createdAt,
      updatedAt: this.outOfOffice.updatedAt,
      isRecurrent: this.outOfOffice.isRecurrent,
      correlationId: this.outOfOffice.correlationId,
      daysOfTheWeek: this.outOfOffice.daysOfTheWeek,
      allowedActions: this.outOfOffice.allowedActions,
      approvers: this.outOfOffice.approvers
    } as OutOfOfficeModel;
  }
}
