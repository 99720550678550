import { Component } from "@angular/core";
import { IFilteredTablePage } from "../../../core/interfaces/i-filtered-table-page";
import { ProviderService } from "../../../core/provider.service";
import { Router } from "@angular/router";
import { Constants } from "../../../core/constants/constants";
import { TableDataSource } from "../../../core/form-utils/table/table.datasource";
import { WelfareModel, WelfareReasonModel } from "../../../models/welfare.model";
import { WelfareListMapper } from "../../../mappers/welfare.mapper";
import { CreateModifyWelfareComponent } from "../../dialogs/create-modify-welfare/create-modify-welfare.component";
import { RoleEnum } from "../../../models/role.enum";
import { ModeEnum } from "../../../core/constants/enums";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { WelfareReportComponent } from "../../dialogs/welfare-report/welfare-report.component";

@Component({
  selector: "app-welfare",
  templateUrl: "./welfare.component.html",
  styleUrl: "./welfare.component.scss",
})
export class WelfareComponent extends IFilteredTablePage {
  loading = false;
  backendUrl = environment.cognito.apiEndpoint + Constants.welfareApiPath;
  dataSource: TableDataSource<WelfareModel> = new TableDataSource<WelfareModel>();
  welfareMapper: WelfareListMapper = new WelfareListMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;
  sensitiveDataVisible = false;

  tableDefinitions = [
    { def: "beSharperCompleteName", title: "beSharper", sortable: false },
    { def: "supplyDate", title: "Inserito il", sortable: true, transformer: (value: string): string => this.formatDate(new Date(value), true) },
    { def: "value", title: "Valore", sortable: true, transformer: (value: string): string => this.sensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
    { def: "reason", title: "Causale", sortable: false, transformer: (value: WelfareReasonModel) => value ? value?.name : 'Non Presente' }
  ];

  actionDefinitions = [
    {
      icon: "fa-solid fa-pen",
      text: "Modifica welfare",
      styleClass: "table-action-modify",
      callback: (welfare: any): void => {
        this.modifyWelfare(welfare);
      },
    },
    {
      icon: "fa-solid fa-trash-can",
      text: "Elimina welfare",
      styleClass: "table-action-delete",
      callback: (welfare: any): void => {
        this.deleteWelfare(welfare);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
    this.detailPage = "welfare-detail";
  }

  modifyWelfare(welfare: WelfareModel): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyWelfareComponent,
      data: {
        mode: ModeEnum.MODIFY,
        beSharperId: welfare.besharperId,
        welfare: welfare,
      }
    });
  }

  deleteWelfare(welfare: WelfareModel): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: environment.cognito.apiEndpoint + Constants.welfareApiPath + `/${welfare.id}`,
    });
  }

  createWelfare(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyWelfareComponent,
      data: {
        mode: ModeEnum.CREATE,
        listMode: true,
        beSharperId: undefined,
      }
    });
  }

  async exportTable() {
    if (this.loading) {
      return;
    }
    this.providerService.utilService
      .openDialog(WelfareReportComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.filterObservable.next(this.lastDebounceValue);
      });
  }
}
