import { Component } from '@angular/core';
import { AuthService } from '../../core/auth-utils/auth.service';
import packageJson from '../../../../package.json';
import RoleEnum from "../../models/role.enum";
import { TextStrings } from "../../core/constants/text-strings";
import eRoleEnum from "../../models/role.enum";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  version = packageJson.version;
  ssToggle = false;
  eRoleEnum = RoleEnum;
  visible = false;
  eTextStrings = TextStrings;

  constructor(private readonly authService: AuthService) { }

  get userRole(): RoleEnum {
    return this.authService.getUser()?.role;
  }

  protected readonly RoleEnum = RoleEnum;
}
