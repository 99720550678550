import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { ContractsEnums } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { ContractMapper } from '../../../mappers/contract.mapper';
import { IMapper } from '../../../models/i-mapper';
import { FormLabels } from '../../../core/interfaces/i-form-labels';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-admin-panel-create-modify-contract',
  templateUrl: './admin-panel-create-modify-contract.component.html',
  styleUrl: './admin-panel-create-modify-contract.component.scss',
})
export class AdminPanelCreateModifyContractComponent {
  loading = false;
  formMode = ModeEnum.CREATE;
  dropDownName = '';

  contractLevelFormLabels: FormLabels = {
    name: TextStrings.CONTRACT_LEVEL_NAME,
    description: TextStrings.CONTRACT_LEVEL_DESCRIPTION,
    messages: {
      nameMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_NAME_PLACEHOLDER,
      descriptionMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_DESCRIPTION_PLACEHOLDER,
    },
  };

  contractTypeFormLabels = {
    name: TextStrings.CONTRACT_TYPE_NAME,
    description: TextStrings.CONTRACT_TYPE_DESCRIPTION,
    messages: {
      nameMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_NAME_PLACEHOLDER,
      descriptionMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_DESCRIPTION_PLACEHOLDER,
    },
  };

  contractRoleFormLabels = {
    name: TextStrings.CONTRACT_ROLE_NAME,
    description: TextStrings.CONTRACT_ROLE_DESCRIPTION,
    messages: {
      nameMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_NAME_PLACEHOLDER,
      descriptionMessage: TextStrings.CONTRACT_LEVEL_MESSAGES_DESCRIPTION_PLACEHOLDER,
    },
  };

  formLabels: FormLabels = {} as FormLabels;

  modifyContractLevelUrl = environment.cognito.apiEndpoint + Constants.contractLevelsApiPath;
  modifyContractRoleUrl = environment.cognito.apiEndpoint + Constants.contractRolesApiPath;
  modifyContractTypeUrl = environment.cognito.apiEndpoint + Constants.contractTypesApiPath;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
  });

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyContractComponent>
  ) {
    this.dropDownName = this.data.dropDown;
    this.formMode = this.data.mode;
    this.setFormLabels();
    if (data.mode === ModeEnum.MODIFY) {
      this.fillControls();
    }
  }

  showFormTitle(): string {
    switch (this.dropDownName) {
      case ContractsEnums.CONTRACT_LEVEL:
        return 'CONTRACT_LEVEL_TITLE';
      case ContractsEnums.CONTRACT_TYPE:
        return 'CONTRACT_TYPE_TITLE';
      case ContractsEnums.CONTRACT_ROLE:
        return 'CONTRACT_ROLE_TITLE';
      default:
        return '';
    }
  }

  async sendRequest(): Promise<void> {
    if (this.formMode === ModeEnum.MODIFY) {
      await this.sendModifyRequest();
    } else {
      await this.sendCreateRequest();
    }
  }

  async deleteItem(): Promise<void> {
    switch (this.data.dropDown) {
      case ContractsEnums.CONTRACT_LEVEL: {
        this.providerService.utilService
          .openDialog(ConfirmDeleteComponent, {
            url: `${this.modifyContractLevelUrl}/${this.data.data.id}`,
          })
          .afterClosed()
          .subscribe(() => {
            document.location.reload();
          });
        break;
      }
      case ContractsEnums.CONTRACT_TYPE: {
        this.providerService.utilService
          .openDialog(ConfirmDeleteComponent, {
            url: `${this.modifyContractTypeUrl}/${this.data.data.id}`,
          })
          .afterClosed()
          .subscribe(() => {
            document.location.reload();
          });
        break;
      }
      case ContractsEnums.CONTRACT_ROLE: {
        this.providerService.utilService
          .openDialog(ConfirmDeleteComponent, {
            url: `${this.modifyContractRoleUrl}/${this.data.data.id}`,
          })
          .afterClosed()
          .subscribe(() => {
            document.location.reload();
          });
        break;
      }
    }
  }

  async sendCreateRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();
    switch (this.data.dropDown) {
      case ContractsEnums.CONTRACT_LEVEL: {
        this.loading = true;
        const createRequestMapper = new ContractMapper();
        this.fillRequestBody(createRequestMapper);
        this.providerService.networkService
          .post(this.modifyContractLevelUrl, createRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
        break;
      }
      case ContractsEnums.CONTRACT_TYPE: {
        this.loading = true;
        const createRequestMapper = new ContractMapper();
        this.fillRequestBody(createRequestMapper);
        this.providerService.networkService
          .post(this.modifyContractTypeUrl, createRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
        break;
      }
      case ContractsEnums.CONTRACT_ROLE: {
        this.loading = true;
        const createRequestMapper = new ContractMapper();
        this.fillRequestBody(createRequestMapper);
        this.providerService.networkService
          .post(this.modifyContractRoleUrl, createRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
      }
    }
  }

  async sendModifyRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();
    switch (this.data.dropDown) {
      case ContractsEnums.CONTRACT_LEVEL: {
        this.loading = true;
        const updateRequestMapper = new ContractMapper();
        this.fillRequestBody(updateRequestMapper);
        this.providerService.networkService
          .put(`${this.modifyContractLevelUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
        break;
      }
      case ContractsEnums.CONTRACT_TYPE: {
        this.loading = true;
        const updateRequestMapper = new ContractMapper();
        this.fillRequestBody(updateRequestMapper);
        this.providerService.networkService
          .put(`${this.modifyContractTypeUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
        break;
      }
      case ContractsEnums.CONTRACT_ROLE: {
        this.loading = true;
        const updateRequestMapper = new ContractMapper();
        this.fillRequestBody(updateRequestMapper);
        this.providerService.networkService
          .put(`${this.modifyContractRoleUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
          .then(() => {
            this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
            this.dialogRef.close();
            this.loading = false;
          })
          .catch((e: any) => {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          });
        break;
      }
    }
  }
  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({ name: this.form.controls.name.value, description: this.form.controls.description.value });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }
  setFormLabels(): void {
    switch (this.data.dropDown) {
      case ContractsEnums.CONTRACT_LEVEL:
        this.formLabels = this.contractLevelFormLabels;
        break;
      case ContractsEnums.CONTRACT_TYPE:
        this.formLabels = this.contractTypeFormLabels;
        break;
      case ContractsEnums.CONTRACT_ROLE:
        this.formLabels = this.contractRoleFormLabels;
        break;
    }
  }

  fillControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
    this.form.controls.description.setValue(this.data.data.description);
  }

  protected readonly ModeEnum = ModeEnum;
}
