import { Component } from '@angular/core';
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-missing-required-information',
  templateUrl: './missing-required-information.component.html',
  styleUrl: './missing-required-information.component.scss'
})
export class MissingRequiredInformationComponent {
  eTextStrings = TextStrings;
}
