import {Component, Inject, OnInit} from '@angular/core';
import {ModeEnum} from "../../../core/constants/enums";
import {environment} from "../../../../environments/environment";
import {TextStrings} from "../../../core/constants/text-strings";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Constants} from "../../../core/constants/constants";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProviderService} from "../../../core/provider.service";
import {CreateConsumableMapper} from "../../../mappers/consumable.mapper";
import {SimpleMapper} from "../../../mappers/simple.mapper";
import {LogLevel} from "../../../models/log-level";
import {ConsumableModel} from "../../../models/consumable.model";
import {IMapper} from "../../../models/i-mapper";

class CreateModifyConsumableComponent {
}

@Component({
  selector: 'app-create-modify-besharper-consumable',
  templateUrl: './create-modify-besharper-consumable.component.html',
  styleUrl: './create-modify-besharper-consumable.component.scss'
})
export class CreateModifyBesharperConsumableComponent  implements OnInit {
  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  consumable?: any;
  backendUrlConsumable = environment.cognito.apiEndpoint + Constants.consumableApiPath;


  eTextStrings = TextStrings;

  public form = new FormGroup({
    qty: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]*$')]),
    name: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    alarm: new FormControl(true, [Validators.required]),
    alarmMinQty: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]*$')]),
    customAlarmEmail: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<CreateModifyConsumableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.bootstrap(data);
  }

  private bootstrap(data: any) {
    if (data["mode"] === "modify") {
      this.mode = ModeEnum.MODIFY;
    } else if (data["mode"] === "create") {
      this.mode = ModeEnum.CREATE;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (this.data['mode'] == ModeEnum.MODIFY && this.data.consumable !== undefined) {
      this.mode = ModeEnum.MODIFY;
      this.consumable = this.data.consumable;
      this.populateFormForModify(this.consumable);
    }
    else if(this.data['mode'] == ModeEnum.CREATE) {
      this.mode = ModeEnum.CREATE;
    }

    this.loading = false;
  }

  formValid(): boolean {
    return this.form.valid;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  async createConsumable(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const url = environment.cognito.apiEndpoint + Constants.consumableApiPath;
    const postMapper = new CreateConsumableMapper();
    const responseMapper = new SimpleMapper();

    try {
      this.fillMapper(postMapper);

      await this.providerService.networkService.post(url, postMapper, responseMapper);

      this.providerService.utilService.showMessage(TextStrings.CONSUMABLE_CREATE_SUCCESS, LogLevel.success);
      this.loading = false;
      this.form.reset();
      if(!addAnother) {
        this.dialogRef.close();
      }
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  async modifyConsumable(): Promise<void> {
    this.loading = true;
    const putMapper = new CreateConsumableMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(putMapper);
      await this.providerService.networkService.put(this.backendUrlConsumable + '/' + this.consumable['id'], putMapper, mapper)
      this.providerService.utilService.showMessage(TextStrings.BONUS_MODIFY_SUCCESS, LogLevel.success);
      this.dialogRef.close();
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  private populateFormForModify(data: ConsumableModel) {
    this.form.controls.qty.setValue(data.qty ?? 0);
    this.form.controls.name.setValue(data.name ?? '');
    this.form.controls.description.setValue(data.description ?? null);
    this.form.controls.alarm.setValue(data.alarm ?? false);
    this.form.controls.alarmMinQty.setValue(data.alarmMinQty ?? 0);
    this.form.controls.customAlarmEmail.setValue(data.customAlarmEmail ?? null);
  }

  fillMapper(mapper: IMapper): void {
    mapper.fillFromJson({
      qty: this.form.controls.qty.value,
      name: this.form.controls.name.value,
      description: this.form.controls.description.value,
      alarm: this.form.controls.alarm.value,
      alarmMinQty: this.form.controls.alarmMinQty.value,
      customAlarmEmail: this.form.controls.customAlarmEmail.value
    });
  }
}
