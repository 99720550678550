import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-email-chips',
  standalone: false,
  templateUrl: './email-chips.component.html',
  styleUrl: './email-chips.component.scss'
})
export class EmailChipsComponent {
  @Input() public label : string = "";
   
  @Input() public addresses : string[] = []; 
  @Output() public addressesChange = new EventEmitter<string[]>();

  public emitAddresses() {
    this.addressesChange.emit([...this.addresses]);
  }

  private recipientCanBeAdded(recipient : string) : boolean {
    const recipientAlreadyPresent = this.addresses.includes(recipient);
    if(recipientAlreadyPresent) {
      return false;
    }

    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if(!emailRegex.test(recipient)) {
      return false;
    }

    return true;
  }

  public editRecipient(recipient : string, event : MatChipEditedEvent) {
    const indexOfRecipient = this.addresses.indexOf(recipient);

    if(indexOfRecipient == -1) {
      return;
    }

    if(!this.recipientCanBeAdded(recipient)) {
      return;
    }

    this.addresses[indexOfRecipient] = event.value;
    this.emitAddresses();
  } 

  public addRecipient(event : MatChipInputEvent) {
    if(!this.recipientCanBeAdded(event.value)) {
      return;
    }

    this.addresses.push(event.value);
    event.chipInput.clear();
    this.emitAddresses()
  }

  public removeRecipient(recipient : string) {
    this.addresses = this.addresses.filter(r => r !== recipient);
    this.emitAddresses()
  }
}
