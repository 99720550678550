import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '../../../../core/constants/constants';
import { ProviderService } from '../../../../core/provider.service';
import RoleEnum from '../../../../models/role.enum';

@Component({
  selector: 'app-saml-callback',
  templateUrl: './saml-callback.component.html',
  styleUrl: './saml-callback.component.scss',
})
export class SamlCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private providerService: ProviderService,
  ) { }

  async ngOnInit(): Promise<void> {
    // Subscribe to route parameter changes
    this.route.queryParamMap.subscribe(async (params) => {
      try {
        const besharperId = params.get("besharper-id");
        const identityId = params.get("identity-id");
        const originalSamlResponse = decodeURIComponent(params.get('saml-response') ?? '');

        const credentials = await this.providerService.authUtilsService.getCredentialsWithSamlResponse(
          originalSamlResponse, identityId ?? ''
        );

        if (credentials) {
          await this.providerService.authUtilsService.saveCredentialsInCookies(credentials);
          await this.providerService.authUtilsService.setLocalUserInformation(originalSamlResponse, besharperId!);

          const userRole = this.providerService.authService.getUser().role
          const userIsAdminOrHr = [RoleEnum.HR, RoleEnum.HR_REPORTS, RoleEnum.ADMIN].includes(userRole)
          if (userIsAdminOrHr) {
            await this.providerService.utilService.navigateTo(Constants.besharpersPath);
          }
          else {
            await this.providerService.utilService.navigateTo(Constants.outOfOfficePath);
          }
        }
      } catch(error: any) {
        this.providerService.utilService.navigateTo(Constants.signInPath).then();
        throw new Error(error.message);
      }
    });
  }
}
