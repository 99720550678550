<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{(isCreateMode() ? eTextStrings.EQUIPMENT_CREATE : eTextStrings.EQUIPMENT_MODIFY )}}{{data.data ? ' - ' + data.data.id : ''}}</h1>

    <form spellcheck="false" [formGroup]="form">
      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_LABELS_IDDEVICE}}</mat-label>
            <input matInput id="idDevice" name="idDevice" formControlName="idDevice">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'idDevice'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_LABELS_SERIAL_NUMBER}}</mat-label>
            <input matInput id="serialNumber" name="serialNumber" formControlName="serialNumber">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'serialNumber'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.EQUIPMENT_LABELS_BESHARPER}}</mat-label>
        <input type="text"
               placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
               aria-label="name"
               matInput
               [readonly] = isReadonlyBesharper()
               (input)="filter()"
               formControlName="besharper"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
          <mat-option (onSelectionChange)="onBesharperSelected(besharper)" *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_LABELS_BRAND}}</mat-label>
            <input matInput id="brand" name="brand" formControlName="brand">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'brand'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_LABELS_MODEL}}</mat-label>
            <input matInput id="model" name="model" formControlName="model">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'model'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_WARRANT}}</mat-label>
            <input matInput id="warrant" name="warrant" formControlName="warrant">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_WARRANT_LINK}}</mat-label>
            <input matInput id="warrantLink" name="warrantLink" formControlName="warrantLink" [type]="'url'">
          </mat-form-field>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_INVOICE}}</mat-label>
            <input matInput id="invoice" name="invoice" formControlName="invoice">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_INVOICE_LINK}}</mat-label>
            <input matInput id="invoiceLink" name="invoiceLink" formControlName="invoiceLink" [type]="'url'">
          </mat-form-field>
        </div>
      </div>

      <div class="col-4">
        <div class="sided"><mat-checkbox formControlName="personal" id="personal" name="personal" [checked]="form.controls.personal.value">{{eTextStrings.EQUIPMENT_PERSONAL}}</mat-checkbox></div>
        <div class="sided"><mat-checkbox formControlName="mixedUse" id="mixedUse" name="mixedUse" [checked]="form.controls.mixedUse.value">{{eTextStrings.EQUIPMENT_MIXEDUSE}}</mat-checkbox></div>
        <div class="sided"><mat-checkbox formControlName="workUse" id="workUse" name="workUse" [checked]="form.controls.workUse.value">{{eTextStrings.EQUIPMENT_FORWORK}}</mat-checkbox></div>
        <div class="sided"><mat-checkbox formControlName="insurance" id="insurance" name="insurance" [checked]="form.controls.insurance.value">{{eTextStrings.EQUIPMENT_INSURANCE}}</mat-checkbox></div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.EQUIPMENT_LABELS_CATEGORY}}</mat-label>
        <mat-select formControlName="equipmentType" (selectionChange)="setMetadataSchemaInForm()">
          <mat-option *ngFor="let et of equipmentCategories" [value]="et.id" [matTooltip]="et.metadataSchema">{{et.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'equipmentType'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Tipo dotazione richiesto"></app-validator-ui>

      <div class="metadata" *ngIf="metadataReady">
        <ng-container *ngFor="let input of metadataSchema">
          <div *ngIf="input[1] === 'text'">
            <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings["EQUIPMENT_MTD_" + input[0].toUpperCase()]}}</mat-label>
            <input matInput [formControlName]="'mtd_' + input[0]" [id]="input[0]" [name]="input[0]" [required]="true">
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'mtd_' + input[0]" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
          </div>
          <div *ngIf="input[1] === 'number'">
            <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings["EQUIPMENT_MTD_" + input[0].toUpperCase()]}}</mat-label>
            <input matInput [formControlName]="'mtd_' + input[0]" [id]="input[0]" [name]="input[0]" [required]="true" type="number">
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'mtd_' + input[0]" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
          </div>
          <div *ngIf="input[1] === 'boolean'">
            <mat-checkbox [formControlName]="'mtd_' + input[0]" [id]="input[0]" [name]="input[0]" >{{eTextStrings["EQUIPMENT_MTD_" + input[0].toUpperCase()]}}</mat-checkbox>
          </div>
        </ng-container>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="buyingDate.open()" (click)="buyingDate.open()">
            <mat-label>{{eTextStrings.EQUIPMENT_BUYING_DATE}}</mat-label>
            <input matInput [matDatepicker]="buyingDate" id="buyingDate" name="buyingDate" formControlName="buyingDate" (focus)="buyingDate.open()" (click)="buyingDate.open()">
            <mat-datepicker-toggle matIconSuffix [for]="buyingDate"></mat-datepicker-toggle>
            <mat-datepicker #buyingDate [lang]="'it'"></mat-datepicker>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'buyingDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="endOfSupport.open()" (click)="endOfSupport.open()">
            <mat-label>{{eTextStrings.EQUIPMENT_END_OF_SUPPORT}}</mat-label>
            <input matInput [matDatepicker]="endOfSupport" id="endOfSupport" name="endOfSupport" formControlName="endOfSupport" (focus)="endOfSupport.open()" (click)="endOfSupport.open()">
            <mat-datepicker-toggle matIconSuffix [for]="endOfSupport"></mat-datepicker-toggle>
            <mat-datepicker #endOfSupport [lang]="'it'"></mat-datepicker>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'endOfSupport'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="invoiceDate.open()" (click)="invoiceDate.open()">
            <mat-label>{{eTextStrings.EQUIPMENT_INVOICE_DATE}}</mat-label>
            <input matInput [matDatepicker]="invoiceDate" id="invoiceDate" name="invoiceDate" formControlName="invoiceDate" (focus)="invoiceDate.open()" (click)="invoiceDate.open()">
            <mat-datepicker-toggle matIconSuffix [for]="invoiceDate"></mat-datepicker-toggle>
            <mat-datepicker #invoiceDate [lang]="'it'"></mat-datepicker>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'invoiceDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.FIELD_REQUIRED}}"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.EQUIPMENT_INVOICE_COMPANY}}</mat-label>
            <input matInput id="invoiceCompany" name="invoiceCompany" formControlName="invoiceCompany">
          </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.EQUIPMENT_STATUS}}</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let es of EquipmentStatus | keyvalue" [value]="es.key">{{es.value}}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'equipmentType'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Tipo dotazione richiesto"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.NOTES}}</mat-label>
        <textarea matInput id="note" name="note" formControlName="note" rows="4"></textarea>
      </mat-form-field>
    </form>
  </div>
  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="closeDialog()">
      <span>{{eTextStrings.EQUIPMENT_BUTTONS_BACK}}</span>
    </button>
    <button [disabled]="!isValid() || loading" color="{{isValid() && !loading ? 'primary' : 'disabled'}}" (click)="sendRequest()" mat-raised-button>{{eTextStrings.EQUIPMENT_BUTTONS_CONFIRM}}</button>
    <button *ngIf="isCreateMode()" [disabled]="!isValid() || loading" color="{{isValid() && !loading ? 'primary' : 'disabled'}}" (click)="sendRequest(true)" mat-raised-button>{{eTextStrings.EQUIPMENT_BUTTONS_CONFIRM_AND_STAY}}</button>
  </div>
</div>
