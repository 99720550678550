import { EquipmentModel } from '../models/besharp-item.model';
import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';

export class ListBesharpItemsMapper implements IListMapper {
  elements: EquipmentModel[] = [];
  count = 0;

  fillFromJson(json: any): PagedModel<EquipmentModel> {
    this.elements = [];
    json['items'].forEach((item: any) => {
      this.elements.push(this.mapItem(item));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  toString(): string {
    return '';
  }

  private mapItem(item: any): EquipmentModel {
    return {
      id: item.id,
      description: item.description,
      code: item.code,
      type: item.type,
      total: item.total,
      acquisitionDate: this.formatDate(new Date(item.createdAt)),
      assignee: item.assignee,
      serialNumber: item.serialNumber,
      position: item.position,
      status: item.status,
      note: item.note,
      photo: item.imageUrl,
    };
  }
  formatDate(date: Date): string {
    const day: string = date.getDate().toString().padStart(2, '0');
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    return date.getFullYear() + '-' + month + '-' + day;
  }
}

export class CreateUpdateBesharpItemMapper implements IMapper {
  body = {};

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }

  toString(): string {
    return '';
  }
}
