<div class="container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="button-container" *ngIf="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER)">
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="modifyEquipment();">
        <i class="fa-solid fa-pen"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button color="primary" (click)="deleteEquipment();">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
  </div>
  <div class="card-detail">
    <h3>Dettagli Dotazione</h3>
    <mat-card>
      <mat-card-content>
        <p><i class="fa-solid fa-id-card"></i>ID Dispositivo: {{equipment?.idDevice}}</p>
        <p><i class="fa-solid fa-id-card"></i>Seriale: {{equipment?.serialNumber}}</p>
        <p><i class="fa-solid fa-id-card"></i>Associato a: {{equipment?.besharper ? equipment?.besharper?.name + " " + equipment?.besharper?.surname : "LIBERO" }}</p>
        <hr>
        <p><i class="fa-solid fa-wreath-laurel"></i>Marca: {{equipment?.brand}}</p>
        <p><i class="fa-solid fa-gamepad-modern"></i>Modello: {{equipment?.model}}</p>
        <p><i class="fa-solid fa-gear"></i>Tipo Dispositivo: {{equipment?.equipmentType?.name}}</p>
        <p><i class="fa-solid fa-list-tree"></i>Metadati:<br><ngx-json-viewer [json]="JSON.parse(equipment?.metadata!)" [expanded]="false" *ngIf="equipment?.metadata"></ngx-json-viewer></p>
        <hr>
        <p><i class="fa-solid fa-question"></i>Personale: <i class="fa {{equipment?.personal ? 'fa-check green' : 'fa-times red' }}"></i></p>
        <p><i class="fa-solid fa-question"></i>Uso promisquo: <i class="fa {{equipment?.mixedUse ? 'fa-check green' : 'fa-times red' }}"></i></p>
        <p><i class="fa-solid fa-question"></i>Per Lavoro: <i class="fa {{equipment?.workUse ? 'fa-check green' : 'fa-times red' }}"></i></p>
        <hr>
        <p><i class="fa-solid fa-calendar"></i>Data di acquisto: {{equipment?.buyingDate}}</p>
        <p><i class="fa-solid fa-calendar"></i>Fine supporto: {{equipment?.endOfSupport}}</p>
        <p><i class="fa-solid fa-question"></i>Assicurato: <i class="fa {{equipment?.insurance ? 'fa-check green' : 'fa-times red' }}"></i></p>
        <hr>
        <p><i class="fa-solid fa-receipt"></i>Fattura: {{equipment?.invoice}}</p>
        <p><i class="fa-solid fa-calendar"></i>Data fattura: {{equipment?.invoiceDate}}</p>
        <p><i class="fa-solid fa-building"></i>Azienda fattura: {{equipment?.invoiceCompany}}</p>
        <p><i class="fa-solid fa-link"></i>Link fattura: <a *ngIf="equipment?.invoiceLink" [href]="equipment?.invoiceLink" target="_blank">Vai alla fattura</a></p>
        <hr>
        <p><i class="fa-solid fa-note"></i>Garanzia: {{equipment?.warrant}}</p>
        <p><i class="fa-solid fa-link"></i>Link Garanzia: <a *ngIf="equipment?.warrantLink" [href]="equipment?.warrantLink" target="_blank">Vai alla garanzia</a></p>
        <hr>
        <p><i class="fa-solid fa-status"></i>Stato: {{equipment?.status}}</p>
        <p><i class="fa-solid fa-note"></i>Note: {{equipment?.note}}</p>
      </mat-card-content>
    </mat-card>
  </div>

</div>
