import { IListMapper, IMapper } from '../models/i-mapper';

export class SimpleMapper implements IMapper {
  body?: any;

  fillFromJson(json: any): any {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class SimpleListMapper extends IListMapper {
  elements: any[] = [];
  count : number = 0;

  override fillFromJson(json: any): void {
    this.elements = json.items;
    this.count = json.total;  
  }

  override fillToJson(): string {
    const body = {
      total: this.count,
      items: this.elements
    }

    return JSON.stringify(body);
  }
}