<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>Tipi di Out Of Office</h1>
  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse()" (closed)="toggleCollapse()">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          Tipi di Out Of Office
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateoutOfOfficeTypesDialog()">{{eTextStrings.CREATE}} un Out Of Office Type</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="outOfOfficeTypesDataModel"
        [backendUrl]="outOfOfficeTypesBackendUrl"
        [tableDefinitions]="outOfOfficeTypesTableDefinitions"
        [actionDefinitions]="outOfOfficeTypesActionsDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="outOfOfficeTypesMapper"
        [showPaginator]=false
        (rowClicked)="openModifyDialog($event)"
        [hasRowAction]=true>
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>



