import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TableDataSource } from '../table.datasource';
import { TableService } from '../table.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { OrderEnum } from '../../../../models/filter.model';
import { IListMapper } from '../../../../models/i-mapper';
import { TextStrings } from "../../../constants/text-strings";
import { BesharperState } from "../../../constants/enums";
import { ProviderService } from '../../../../core/provider.service';

@Component({
  selector: 'app-table-ui',
  templateUrl: './table-ui.component.html',
  styleUrls: ['./table-ui.component.scss'],
})
export class TableUiComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  @Input()
  backendUrl?: string;

  @Input()
  datasource?: TableDataSource<any>;

  @Input()
  actionTitle = TextStrings.ACTIONS;

  @Input()
  actionDefinitions?: {
    icon: string;
    text: string;
    styleClass: string;
    callback: any;
    tooltip?: string;
    active?: (row: any) => boolean;
  }[];

  @Input()
  tableDefinitions?: {
    def: string;
    title: string;
    json?: boolean,
    sortable?: boolean;
    transformer?: (value: any) => string;
    link?: boolean;
    virtual?: (row: any) => string;
    tooltip?: (row: any) => string;
  }[];

  @Input()
  parentID?: number;

  @Input()
  pageSize = 3;

  @Input()
  pageSizeOptions: number[] = [3, 6, 8];

  @Input()
  filterObservable?: Observable<string>;

  @Input()
  daysOfTheWeekObservable?: Observable<string>;

  @Input()
  activeCeasedStatusObservable?: Observable<string>;

  @Input()
  mapper!: IListMapper;

  @Input()
  hasRowAction? = false;

  @Input()
  showPaginator? = true;

  @Input()
  actionCondition?: (row: any) => boolean;

  @Input()
  role?: string;

  @Input()
  defaultActive?: string;

  @Input() mobileWindowObservable?: Observable<boolean>;

  @Input() onlyMeObservable?: Observable<boolean>;

  @Input()
  defaultDirection?: "asc" | "desc" = "asc";

  @Output()
  rowClicked: EventEmitter<any> = new EventEmitter();

  tableDefinitionHeaders?: string[];

  isLoading = true;

  private filterSubscription?: Subscription;
  private loadingSubscription?: Subscription;
  private daysOfWeekFilterSubscription?: Subscription;
  private mobileWindowSubscription?: Subscription;
  private onlyMeSubscription?: Subscription;
  private activeCeasedStatusFilterSubscription?: Subscription;
  private filter = '';
  private daysOfTheWeek = '00000';
  private activeCeasedStatus = BesharperState.ALL;
  private mobileWindowFilter = false;
  private onlyMe = false;
  protected tableService: TableService;

  constructor(public providerService: ProviderService) {
    this.tableService = new TableService(providerService);
  }

  ngOnInit(): void {
    if (!this.datasource || !this.backendUrl || !this.tableDefinitions) {
      throw new Error('Verify that datasource, backendUrl and tableDefinitions are defined in the parent component');
    }
    this.datasource.setTableService(this.tableService);
    this.tableDefinitionHeaders = this.tableDefinitions.map((i) => i.def);

    if (this.actionDefinitions && this.actionDefinitions.length > 0) {
      this.tableDefinitionHeaders = this.tableDefinitionHeaders.concat('actions');
    }
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe(() => this.refreshData());
    this.loadingSubscription = this.datasource?.loading$.subscribe((value: boolean) => {
      this.isLoading = value;
    });

    // reset the paginator after sorting
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.filterSubscription = this.filterObservable?.subscribe((value: string) => {
      this.filter = value;
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.daysOfWeekFilterSubscription = this.daysOfTheWeekObservable?.subscribe((value: string) => {
      this.daysOfTheWeek = value;
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.activeCeasedStatusFilterSubscription = this.activeCeasedStatusObservable?.subscribe((value: string) => {
      this.activeCeasedStatus = value as BesharperState;
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.mobileWindowSubscription = this.mobileWindowObservable?.subscribe((value: boolean) => {
      this.mobileWindowFilter = value as boolean;
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    this.onlyMeSubscription = this.onlyMeObservable?.subscribe((value: boolean) => {
      this.onlyMe = value as boolean;
      this.paginator.pageIndex = 0;
      this.refreshData();
    });

    setTimeout(() => this.refreshData(), 10);
  }

  ngOnDestroy(): void {
    this.filterSubscription?.unsubscribe();
    this.loadingSubscription?.unsubscribe();
    this.daysOfWeekFilterSubscription?.unsubscribe();
    this.activeCeasedStatusFilterSubscription?.unsubscribe();
    this.mobileWindowSubscription?.unsubscribe();
    this.onlyMeSubscription?.unsubscribe();
  }

  rowClickedAction(row: any): void {
    this.rowClicked.emit(row);
  }

  isActionActive(row: any, action: any): boolean {
    return action.active !== undefined ? action.active(row) : true;
  }

  traverseCellProperty(cell: any, propertyArrayString: any): any {
    const property = propertyArrayString.split('.');
    for (let i = 0; i < property.length; i++) {
      cell = cell[property[i]];
    }
    return cell;
  }

  private refreshData(): void {
    const sortDirection = this.sort.direction === '' ? OrderEnum.asc : OrderEnum[this.sort.direction];
    if (this.datasource && this.backendUrl && this.tableDefinitions) {
      this.datasource
        .loadElements(
          this.backendUrl,
          this.parentID ?? -1,
          this.filter ?? '',
          this.daysOfTheWeek ?? '00000',
          this.activeCeasedStatus ?? BesharperState.ALL,
          this.mobileWindowFilter ?? false,
          this.onlyMe ?? false,
          this.sort.active,
          sortDirection,
          this.paginator.pageIndex,
          this.paginator.pageSize,
          this.mapper,
          this.role,
        )
        .then(() => { });
    }
  }
}
