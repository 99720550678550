import { Component } from '@angular/core';
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { EquipmentModel } from '../../../../models/besharp-item.model';
import { ListBesharpItemsMapper } from '../../../../mappers/items.mapper';
import { Constants } from '../../../../core/constants/constants';
import { ProviderService } from '../../../../core/provider.service';
import { CreateModifyBesharpItemComponent } from '../../../dialogs/create-modify-besharp-item/create-modify-besharp-item.component';
import { IFilteredTablePage } from '../../../../core/interfaces/i-filtered-table-page';
import { RoleEnum } from "../../../../models/role.enum";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { ModeEnum } from "../../../../core/constants/enums";
import { Router } from "@angular/router";

@Component({
  selector: 'app-besharp-items',
  templateUrl: './besharp-items.component.html',
  styleUrl: './besharp-items.component.scss',
})
export class BesharpItemsComponent extends IFilteredTablePage {
  loading = false;
  besharpItemsBackendUrl = environment.cognito.apiEndpoint + Constants.besharpItemsPath;
  dataSource: TableDataSource<EquipmentModel[]> = new TableDataSource<EquipmentModel[]>();
  eRoleEnum = RoleEnum;

  tableDefinitions = [
    { def: 'description', title: 'Descrizione', sortable: true },
    { def: 'code', title: 'Numero interno', sortable: true },
    { def: 'serialNumber', title: 'Numero seriale', sortable: false },
    { def: 'type', title: 'Tipo', sortable: true },
    { def: 'position', title: 'Posizione', sortable: false },
    { def: 'assignee', title: 'Assegnato a', sortable: false },
    { def: 'total', title: 'Totale', sortable: false },
    { def: 'acquisitionDate', title: 'Data acquisto', sortable: false },
    { def: 'photo', title: 'Foto', sortable: false },
  ];

  actionDefinitions = [
    {
      icon: 'fas fa-pen',
      text: 'Modifica',
      styleClass: 'table-action-modify',
      callback: (item: EquipmentModel) => {
        this.modifyItem(item);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: EquipmentModel) => {
        this.deleteItem(item);
      },
    },
  ];

  tableMapper = new ListBesharpItemsMapper();

  eTextStrings = TextStrings;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  createItem(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharpItemComponent,
      data: { mode: ModeEnum.CREATE, item: {} }
    });
  }

  modifyItem(item: EquipmentModel): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharpItemComponent,
      data: { mode: ModeEnum.MODIFY, item: item }
    });
  }

  async deleteItem(item: EquipmentModel): Promise<void> {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.besharpItemsBackendUrl}/${item.id}`,
    });
  }
}
