<div>
  <div class="align-right">
    <button mat-raised-button color="primary" (click)="exportTable();"
      *ngIf="currentUser.role === eRoleEnum.ADMIN || currentUser.role === eRoleEnum.HR || currentUser.role === eRoleEnum.HR_REPORTS">
      <i class="fa-solid fa-file-excel"></i>&nbsp;
      <span>{{eTextStrings.EXPORT}}</span>
    </button>
    <button mat-raised-button color="primary" (click)="createOutOfOffice();">
      <i class="fa-solid fa-user"></i>&nbsp;
      &nbsp;
      <span>{{eTextStrings.OUT_OF_OFFICE_CREATE}}</span>
    </button>
  </div>
</div>

<div class="search-row">
  <h1 [innerHTML]="eTextStrings.PAGES_OUT_OF_OFFICE"></h1>
  <div class="search-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.OUT_OF_OFFICE_CREATE_SEARCH}}</mat-label>
      <input matInput (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
    </mat-form-field>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<app-table-ui *ngIf="showTable" [backendUrl]="outOfOfficeBackendUrl" [datasource]="holidaysDataSource"
  [tableDefinitions]="tableDefinitions" [actionDefinitions]="actionDefinitions" [defaultActive]="'status'"
  [defaultDirection]="'asc'" [mapper]="mapper" [role]="currentUser.role" (rowClicked)="rowClicked($event);"
  [hasRowAction]=true [pageSize]="10" [pageSizeOptions]="[10,20,30]" [filterObservable]="filterObservable">
</app-table-ui>