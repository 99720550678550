import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";
import { Constants } from "../../../core/constants/constants";
import { ProviderService } from "../../../core/provider.service";
import { BeSharperModel } from "../../../models/be-sharper.model";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import {CreateEquipmentMapper} from "../../../mappers/equipment.mapper";
import { LogLevel } from "../../../models/log-level";
import { EquipmentStatus, ModeEnum} from "../../../core/constants/enums";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import {EquipmentType} from "../../../models/equipment-type.model";
import {ListEquipmentTypeMapper} from "../../../mappers/equipment-type-mapper";

@Component({
  selector: 'app-create-modify-besharper-equipment',
  templateUrl: './create-modify-besharper-equipment.component.html',
  styleUrl: './create-modify-besharper-equipment.component.scss',
})
export class CreateModifyBesharperEquipmentComponent implements OnInit {
  listEquipmentTypesMapper = new ListEquipmentTypeMapper();
  listBesharpersMapper = new BeSharperListMapper();
  createBesharperEquipmentResponseMapper = new SimpleMapper();
  createBesharperEquipmentRequestMapper = new CreateEquipmentMapper();

  listBesharpersBEUrl = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  listEquipmentTypesUrl = environment.cognito.apiEndpoint + Constants.equipmentTypesApiPath;
  createBesharperEquipmentBEUrl = environment.cognito.apiEndpoint + Constants.equipmentApiPath;

  besharpers: BeSharperModel[] = [];
  filteredBesharpers: BeSharperModel[] = [];
  selectedBesharper?: BeSharperModel;

  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  metadataSchema: any[] = [];
  metadataReady = false;

  form = new FormGroup({
    idDevice: new FormControl('', Validators.required),
    serialNumber: new FormControl('', Validators.required),
    besharper: new FormControl('', Validators.required),
    equipmentType: new FormControl('', Validators.required),

    brand: new FormControl('', Validators.required),
    model: new FormControl('', Validators.required),

    warrant: new FormControl(''),
    warrantLink: new FormControl(''),

    invoiceLink: new FormControl(''),
    invoice: new FormControl(''),

    metadata: new FormControl(''),

    personal: new FormControl(false),
    mixedUse: new FormControl(true),
    workUse: new FormControl(false),
    insurance: new FormControl(false),

    buyingDate: new FormControl(null, Validators.required),
    endOfSupport: new FormControl(null),
    invoiceDate: new FormControl(null),
    invoiceCompany: new FormControl(''),

    status: new FormControl(EquipmentStatus.OK, Validators.required),
    note: new FormControl(''),
  });

  eTextStrings = TextStrings;
  protected readonly equipmentCategories: EquipmentType[] = [];
  protected readonly ModeEnum = ModeEnum;
  protected readonly EquipmentStatus = EquipmentStatus;

  constructor(
    private dialogRef: MatDialogRef<CreateModifyBesharperEquipmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _providerService: ProviderService
  ) {
    if (this.data['mode'] === ModeEnum.MODIFY.valueOf()) {
      this.mode = ModeEnum.MODIFY;
    } else if (this.data['mode'] === ModeEnum.CREATE.valueOf()) {
      this.mode = ModeEnum.CREATE;
    }
    console.log(this.mode);
  }

  async ngOnInit(): Promise<void> {
    await this.getBesharpers();
    await this.getEquipmentTypes();
    this.filteredBesharpers = this.besharpers;
    if (this.data.mode === ModeEnum.MODIFY.valueOf()) {
      this.fillControls();
    }
    if (this.data.beSharperId) {
      this.form.controls.besharper.setValue(this.data.beSharperId);
      this.selectedBesharper = this.filteredBesharpers.find(be => be.id === this.data.beSharperId)
    }
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE
  }

  isReadonlyBesharper(): boolean {
    return !!this.data.selectedBesharper;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  async getBesharpers(): Promise<void> {
    this.loading = true;
    const besharpers = await this._providerService.networkService.get(
      this.listBesharpersBEUrl,
      this.listBesharpersMapper,
      { limit: '999999', orderBy: 'surname' }
    );
    this.processApiResponse(besharpers);
    this.loading = false;
  }
  processApiResponse(obj: any): void {
    obj.elements.map((obj: any) => {
      this.besharpers.push(obj);
    });
  }
  displaySelectedBesharper(id: string): string {
    const selectedBesharper = this.filteredBesharpers.find((_) => _.id === id);
    return selectedBesharper ? selectedBesharper?.name + ' ' + selectedBesharper?.surname : '';
  }
  onBesharperSelected(besharper: BeSharperModel): void {
    this.selectedBesharper = besharper;
  }

  async getEquipmentTypes(): Promise<void> {
    this.loading = true;
    const equipments = await this._providerService.networkService.get(
      this.listEquipmentTypesUrl,
      this.listEquipmentTypesMapper,
      { limit: '999999', orderBy: 'surname' }
    );
    equipments.elements.map((obj: any) => {
      this.equipmentCategories.push(obj);
    });
    this.loading = false;
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  async sendRequest(addAnother?:boolean): Promise<void> {
    this.loading = true;
    if (this.data.mode === ModeEnum.CREATE.valueOf()) {
      this.fillRequestBody();
      this._providerService.networkService
        .post(
          this.createBesharperEquipmentBEUrl,
          this.createBesharperEquipmentRequestMapper,
          this.createBesharperEquipmentResponseMapper
        )
        .then(() => {
          this._providerService.utilService.showMessage(TextStrings.EQUIPMENT_CREATE_SUCCESS, LogLevel.success);
          this.form.reset();
          if(!addAnother) {
            this.dialogRef.close();
          }
          this.loading = false;
        })
        .catch((e: any) => {
          this._providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } else {
      this.fillRequestBody();
      this._providerService.networkService
        .put(
          `${this.createBesharperEquipmentBEUrl}/${this.data.equipment.id}`,
          this.createBesharperEquipmentRequestMapper,
          this.createBesharperEquipmentResponseMapper
        )
        .then(() => {
          this._providerService.utilService.showMessage(TextStrings.EQUIPMENT_MODIFY_SUCCESS, LogLevel.success);
          this.dialogRef.close();
          this.loading = false;
        })
        .catch((e: any) => {
          this._providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    }
  }
  fillRequestBody(): void {
    const jsonMetadataObject: {[s:string]: string | number | boolean } = {};
    for(const control of Object.entries(this.form.controls)) {
      if(control[0].startsWith("mtd_")) {
        let value = control[1].value!;
        if( typeof value === 'boolean') {
          value = value.toString();
        }
        jsonMetadataObject[control[0].replace("mtd_", "")] = value;
      }
    }
    this.form.controls.metadata.setValue(JSON.stringify(jsonMetadataObject));

    this.createBesharperEquipmentRequestMapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      equipmentTypeId: this.form.controls.equipmentType.value,

      metadata:  this.form.controls.metadata.value,


      idDevice: this.form.controls.idDevice.value,
      serialNumber:  this.form.controls.serialNumber.value,

      brand:  this.form.controls.brand.value,
      model:  this.form.controls.model.value,
      buyingDate:  this.form.controls.buyingDate.value,
      warrant:  this.form.controls.warrant.value,
      invoice:  this.form.controls.invoice.value,


      endOfSupport: this.form.controls.endOfSupport.value,
      warrantLink:  this.form.controls.warrantLink.value,
      invoiceLink:  this.form.controls.invoiceLink.value,
      personal:  this.form.controls.personal.value,
      mixedUse:  this.form.controls.mixedUse.value,
      workUse:  this.form.controls.workUse.value,
      insurance:  this.form.controls.insurance.value,

      note:  this.form.controls.note.value,
      invoiceDate:  this.form.controls.invoiceDate.value,
      invoiceCompany:  this.form.controls.invoiceCompany.value,
      status: this.form.controls.status.value,
    });
  }

  setMetadataSchemaInForm() {
    this.metadataReady = false;
    this.removeMetadataFieldsFromForm();

    const metadata = this.equipmentCategories.find(ec => ec.id === this.form.controls.equipmentType.value);
    const json = metadata?.metadataSchema;
    try {
      this.metadataSchema = Object.entries(JSON.parse(json!));
      console.log(this.metadataSchema);
      for(const item of this.metadataSchema) {
        switch(item[1]) {
          case "text" || "date": (this.form.controls as any)["mtd_" + item[0]] = new FormControl('', [Validators.required]); break;
          case "number": (this.form.controls as any)["mtd_" + item[0]] = new FormControl(0, [Validators.required]); break;
          case "boolean": (this.form.controls as any)["mtd_" + item[0]] = new FormControl(false); break;
        }
      }
      this.form.markAllAsTouched();
    } catch (_: any) {
      console.log(_);
      this.metadataSchema = [];
    } finally {
      this.metadataReady = true;
    }
  }

  isValid(): boolean {
    let result = true;
    for(const control of Object.entries(this.form.controls)) {
      result &&= (control[1].validator ? control[1].valid : true);
      console.log(control[0] + " - " + control[1].valid + " - " + control[1].validator);
    }
    return result;
  }

  private removeMetadataFieldsFromForm() {
    for(const control of Object.entries(this.form.controls)) {
      if(control[0].startsWith("mtd_"))
        delete (this.form.controls as {[s:string]: any})[control[0] as string];
    }
  }

  private fillControls() {
    this.form.controls.idDevice.setValue(this.data.equipment?.idDevice);
      this.form.controls.serialNumber.setValue(this.data.equipment?.serialNumber);
      this.form.controls.besharper.setValue(this.data.equipment?.besharperId);
      this.form.controls.equipmentType.setValue(this.data.equipment?.equipmentTypeId);

      this.form.controls.brand.setValue(this.data.equipment?.brand);
      this.form.controls.model.setValue(this.data.equipment?.model);

      this.form.controls.warrant.setValue(this.data.equipment?.warrant);
      this.form.controls.warrantLink.setValue(this.data.equipment?.warrantLink);

      this.form.controls.invoiceLink.setValue(this.data.equipment?.invoiceLink);
      this.form.controls.invoice.setValue(this.data.equipment?.invoice);

      this.form.controls. metadata.setValue(this.data.equipment?.metadata);

      this.form.controls.personal.setValue(this.data.equipment?.personal);
      this.form.controls.mixedUse.setValue(this.data.equipment?.mixedUse);
      this.form.controls.workUse.setValue(this.data.equipment?.workUse);
      this.form.controls.insurance.setValue(this.data.equipment?.insurance);

      this.form.controls.buyingDate.setValue(this.data.equipment?.buyingDate);
      this.form.controls.endOfSupport.setValue(this.data.equipment?.endOfSupport);
      this.form.controls.invoiceDate.setValue(this.data.equipment?.invoiceDate);
      this.form.controls.invoiceCompany.setValue(this.data.equipment?.invoiceCompany);

      this.form.controls.status.setValue(this.data.equipment?.status);
      this.form.controls.note.setValue(this.data.equipment?.note);

      this.setMetadataSchemaInForm();
      this.fillMetadataSchemaInform();
  }

  private fillMetadataSchemaInform() {
    const json = this.form.controls.metadata.value;
    if (json) {
      const metadata = JSON.parse(json);
      for (const data of Object.entries(metadata)) {
        let value = data[1] as string;
        const type = this.metadataSchema.find(ms => ms[0] === data[0]);
        if(type && type[1] === 'boolean') {
          value = JSON.parse(value);
        }
        (this.form.controls as any)["mtd_" + data[0]]?.setValue(value);
      }
      this.form.markAllAsTouched();
    }
  }
}
