import { Component, OnInit } from "@angular/core";
import { TableDataSource } from '../../../core/form-utils/table/table.datasource';
import { ProviderService } from '../../../core/provider.service';
import { BeSharperModel } from '../../../models/be-sharper.model';
import { IFilteredTablePage } from '../../../core/interfaces/i-filtered-table-page';
import { Constants } from '../../../core/constants/constants';
import { ActivatedRoute, Router } from "@angular/router";
import { BeSharperListMapper } from '../../../mappers/besharper.mapper';
import { CreateModifyBesharperComponent } from '../../dialogs/create-modify-besharper/create-modify-besharper.component';
import { RoleEnum } from "../../../models/role.enum";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { BesharperState, ModeEnum } from "../../../core/constants/enums";

@Component({
  selector: 'app-be-sharpers',
  templateUrl: './be-sharpers.component.html',
  styleUrl: './be-sharpers.component.scss',
})
export class BeSharpersComponent extends IFilteredTablePage implements OnInit {
  dataSource: TableDataSource<BeSharperModel> = new TableDataSource<BeSharperModel>();

  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;

  backendUrl = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  besharperMapper: BeSharperListMapper = new BeSharperListMapper();

  eBesharperState = BesharperState;
  beSharperStateI18nMap = [
    { id: this.eBesharperState.ALL, value: 'Tutti'},
    { id: this.eBesharperState.ACTIVE, value: 'Attivi'},
    { id: this.eBesharperState.CEASED, value: 'Cessati'},
  ];

  tableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'surname', title: 'Cognome', sortable: true },
    { def: 'email', title: 'Email', sortable: true },
    { def: 'cf', title: 'Codice Fiscale', sortable: false },
    { def: 'active', title: 'Stato', sortable: true, virtual: (cell: any) => {
        const isActive = cell.firingDate === null;
        if(isActive) {
          return '<span class="green"><i class="fa fa-check"></i>ATTIVO</span>';
        }
        return '<span class="red"><i class="fa fa-times"></i>CESSATO</span>';
      }
    },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: 'Modifica beSharper',
      styleClass: 'table-action-modify',
      callback: (user: BeSharperModel): void => {
        this.modifyBesharper(user);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: 'Elimina beSharper',
      styleClass: 'table-action-delete',
      callback: (user: BeSharperModel): void => {
        this.deleteBesharper(user).then();
      },
    },
  ];

  selectedBesharperStatusFilter: BesharperState = BesharperState.ALL;

  constructor(protected override router: Router, private providerService: ProviderService, private route: ActivatedRoute) {
    super(router, providerService.authService, providerService.utilService);
    this.detailPage = 'besharper-detail';
  }

  sendActiveCeasedStatusQuery(activeCeasedStatus: any): void {
    this.lastDebounceActiveCeasedState = activeCeasedStatus.value.toString();
    this.debounceActiveCeasedStatusSubject.next(activeCeasedStatus.value.toString());
    console.log(activeCeasedStatus.value);
  }

  createBesharper(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperComponent,
      data: { mode: ModeEnum.CREATE }
    });
  }

  modifyBesharper(user: BeSharperModel): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperComponent,
      data: {mode: ModeEnum.MODIFY, user: user}
    });
  }

  async deleteBesharper(user: BeSharperModel): Promise<void> {
    const basePath = environment.cognito.apiEndpoint + Constants.besharperApiPath;
    const besharperBackendUrl = basePath + `/${user.id}`

    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: besharperBackendUrl,
      description: "Per eliminare il beSharper, assicurarsi di eliminare prima contratti, welfare e bonus associati."
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
          if(params["openCreate"]) {
            this.createBesharper();
          }
        }
      );
  }
}
