import { Component } from '@angular/core';
import { Constants } from '../../../../core/constants/constants';
import { ActivatedRoute, Router } from "@angular/router";
import { ProviderService } from '../../../../core/provider.service';
import { ModeEnum } from '../../../../core/constants/enums';
import { BonusModel } from '../../../../models/bonus.model';
import { CreateModifyBonusComponent } from '../../../dialogs/create-modify-bonus/create-modify-bonus.component';
import { BonusDetailMapper } from '../../../../mappers/bonus.mapper';
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import IBasePage from "../../../../core/interfaces/i-base-page";
import RoleEnum from "../../../../models/role.enum";
import { CoreModule } from "../../../../core/core.module";
import { EyeToggleComponent } from "../../../../core/form-utils/eye-toggle/eye-toggle.component";

@Component({
  selector: 'app-bonus-detail',
  templateUrl: './bonus-detail.component.html',
  styleUrl: './bonus-detail.component.scss',
})
export class BonusDetailComponent extends IBasePage {
  loading = false;
  id = '';
  bonusBackendUrl = environment.cognito.apiEndpoint;
  bonus?: BonusModel;
  mapper = new BonusDetailMapper();
  eTextStrings = TextStrings;
  eRoleEnum = RoleEnum;
  sensitiveDataVisible = false;

  constructor(private activatedRoute: ActivatedRoute, protected override router: Router, private providerService: ProviderService) {
    super(router, providerService.authService, providerService.utilService);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params: any) => {
      this.id = params['id'];
      this.bonusBackendUrl += `${Constants.bonusApiPath}/${this.id}`;
      await this.getBonus();
    }).unsubscribe();
  }

  modifyBonus(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBonusComponent,
      data: {
        mode: ModeEnum.MODIFY,
        bonus: this.bonus,
      },
      closeCallback: () => this.getBonus(),
    });
  }

  async deleteBonus(): Promise<void> {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.bonusBackendUrl,
      closeCallback: () => this.providerService.utilService.navigateBack(),
    });
  }

  async getBonus() {
    this.loading = true;
    await this.providerService.networkService.get(this.bonusBackendUrl, this.mapper);
    this.bonus = this.mapper.bonus;
    this.loading = false;
  }
}
