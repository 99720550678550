<img class="logo" src="assets/images/logo-beSharp_bianco.svg" title="logo" alt="logo" />

<h1>Sign Up</h1>
<h3>Please register to access the portal</h3>

<div class="auth-form">
  <form [formGroup]="form">
    <div *ngIf="!isConfirm">

      <mat-form-field appearance="fill">
        <mat-label>Add Email</mat-label>
        <input matInput type="email" id="email" name="email" formControlName="email" placeholder="email">
        <i class="fa fa-regular fa-envelope" matSuffix></i>
        <mat-hint>Insert your email</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'email'" [icon]="'fa-regular fa-circle-exclamation'" [message]="'Invalid email'"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>Add Password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" id="password" name="password" formControlName="password">
        <button class="transparent" matSuffix type="button" (click)="showPassword = !showPassword">
          <i class="fa-regular {{ showPassword ? 'fa-eye-slash' : 'fa-eye' }}"></i>
        </button>
        <mat-hint>Insert your password</mat-hint>
      </mat-form-field>
      <app-validator-ui
        [forceShow]="true"
        [form]="form"
        [name]="'password'"
        [icon]="'fa-regular fa-circle-exclamation'"
        [iconSuccess]="'fa-regular fa-shield-check'"
        [message]="'Min length is 8 characters'"
        [errorCode]="'minlength'"></app-validator-ui>
      <app-validator-ui
        [forceShow]="true"
        [form]="form"
        [name]="'password'"
        [icon]="'fa-regular fa-circle-exclamation'"
        [iconSuccess]="'fa-regular fa-shield-check'"
        [message]="'At least 1 uppercase letter'"
        [errorCode]="'hasCapitalCase'"></app-validator-ui>
      <app-validator-ui
        [forceShow]="true"
        [form]="form"
        [name]="'password'"
        [icon]="'fa-regular fa-circle-exclamation'"
        [iconSuccess]="'fa-regular fa-shield-check'"
        [message]="'At least 1 lowercase letter'"
        [errorCode]="'hasSmallCase'"></app-validator-ui>
      <app-validator-ui
        [forceShow]="true"
        [form]="form"
        [name]="'password'"
        [icon]="'fa-regular fa-circle-exclamation'"
        [iconSuccess]="'fa-regular fa-shield-check'"
        [message]="'At least 1 number'"
        [errorCode]="'hasNumber'"></app-validator-ui>
      <app-validator-ui
        [forceShow]="true"
        [form]="form"
        [name]="'password'"
        [icon]="'fa-regular fa-circle-exclamation'"
        [iconSuccess]="'fa-regular fa-shield-check'"
        [message]="'At least 1 special character'"
        [errorCode]="'hasSpecialCharacters'"></app-validator-ui>

      <button color="primary" mat-raised-button type="button" (click)="signUp()" [disabled]="loading">
        <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true" *ngIf="loading"></span>
        Sign up
      </button>
    </div>

    <div class="card-body" *ngIf="isConfirm">
      <mat-form-field appearance="fill">
        <mat-label>Insert the confirmation code</mat-label>
        <input matInput type="text" id="code" name="code" formControlName="code" placeholder="code">
        <i class="fa fa-regular fa-envelope" matSuffix></i>
        <mat-hint>Your code goes here</mat-hint>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'code'" [icon]="'fa-regular fa-circle-exclamation'" [message]="'Empty code'"></app-validator-ui>

      <button color="primary" mat-raised-button type="button" (click)="confirmSignUp()" [disabled]="loading">
        <span class="fa fa-spin fa-spinner" role="status" aria-hidden="true" *ngIf="loading"></span>
        Confirm
      </button>
    </div>
  </form>
  <br>
  <br>
  <span>Already registered? Sign in <a href="/auth/sign-in">here</a>!</span>
</div>


