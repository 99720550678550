<div class="align-right">
    <button mat-raised-button color="primary" (click)="createCronjob();" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
        <i class="fa-solid fa-file-contract"></i>
        &nbsp;
        <span>{{eTextStrings.CRONJOB_CREATE}}</span>
    </button>
</div>

<div class="table-header-container">
    <div class="table-header-text">
        <i class="fa-solid fa-clock"></i>
        <h1>{{eTextStrings.PAGES_CRONJOB}}</h1>
    </div>

    <div class="table-search-form-container">
        <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.CRONJOB_SEARCH}}</mat-label>
            <input matInput placeholder="Search items" (keyup)="sendFilterQuery($event);">
            <i class="fa-regular fa-magnifying-glass" matSuffix></i>
            <mat-hint>
                <!--                {{'AREAS.TYPE_YOUR_FULL_TEXT_QUERY'}}-->
            </mat-hint>
        </mat-form-field>
    </div>
</div>

<app-table-ui
        [backendUrl]="backendUrl"
        [datasource]="dataSource"
        [defaultDirection]="'asc'"
        [defaultActive]="'name'"
        [tableDefinitions]="tableDefinitions"
        [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
        [pageSize]="10"
        [pageSizeOptions]="[10,20,30]"
        [filterObservable]="filterObservable"
        [mapper]="cronjobMapper"
        [hasRowAction]=true
        (rowClicked)="null"
>

</app-table-ui>
