<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title">
      {{eTextStrings.MODIFY}}{{' '}}{{eTextStrings.SETUP}}
    </h1>
    <form [formGroup]="form">
      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.SETUP_BESHARPER}}</mat-label>
          <input type="text"
                 placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                 aria-label="name"
                 matInput
                 (input)="filter()"
                 formControlName="besharper"
                 [matAutocomplete]="auto"
          />

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
            <mat-option (onSelectionChange)="onBesharperSelected(besharper)" *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
          </mat-autocomplete>
        <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>
      </mat-form-field>
        </div>
        <div class="sided">
          <div class="new-one">
            <small>Non hai trovato il beSharper che cerchi? <a [routerLink]="['/', 'app', 'besharpers']" [queryParams]="{ openCreate: true}" [target]="'_blank'">Crea beSharper</a></small>
            <button color="primary" mat-raised-button type="button" (click)="getBesharpers()"><i class="fa fa-refresh"></i></button>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>{{eTextStrings.SETUP_CONTRACT}}</mat-label>
            <input matInput [matTooltip]="selectedContract ? JSON.stringify(selectedContract) : ''" class="readonly" readonly="readonly" value="{{data?.data?.contract?.startDate}} {{data?.data?.contract?.endDate ?? 'Corrente'}} {{data?.data?.contract?.contractLevel}}" formControlName="contract">
          </mat-form-field>
        </div>
        <div class="sided">
          <div class="new-one">
            <small>Non hai trovato il contratto che cerchi? <a [routerLink]="['/', 'app', 'contracts']" [queryParams]="{ openCreate: true}" [target]="'_blank'">Crea Contratto</a></small>
            <button color="primary" mat-raised-button type="button" (click)="setCurrentContract()"><i class="fa fa-refresh"></i></button>
          </div>
        </div>
      </div>

      <mat-form-field appearance="fill">
        <mat-label>{{eTextStrings.SETUP_ROLE}}</mat-label>
        <mat-select formControlName="role">
          <mat-option [value]="s.id" *ngFor="let s of roles">{{s.name}}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Sei TPM?</mat-label>
            <mat-select formControlName="isTpm">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Sei Line manager?</mat-label>
            <mat-select formControlName="isLineManager">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="false">
          <mat-form-field appearance="fill">
            <mat-label>Sei Supervisor?</mat-label>
            <mat-select formControlName="isSupervisor">
              <mat-option [value]="false">NO</mat-option>
              <mat-option [value]="true">SI</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="{{(!this.form.controls.isTpm.value && !this.form.controls.isLineManager.value) ? 'col-2': ''}}">
        <div class="sided" *ngIf="showTpm()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo TPM</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterTpm()"
                   formControlName="tpm"
                   [matAutocomplete]="autoTpm"
            />

            <mat-autocomplete #autoTpm="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperTpmSelected(besharper)" *ngFor="let besharper of filteredTpmBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="showLineManager()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo Line Manager</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterLm()"
                   formControlName="lineManager"
                   [matAutocomplete]="autoLinemanager"
            />

            <mat-autocomplete #autoLinemanager="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperLineManagerSelected(besharper)" *ngFor="let besharper of filteredLinemanagerBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="sided" *ngIf="showSupervisor()">
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il tuo Supervisor</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filterSupervisor()"
                   formControlName="supervisor"
                   [matAutocomplete]="autoSupervisor"
            />

            <mat-autocomplete #autoSupervisor="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperSupervisorSelected(besharper)" *ngFor="let besharper of filteredSupervisorBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

      <div class="create-modify-button">
        <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" disabled="{{form.invalid || loading}}" mat-raised-button (click)="sendRequest()">{{eTextStrings.CONFIRM}}</button>
        <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
      </div>
    </form>
  </div>
</div>
