import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-remind-ooo-args-form',
  templateUrl: './remind-ooo-args-form.component.html',
  styleUrl: './remind-ooo-args-form.component.scss'
})
export class RemindOooArgsFormComponent implements OnInit {
  ngOnInit(): void {
    const remindAfterDays = this.args?.remindAfterDays;
    if(remindAfterDays && typeof remindAfterDays === 'number') {
      this.form.controls.remindAfterDays.setValue(remindAfterDays);
    }
  }


  @Input() args : any = {};
  @Output() argsChange = new EventEmitter<any | null>();
  
  public form = new FormGroup({
    remindAfterDays: new FormControl(3, [Validators.required]),
  });

  public emitArgs() {
    if(!this.form.valid) {
      this.argsChange.emit(null);
    }
    else {
      this.argsChange.emit({
        remindAfterDays: this.form.controls.remindAfterDays.value ?? 3
      })
    }
  }
}
