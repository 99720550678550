<div class="create-modify-container">
  <app-loader *ngIf="loading"></app-loader>
  <div class="create-modify-form">
    <h1 class="create-modify-title">
      {{eTextStrings.CREATE}} Festività
    </h1>
    <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="inserisci il nome della festività" formControlName="name">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Giorno</mat-label>
        <input matInput placeholder="inserisci il giorno" formControlName="day" type="number" min="1">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Mese</mat-label>
        <mat-select placeholder="inserisci il mese" formControlName="month">
          <mat-option *ngFor="let month of months" [value]="month.index">
            {{ month.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="create-modify-button">
        <button color="{{form.valid && !loading ? 'primary' : 'disabled'}}" [disabled]="form.invalid || loading"
          mat-raised-button (click)="handleSubmit()">{{eTextStrings.CONFIRM}}</button>
        <button color="primary" mat-raised-button (click)="dialogRef.close()">{{eTextStrings.CANCEL}}</button>
      </div>
    </form>
  </div>
</div>