<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{isCreateMode() ? eTextStrings.BONUS.CREATE : eTextStrings.BONUS.MODIFY}}</h1>

    <form spellcheck="false" [formGroup]="form">

        <ng-container *ngIf="listMode">
          <mat-form-field appearance="fill">
            <mat-label>BeSharper</mat-label>
            <input type="text"
                   placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                   aria-label="name"
                   matInput
                   (input)="filter()"
                   formControlName="besharper"
                   [matAutocomplete]="auto"
                   [disabled]="this.besharperLocked"
            />

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
              <mat-option (onSelectionChange)="onBesharperSelected(besharper)" *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>

        </ng-container>

      <mat-form-field appearance="fill">
        <mat-label>Valore</mat-label>
        <input matInput type="number" id="value" name="value" formControlName="value" placeholder="Valore..." value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'value'" [errorCode]="'required'"
        [icon]="'fa-regular fa-circle-exclamation'" message="Inserire valore welfare"></app-validator-ui>
      <app-validator-ui [form]="form" [name]="'value'" [errorCode]="'pattern'"
        [icon]="'fa-regular fa-circle-exclamation'" message="Solo valori numerici"></app-validator-ui>

      <mat-form-field appearance="fill">
        <mat-label>Motivo</mat-label>
        <input matInput type="text" id="reason" name="reason" formControlName="reason" value="">
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'reason'" [icon]="'fa-regular fa-circle-exclamation'"
        message="Inserire motivo"></app-validator-ui>

      <mat-form-field appearance="fill" (focus)="datepicker.open()" (click)="datepicker.open()">
        <mat-label>Data di inizio</mat-label>
        <input matInput [matDatepicker]="datepicker" id="supplyDate" name="supplyDate" formControlName="supplyDate" (focus)="datepicker.open()" (click)="datepicker.open()">
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker></mat-datepicker>
      </mat-form-field>
      <app-validator-ui [form]="form" [name]="'supplyDate'" [icon]="'fa-regular fa-circle-exclamation'"
        message="Inserire data di inizio"></app-validator-ui>


    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>{{eTextStrings.BUTTONS_BACK}}</span>
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button" (click)="createBonus()" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_CREATE}}
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
            type="button" (click)="createBonus(true)" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_CREATE_AND_CONTINUE}}
    </button>
    <button *ngIf="isModifyMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button" (click)="modifyBonus();" [disabled]="!formValid() || loading">
      {{eTextStrings.BONUS_MODIFY}}
    </button>
  </div>
</div>
