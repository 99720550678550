import { Component } from "@angular/core";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { ProviderService } from "../../../../core/provider.service";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { QualificationModel } from "../../../../models/qualification.model";
import { Constants } from "../../../../core/constants/constants";
import { ListQualificationMapper } from "../../../../mappers/qualification.mapper";
import { ModeEnum } from "../../../../core/constants/enums";
import { AdminPanelCreateModifyBesharperComponent } from "../../../dialogs/admin-panel-create-modify-besharper/admin-panel-create-modify-besharper.component";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { Router } from "@angular/router";

@Component({
  selector: 'app-set-besharp-dropdown-menus',
  templateUrl: './set-besharp-dropdown-menus.component.html',
  styleUrl: './set-besharp-dropdown-menus.component.scss',
})
export class SetBesharpDropdownMenusComponent extends IFilteredTablePage {
  besharperQualificationCollapsed = true;

  besharperQualificationDataSource: TableDataSource<QualificationModel> = new TableDataSource<QualificationModel>();
  besharperQualificationBackendUrl = environment.cognito.apiEndpoint + Constants.qualificationsApiPath;
  besharperQualificationTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: false },
    { def: 'description', title: 'Descrizione', sortable: true },
  ];
  besharperActionDefinitions = [
    {
      icon: 'fa-solid fa-pencil',
      text: 'Modifica',
      styleClass: 'table-action-success',
      callback: (item: any) => {
        this.openModifyBesharperDialog(item);
      },
    },
    {
      icon: 'fa-solid fa-trash',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: any) => {
        this.deleteItem(item.id).then();
      },
    },
  ];
  besharperQualificationMapper = new ListQualificationMapper();

  eTextStrings = TextStrings;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.besharperQualificationCollapsed = !this.besharperQualificationCollapsed;
  }

  async deleteItem(id: string): Promise<void> {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.besharperQualificationBackendUrl}/${id}`,
    });
  }

  openModifyBesharperDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyBesharperComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
      }
    });
  }

  openCreateBesharperDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyBesharperComponent,
      data: { mode: ModeEnum.CREATE }
    });
  }
}
