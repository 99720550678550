import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrl: './confirm-delete.component.scss',
})
export class ConfirmDeleteComponent {
  loading: boolean;
  eTextStrings = TextStrings;
  hasDescription = false;
  description: string | undefined = ""

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.loading = false;
    this.hasDescription = !!data.description;
    this.description = data.description;
  }

  async confirmDelete(): Promise<void> {
    this.loading = true;
    const mapper = new SimpleMapper();
    await this.providerService.networkService
      .delete(this.data['url'], mapper)
      .then(() => {
        this.loading = false;
        this.providerService.utilService.showMessage(TextStrings.DELETE_SUCCESS, LogLevel.success);
        this.dialogRef.close(true);

        if(this.data['returnPath']) {
          this.providerService.utilService.navigateTo(this.data['returnPath']);
        }
      })
      .catch((e) => {
        this.loading = false;
        if (e.includes('409')) {
          this.providerService.utilService.showMessage('DELETE_DATABASE_ERROR', LogLevel.error);
        } else {
          this.providerService.utilService.showMessage(e, LogLevel.error);
        }
      });
  }
}
