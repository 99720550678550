import { Component } from '@angular/core';
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { EquipmentModel } from '../../../../models/equipment.model';
import { ListEquipmentMapper } from '../../../../mappers/equipment.mapper';
import { ProviderService } from '../../../../core/provider.service';
import { CreateModifyBesharperEquipmentComponent } from '../../../dialogs/create-modify-besharper-equipment/create-modify-besharper-equipment.component';
import { Constants } from '../../../../core/constants/constants';
import { IFilteredTablePage } from '../../../../core/interfaces/i-filtered-table-page';
import { ConfirmDeleteComponent } from '../../../dialogs/confirm-delete/confirm-delete.component';
import { RoleEnum } from "../../../../models/role.enum";
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { Router } from "@angular/router";

@Component({
  selector: 'app-besharper-equipment',
  templateUrl: './besharper-equipment.component.html',
  styleUrl: './besharper-equipment.component.scss',
})
export class BesharperEquipmentComponent extends IFilteredTablePage {
  backendUrl = environment.cognito.apiEndpoint + Constants.equipmentApiPath;
  dataSource: TableDataSource<EquipmentModel[]> = new TableDataSource<EquipmentModel[]>();
  tableMapper = new ListEquipmentMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;

  tableDefinitions = [
    { def: 'equipmentName', title: TextStrings.EQUIPMENT.NAME, sortable: true },
    { def: 'equipmentCode', title: TextStrings.EQUIPMENT.CODE, sortable: true },
    { def: 'equipmentCategory', title: TextStrings.EQUIPMENT.CATEGORY, sortable: true },
    { def: 'beSharperName', title: TextStrings.EQUIPMENT.LABELS.BESHARPERNAME, sortable: true },
    { def: 'besharperSurname', title: TextStrings.EQUIPMENT.LABELS.BESHARPERLASTNAME, sortable: true },
  ];

  actionDefinitions = [
    {
      icon: 'fas fa-pen',
      text: TextStrings.EQUIPMENT.MODIFY,
      styleClass: 'table-action-modify',
      callback: (item: EquipmentModel): void => {
        this.openCreateOrModifyBesharperEquipmentDialog('modify', item);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.EQUIPMENT.DELETE,
      styleClass: 'table-action-delete',
      callback: (item: EquipmentModel): void => {
        this.deleteEquipment(item.id);
      },
    }
  ];

  constructor(public providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  async deleteEquipment(id: string): Promise<void> {
    this.providerService.utilService
      .openDialog(ConfirmDeleteComponent, {
        url: `${this.backendUrl}/${id}`,
      })
      .afterClosed()
      .subscribe(() => {
        this.filterObservable.next(this.lastDebounceValue);
      });
  }

  openCreateOrModifyBesharperEquipmentDialog(dialogType: string, item?: EquipmentModel): void {
    dialogType === 'create'
      ? this.providerService.utilService
        .openDialog(CreateModifyBesharperEquipmentComponent, { mode: 'create' })
        .afterClosed()
        .subscribe(() => {
          this.filterObservable.next(this.lastDebounceValue);
        })
      : this.providerService.utilService
        .openDialog(CreateModifyBesharperEquipmentComponent, {
          mode: 'modify',
          data: item,
        })
        .afterClosed()
        .subscribe(() => {
          this.filterObservable.next(this.lastDebounceValue);
        });
  }
}
