import { Injectable } from '@angular/core';
import { IAuthenticator } from '../../models/i-authenticator';
import { IMapper } from '../../models/i-mapper';
import { HttpVerb } from '../../models/http-verb';
import axios from "axios";
import { SimpleMapper } from "../../mappers/simple.mapper";

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private authenticator?: IAuthenticator;
  private headers: { [key: string]: string } = {};

  constructor() {
    this.headers['Content-Type'] = 'application/json';
  }

  setAuthenticator(authenticator: IAuthenticator) {
    this.authenticator = authenticator;
  }

  async get(url: string, mapper: IMapper, params?: { [key: string]: string }): Promise<IMapper | any> {
    this.headers = {};

    try {
      const request = await this.authenticator?.getRequest(url, HttpVerb.get, this.headers, undefined, params);
      delete request?.headers?.host;
      const response = await axios.get(url, {
        headers: request?.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error: any) {
      return this.manageResponse(error.response, new SimpleMapper());
    }
  }

  async post(url: string, bodyMapper: IMapper, responseMapper: IMapper): Promise<IMapper | any> {
    this.headers = {};
    this.headers['Content-Type'] = 'application/json';
    const body = bodyMapper.fillToJson();

    try {
      const request = await this.authenticator?.getRequest(url, HttpVerb.post, this.headers, body);
      delete request.headers.host;
      const response = await axios.post(url, request.body, {
        headers: request.headers,
      });
      //console.log(responseMapper);
      return this.manageResponse(response, responseMapper);
    } catch (error: any) {
      return this.manageResponse(error.response, new SimpleMapper());
    }
  }

  async put(url: string, bodyMapper: IMapper, responseMapper: IMapper): Promise<IMapper | any> {
    this.headers = {}
    this.headers['Content-Type'] = 'application/json';
    const body = bodyMapper.fillToJson();

    try {
      const request = await this.authenticator?.getRequest(url, HttpVerb.put, this.headers, body);
      delete request?.headers?.host;
      const response = await axios.put(url, request.body, {
        headers: request?.headers,
      });
      return this.manageResponse(response, responseMapper);
    } catch (error: any) {
      return this.manageResponse(error.response, new SimpleMapper());
    }
  }

  async delete(url: string, mapper: IMapper, params?: { [key: string]: string }): Promise<IMapper | any> {
    this.headers = {};

    try {
      const request = await this.authenticator?.getRequest(url, HttpVerb.delete, this.headers, undefined, params);
      delete request?.headers?.host;
      const response = await axios.delete(url, {
        headers: request?.headers,
        params,
      });
      return this.manageResponse(response, mapper);
    } catch (error: any) {
      return this.manageResponse(error.response, new SimpleMapper());
    }
  }

  private async manageResponse(response: any, mapper: IMapper): Promise<any> {
    console.log(response);
    if (this.authenticator && this.authenticator?.isAuthenticationError(response.status)) {
      return this.authenticator?.manageError(response);
    } else if (response.status <= 299 && response.status > 199) {
      return Promise.resolve(this.convertToObject(response.data, mapper));
    } else {
      if ((response.data.msg ?? "").toString().includes("Google calendar")) {
        return Promise.resolve(this.convertToObject({ googleCalendar: true, warning: response.data.msg }, mapper));
      }
      return Promise.reject(`Status code: ${response.status} - error: ${response.data.responseMessage || response.data.msg}`);
    }
  }

  private convertToObject(responseBody: any, mapper: IMapper): IMapper {
    mapper.fillFromJson(responseBody);
    return mapper;
  }
}
