import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IMapper } from '../../../models/i-mapper';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import { OutOfOfficeTypeDetailsMapper } from "../../../mappers/out-of-office.mapper";

@Component({
  selector: 'app-admin-panel-create-modify-out-of-office-type',
  templateUrl: './admin-panel-create-modify-out-of-office-type.component.html',
  styleUrl: './admin-panel-create-modify-out-of-office-type.component.scss'
})
export class AdminPanelCreateModifyOutOfOfficeTypeComponent {
  loading = false;
  formMode = ModeEnum.CREATE;
  dropDownName = '';

  modifyOutOfOfficeTypeUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeTypeApiPath;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    offsetDays: new FormControl(1, [Validators.required, Validators.min(0)]),
    hasAttachment: new FormControl(false, [Validators.required]),
    onlyWorkDays: new FormControl(false, [Validators.required]),
    showTypeInCalendar: new FormControl(false, [Validators.required]),
    sortKey: new FormControl(1, [Validators.required]),
  });

  eTextStrings = TextStrings;
  protected readonly ModeEnum = ModeEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyOutOfOfficeTypeComponent>
  ) {
    this.formMode = this.data.mode;
    if (data.mode === ModeEnum.MODIFY) {
      this.fillControls();
    }
  }

  async sendRequest(): Promise<void> {
    if (this.formMode === ModeEnum.MODIFY) {
      await this.sendModifyRequest();
    } else {
      await this.sendCreateRequest();
    }
  }

  async deleteItem(): Promise<void> {
    this.providerService.utilService.openDialog(ConfirmDeleteComponent, { url: `${this.modifyOutOfOfficeTypeUrl}/${this.data.data.id}` })
      .afterClosed()
      .subscribe(() => {
        document.location.reload();
      });
  }

  async sendCreateRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();
    this.loading = true;
    const createRequestMapper = new OutOfOfficeTypeDetailsMapper();
    this.fillRequestBody(createRequestMapper);
    this.providerService.networkService
      .post(this.modifyOutOfOfficeTypeUrl, createRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });

  }

  async sendModifyRequest(): Promise<void> {
    const responseMapper = new SimpleMapper();

    this.loading = true;
    const updateRequestMapper = new OutOfOfficeTypeDetailsMapper();
    this.fillRequestBody(updateRequestMapper);
    this.providerService.networkService
      .put(`${this.modifyOutOfOfficeTypeUrl}/${this.data.data.id}`, updateRequestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      });
  }
  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
      code: this.form.controls.code.value,
      offsetDays: this.form.controls.offsetDays.value,
      hasAttachment: this.form.controls.hasAttachment.value,
      sortKey: this.form.controls.sortKey.value,
      onlyWorkDays: this.form.controls.onlyWorkDays.value,
      showTypeInCalendar: this.form.controls.showTypeInCalendar.value,
    });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillControls(): void {
    console.log("DATA: ", this.data.data);

    this.form.controls.name.setValue(this.data.data.name);
    this.form.controls.code.setValue(this.data.data.code);
    this.form.controls.offsetDays.setValue(this.data.data.offsetDays ?? 1);
    this.form.controls.hasAttachment.setValue(this.data.data.hasAttachment ?? false);
    this.form.controls.sortKey.setValue(this.data.data.sortKey ?? 1);
    this.form.controls.onlyWorkDays.setValue(this.data.data.onlyWorkDays ?? false);
    this.form.controls.showTypeInCalendar.setValue(this.data.data.showTypeInCalendar ?? false);
  }
}

