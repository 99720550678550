import { Component } from '@angular/core';
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrl: './admin-panel.component.scss'
})
export class AdminPanelComponent {
  eTextStrings = TextStrings;
}
