import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FormLabels } from "../../../core/interfaces/i-form-labels";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { TextStrings } from "../../../core/constants/text-strings";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { WelfarePostMapper } from "../../../mappers/welfare.mapper";
import { LogLevel } from "../../../models/log-level";
import { IMapper } from "../../../models/i-mapper";
import { ModeEnum } from '../../../core/constants/enums';

@Component({
  selector: 'app-admin-panel-create-modify-welfare-reason',
  templateUrl: './admin-panel-create-modify-welfare-reason.component.html',
  styleUrl: './admin-panel-create-modify-welfare-reason.component.scss'
})
export class AdminPanelCreateModifyWelfareReasonComponent {
  loading = false;
  formMode = '';

  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  createModifyWelfareReasonUrl = environment.cognito.apiEndpoint + Constants.welfareReasonApiPath;

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyWelfareReasonComponent>
  ) {
    this.formMode = this.data.mode;
    if (this.data.mode === ModeEnum.MODIFY) {
      this.fillFormControls();
    }
  }

  async sendRequest(): Promise<void> {
    if (this.data.mode === ModeEnum.CREATE) {
      await this.sendCreateRequest();
    } else {
      await this.sendModifyRequest();
    }
  }

  async sendCreateRequest(): Promise<void> {
    this.loading = true;
    const responseMapper = new SimpleMapper();
    const requestMapper = new WelfarePostMapper();
    this.fillRequestBody(requestMapper);
    this.providerService.networkService
      .post(this.createModifyWelfareReasonUrl, requestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  async sendModifyRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new WelfarePostMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .put(`${this.createModifyWelfareReasonUrl}/${this.data.data.id}`, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
    });
  }

  fillFormControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
  }

  protected readonly ModeEnum = ModeEnum;
}
