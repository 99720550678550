import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { ContractLevelModel } from "../models/contract-level.model";
import { ContractRoleModel } from "../models/contract-role.model";
import { ContractTypeModel } from "../models/contract-type.model";

export class ContractMapper implements IMapper {
  contract = {};

  fillFromJson(json: any): void {
    this.contract = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.contract);
  }
}

export class ContractLevelMapper implements IListMapper {
  count: number = 0;
  elements: ContractLevelModel[] = [];

  fillFromJson(json: any): PagedModel<ContractLevelModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push(this.mapContractType(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  private mapContractType(value: any): ContractLevelModel {
    return {
      id: value['id'],
      name: value['name'],
      description: value['description'],
      createdAt: value['createAt'],
      updatedAt: value['updatedAt'],
    };
  }
  formatDate(date: Date): string {
    const day: string = date.getDate().toString().padStart(2, '0');
    const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
    return date.getFullYear() + '-' + month + '-' + day;
  }
}

export class ContractRoleMapper implements IListMapper {
  count: number = 0;
  elements: ContractRoleModel[] = [];

  fillFromJson(json: any): PagedModel<ContractRoleModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push(this.mapContractType(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  private mapContractType(value: any): ContractRoleModel {
    return {
      id: value['id'],
      name: value['name'],
      description: value['description'],
      createdAt: value['createAt'],
      updatedAt: value['updatedAt'],
    };
  }
}

export class ContractTypeMapper implements IListMapper {
  count: number = 0;
  elements: ContractTypeModel[] = [];

  fillFromJson(json: any): PagedModel<ContractTypeModel> {
    this.elements = [];
    json['items'].forEach((value: any) => {
      this.elements.push(this.mapContractType(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  private mapContractType(value: any): ContractTypeModel {
    return {
      id: value['id'],
      name: value['name'],
      description: value['description'],
      createdAt: value['createAt'],
      updatedAt: value['updatedAt'],
    };
  }
}
