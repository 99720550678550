<div class="confirm-delete-container">
    <h2>{{eTextStrings.CONFIRM_DELETE}}</h2>
    <ng-container *ngIf="hasDescription">
      <p>{{description}}</p>
    </ng-container>
    <div class="button-container">
        <div class="align-right create-button">
            <button mat-raised-button color="secondary" (click)="dialogRef.close(false)">
                <i class="fa-solid fa-xmark"></i>
                &nbsp;
                <span>{{eTextStrings.CANCEL}}</span>
            </button>
        </div>
        <div class="align-right create-button">
            <button mat-raised-button [disabled]="loading" color="{{!loading ? 'primary' : 'disabled'}}" (click)="confirmDelete();">
                <i class="fa-solid fa-trash"></i>
                &nbsp;
                <span>{{eTextStrings.DELETE}}</span>
            </button>
        </div>
    </div>
</div>

