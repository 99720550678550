import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LogLevel } from "../../models/log-level";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { Constants } from "../constants/constants";
import { CustomSnackBarComponent } from "../form-utils/custom-snack-bar/custom-snack-bar.component";
import { filter, Subscription } from "rxjs";
import { Location } from "@angular/common";
import moment, { Moment } from "moment";
import { ConfirmDeleteComponent } from "../../components/dialogs/confirm-delete/confirm-delete.component";
import IBasePage from "../interfaces/i-base-page";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { IUser } from "../../models/i-user";
import { ContractModel } from "../../models/contract.model";
import RoleEnum from "../../models/role.enum";
import fileTypeChecker from "file-type-checker";

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  dialogRef: any = null;
  private loaderEnabled = true;

  constructor(private snackbar: MatSnackBar, private router: Router, private dialog: MatDialog, private location: Location) { }

  showMessage(message: string, type: LogLevel, options: { duration: "normal" | "long" | number } = { duration: "normal" }): void {
    let duration_ms: number | undefined;
    switch (options.duration) {
      case "normal":
        duration_ms = Constants.snackbarDuration;
        break;
      case "long":
        duration_ms = Constants.snackbarDurationLong;
        break;
      default:
        duration_ms = options.duration;
        break;
    }

    this.snackbar.openFromComponent(CustomSnackBarComponent, {
      data: { message, type },
      duration: duration_ms
    });
  }

  formatDate(date: Date | Moment | null): string | null {
    if (date) {
      date = moment(date).toDate();
      const day: string = date.getDate().toString().padStart(2, '0');
      const month: string = (date.getMonth() + 1).toString().padStart(2, '0');
      return date.getFullYear() + '-' + month + '-' + day;
    }
    return null;
  }

  formatBytes(bytes: number, decimals: number = 2): string {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  async validateFileMimeType(file: File, acceptedMimeTypes: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(fileTypeChecker.validateFileType(reader.result as any, acceptedMimeTypes));
      };
      reader.onerror = () => {
        reject(false);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  toItalianDateFormat(date: Date, onlyDate = false): string {

    return moment(date).format("DD/MM/YYYY") + (onlyDate ? '' : (" " + moment(date).format("HH:mm")));
  }

  openDialog(component: any, data: any, panelClass?: string): MatDialogRef<any> {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.dialogRef = this.dialog.open(component, {
      data,
      panelClass,
      autoFocus: false
    });
    return this.dialogRef;
  }

  openCrudDialog(options: { page: IBasePage, template: any, data: any, closeCallback?: (result?: any) => void }): void {
    this.openDialog(options.template, options.data).afterClosed().subscribe((result?: any) => {
      options.page.filterObservable.next(options.page.lastDebounceValue);
      if (options.closeCallback) {
        options.closeCallback(result);
      }
    });
  }

  deleteCrudDialog(options: { page: IBasePage, apiUrl: string, returnPath?: string, description?: string, closeCallback?: (result?: any) => void }): void {
    this.openDialog(ConfirmDeleteComponent, {
      url: options.apiUrl,
      returnPath: options.returnPath,
      description: options.description
    }).afterClosed().subscribe((result?: any) => {
      options.page.filterObservable.next(options.page.lastDebounceValue);
      if (options.closeCallback) {
        options.closeCallback(result);
      }
    });
  }

  initializeAppNavigationHistory(): Subscription {
    sessionStorage.setItem('totalNavigations', '0');
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      let navigations = parseInt(sessionStorage.getItem('totalNavigations')!, 10) || 0;
      navigations++;
      sessionStorage.setItem('totalNavigations', navigations.toString());
    });
  }

  navigateBack(): void {
    const totalNavigations = parseInt(sessionStorage.getItem('totalNavigations')!, 10);
    if (totalNavigations > 1) {
      this.location.back();
    } else {
      this.router.navigateByUrl(Constants.besharpersPath).then(); // Home
    }
  }

  async navigateTo(href: string, data?: string): Promise<void> {
    if (data) {
      await this.router.navigate([href, data]);
    } else {
      await this.router.navigate(href.split('/'));
    }
  }

  refreshPage(): void {
    window.location.reload();
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getTimeZoneOffset(date: Date) {
    const tzo = - date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    return dif + this.pad(Math.floor(Math.abs(tzo) / 60), 2) + ':' + this.pad(Math.abs(tzo) % 60, 2);
  }

  isLoaderEnabled(): boolean {
    return this.loaderEnabled;
  }

  setLoader(enabled: boolean): void {
    this.loaderEnabled = enabled;
  }

  convertBooleanToUISymbols(value: any): string {
    return `<span class="${value ? 'green' : 'red'}"><i class="fa fa-${value ? 'check' : 'times'}"></i></span>`;
  }

  convertBooleanToWarningSymbols(value: any): string {
    return value ? `<span class="yellow"><i class="fa fa-warning"></i></span>` : '';
  }

  checkIfDataIsMissing(user: IUser, currentContract: ContractModel): boolean {

    if (user.name === "User") {
      console.log("CIAO: ", user, currentContract);
    }


    const role = user.role;
    if (role === RoleEnum.ADMIN) return false;


    if ([RoleEnum.EMPLOYEE, RoleEnum.HR, RoleEnum.HR_REPORTS].includes(role)) {
      if (!currentContract.businessUnit) return true;
      if (!currentContract.isTpm && !currentContract.tpm) return true;
      if (!currentContract.isLineManager && !currentContract.lineManager) return true;
    }
    if (role === RoleEnum.TPM) {
      if (!currentContract.businessUnit) return true;
      if (!currentContract.isTpm) return true;
      if (!currentContract.isLineManager && !currentContract.lineManager) return true;
    }
    if (role === RoleEnum.LINE_MANAGER) {
      if (!currentContract.businessUnit) return true;
      if (!currentContract.isLineManager) return true;
      if (!currentContract.supervisor) return true;
    }

    return false;
  }

  static jsonValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!value) {
      return { jsonInvalid: true };
    }

    try {
      const json = JSON.parse(value);
      for (const key of Object.keys(json)) {
        const value = json[key];
        if (value !== "text" && value !== "number" && value !== "boolean") {
          return { jsonInvalid: true };
        }
      }

      return null;
    } catch (ex: any) {
      return { jsonInvalid: true };
    }
  }

  checkContractWarnings(data: any) {
    if (data.role === RoleEnum.ADMIN) return "";

    if (data.contract) {
      data.contract["businessUnit"] = data.contract.businessUnitId || null;
      data.contract["tpm"] = data.contract.tpmId || null;
      data.contract["lineManager"] = data.contract.lineManagerId || null;
      data.contract["supervisor"] = data.contract.supervisorId || null;

      return this.convertBooleanToWarningSymbols(this.checkIfDataIsMissing({ role: data.role, name: data.besharper.name } as IUser, data.contract));
    } else {
      return this.convertBooleanToWarningSymbols(true);
    }
  }
}
