import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { TextStrings } from "../../../core/constants/text-strings";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { ModeEnum } from "../../../core/constants/enums";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { WelfareMaximalsPostMapper, WelfarePostMapper } from "../../../mappers/welfare.mapper";
import { LogLevel } from "../../../models/log-level";
import { IMapper } from "../../../models/i-mapper";

@Component({
  selector: 'app-admin-panel-create-modify-welfare-maximals',
  templateUrl: './admin-panel-create-modify-welfare-maximals.component.html',
  styleUrl: './admin-panel-create-modify-welfare-maximals.component.scss'
})
export class AdminPanelCreateModifyWelfareMaximalsComponent {
  loading = false;
  formMode = '';
  id: string = '';

  form = new FormGroup({
    family: new FormControl(false, Validators.required),
    value: new FormControl(0, Validators.required),
  });

  createModifyWelfareMaximalsUrl = environment.cognito.apiEndpoint + Constants.welfareMaximalsApiPath;

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyWelfareMaximalsComponent>
  ) {
    this.formMode = this.data.mode;
    if (this.data.mode === ModeEnum.MODIFY) {
      this.fillFormControls();
    }
  }


  async sendRequest(): Promise<void> {
    if (this.data.mode === ModeEnum.CREATE) {
      await this.sendCreateRequest();
    } else {
      await this.sendModifyRequest();
    }
  }

  async sendCreateRequest(): Promise<void> {
    this.loading = true;
    const responseMapper = new SimpleMapper();
    const requestMapper = new WelfareMaximalsPostMapper();
    this.fillRequestBody(requestMapper);
    this.providerService.networkService
      .post(this.createModifyWelfareMaximalsUrl, requestMapper, responseMapper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.CREATE_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  async sendModifyRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new WelfareMaximalsPostMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .put(`${this.createModifyWelfareMaximalsUrl}/${this.data.data.id}`, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.MODIFY_SUCCESS, LogLevel.success);
        this.dialogRef.close();
        this.loading = false;
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }

  fillRequestBody(mapper: IMapper): void {
    if (this.id) {
      mapper.fillFromJson({
        id: this.id,
        family: this.form.controls.family.value,
        value: this.form.controls.value.value,
      });
    } else {
      mapper.fillFromJson({
        family: this.form.controls.family.value,
        value: this.form.controls.value.value,
      });
    }
  }

  fillFormControls(): void {
    this.id = this.data.data.id;
    this.form.controls.family.setValue(this.data.data.family);
    this.form.controls.value.setValue(this.data.data.value);
  }

  protected readonly ModeEnum = ModeEnum;
}
