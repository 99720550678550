import { Component } from '@angular/core';
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { TextStrings } from "../../../../core/constants/text-strings";
import { environment } from "../../../../../environments/environment";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { Constants } from "../../../../core/constants/constants";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { ModeEnum } from "../../../../core/constants/enums";
import { OutOfOfficeTypeModel } from "../../../../models/out-of-office-type.model";
import { AdminPanelCreateModifyOutOfOfficeTypeComponent } from "../../../dialogs/admin-panel-create-modify-out-of-office-type/admin-panel-create-modify-out-of-office-type.component";
import { SimpleListMapper } from "../../../../mappers/simple.mapper";
import { AdminPanelModifyUserContractRoleComponent } from "../../../dialogs/admin-panel-modify-user-contract-role/admin-panel-modify-user-contract-role.component";

export interface UserContractRole {
  besharperId: string;
  contractId: string;

  role: string;

  isTpm: boolean;
  isLineManager: boolean;
  isSupervisor: boolean;

  tpmEmail?: string;
  lineManagerEmail?: string;
  supervisorEmail?: string;
}

@Component({
  selector: 'app-set-user-contract-role',
  templateUrl: './set-user-contract-role.component.html',
  styleUrl: './set-user-contract-role.component.scss'
})
export class SetUserContractRoleComponent extends IFilteredTablePage {
  eTextStrings = TextStrings;
  loading = false;
  basePath = environment.cognito.apiEndpoint;

  userContractRoleDataModel: TableDataSource<UserContractRole> = new TableDataSource<UserContractRole>();
  userContractRoleCollapsed = true;
  userContractRoleBackendUrl = this.basePath + Constants.backOfficeApiPath + '/setup/besharper';
  userContractRoleMapper: SimpleListMapper = new SimpleListMapper();

  userContractRoleTableDefinitions = [
    { def: 'besharper', title: 'beSharper', sortable: false, virtual: (row: any) => row.besharper.name + " " + row.besharper.surname },
    { def: 'role', title: 'Ruolo', sortable: false },

    { def: 'isTpm', title: 'Tpm?', sortable: false, transformer: (value: any) => this.providerService.utilService.convertBooleanToUISymbols(value) },
    { def: 'isLineManager', title: 'LM?', sortable: false, transformer: (value: any) => this.providerService.utilService.convertBooleanToUISymbols(value) },

    { def: 'tpm', title: 'TPM', sortable: false, transformer: (value: any) => value?.email },
    { def: 'lineManager', title: 'LM', sortable: false, transformer: (value: any) => value?.email },
    { def: 'supervisor', title: 'Supervisore', sortable: false, transformer: (value: any) => value?.email },

    { def: 'isSupervisor', title: 'Warning', sortable: false, virtual: (row: any) => this.providerService.utilService.checkContractWarnings(row) },

   // { def: 'offsetDays', title: 'N° Giorni entro cui fare richiesta', sortable: false, transformer: (value: any)  => value ?? 1 },
  ];
  userContractRoleActionsDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Modifica',
      styleClass: 'table-action-modify',
      callback: (item: OutOfOfficeTypeModel) => {
        this.openModifyDialog(item);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.userContractRoleCollapsed = !this.userContractRoleCollapsed;
  }

  openCreateuserContractRoleDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelModifyUserContractRoleComponent,
      data: {
        mode: ModeEnum.CREATE
      }
    });
  }

  openModifyDialog(row: any): void {
    console.log("SETUP ITEM: ", row);

    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelModifyUserContractRoleComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row
      }
    });
  }
}
