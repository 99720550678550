<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>{{eTextStrings.CONTRACT_SET_DROPDOWNS}}</h1>
  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse(eContractsEnums.CONTRACT_LEVEL)"
                         (closed)="toggleCollapse(eContractsEnums.CONTRACT_LEVEL)">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.CONTRACT_LEVEL_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateContractDialog(eContractsEnums.CONTRACT_LEVEL)">{{eTextStrings.CREATE}} {{' '}} {{eTextStrings.CONTRACT_LEVEL_TITLE}}</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="contractLevelDataSource"
        [backendUrl]="contractLevelsBackendUrl"
        [tableDefinitions]="contractLevelTableDefinitions"
        [actionDefinitions]="contractLevelActionsDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="contractLevelsMapper"
        [showPaginator]=false
        [hasRowAction]=true
        (rowClicked)="openModifyDialog($event, eContractsEnums.CONTRACT_LEVEL);">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse(eContractsEnums.CONTRACT_TYPE)"
                         (closed)="toggleCollapse(eContractsEnums.CONTRACT_TYPE)">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.CONTRACT_TYPE_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateContractDialog(eContractsEnums.CONTRACT_TYPE)">{{eTextStrings.CREATE}} {{eTextStrings.CONTRACT_TYPE_TITLE}}</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="contractTypeDataSource"
        [backendUrl]="contractTypesBackendUrl"
        [tableDefinitions]="contractTypesTableDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [actionDefinitions]="contractTypeActionsDefinitions"
        [mapper]="contractTypesMapper"
        [showPaginator]=false
        (rowClicked)="openModifyDialog($event, eContractsEnums.CONTRACT_TYPE);">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse(eContractsEnums.CONTRACT_ROLE)"
                         (closed)="toggleCollapse(eContractsEnums.CONTRACT_ROLE)">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.CONTRACT_ROLE_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateContractDialog(eContractsEnums.CONTRACT_ROLE)">{{eTextStrings.CREATE}} {{' '}}  {{eTextStrings.CONTRACT_ROLE_TITLE}}</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="contractRoleDataSource"
        [backendUrl]="contractRolesBackendUrl"
        [tableDefinitions]="contractRolesTableDefinitions"
        [actionDefinitions]="contractRoleActionsDefinitions"
        [pageSize]="9999"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="contractRolesMapper"
        [showPaginator]=false
        (rowClicked)="openModifyDialog($event, eContractsEnums.CONTRACT_ROLE);">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>



