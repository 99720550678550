import { IListMapper, IMapper } from '../models/i-mapper';
import { BeSharperModel } from '../models/be-sharper.model';
import { PagedModel } from '../models/paged.model';

export class BeSharperMapper implements IMapper {
  beSharper = {};

  fillFromJson(json: any): void {
    this.beSharper = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.beSharper);
  }
}

export class BeSharperListMapper implements IListMapper {
  count = 0;
  elements: BeSharperModel[] = [];

  fillFromJson(json: any): PagedModel<BeSharperModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapBeSharper(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  private mapBeSharper(value: any): BeSharperModel {
    return {
      id: value['id'],
      name: value['name'],
      surname: value['surname'],
      cf: value['cf'],
      email: value['email'],
      photoUrl: value['photoUrl'],
      birthDate: value['birthDate'],
      school: value['school'],
      role: value['role'],
      sex: value['sex'],

      address: {
        street: value['address']['street'],
        number: value['address']['number'],
        city: value['address']['city'],
        zipCode: value['address']['zipCode'],
        province: value['address']['province'],
        country: value['address']['country'],
      },

      customMaximal: value['customMaximal'],

      parentFrom: value['parentFrom'],
      createdAt: value['createdAt'],
      updatedAt: value['updatedAt'],
      hiringDate: value['hiringDate'],
      firingDate: value['firingDate'],
    };
  }
}

export class BeSharperPostMapper implements IMapper {
  beSharper = {};

  fillFromJson(json: any): void {
    this.beSharper = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.beSharper);
  }
}
