export abstract class IMapper {
  abstract fillFromJson(json: any): void;
  abstract fillToJson(): string;
}

export abstract class IListMapper {
  abstract elements: any[];
  abstract fillFromJson(json: any): void;
  abstract fillToJson(): string;
}
