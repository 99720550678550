import { IListMapper, IMapper } from '../models/i-mapper';
import { PagedModel } from '../models/paged.model';
import { BonusModel, BonusSummaryModel } from '../models/bonus.model';

export class BonusMapper implements IListMapper {
  count = 0;
  elements: BonusSummaryModel[] = [];

  fillFromJson(json: any): PagedModel<BonusSummaryModel> {
    this.elements = [];

    json['items'].forEach((value: any) => {
      this.elements.push(this.mapBonus(value));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return JSON.stringify(this);
  }

  mapBonus(value: any): BonusSummaryModel {
    //console.log(value);
    return {
      besharperId: value['besharper']['id'],
      beSharperName: value['besharper']['name'],
      beSharperSurname: value['besharper']['surname'],
      beSharperCompleteName: value['besharper']['name'] + ' ' + value['besharper']['surname'],
      id: value['id'],
      supplyDate: value['supplyDate'],
      value: value['value'],
      reason: value['reason'],
    };
  }
}

export class BonusPostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
    this.body.value = parseInt(this.body.value);
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class BonusDetailMapper implements IMapper {
  bonus?: BonusModel;

  fillFromJson(json: any): BonusModel {
    //console.log(json);
    this.bonus = {
      id: json['id'],
      beSharperName: json['besharper']['name'],
      besharperAddress: json['besharper']['address'],
      besharperBirthDate: json['besharper']['birthDate'],
      besharperCreatedAt: json['besharper']['createdAt'],
      besharperEmail: json['besharper']['email'],
      besharperId: json['besharper']['id'],
      besharperPhotoUrl: json['besharper']['photoUrl'],
      besharperSchool: json['besharper']['school'],
      besharperSurname: json['besharper']['surname'],
      besharperUpdatedAt: json['besharper']['updatedAt'],
      createdAt: json['createdAt'],
      reason: json['reason'],
      supplyDate: json['supplyDate'],
      updatedAt: json['updatedAt'],
      value: json['value'],
    };

    return this.bonus;
  }

  fillToJson(): string {
    return '';
  }
}
