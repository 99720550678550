import { Component, Inject } from '@angular/core';
import { TextStrings } from "../../../core/constants/text-strings";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import {Constants, MONTHS} from "../../../core/constants/constants";
import {SimpleMapper} from "../../../mappers/simple.mapper";
import { environment } from '../../../../environments/environment';
import moment from 'moment';


@Component({
  selector: 'app-out-of-office-report',
  templateUrl: './out-of-office-report.component.html',
  styleUrl: './out-of-office-report.component.scss'
})
export class OutOfOfficeReportComponent {
  loading = false;
  eTextStrings = TextStrings;
  currentYear!: string;
  customRange = false;
  months = MONTHS;

  montlyReportForm = new FormGroup({
    referenceMonth: new FormControl(new Date().getMonth() + 1, [Validators.required])
  });

  customReportForm = new FormGroup({
    startDate: new FormControl(new Date()),
    endDate: new FormControl(new Date()),
  })

  constructor(
    private dialogRef: MatDialogRef<OutOfOfficeReportComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: any,
    private providerService: ProviderService
  ) {
    this.currentYear = new Date().getFullYear().toString();
  }

  close(): void {
    this.dialogRef.close();
  }

  formValid() {
    if(this.customRange) {
      return this.customReportForm.valid;
    }
    else {
      return this.montlyReportForm.valid;
    }
  }

  async generateReport(): Promise<void> {
    let fromDate : string;
    let toDate : string;

    if(!this.customReportForm.valid){
      return;
    }

    if(this.customRange) {
      fromDate = moment(this.customReportForm.controls.startDate.value).format('YYYY-MM-DD');
      toDate = moment(this.customReportForm.controls.endDate.value!).format('YYYY-MM-DD');
    }
    else {
      const monthIndex = this.montlyReportForm.controls.referenceMonth.value! - 1;
      fromDate = moment({year: new Date().getFullYear(), month: monthIndex, day: 1}).format('YYYY-MM-DD');
      toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD');
    }


    this.loading = true;
    console.log("- Start Date: ", this.customReportForm.controls.startDate.value);
    console.log("- End Date: ", this.customReportForm.controls.endDate.value);

    const result = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.outOfOfficeReportApiPath,
      new SimpleMapper(),
      {
        fromDate,
        toDate
      });

    window.location.href = result.body.url;
    this.loading = false;
  }

  changeReportType(value: string) {
    if(value === 'monthly') {
      this.customRange = false;
    }
    else {
      this.customRange = true;
    }
  }
}
