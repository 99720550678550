import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ProviderService } from '../provider.service';
import { RoleEnum } from '../../models/role.enum';
import { TextStrings } from "../constants/text-strings";
import { LogLevel } from "../../models/log-level";
import { environment } from "../../../environments/environment";
import { Constants } from "../constants/constants";
import { SimpleMapper } from "../../mappers/simple.mapper";
import { IUser } from "../../models/i-user";
import { ContractModel } from "../../models/contract.model";

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private providerService: ProviderService) { }

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!(await this.providerService.authService.isAuthenticated())) {
      this.providerService.utilService.showMessage(TextStrings.TOKEN_EXPIRED.replace("[403-tk]", ""), LogLevel.info);
      this.router.navigate(['/', 'auth', 'sign-in']).then();
      return false;
    }

    // Check for missing values in user contracts
    const user = this.providerService.authService.getUser();
    const getContractUrl = environment.cognito.apiEndpoint + Constants.besharperApiPath + `/${user.id}/contract/current`;


    if (user.role !== RoleEnum.ADMIN) {
      try {
        const currentContract = (await this.providerService.networkService.get(getContractUrl, new SimpleMapper()));
        if (this.providerService.utilService.checkIfDataIsMissing(user, currentContract?.body)) {
          this.router.navigate(['/', 'app', 'missing-required-information']).then();
          return false;
        }
      }
      catch {
        this.router.navigate(['/', 'app', 'missing-required-information']).then();
        return false;
      }
    }

    const role = user.role;
    if (state.url.includes("/admin-panel") && role !== RoleEnum.ADMIN) {
      const userIsHr = [RoleEnum.HR, RoleEnum.HR_REPORTS].includes(role);
      if (userIsHr) {
        this.router.navigate(['/', 'app', 'besharpers']).then();
      }
      else {
        this.router.navigate(['/', 'app', 'out-of-office']).then();
      }
      return false;
    }

    return true;
  }
}
