import { Component, Inject, OnInit } from "@angular/core";
import { TextStrings } from "../../../core/constants/text-strings";
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { BeSharperPostMapper } from "../../../mappers/besharper.mapper";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { BeSharperModel } from "../../../models/be-sharper.model";

@Component({
  selector: 'app-custom-maximal',
  templateUrl: './custom-maximal.component.html',
  styleUrl: './custom-maximal.component.scss'
})
export class CustomMaximalComponent implements OnInit {
  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  loading = false;
  eTextStrings = TextStrings;
  form = new FormGroup({
    customMaximal: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<CustomMaximalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService) {

  }

  ngOnInit(): void {
    this.form.controls.customMaximal.setValue(this.data?.user?.customMaximal || '');
  }


  formValid(): boolean {
    return this.form.valid;
  }

  fillMapper(mapper: BeSharperPostMapper): void {
    mapper.fillFromJson({
      name: (this.data.user as BeSharperModel).name,
      surname: (this.data.user as BeSharperModel).surname,
      email: (this.data.user as BeSharperModel).email,
      cf: (this.data.user as BeSharperModel).cf,
      birthDate: this.providerService.utilService.formatDate((this.data.user as BeSharperModel).birthDate!),
      role: (this.data.user as BeSharperModel).role,
      parentFrom: (this.data.user as BeSharperModel).parentFrom,
      school: (this.data.user as BeSharperModel).school,
      customMaximal: this.form.controls.customMaximal.value,
      address: {
        street: (this.data.user as BeSharperModel).address?.street,
        number: (this.data.user as BeSharperModel).address?.number,
        city: (this.data.user as BeSharperModel).address?.city,
        zipCode: (this.data.user as BeSharperModel).address?.zipCode,
        province: (this.data.user as BeSharperModel).address?.province,
        country: (this.data.user as BeSharperModel).address?.country,
      },
    });
  }

  setMaximal(): void {
    this.loading = true;
    const postMapper = new BeSharperPostMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(postMapper);
      this.providerService.networkService
        .put(this.backendUrlBesharper + '/' + this.data?.user?.id, postMapper, mapper)
        .then(() => {
          this.providerService.utilService.showMessage('Il massimale è stato aggiornato.', LogLevel.success);
          this.dialogRef.close();
          this.loading = false;
        })
        .catch((e) => {
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }
}
