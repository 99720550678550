import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { OutOfOfficeModel } from "../../../models/out-of-office.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { OutOfOfficeRequestMapper, OutOfOfficeTypeListMapper } from "../../../mappers/out-of-office.mapper";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { TextStrings } from "../../../core/constants/text-strings";
import { BeSharperModel } from "../../../models/be-sharper.model";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";
import { RequestStatus } from "../../../models/out-of-office.enums";
import { OutOfOfficeTypeModel } from "../../../models/out-of-office-type.model";
import moment from "moment";
import RoleEnum from "../../../models/role.enum";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { Subject, Subscription } from "rxjs";
import IBasePage from "../../../core/interfaces/i-base-page";
import { Router } from "@angular/router";
import { TimePickerComponent } from "../../../core/form-utils/time-picker/time-picker.component";
import { SmartWhy } from "../../../models/smart-why.model";
import { SmartWhyListMapper } from "../../../mappers/smart-why.mapper";
import { SettingsListMapper } from "../../../mappers/settings.mapper";
import { SettingModel } from "../../../models/setting-model";
import { FileInputValidators } from "@ngx-dropzone/cdk";

const MAX_FILE_SIZE = 10 * 1024 * 1024;

@Component({
  selector: 'app-create-modify-out-of-order',
  templateUrl: './create-modify-out-of-office.component.html',
  styleUrl: './create-modify-out-of-office.component.scss',
})
export class CreateModifyOutOfOfficeComponent extends IBasePage implements OnInit, OnDestroy {

  @ViewChild('timePickerStart')
  timePickerStart!: TimePickerComponent;

  @ViewChild('timePickerEnd')
  timePickerEnd!: TimePickerComponent;

  backendUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;
  attachmentValidators = [
    FileInputValidators.maxSize(MAX_FILE_SIZE),
    FileInputValidators.accept(".zip,.png,.jpeg,.jpg,.pdf")
  ];

  form = new FormGroup({
    id: new FormControl(''),
    besharper: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    smartWhy: new FormControl(''),
    otherWhy: new FormControl(''),
    startingAtDate: new FormControl<Date | undefined>(undefined, Validators.required),
    startingAtTime: new FormControl<string | null>(null, Validators.required),
    endingAtDate: new FormControl<Date | undefined>(undefined, Validators.required),
    endingAtTime: new FormControl<string | null>(null, Validators.required),
    attachment: new FormControl<File | null>(
      null,
      this.attachmentValidators
    )
  }, { validators: [this.startBeforeEndValidator] });

  showOtherWhyInput = false;
  loading = false;
  eTextStrings = TextStrings;
  editingExistingItem = false;
  besharpers: BeSharperModel[] = [];
  outOfOfficeTypes: OutOfOfficeTypeModel[] = [];
  smartWhys: SmartWhy[] = [];
  dialogWasOpenedFromDetailsPage = false;

  outOfOfficeData: OutOfOfficeModel;

  selectedPermissionType?: OutOfOfficeTypeModel;

  filteredBesharpers: BeSharperModel[] = [];
  filteredTypes: OutOfOfficeTypeModel[] = [];
  filteredSmartWhy: SmartWhy[] = [];

  startDatePicker = new Subject<MatDatepickerInputEvent<any>>();
  endDatePicker = new Subject<MatDatepickerInputEvent<any>>();

  eMoment = moment;
  isCreateMode = false;

  showAttachmentUploadControl = false;
  hasAttachmentChanged = false;

  private subscription!: Subscription;
  private settings: SettingModel[] = [];
  private settingsListUrl = environment.cognito.apiEndpoint + Constants.settingsApiPath;
  private warningOnConsecutiveDays = false;

  constructor(
    public dialogRef: MatDialogRef<CreateModifyOutOfOfficeComponent>,
    @Inject(MAT_DIALOG_DATA) data: { outOfOfficeData: OutOfOfficeModel | undefined, openedFromDetailsPage?: boolean },
    public providerService: ProviderService,
    protected override router: Router,
  ) {
    super(router, providerService.authService, providerService.utilService);

    this.dialogWasOpenedFromDetailsPage = !!data.outOfOfficeData || false;
    this.hasAttachmentChanged = false;


    if (data.outOfOfficeData) {
      this.outOfOfficeData = data.outOfOfficeData;

      const dummy = new Date();
      dummy.setMinutes(0);
      this.outOfOfficeData.startDate ||= dummy.toISOString();
      this.outOfOfficeData.endDate ||= dummy.toISOString();

      this.editingExistingItem = true;
    } else {
      const dummy = new Date();
      dummy.setMinutes(0);
      this.outOfOfficeData = {
        besharperId: providerService.authService.getUser().id,
        createdAt: new Date(),
        updatedAt: new Date(),
        startDate: '',
        endDate: '',
        status: RequestStatus.WaitingForApproval,
        id: '',
        description: '',
        besharperName: '',
        besharperSurname: '',
        typeName: '',
        typeId: '',
        smartWorkingReason: '',
        isRecurrent: false,
        correlationId: null,
        daysOfTheWeek: "00000",
        approvers: {
          lineManager: null,
          tpm: null,
          supervisor: null,
        },
        allowedActions: [],
        attachments: []
      };
      this.isCreateMode = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.warningOnConsecutiveDays = false;

    this.form.controls.type.valueChanges.subscribe(this.handleTypeValueChanges.bind(this));

    const [besharpers, types, smartWhys, settings] = await Promise.all([
      this.getBesharpers(),
      this.getOutOfOfficeTypes(),
      this.getSmartWorkingTypes(),
      this.getSettings()
    ]);

    this.besharpers = besharpers;
    this.outOfOfficeTypes = types.sort((a, b) => a.sortKey - b.sortKey);
    this.smartWhys = smartWhys.sort((a, b) => b.name.localeCompare(a.name));
    this.settings = settings;

    this.filteredBesharpers = this.besharpers;
    this.filteredTypes = this.outOfOfficeTypes;
    this.filteredSmartWhy = this.smartWhys;

    this.populateForm();

    if (this.isCreateMode) {
      this.form.controls.startingAtDate.setValue(undefined);
      this.form.controls.endingAtDate.setValue(undefined);
    }

    this.loading = false;
  }

  private handleTypeValueChanges(value: any): void {
    const oType = this.outOfOfficeTypes.find(sw => sw.id === value);

    if (oType?.hasAttachment) {
      this.form.controls.attachment.setValidators([Validators.required, ...this.attachmentValidators]);
    } else {
      this.form.controls.attachment.clearValidators();
    }

    this.form.controls.attachment.setValue(null);
    this.form.controls.attachment.updateValueAndValidity();
    this.showAttachmentUploadControl = !!oType?.hasAttachment;
  }

  getDisplayNameForBesharper(besharperId: string) {
    const besharper: BeSharperModel = this.filteredBesharpers?.find(b => b.id === besharperId)!;
    return `${besharper?.name ?? ''} ${besharper?.surname ?? ''}`
  }

  getDisplayNameForType(typeId: string) {
    const oooType: OutOfOfficeTypeModel = this.filteredTypes?.find(t => t.id === typeId)!;
    return `${oooType?.name ?? ''}`
  }

  getDisplayNameForSmartWhy(id: string) {
    const why: SmartWhy = this.filteredSmartWhy?.find(sw => sw.id === id)!;
    return `${why?.name ?? ''}`
  }

  isCurrentUserHr(): boolean {
    const role = this.providerService.authService.getUser().role;
    return RoleEnum.HR === role || RoleEnum.ADMIN === role;
  }

  async getBesharpers(): Promise<BeSharperModel[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.besharperApiPath,
      new BeSharperListMapper(),
      { limit: '999999', orderBy: 'surname' }
    )

    return response.elements;
  }

  async getOutOfOfficeTypes(): Promise<OutOfOfficeTypeModel[]> {
    const response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.outOfOfficeTypeApiPath,
      new OutOfOfficeTypeListMapper(),
      {
        limit: '9999'
      }
    );
    return response.elements;
  }
  async getSettings(): Promise<SettingModel[]> {
    const result = await this.providerService.networkService.get(this.settingsListUrl, new SettingsListMapper());
    return result.elements;
  }

  async submit() {
    let fileTypeIsValid = true;
    if (this.form.controls.attachment.value !== null && this.hasAttachmentChanged) {
      fileTypeIsValid = await this.utilService.validateFileMimeType(this.form.controls.attachment.value, ["zip", "png", "jpeg", "pdf"])
    }

    if (!fileTypeIsValid) {
      this.providerService.utilService.showMessage("Il tipo di file selezionato non è valido. Sono ammessi solo file di tipo .zip, .png, .jpeg, .jpg, .pdf", LogLevel.warning);
    }

    if (this.form.valid && fileTypeIsValid) {
      this.editingExistingItem ? await this.modifyOutOfOffice() : await this.createOutOfOffice()
    }

    this.clearForm();
  }

  async submitAndClose() {
    await this.submit();
    this.dialogRef.close();
  }

  async modifyOutOfOffice(): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();

    try {
      await this.fillRequestBody(requestMapper);
      const response = await this.providerService.networkService.put(
        `${this.backendUrl}/${this.form.controls['id'].value}`,
        requestMapper,
        responseMapper
      );

      if (this.hasAttachmentChanged) {
        await this.deleteAllAttachments();

        if (this.form.controls.attachment.value !== null) {
          await this.addAttachmentToOutOfOfficeFlow(response.body.id, this.form.controls.attachment.value!);
        }
      }

      this.sendFinalMessage(response);
    } catch (e: any) {
      if (typeof e == "string" && e.includes("400")) {
        this.providerService.utilService.showMessage(e, LogLevel.error, { duration: "long" });
      } else {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      }
    } finally {
      this.loading = false;
      this.warningOnConsecutiveDays = false;
    }
  }

  async createOutOfOffice(): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();
    try {
      await this.fillRequestBody(requestMapper);
      const response = await this.providerService.networkService.post(`${this.backendUrl}`, requestMapper, responseMapper);
      try {
        if (this.form.controls.attachment.value) {
          await this.addAttachmentToOutOfOfficeFlow(response.body.id, this.form.controls.attachment.value);
        }
        this.sendFinalMessage(response);
      } catch (e: any) {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      } finally {
        this.loading = false;
        this.warningOnConsecutiveDays = false;
      }
    } catch (e: any) {
      //Show error messages for longer if the error is 400
      if (typeof e == "string" && e.includes("400")) {
        this.providerService.utilService.showMessage(e, LogLevel.error, { duration: "long" });
      } else {
        this.providerService.utilService.showMessage(e, LogLevel.error);
      }
      this.loading = false;
    }
  }

  async deleteAllAttachments(): Promise<void> {
    const attachmentsUrl = `${this.backendUrl}/${this.outOfOfficeData.id}/attachment`;
    const attachmentsResponse = await this.providerService.networkService.get(attachmentsUrl, new SimpleMapper());

    const deletePromises = attachmentsResponse.body.items.map((attachment: any) => {
      const deleteUrl = `${attachmentsUrl}/${attachment.id}`;
      return this.providerService.networkService.delete(deleteUrl, new SimpleMapper(), {});
    });

    await Promise.all(deletePromises);
  }

  private async addAttachmentToOutOfOfficeFlow(outOfOfficeId: any, file: File) {
    const signedUrlResponse = new SimpleMapper();
    const requestAttachmentMapper = new SimpleMapper();

    await this.providerService.networkService.get(this.backendUrl + "/attachment/upload-url", signedUrlResponse);

    //Upload the file to the signed URL
    await this.providerService.networkService.sendRequest(
      "post",
      signedUrlResponse.body.url,
      {
        body: { ...signedUrlResponse.body.fields, file: file },
        mode: "form"
      }
    );

    const addAttachmentToOutOfOfficeResponse = new SimpleMapper();
    await this.fillRequestBodyAttachment(requestAttachmentMapper, signedUrlResponse.body.objectKey);
    return await this.providerService.networkService.post(this.backendUrl + `/${outOfOfficeId}/attachment`, requestAttachmentMapper, addAttachmentToOutOfOfficeResponse);
  }

  private async fillRequestBodyAttachment(requestAttachmentMapper: SimpleMapper, objectKey: string): Promise<void> {
    requestAttachmentMapper.fillFromJson({
      name: this.form.controls.attachment?.value?.name,
      size: this.form.controls.attachment?.value?.size,
      contentType: this.form.controls.attachment?.value?.type,
      objectKey: objectKey,
    });
  }

  async fillRequestBody(mapper: OutOfOfficeRequestMapper): Promise<void> {
    const startTime = this.form.controls.startingAtTime.value
    const endTime = this.form.controls.endingAtTime.value;

    const localStartingDate = moment(this.form.controls.startingAtDate.value);
    localStartingDate?.hours(parseInt(startTime!.split(':')[0]));
    localStartingDate?.minutes(parseInt(startTime!.split(':')[1]));

    const localEndingDate = moment(this.form.controls.endingAtDate.value);
    localEndingDate?.hours(parseInt(endTime!.split(':')[0]));
    localEndingDate?.minutes(parseInt(endTime!.split(':')[1]));

    localStartingDate.seconds(0).milliseconds(0);
    localEndingDate.seconds(0).milliseconds(0);

    let finalStartDate = localStartingDate.toISOString(true);
    let finalEndDate = localEndingDate.toISOString(true);

    if (this.checkIfDatesAreTheSame(finalStartDate, finalEndDate) && localStartingDate > localEndingDate) {
      const temp = finalStartDate;
      finalStartDate = finalEndDate;
      finalEndDate = temp;
    }

    if (localStartingDate && localEndingDate) {
      const differenceInTime = localEndingDate.toDate().getTime() - localStartingDate.toDate().getTime();
      const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      const maxPermittedDaysForSmart = this.settings.find(setting => setting.name === "max_consecutive_smart_working_days_for_warning");

      if (this.checkIfOOOIsSmartWorking && differenceInDays > parseInt(maxPermittedDaysForSmart!.value)) {
        this.warningOnConsecutiveDays = true;
      }
    }

    const reason = (this.form.controls.otherWhy.value !== null && this.form.controls.otherWhy.value !== "" && this.form.controls.otherWhy.value !== undefined) ?
      this.form.controls.otherWhy.value : this.smartWorkingReasonLabel(this.form.controls.smartWhy.value);

    mapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      typeId: this.form.controls.type.value,
      startDate: finalStartDate,
      endDate: finalEndDate,
      smartWorkingReason: reason,
      status: '',
      description: '',
    });
  }

  async populateForm(): Promise<void> {
    this.form.controls['id'].setValue(this.outOfOfficeData?.id ?? null);
    this.form.controls['besharper'].setValue(this.outOfOfficeData?.besharperId ?? null);
    this.form.controls['type'].setValue(this.outOfOfficeData?.typeId ?? null);
    this.setSmartWorkingFields();

    const localStartingAt = moment(this.outOfOfficeData.startDate || new Date().toISOString());
    if (localStartingAt.minutes() !== 0 && localStartingAt.minutes() !== 30) {
      localStartingAt.minutes(0);
    }

    this.form.controls['startingAtDate'].setValue(localStartingAt?.toDate() as any);

    if (!this.isCreateMode) {
      this.form.controls.startingAtTime.setValue(localStartingAt.format("HH:mm") ?? null);
    }

    const localEndingAt = moment(this.outOfOfficeData.endDate || new Date().toISOString());
    if (localEndingAt.minutes() !== 0 && localEndingAt.minutes() !== 30) {
      localEndingAt.minutes(0);
    }

    this.form.controls['endingAtDate'].setValue(localEndingAt?.toDate() as any);

    if (!this.isCreateMode) {
      this.form.controls.endingAtTime.setValue(localEndingAt.format("HH:mm") ?? null);
    }

    const outOfOfficeType = this.outOfOfficeTypes.find(t => t.id === this.outOfOfficeData.typeId);
    this.showAttachmentUploadControl = outOfOfficeType?.hasAttachment ?? false;
    const attachment = (this.outOfOfficeData?.attachments?.length ?? 0) > 0 ? this.outOfOfficeData?.attachments![0] : null;
    if (attachment) {
      this.form.controls.attachment.setValue(new File([], attachment.name));
    }
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  filterType(): void {
    if (this.form.controls.type.value) {
      const typeInput = this.form.controls.type.value.toLowerCase();
      this.filteredTypes = this.outOfOfficeTypes.filter((t) => t.name.toLowerCase().includes(typeInput));
    } else {
      this.filteredTypes = this.outOfOfficeTypes;
    }
  }

  smartWhyType(): void {
    if (this.form.controls.smartWhy.value) {
      const typeInput = this.form.controls.smartWhy.value.toLowerCase();
      this.filteredSmartWhy = this.smartWhys.filter((t) => t.name.toLowerCase().includes(typeInput));
    } else {
      this.filteredSmartWhy = this.smartWhys;
    }
  }

  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private checkIfDatesAreTheSame(finalStartDate: string, finalEndDate: string) {
    return finalStartDate.substring(0, 10) === finalEndDate.substring(0, 10);
  }

  private clearForm() {
    if (this.isCurrentUserHr()) {
      this.form.controls.besharper.reset();
    }

    this.form.controls.type.reset();
    this.form.controls.startingAtDate.reset();
    this.form.controls.startingAtTime.setValue(null);
    this.form.controls.endingAtDate.reset();
    this.form.controls.endingAtTime.setValue(null);

    this.form.controls.attachment.reset();

    this.timePickerStart.setTime(null);
    this.timePickerEnd.setTime(null);
  }

  checkAndSetSingleDay() {
    if (
      (this.form.controls.endingAtDate.value as any)?.toString() === 'Invalid Date' ||
      !this.form.controls.endingAtDate.value
    ) {
      this.form.controls.endingAtDate.setValue(this.form.controls.startingAtDate.value);
    }
  }

  setSmartWhy($event: MatAutocompleteSelectedEvent) {
    this.showOtherWhyInput = $event.option.getLabel() === "Altro";
    if (this.showOtherWhyInput) {
      this.form.controls.otherWhy.addValidators(Validators.required);
      this.form.controls.otherWhy.updateValueAndValidity();
    } else {
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
    }
  }

  smartWorkingReasonLabel(id: string | null): string {
    if (!id) return "";
    return this.smartWhys.find(sw => sw.id === this.form.controls.smartWhy.value)?.name || "";
  }

  get checkIfOOOIsSmartWorking(): boolean {
    const displayNameForType = this.getDisplayNameForType(this.form.controls.type.value || "");
    const isSmartWorking = displayNameForType === "Smart Working" || displayNameForType === "Remote Working";
    if (isSmartWorking) {
      this.form.controls.smartWhy.addValidators(Validators.required);
      this.form.controls.smartWhy.updateValueAndValidity();

      this.showOtherWhyInput = this.smartWorkingReasonLabel(this.form.controls.smartWhy.value) === "Altro";
      if (this.showOtherWhyInput) {
        this.form.controls.otherWhy.addValidators(Validators.required);
        this.form.controls.otherWhy.updateValueAndValidity();
      } else {
        this.form.controls.otherWhy.setValue(null);
        this.form.controls.otherWhy.clearValidators();
        this.form.controls.otherWhy.updateValueAndValidity();
      }
    } else {
      this.form.controls.smartWhy.setValue(null);
      this.form.controls.smartWhy.clearValidators();
      this.form.controls.smartWhy.updateValueAndValidity();

      this.form.controls.otherWhy.setValue(null);
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = false;
    }
    return isSmartWorking;
  };

  private setSmartWorkingFields() {
    const smartWorkingReason = this.outOfOfficeData.smartWorkingReason;
    const smart = this.smartWhys.find(sw => sw.name === smartWorkingReason);
    if (smart) {
      this.form.controls.smartWhy.setValue(smart.id);
      this.form.controls.otherWhy.setValue("");
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = false;
    } else {
      this.form.controls.smartWhy.setValue(this.smartWhys.find(swl => swl.name === "Altro")?.id!);
      this.form.controls.otherWhy.setValue(smartWorkingReason);
      this.form.controls.otherWhy.addValidators(Validators.required);
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = true;
    }
  }

  async getSmartWorkingTypes(): Promise<SmartWhy[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.smartWorkingTypeApiPath,
      new SmartWhyListMapper(),
      {
        limit: '9999'
      }
    )
    return response.elements;
  }

  remove() {
    this.form.controls.attachment?.setValue(null);
  }

  private sendFinalMessage(response: any) {
    if (response.body.googleCalendar) {
      if (this.warningOnConsecutiveDays) {
        this.providerService.utilService.showMessage("Attenzione hai selezionato un numero di giorni di remote working maggiore del valore massimo consentito in una singola richiesta. La richesta è stata elaborata, ma ti consigliamo di parlare direttamante col tuo responsabile o con HR per verificare un'opzione più strutturata.", LogLevel.warning)
      } else {
        this.providerService.utilService.showMessage('Permesso modificato, ma qualcosa è andato storto nella sincronizzazione con Google Calendar, avvisa il tuo responsabile: ' + response.body.warning, LogLevel.warning);
      }
    } else {
      if (this.warningOnConsecutiveDays) {
        this.providerService.utilService.showMessage("Attenzione hai selezionato un numero di giorni di remote working maggiore del valore massimo consentito in una singola richiesta. La richesta è stata elaborata, ma ti consigliamo di parlare direttamante col tuo responsabile o con HR per verificare un'opzione più strutturata.", LogLevel.warning)
      } else {
        this.providerService.utilService.showMessage('Permesso modificato con successo', LogLevel.success);
      }
    }
  }

  protected readonly alert = alert;

  startBeforeEndValidator(group: AbstractControl): { [key: string]: boolean } | null {
    if (!(group instanceof FormGroup)) return null;

    const { startingAtDate, endingAtDate, startingAtTime, endingAtTime } = group.controls;

    if (!startingAtDate.value || !endingAtDate.value || !startingAtTime.value || !endingAtTime.value) return null;

    const [startHour, startMinute] = startingAtTime.value.split(':').map(Number);
    const startDateTime = moment(startingAtDate.value).set({ hour: startHour, minute: startMinute });

    const [endHour, endMinute] = endingAtTime.value.split(':').map(Number);
    const endDateTime = moment(endingAtDate.value).set({ hour: endHour, minute: endMinute });

    return startDateTime.isAfter(endDateTime) ? { 'startAfterEnd': true } : null;
  }

  attachmentValueChanged() {
    this.hasAttachmentChanged = true;
    this.form.controls.attachment?.updateValueAndValidity();
  }
}
