import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notify-expiring-consumables-args-form',
  templateUrl: './notify-expiring-consumables-args-form.component.html',
  styleUrl: './notify-expiring-consumables-args-form.component.scss'
})
export class NotifyExpiringConsumablesArgsFormComponent implements OnInit {
  ngOnInit(): void {
    this.emitArgs();
  }

  args : any = {};
  @Output() argsChange = new EventEmitter<any | null>();

  public form = new FormGroup({});

  public emitArgs() {
    this.argsChange.emit(this.args);
  }
}
