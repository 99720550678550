export const TextStrings: any = {
  PAGES_BESHARPERS: "beSharpers",
  PAGES_WELFARE: "Welfare",
  PAGES_BONUS: "Bonus",
  PAGES_CONTRACTS: "Contratti",
  PAGES_OUT_OF_OFFICE: "Ferie e Permessi <br/> Remote by Opportunity",
  PAGES_WAREHOUSE: "Magazzino Tecnico",
  PAGES_ADMIN_PANEL: "Admin Panel",
  PAGES_CRONJOB: "Azioni Programmate",

  ADMINPANEL_BACK: "Torna al pannello admin",

  BESHARPER_EMPTY: "beSharper",
  BESHARPER_SET_DROPDOWNS: "Imposta valori del dropdown beSharper",
  BESHARPER_CREATE: "Crea beSharper",
  BESHARPER_ROLE_CREATE_MODIFY: "Crea/Modifica ruoli beSharper",
  BESHARPER_MODIFY: "Modifica beSharper",
  BESHARPER_DELETE: "Cancella beSharper",
  BESHARPER_SEARCH: "Cerca beSharper",
  BESHARPER_CREATE_SUCCESS: "beSharper creato con successo",
  BESHARPER_MODIFY_SUCCESS: "beSharper modificato con successo",

  CRONJOB_SEARCH: "Cerca Azioni",
  CRONJOB_CREATE: "Crea Azione",
  CRONJOB: "Azione",
  CRONJOB_CREATE_AND_CONTINUE: "Crea azione e continua ad inserire",
  CRONJOB_MODIFY: "Modifica Azione",
  CRONJOB_DELETE: "Cancella Azione",
  CRONJOB_CREATE_SUCCESS: "Azione creata con successo",
  CRONJOB_MODIFY_SUCCESS: "Azione modificata con successo",

  QUALIFICATION_TITLE: "Qualifica beSharper",
  QUALIFICATION_NAME: "Nome qualifica del beSharper",
  QUALIFICATION_DESCRIPTION: "Descrizione certificato del beSharper",
  QUALIFICATION_MESSAGES_NAME_PLACEHOLDER: "Inserisci il nome del certificato del beSharper",
  QUALIFICATION_MESSAGES_DESCRIPTION_PLACEHOLDER: "Inserisci la descrizione del certificato del beSharper",

  CONTRACT: "contratto",
  CONTRACT_SET_DROPDOWNS: "Imposta valori del dropdown beSharper",
  CONTRACT_CREATE: "Crea Contratto",
  CONTRACT_CREATE_AND_ADD_ANOTHER: "Crea Contratto e continua ad inserire",
  CONTRACT_STARTDATE: "Data inizio",
  CONTRACT_ENDDATE: "Data fine",
  CONTRACT_MODIFY: "Modifica Contratto",
  CONTRACT_DELETE: "Cancella Contratto",
  CONTRACT_SEARCH: "Cerca Contratto",
  CONTRACT_CREATE_SUCCESS: "Contratto creato con successo",
  CONTRACT_MODIFY_SUCCESS: "Contratto modificato con successo",
  CONTRACT_STARTDATE_MESSAGE: "Data inizio obbligatoria",
  CONTRACT_ENDDATE_MESSAGE: "Data fine obbligatoria",
  CONTRACT_WEEKHOURS: "Ore settimanali",
  CONTRACT_WEEKHOURS_MESSAGE: "Ore obbligatorie, solo numeri ammessi, max 40",
  CONTRACT_RAL_MESSAGE: "RAL obbligatoria, solo numeri ammessi",
  CONTRACT_LINK: "Link al Contratto",

  CONTRACT_LEVEL_TITLE: "Livello CCNL",
  CONTRACT_LEVEL_NAME: "Nome Livello CCNL",
  CONTRACT_LEVEL_DESCRIPTION: "Descrizione Livello CCNL",
  CONTRACT_LEVEL_MESSAGES_TYPE: "Tipologia obbligatoria",
  CONTRACT_LEVEL_MESSAGES_SELECT: "Seleziona un beSharper",
  CONTRACT_LEVEL_MESSAGES_NAME_PLACEHOLDER: "Inserire nome livello CCNL",
  CONTRACT_LEVEL_MESSAGES_DESCRIPTION_PLACEHOLDER: "Inserire descrizione livello CCNL",

  CONTRACT_TYPE_TITLE: "Tipologia CCNL",
  CONTRACT_TYPE_NAME: "Nome tipologia Contratto",
  CONTRACT_TYPE_DESCRIPTION: "Descrizione tipologia Contratto",
  CONTRACT_TYPE_MESSAGES_NAME_PLACEHOLDER: "Inserisci il nome del tipo di contratto",
  CONTRACT_TYPE_MESSAGES_DESCRIPTION_PLACEHOLDER: "Inserisci la descrizione del tipo di contratto",

  CONTRACT_ROLE_TITLE: "Livello beSharp",
  CONTRACT_ROLE_NAME: "Nome ruolo",
  CONTRACT_ROLE_DESCRIPTION: "Descrizione ruolo",
  CONTRACT_ROLE_MESSAGES_ROLE: "Ruolo obbligatorio",
  CONTRACT_ROLE_MESSAGES_NAME_PLACEHOLDER: "Inserire nome ruolo",
  CONTRACT_ROLE_MESSAGES_DESCRIPTION_PLACEHOLDER: "Inserire descrizione ruolo",

  CONTRACT_TYPE_CREATE_MODIFY: "Crea/Modifica dettagli Contratto",
  BU_TYPE_CREATE_MODIFY: "Crea/Modifica dettagli Business Unit",

  OOO_TYPE_CREATE_MODIFY: "Crea/Modifica Tipologia di Out of Office",
  SMART_WORKING_TYPE_CREATE_MODIFY: "Crea/Modifica Tipologia di Remote Working",

  EQ_TYPE_CREATE_MODIFY: "Crea/Modifica Tipo di Dotazione",
  EQ_TYPE_SET_DROPDOWNS: "Imposta valori del dropdown Tipo Dotazione",
  EQ_TYPE_TITLE: "Tipo Dotazione",
  EQ_TYPE_NAME: "Nome Tipo Dotazione",
  EQ_TYPE_JSONSCHEMA: "Json Schema",
  EQ_TYPE_MESSAGES_NAME_PLACEHOLDER: "Inserisci un nome per il nuovo tipo di dotazione",
  EQ_TYPE_MESSAGES_JSONSCHEMA_PLACEHOLDER: "prepara il Json Schema: i valori possono essere [text, boolean, number]",

  CONTRACT_IN_THE_FUTURE: "Non si può aggiungere al momento un contratto che partirà in futuro",

  WELFARE: "Welfare",
  WELFARE_SET_DROPDOWNS: "Imposta valori del dropdown Welfare",
  WELFARE_CREATE: "Crea Welfare",
  WELFARE_TYPE_CREATE_MODIFY: "Crea/Modifica tipologia Welfare",
  WELFARE_CREATE_AND_ADD_ANOTHER: "Crea Welfare e continua ad inserire",
  WELFARE_MODIFY: "Modifica Welfare",
  WELFARE_DELETE: "Cancella Welfare",
  WELFARE_SEARCH: "Cerca Welfare",
  WELFARE_REASON: "Motivo Welfare",
  WELFARE_CREATE_SUCCESS: "Welfare creato con successo",
  WELFARE_MODIFY_SUCCESS: "Welfare modificato con successo",
  WELFARE_MODIFY_MAXIMAL: "Modifica Massimale Welfare",
  WELFARE_CHANGE_MAXIMAL: "Modifica Massimale",

  WELFARE_TYPE_TITLE: "Tipo Welfare",
  WELFARE_TYPE_NAME: "Nome tipo Welfare",
  WELFARE_TYPE_DESCRIPTION: "Descrizione tipo Welfare",
  WELFARE_TYPE_MESSAGES_NUMERIC: "Solo valori numerici",
  WELFARE_TYPE_MESSAGES_TYPE: "Tipo welfare richiesto",
  WELFARE_TYPE_MESSAGES_VALUE: "Inserire valore welfare",
  WELFARE_TYPE_MESSAGES_REASON: "Inserire motivo",
  WELFARE_TYPE_MESSAGES_STARTDATE_VALIDATOR: "Inserire data di inizio",
  WELFARE_TYPE_MESSAGES_NAME_PLACEHOLDER: "Inserire nome tipo welfare",
  WELFARE_TYPE_MESSAGES_DESCRIPTION_PLACEHOLDER: "Inserire descrizione tipo welfare",

  WELFARE_REASON_TITLE: "Motivo Welfare",
  WELFARE_REASON_NAME: "Nome motivo Welfare",
  WELFARE_REASON_CREATE_MODIFY: "Crea/Modifica motivo Welfare",
  WELFARE_REASON_NAME_PLACEHOLDER: "inserisci il motivo welfare",
  WELFARE_REPORT_TITLE: "Report Welfare",

  WELFARE_MAXIMALS_CREATE_MODIFY: "Crea/Modifica massimale Welfare",
  WELFARE_MAXIMAL_TITLE: "Massimali Welfare",
  WELFARE_MAXIMAL_SET_DROPDOWNS: "Imposta valori del dropdown Welfare Maximals",
  WELFARE_MAXIMALS_TITLE: "Massimale Welfare",
  WELFARE_MAXIMALS_VALUE: "Valore Mensile del Massimale",
  WELFARE_MAXIMALS_VALUE_PLACEHOLDER: "Inserisci il valore menisle del massimale",
  WELFARE_MAXIMALS_FAMILY: "Bonus Familiari",

  BONUS: "Bonus",
  BONUS_SET_DROPDOWNS: "Imposta valori del dropdown Bonus",
  BONUS_CREATE: "Crea Bonus",
  BONUS_CREATE_AND_CONTINUE: "Crea Bonus e continua ad inserire",
  BONUS_MODIFY: "Modifica Bonus",
  BONUS_DELETE: "Cancella Bonus",
  BONUS_SEARCH: "Cerca Bonus",
  BONUS_CREATE_SUCCESS: "Bonus creato con successo",
  BONUS_MODIFY_SUCCESS: "Bonus modificato con successo",

  CONSUMABLE: "Consumabili",
  CONSUMABLE_CREATE: "Crea Consumabile",
  CONSUMABLE_MODIFY: "Modifica Consumabile",
  CONSUMABLE_DELETE: "Cancella Consumabile",
  CONSUMABLE_SEARCH: "Cerca Consumablie",
  CONSUMABLE_SEARCH_PLACEHOLDER: "Cerca",
  CONSUMABLE_CREATE_SUCCESS: "Consumabile creato con successo",
  CONSUMABLE_UPLOAD_FAIL: "caricamento della foto non riuscito",
  CONSUMABLE_MODIFY_SUCCESS: "Consumabile modificato con successo",

  EQUIPMENT: "Dotazione",
  EQUIPMENT_NAME: "Nome Dotazione",
  EQUIPMENT_CODE: "Codice",
  EQUIPMENT_CATEGORY: "Categoria",
  EQUIPMENT_LABELS_BESHARPER: "beSharper",
  EQUIPMENT_LABELS_BESHARPERNAME: "Nome",
  EQUIPMENT_LABELS_BESHARPERLASTNAME: "Cognome",
  EQUIPMENT_LABELS_CATEGORY: "Categoria",
  EQUIPMENT_LABELS_NAME: "Nome Dotazione",
  EQUIPMENT_LABELS_CODE: "Codice Dotazione",

  EQUIPMENT_WARRANT: "Garanzia",
  EQUIPMENT_WARRANT_LINK: "Link Garanzia",
  EQUIPMENT_INVOICE: "Assicurazione",
  EQUIPMENT_INVOICE_LINK: "Link Assicurazione",
  EQUIPMENT_LABELS_IDDEVICE: "Id Del Dispositivo",
  EQUIPMENT_LABELS_SERIAL_NUMBER: "Numero Seriale",
  EQUIPMENT_LABELS_BRAND: "Marca",
  EQUIPMENT_LABELS_MODEL: "Modello",
  EQUIPMENT_PERSONAL: "Personale",
  EQUIPMENT_MIXEDUSE: "Uso promisquo",
  EQUIPMENT_FORWORK: "Per Lavoro",
  EQUIPMENT_INSURANCE: "Assicurazione",
  EQUIPMENT_STATUS: "Stato",
  EQUIPMENT_BUYING_DATE: "Data di acquisto",
  EQUIPMENT_END_OF_SUPPORT: "Data di fine supporto",
  EQUIPMENT_INVOICE_DATE: "Data fattura",
  EQUIPMENT_INVOICE_COMPANY: "Azienda fattura",

  EQUIPMENT_MESSAGES_CODE: "Inserire codice Dotazione",
  EQUIPMENT_MESSAGES_NAME: "Inserire nome Dotazione",
  EQUIPMENT_MESSAGES_SELECT_BESHARPER: "Scegliere beSharper",

  EQUIPMENT_BUTTONS_BACK: "Indietro",
  EQUIPMENT_BUTTONS_CONFIRM: "Conferma",
  EQUIPMENT_BUTTONS_CONFIRM_AND_STAY: "Conferma e continua ad inserire",

  EQUIPMENT_CREATE: "Crea Dotazione",
  EQUIPMENT_SEARCH: "Cerca Dotazione",
  EQUIPMENT_CREATE_SUCCESS: "Dotazione creata con successo",
  EQUIPMENT_MODIFY_SUCCESS: "Dotazione modificata con successo",
  EQUIPMENT_MODIFY: "Modifica Dotazione",
  EQUIPMENT_DELETE: "Cancella Dotazione",
  EQUIPMENT_NON_ASSIGNED: "Dotazione non assegnata",

  // Translation for Equipment Type
  EQUIPMENT_MTD_DIMENSION: "Dimensione in \"",
  EQUIPMENT_MTD_RESOLUTION: "Risoluzione",
  EQUIPMENT_MTD_WATT_OUT: "Watt",
  EQUIPMENT_MTD_RECOVERY_KEY: "Recovery Key",
  EQUIPMENT_MTD_ADMINISTRATOR: "Amministratore",
  EQUIPMENT_MTD_NUMBER: "Numero",
  EQUIPMENT_MTD_PIN: "Pin",
  EQUIPMENT_MTD_PUK: "Puk",
  EQUIPMENT_MTD_SIM: "SIM",
  EQUIPMENT_MTD_SIM_TYPE: "Operatore/Tipo SIM",
  EQUIPMENT_MTD_MONTHLY_COST: "Costo mensile",
  EQUIPMENT_MTD_SIM_ACTIVATION: "Sim attivata il",
  EQUIPMENT_MTD_CONTRACT_TYPE: "Tipo contratto",
  EQUIPMENT_MTD_SIZE: "Taglia",


  OUT_OF_OFFICE_CREATE: "Nuova richiesta",
  OUT_OF_OFFICE_MODIFY: "Modifica richiesta",
  OUT_OF_OFFICE_CREATE_REASON: "Motivo",
  OUT_OF_OFFICE_CREATE_TYPE: "Tipo",
  OUT_OF_OFFICE_CREATE_MAX_REASON_LENGTH: "Max 50 caratteri",
  OUT_OF_OFFICE_CREATE_PERMISSIONDATE: "Data permesso",
  OUT_OF_OFFICE_CREATE_PERMISSION_FOR_ENTIRE_DAY: "Richiesta giornata intera",
  OUT_OF_OFFICE_CREATE_STARTDATE: "Data inizio",
  OUT_OF_OFFICE_CREATE_OPEN_HOLIDAY: "Apri modulo ferie",
  OUT_OF_OFFICE_CREATE_OPEN_PERMISSION: "Apri modulo permesso",
  OUT_OF_OFFICE_CREATE_SEARCH: "Cerca",
  OUT_OF_OFFICE_CREATE_ENDDATE: "Data fine",
  OUT_OF_OFFICE_CREATE_STARTTIME: "Ora inizio",
  OUT_OF_OFFICE_CREATE_ENDTIME: "Ora fine",
  OUT_OF_OFFICE_CREATE_PERMISSION: "Permesso",
  OUT_OF_OFFICE_CREATE_HOLIDAY: "Ferie",
  OUT_OF_OFFICE_CREATE_DESCRIPTION: "Descrizione",
  OUT_OF_OFFICE_CREATE_MAX_DESCRIPTION_LENGTH: "Max 150 caratteri",
  OUT_OF_OFFICE_CREATE_MODIFY_PERMISSION: "Modifica permesso per id = ",
  OUT_OF_OFFICE_CREATE_MODIFY_HOLIDAY: "Modifica ferie per id = ",
  OUT_OF_OFFICE_REPORT: "Report Ferie e Permessi",

  OUT_OF_OFFICE_CONFIRM_DENY: "Conferma ferie e permessi",
  OUT_OF_OFFICE_MODIFY_SUCCESS: "Ferie modificate con successo",
  OUT_OF_OFFICE_STATUS_APPROVEDBYTPM: "Approvato dal TPM",
  OUT_OF_OFFICE_STATUS_DENIEDBYLM: "Rifiutata",
  OUT_OF_OFFICE_STATUS_DENIEDBYTPM: "Rifiutata da TPM",
  OUT_OF_OFFICE_STATUS_WAITINGFORAPPROVAL: "In attesa di approvazione",
  OUT_OF_OFFICE_STATUS_APPROVEDBYLM: "Approvata",
  OUT_OF_OFFICE_STATUS: "Stato",
  OUT_OF_OFFICE_SMART_WHY: "Motivo Remote Working",
  OUT_OF_OFFICE_CREATED_AT: "Creato Il",
  OUT_OF_OFFICE_PERFORM_ACTION_DISABLED: "Al momento non puoi eseguire questa azione",

  OUT_OF_OFFICE_MODIFY_RECURRENT: "Modifica Remote Strutturato",
  OUT_OF_OFFICE_CREATE_RECURRENT: "Crea Remote Strutturato",

  CUSTOM_HOLIDAY_TITLE: "Festività",

  CONFIRM_DELETE: "Conferma eliminazione",
  DELETE_SUCCESS: "Eliminato con successo",
  CREATE_SUCCESS: "Creazione avvenuta con successo",
  DELETE_DATABASE_ERROR: "Impossibile eliminare il beShaper, sono ancora presenti risorse associate",
  CREATE: "Crea",
  MODIFY: "Modifica",
  DELETE: "Elimina",
  APPROVE_PERMISSION_ERROR: "Permesso non approvato, già rifiutato",
  APPROVE_PERMISSION_SUCCESS: "Permesso approvato con successo",
  APPROVE_HOLIDAY_ERROR: "Ferie non approvata, già rifiutata",
  APPROVE_HOLIDAY_SUCCESS: "Ferie approvata con successo",
  DENY_HOLIDAY_ERROR: "Ferie già rifiutata",
  DENY_PERMISSION_ERROR: "Permesso già rifiutato",
  DENY_PERMISSION_SUCCESS: "Permesso rifiutato con successo",
  DENY_HOLIDAY_SUCCESS: "Ferie rifiutata con successo",
  CANCEL: "Annulla",
  CONFIRM: "Conferma",
  CONFIRM_AND_CONTINUE: "Conferma e inseririsci nuovo",
  BESHARP_OBJECTS: "Inventario",
  APPROVE: "Approva",
  DENY: "Rifiuta",
  ACTIONS: "Azioni",
  NAME: "Nome",
  LASTNAME: "Cognome",
  VALUE: "Valore",
  PERMISSION_REQUEST_SUCCESS: "Permesso creato con successo, attendere per la verifica",
  HOLIDAY_REQUEST_SUCCESS: "Ferie creata con successo, attendere per la verifica",
  HOLIDAYS: "Ferie",
  PERMITS: "Permessi",

  TABLE_HOLIDAYS: "Tabella Ferie",
  TABLE_PERMITS: "Tabella Permessi",

  HIDE_SENSITIVE_DATA: "Nascondi dati sensibili",
  SHOW_SENSITIVE_DATA: "Mostra dati sensibili",

  SHOW_HOLIDAY_TABLE_HOLIDAYS: "Mostra tabella Ferie",
  SHOW_HOLIDAY_TABLE_PERMITS: "Mostra tabella Permessi",

  BUTTONS_BACK: "Indietro",
  EXPORT: "Esporta",
  REQUIRED: "Campo Richiesto",

  BESHARPER_QUALIFICATION_TITLE: "Ruolo",
  BESHARPER_QUALIFICATION_NAME: "Nome Ruolo",
  BESHARPER_QUALIFICATION_DESCRIPTION: "Descrizione Ruolo",

  DECREASE_TOTAL: "Decrementa il welfare?",
  MODIFY_SUCCESS: "Modifica avvenuta con successo",

  FIELD_REQUIRED: "Campo obbligatorio",
  FORBIDDEN: "Non hai il permesso di accedere a queste informazioni",
  TOKEN_EXPIRED: "[403-tk] - La tua sessione temporanea di AWS è scaduta. Nessun problema, ti basta effettuare il Login per riprendere a lavorare.",
  RECURRENT_SW: "Remote Strutturato",
  DAYS_OF_THE_WEEK: "Giorni",
  NOTES: "Note",
  SET_SETTINGS: "Settings Globali",
  WARNING_TOO_MANY_SW_DAYS: "Attenzione hai selezionato un numero insolito di giorni di remote working consecutivi in una singola richiesta. La richesta è stata elaborata, ma ti consigliamo di parlare direttamante col tuo responsabile o con HR per verificare un'opzione più strutturata.",

  WARNING_MISSING_INFO: "Attenzione: dati profilo incompleti!",
  MISSING_INFO_MESSAGE: "Alcuni dati del tuo profilo personale e del contratto in essere risultano incompleti lato amministrazione.<br>" +
    "Al momento non puoi utilizzare le funzionalità della Intranet, per favore avvisa <strong>il tuo Line Manager</strong> o direttamente <strong>HR</strong> per includere i dati mancanti.<br>" +
    "Una volta aggiunti, prova a refreshare la pagina per accedere al sistema.",

  SET_USER_CONTRACT_ROLE: "Setup Utente/Ruolo in beSharp",
  SETUP_HEADER_TITLE: "Setup Utente/Ruolo in beSharp",
  NEW_SETUP_TITLE: "Setup utenti correnti",
  NEW_SETUP_SUBTITLE: "In questa vista puoi verificare le impostazioni di setup per gli utenti collegati alla Intranet, l'unica opzione è 'Modifica', perchè utenti e contratti sono creati, o possono essere creati, separatamente. Puoi accedere al modale di setup rapido cliccando sulla riga o usando l'opzione 'modifica' dal menù a tendina.",
  NEW_SETUP: "Nuovo Setup",
  SETUP: "Setup",
  OK: "Conferma",
  SUCCESS: "Operazione completata con successo!",

  SET_CUSTOM_HOLIDAYS: "Setup festività",

  SETUP_CONTRACT: "Contratto",
  SETUP_BESHARPER: "beSharper",
  SETUP_NAME: "Nome",
  SETUP_SURNAME: "Cognome",
  SETUP_ROLE: "Ruolo",
  SETUP_IS_TPM: "E' TPM?",
  SETUP_IS_LINE_MANAGER: "E' Line Manager?",
  SETUP_IS_SUPERVISOR: "E' Supervisore?",
  SETUP_TPM: "Email Tpm",
  SETUP_LINE_MANAGER: "Email Line Manager",
  SETUP_SUPERVISOR: "Email Supervisore",

  ERROR_ATTACHMENT_REQUIRED: "Allegato necessario all'atto della richiesta.",
  ERROR_ATTACHMENT_MIMETYPE_WRONG: "L'allegato non è in un formato prevvisto dal sistema.",
};
