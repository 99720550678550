<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Enter addresses">
        @for (address of addresses; track address) {
            <mat-chip-row
                [editable]="true"
                (edited)="editRecipient(address, $event)" 
                (removed)="removeRecipient(address)"
                [aria-description]="'press enter to edit ' + address"
            >
                {{address}}
                <button matChipRemove [attr.aria-label]="'remove ' + address">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        }
        <input
            placeholder="Destinatari"
            [matChipInputFor]="chipGrid"
            [matChipInputSeparatorKeyCodes]="[32, 188]"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addRecipient($event)"
        />
    </mat-chip-grid>
</mat-form-field>
