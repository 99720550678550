<div class="table-header-container">
  <div class="table-header-text">
    <div class="back-button-container">
      <button mat-raised-button color="primary" [routerLink]="['../']"><i matTooltip="{{eTextStrings.ADMINPANEL_BACK}}" class="fa fa-arrow-left" aria-hidden="true"></i></button>
    </div>
    <i class="far fa-file-contract"></i>
    <h1>{{eTextStrings.WELFARE_SET_DROPDOWNS}}</h1>
  </div>
</div>
<div class="cards-container">
  <mat-accordion>
    <mat-expansion-panel (opened)="toggleCollapse()"
                         (closed)="toggleCollapse()">
      <mat-expansion-panel-header class="card-header">
        <mat-panel-title class="card-header-title">
          {{eTextStrings.WELFARE_TYPE_TITLE}}
        </mat-panel-title>
        <mat-panel-description>
          <div class="card-header-actions">
            <button mat-raised-button color="primary" (click)="openCreateWelfareDialog()">{{eTextStrings.CREATE}}{{' '}}{{eTextStrings.WELFARE_TYPE_TITLE}}</button>
          </div>
        </mat-panel-description>

      </mat-expansion-panel-header>
      <app-table-ui
        [datasource]="welfareTypeDataSource"
        [backendUrl]="welfareTypeBackendUrl"
        [tableDefinitions]="welfareTypeTableDefinitions"
        [pageSize]="9999"
        [actionDefinitions]="welfareActionDefinitions"
        [pageSizeOptions]="[9999]"
        [filterObservable]="filterObservable"
        [mapper]="welfareTypeMapper"
        [showPaginator]=false
        [hasRowAction]=true
        (rowClicked)="openModifyWelfareDialog($event);">
      </app-table-ui>
    </mat-expansion-panel>
  </mat-accordion>
</div>



