import { Component } from '@angular/core';
import { ProviderService } from "../../core/provider.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  constructor(public providerService: ProviderService) {}
}
