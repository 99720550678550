import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'; 
import { EmailChipsComponent } from '../../utils/email-chips/email-chips.component';

@Component({
  selector: 'app-send-email-args-form',
  templateUrl: './send-email-args-form.component.html',
  styleUrl: './send-email-args-form.component.scss'
})
export class SendEmailArgsFormComponent implements OnInit {
  ngOnInit(): void {
    const subject = this.args?.subject;
    const title = this.args?.title;
    const body = this.args?.body;
    const recipients = this.args?.to;

    if(subject) {
      this.form.controls.subject.setValue(subject);
    }
    if(body) {
      this.form.controls.body.setValue(body);
    }
    if(title){
      this.form.controls.title.setValue(title ?? '');
    }
    if(Array.isArray(recipients)) {
      this.recipients = recipients.map(r => r.toString());
    }
  }

  @Input() args : any = {};
  @Output() argsChange = new EventEmitter<any | null>();
  
  public recipients : string[] = [];
  public form = new FormGroup({
    subject: new FormControl('', [Validators.required]),
    title: new FormControl(''),
    body: new FormControl('', [Validators.required]),
  });

  public emitArgs() {
    if(!this.form.valid || this.recipients.length === 0) {
      this.argsChange.emit(null);
    }
    else {
      this.argsChange.emit({ 
        subject: this.form.controls.subject.value,
        title: this.form.controls.title.value ?? '',
        body: this.form.controls.body.value,
        to: this.recipients
      })
    }
  }
}
