<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{eTextStrings.WELFARE_MODIFY_MAXIMAL}}</h1>

    <form spellcheck="false" [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Massimale Custom</mat-label>
          <input matInput type="number" id="customMaximal" name="customMaximal" formControlName="customMaximal" placeholder="Inserisci un eventuale massimale custom" value="">
        </mat-form-field>
    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>Indietro</span>
    </button>
    <button mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
            (click)="setMaximal();" [disabled]="!formValid() || loading">
      Conferma Massimale
    </button>
  </div>
</div>
