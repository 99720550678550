import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-log-message-args-form',
  templateUrl: './log-message-args-form.component.html',
  styleUrl: './log-message-args-form.component.scss'
})
export class LogMessageArgsFormComponent implements OnInit {
  ngOnInit(): void {
    const message = this.args?.message;

    if(message) {
      this.form.controls.message.setValue(message);
    }
  }

  @Input() args : any = {};
  @Output() argsChange = new EventEmitter<any | null>();
  
  public form = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  public onInput(event : any) {
    if(!this.form.valid) {
      this.argsChange.emit(null);
    }
    else {
      this.argsChange.emit({ message: this.form.controls.message.value ?? '-' })
    }
  }
}
