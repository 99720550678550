import { Component, OnDestroy } from "@angular/core";
import { ProviderService } from './core/provider.service';
import { Constants } from './core/constants/constants';
import { IUser } from './models/i-user';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  navigationHistorySubscription?: Subscription;

  // ===== Boostrap application data here =====
  // ==========================================
  constructor(private providerService: ProviderService) {
    this.providerService.networkService.setAuthenticator(this.providerService.iamAuthenticatorService);
    this.setInitialUserIfLoggedIn();
    this.navigationHistorySubscription = this.providerService.utilService.initializeAppNavigationHistory();
  }

  private setInitialUserIfLoggedIn() {
    let userInfo: IUser;

    const userInfoOrNull = localStorage.getItem(Constants.userInfo);
    if (userInfoOrNull !== null) {
      const userInfoJson = userInfoOrNull as string;
      userInfo = JSON.parse(userInfoJson) as IUser;

      this.providerService.authService.setUser(userInfo);
    }
  }

  ngOnDestroy(): void {
    if(this.navigationHistorySubscription) {
      this.navigationHistorySubscription.unsubscribe();
    }
  }
}
