import { Component } from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { OutOfOfficeDetailsMapper, OutOfOfficeListMapper } from '../../../mappers/out-of-office.mapper';
import { TableDataSource } from '../../../core/form-utils/table/table.datasource';
import { canBesharperPerformActionOnOutOfOffice, OutOfOfficeModel } from '../../../models/out-of-office.model';
import { ProviderService } from '../../../core/provider.service';
import { Router } from '@angular/router';
import { CreateModifyOutOfOfficeComponent } from '../../dialogs/create-modify-out-of-office/create-modify-out-of-office.component'
import { ConfirmDeleteComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { LogLevel } from '../../../models/log-level';
import { RequestStatus } from '../../../models/out-of-office.enums';
import RoleEnum from "../../../models/role.enum";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { OutOfOfficeReportComponent } from "../../dialogs/out-of-office-report/out-of-office-report.component";
import { IFilteredTablePage } from "../../../core/interfaces/i-filtered-table-page";
import { CreateModifyRecurrentOooComponent } from "../../dialogs/create-modify-recurrent-ooo/create-modify-recurrent-ooo.component";
import eRoleEnum from "../../../models/role.enum";
import { SimpleMapper } from 'src/app/mappers/simple.mapper';
import { DialogBoxComponent } from "../../dialogs/dialog-box/dialog-box.component";

@Component({
  selector: 'app-out-of-office',
  templateUrl: './out-of-office.component.html',
  styleUrl: './out-of-office.component.scss',
})
export class OutOfOfficeComponent extends IFilteredTablePage {
  outOfOfficeBackendUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;
  mapper = new OutOfOfficeListMapper();

  holidaysDataSource: TableDataSource<OutOfOfficeModel> = new TableDataSource<OutOfOfficeModel>();
  loading = false;
  showTable = false;

  tableDefinitions = [
    { def: 'besharperName', title: TextStrings.NAME, sortable: false },
    { def: 'besharperSurname', title: TextStrings.LASTNAME, sortable: false },
    { def: 'typeName', title: TextStrings.OUT_OF_OFFICE_CREATE_TYPE, sortable: false },
    { def: 'createdAt', title: TextStrings.OUT_OF_OFFICE_CREATED_AT, sortable: true, transformer: (value: string) => this.formatDate(value) },
    { def: 'startDate', title: TextStrings.OUT_OF_OFFICE_CREATE_STARTDATE, sortable: true, transformer: (value: string) => this.formatDate(value) },
    { def: 'endDate', title: TextStrings.OUT_OF_OFFICE_CREATE_ENDDATE, sortable: true, transformer: (value: string) => this.formatDate(value) },
    { def: 'status', title: TextStrings.OUT_OF_OFFICE_STATUS, tooltip: (row: any) => { return row.approverNotes }, sortable: true, transformer: (value: string) => {
        const role = this.currentUser.role;
        let valueEnum = value.toUpperCase() ?? value;
        let color = "";
        if(role === RoleEnum.EMPLOYEE && (valueEnum === "APPROVEDBYTPM" || valueEnum === "DENIEDBYTPM")) {
          valueEnum = "WAITINGFORAPPROVAL";
        }

        switch (valueEnum) {
          case "APPROVEDBYLM": color = "green"; break;
          case "DENIEDBYLM": color = "red"; break;
          case "APPROVEDBYTPM": color = "green"; break;
          case "DENIEDBYTPM": color = "red"; break;
          case "WAITINGFORAPPROVAL": color = ""; break;
          case "APPROVED": color = "green"; break;
          case "DENIED": color = "red"; break;
        }

        return `<span class="${color}">${TextStrings["OUT_OF_OFFICE_STATUS_" + valueEnum]}</span>`;
      }
    },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: TextStrings.MODIFY,
      styleClass: 'table-action-modify',
      active: (outOfOfficeData: OutOfOfficeModel) => canBesharperPerformActionOnOutOfOffice(this.providerService.authService.getUser(), "edit", outOfOfficeData),
      callback: (outOfOfficeData: OutOfOfficeModel) => {
        this.openModifyDialog(outOfOfficeData);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: TextStrings.DELETE,
      styleClass: 'table-action-delete',
      active: (outOfOfficeData: OutOfOfficeModel) => canBesharperPerformActionOnOutOfOffice(this.providerService.authService.getUser(), "delete", outOfOfficeData),
      callback: (row: any) => {
        this.deleteRequest(row).then();
      },
    },
    {
      icon: 'fa-solid fa-check',
      text: TextStrings.APPROVE,
      styleClass: 'table-action-approve',
      active: (outOfOfficeData: OutOfOfficeModel) => canBesharperPerformActionOnOutOfOffice(this.providerService.authService.getUser(), "approve", outOfOfficeData),
      callback: (outOfOfficeData: OutOfOfficeModel) => {
        this.approveRequest(outOfOfficeData);
      },
    },
    {
      icon: 'fa-solid fa-xmark',
      text: TextStrings.DENY,
      styleClass: 'table-action-delete',
      active: (outOfOfficeData: OutOfOfficeModel) => canBesharperPerformActionOnOutOfOffice(this.providerService.authService.getUser(), "deny", outOfOfficeData),
      callback: (outOfOfficeData: OutOfOfficeModel) => {
        this.denyRequest(outOfOfficeData);
      },
    }
  ];

  eTextStrings = TextStrings;
  eRoleEnum = RoleEnum;
  onlyMeFilter = false;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);

    this.detailPage = 'out-of-office-detail';
    this.showTable = true;
  }

  async exportTable() {
    if (this.loading) {
      return;
    }
    this.providerService.utilService
      .openDialog(OutOfOfficeReportComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.filterObservable.next(this.lastDebounceValue);
      });
  }

  createOutOfOffice() {
    this.providerService.utilService
      .openCrudDialog({
        data: {},
        template: CreateModifyOutOfOfficeComponent,
        page: this,
        closeCallback: () => { }
      });
  }

  async deleteRequest(ooo: any): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService
        .openDialog(ConfirmDeleteComponent, {
          url: `${this.outOfOfficeBackendUrl}/${ooo['id']}`,
          returnPath: Constants.outOfOfficePath,
        })
        .afterClosed()
        .subscribe(() => {
          this.filterObservable.next(this.lastDebounceValue);
        });
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  async approveRequest(ooo: OutOfOfficeModel): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService.openDialog(DialogBoxComponent, {
        title: "Approva la richiesta",
        description: "aggiungi una motivazione (opzionale) per approvare questa richiesta",
        url: `${this.outOfOfficeBackendUrl}/${ooo.id}/approval`,
        returnPath: null,
        callback: () => {
          this.filterObservable.next(this.lastDebounceValue);
          this.loading = false;
        },
        method: "post",
        schema: {
          type: 'object',
          properties: {
            notes: {
              title: "Motivazione",
              type: "string"
            } },
          required: []
        }
      });

    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
    finally {
      this.filterObservable.next(this.lastDebounceValue);
      this.loading = false;
    }
  }

  async denyRequest(ooo: OutOfOfficeModel): Promise<void> {
    this.loading = true;
    try {
      this.providerService.utilService.openDialog(DialogBoxComponent, {
        title: "Rifiuta la richiesta",
        description: "aggiungi una motivazione per rifiutare questa richiesta",
        url: `${this.outOfOfficeBackendUrl}/${ooo.id}/denial`,
        returnPath: null,
        method: "post",
        callback: () => {
          this.filterObservable.next(this.lastDebounceValue);
          this.loading = false;
        },
        schema: {
          type: 'object',
          properties: {
            notes: {
              title: "Motivazione",
              type: "string"
            } },
          required: ["notes"]
        }
      });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
    finally {
      this.filterObservable.next(this.lastDebounceValue);
      this.loading = false;
    }
  }

  openModifyDialog(outOfOfficeData: OutOfOfficeModel): void {
    this.providerService.utilService.openCrudDialog({
      data: { outOfOfficeData },
      template: CreateModifyOutOfOfficeComponent,
      page: this,
    });
  }

  toggleOnlyMe() {
    this.onlyMeFilter = !this.onlyMeFilter;
    this.sendOnlyMeQuery(this.onlyMeFilter);
  }

  protected readonly RoleEnum = RoleEnum;
}
