import { Component } from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { TableDataSource } from '../../../core/form-utils/table/table.datasource';
import { ProviderService } from '../../../core/provider.service';
import { Router } from '@angular/router';
import { CronjobModel } from '../../../models/cronjob.model';
import { IFilteredTablePage } from '../../../core/interfaces/i-filtered-table-page';
import { RoleEnum } from "../../../models/role.enum";
import { ModeEnum } from '../../../core/constants/enums';
import { ConfirmDeleteComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";
import { CreateModifyCronjobComponent } from '../../dialogs/create-modify-cronjob/create-modify-cronjob.component';
import { SimpleListMapper } from "../../../mappers/simple.mapper";

@Component({
  selector: 'app-cronjob',
  templateUrl: './cronjob.component.html',
  styleUrl: './cronjob.component.scss',
})
export class CronjobComponent extends IFilteredTablePage {
  backendUrl = environment.cognito.apiEndpoint + Constants.cronjobApiPath;
  dataSource: TableDataSource<CronjobModel> = new TableDataSource<CronjobModel>();
  cronjobMapper: SimpleListMapper = new SimpleListMapper();
  eRoleEnum = RoleEnum;

  tableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'action', title: 'Azione', sortable: false },
    { def: 'schedule', title: 'Crontab', sortable: false },
  ];

  actionDefinitions = [
    {
      icon: 'fa-solid fa-pen',
      text: 'Modifica cronjob',
      styleClass: 'table-action-modify',
      callback: (cronjob: any): void => {
        this.modifyCronjob(cronjob);
      },
    },
    {
      icon: 'fa-solid fa-trash-can',
      text: 'Elimina cronjob',
      styleClass: 'table-action-delete',
      callback: (cronjob: any): void => {
        this.deleteCronjob(cronjob['id'] as string).then();
      },
    },
  ];

  eTextStrings = TextStrings;

  constructor(protected providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
    this.detailPage = 'cronjob-detail';
  }

  createCronjob() {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyCronjobComponent,
      data: {
        mode: ModeEnum.CREATE,
        listMode: true,
      }
    });
  }

  modifyCronjob(cronjob: any) {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyCronjobComponent,
      data: {
        mode: ModeEnum.MODIFY,
        cronjob: cronjob
      }
    });
  }

  async deleteCronjob(cronjobId: any) {
    const url = environment.cognito.apiEndpoint + `${Constants.cronjobApiPath}/${cronjobId}`;

    this.providerService.utilService.openDialog(ConfirmDeleteComponent, {
      url: url,
    })
    .afterClosed()
    .subscribe(() => {
      this.filterObservable.next(this.lastDebounceValue)
    });
  }
}
