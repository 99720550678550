import { Component } from '@angular/core';
import { TextStrings } from "../../../../core/constants/text-strings";
import { environment } from "../../../../../environments/environment";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { BusinessUnitModel } from "../../../../models/business-unit.model";
import { Constants } from "../../../../core/constants/constants";
import { BusinessUnitListMapper } from "../../../../mappers/business-unit.mapper";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { ModeEnum } from "../../../../core/constants/enums";
import { AdminPanelCreateModifyBusinessUnitComponent } from "../../../dialogs/admin-panel-create-modify-business-unit/admin-panel-create-modify-business-unit.component";

@Component({
  selector: 'app-set-business-unit-dropdown-menus',
  templateUrl: './set-business-unit-dropdown-menus.component.html',
  styleUrl: './set-business-unit-dropdown-menus.component.scss'
})
export class SetBusinessUnitDropdownMenusComponent extends IFilteredTablePage {
  eTextStrings = TextStrings;
  loading = false;
  basePath = environment.cognito.apiEndpoint;

  businessUnitDataModel: TableDataSource<BusinessUnitModel> = new TableDataSource<BusinessUnitModel>();
  businessUnitCollapsed = true;
  businessUnitBackendUrl = this.basePath + Constants.businessUnitsApiPath;
  businessUnitMapper: BusinessUnitListMapper = new BusinessUnitListMapper();

  businessUnitTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
  ];
  businessUnitActionsDefinitions = [
    {
      icon: 'fa-solid fa-pencil',
      text: 'Modifica',
      styleClass: 'table-action-success',
      callback: (item: any) => {
        this.openModifyDialog(item);
      },
    },
    {
      icon: 'fa-solid fa-trash',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: any) => {
        this.deleteItem(item.id);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.businessUnitCollapsed = !this.businessUnitCollapsed;
  }

  openCreateBusinessUnitDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyBusinessUnitComponent,
      data: {
        mode: ModeEnum.CREATE
      }
    });
  }

  deleteItem(id: string): void {
    const apiUrl = `${this.businessUnitBackendUrl}/${id}`;

    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: apiUrl!,
    });
  }

  openModifyDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyBusinessUnitComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row
      }
    });
  }
}
