import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OutOfOfficeModel } from "../../../models/out-of-office.model";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { OutOfOfficeRequestMapper, OutOfOfficeTypeListMapper } from "../../../mappers/out-of-office.mapper";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { environment } from "../../../../environments/environment";
import { Constants } from "../../../core/constants/constants";
import { TextStrings } from "../../../core/constants/text-strings";
import { BeSharperModel } from "../../../models/be-sharper.model";
import { BeSharperListMapper } from "../../../mappers/besharper.mapper";
import { RequestStatus } from "../../../models/out-of-office.enums";
import { OutOfOfficeTypeModel } from "../../../models/out-of-office-type.model";
import moment from "moment";
import RoleEnum from "../../../models/role.enum";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { combineLatest, map, Subject, Subscription } from "rxjs";
import IBasePage from "../../../core/interfaces/i-base-page";
import { Router } from "@angular/router";
import { TimePickerComponent } from "../../../core/form-utils/time-picker/time-picker.component";
import { SmartWhy } from "../../../models/smart-why.model";
import { SmartWhyListMapper } from "../../../mappers/smart-why.mapper";
import { SettingsListMapper } from "../../../mappers/settings.mapper";
import { SettingModel } from "../../../models/setting-model";

@Component({
  selector: 'app-create-modify-out-of-order',
  templateUrl: './create-modify-out-of-office.component.html',
  styleUrl: './create-modify-out-of-office.component.scss',
})
export class CreateModifyOutOfOfficeComponent extends IBasePage implements OnInit, OnDestroy {

  @ViewChild('timePickerStart')
  timePickerStart!: TimePickerComponent;

  @ViewChild('timePickerEnd')
  timePickerEnd!: TimePickerComponent;

  backendUrl = environment.cognito.apiEndpoint + Constants.outOfOfficeApiPath;

  form = new FormGroup({
    id: new FormControl(''),
    besharper: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    smartWhy: new FormControl(''),
    otherWhy: new FormControl(''),
    startingAtDate: new FormControl<Date | undefined>(undefined, Validators.required),
    endingAtDate: new FormControl<Date | undefined>(undefined, Validators.required),
  });

  startingAtTime: string | null = null;
  endingAtTime: string | null = null;

  showOtherWhyInput = false;
  loading = false;
  eTextStrings = TextStrings;
  editingExistingItem = false;
  besharpers: BeSharperModel[] = [];
  outOfOfficeTypes: OutOfOfficeTypeModel[] = [];
  smartWhys: SmartWhy[] = [];
  dialogWasOpenedFromDetailsPage = false;

  outOfOfficeData: OutOfOfficeModel;

  selectedPermissionType?: OutOfOfficeTypeModel;

  filteredBesharpers: BeSharperModel[] = [];
  filteredTypes: OutOfOfficeTypeModel[] = [];
  filteredSmartWhy: SmartWhy[] = [];

  startDatePicker = new Subject<MatDatepickerInputEvent<any>>();
  endDatePicker = new Subject<MatDatepickerInputEvent<any>>();

  eMoment = moment;
  isCreateMode = false;

  private subscription!: Subscription;
  private settings: SettingModel[] = [];
  private settingsListUrl = environment.cognito.apiEndpoint + Constants.settingsApiPath;
  private warningOnConsecutiveDays = false;

  constructor(
    public dialogRef: MatDialogRef<CreateModifyOutOfOfficeComponent>,
    @Inject(MAT_DIALOG_DATA) data: { outOfOfficeData: OutOfOfficeModel | undefined, openedFromDetailsPage?: boolean },
    public providerService: ProviderService,
    protected override router: Router,
  ) {
    super(router, providerService.authService, providerService.utilService);

    this.dialogWasOpenedFromDetailsPage = data.openedFromDetailsPage || false;

    if (data.outOfOfficeData) {
      this.outOfOfficeData = data.outOfOfficeData;
      // Extra check for null dates (it usually can't happen but in rare occasion this must be addressed)
      const dummy = new Date();
      dummy.setMinutes(0);
      this.outOfOfficeData.startDate ||= dummy.toISOString();
      this.outOfOfficeData.endDate ||= dummy.toISOString();

      this.editingExistingItem = true;
    } else {
      const dummy = new Date();
      dummy.setMinutes(0);
      this.outOfOfficeData = {
        besharperId: providerService.authService.getUser().id,
        createdAt: new Date(),
        updatedAt: new Date(),
        startDate: '',
        endDate: '',
        status: RequestStatus.WaitingForApproval,
        id: '',
        description: '',
        besharperName: '',
        besharperSurname: '',
        typeName: '',
        typeId: '',
        smartWorkingReason: '',
        isRecurrent: false,
        correlationId: null,
        daysOfTheWeek: "00000",
        approvers: {
          lineManager: null,
          tpm: null,
          supervisor: null,
        },
        allowedActions: [],
      };
      this.isCreateMode = true;
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.warningOnConsecutiveDays = false;


    /* If the user is an employee, we only want to show his/her name
     * since the /besharper endpoint is not available for employees */
    const currentUser = this.providerService.authService.getUser();
    const besharpersPromise = this.getBesharpers().then(items => this.besharpers = items);
    const typesPromise = this.getOutOfOfficeTypes().then(items => {
      this.outOfOfficeTypes = items;
      this.outOfOfficeTypes.sort((a: OutOfOfficeTypeModel, b: OutOfOfficeTypeModel) => (a.sortKey < b.sortKey) ? -1 : 1);
    });
    const swTypesPromise = this.getSmartWorkingTypes().then(items => {
      this.smartWhys = items;
      this.smartWhys.sort((a: SmartWhy, b: SmartWhy) => (a.name > b.name) ? -1 : 1);
    });

    const settingsPromise = this.getSettings().then(items => this.settings = items);

    await Promise.all([besharpersPromise, typesPromise, swTypesPromise, settingsPromise]);

    this.filteredBesharpers = this.besharpers;
    this.filteredTypes = this.outOfOfficeTypes;
    this.filteredSmartWhy = this.smartWhys;

    this.populateForm();
    if (this.isCreateMode) {
      this.form.controls.startingAtDate.setValue(undefined);
      this.form.controls.endingAtDate.setValue(undefined);
    }

    this.loading = false;
  }

  formValid(): boolean {
    try {
      if (this.startingAtTime === null || this.endingAtTime === null) {
        return false;
      }

      if (this.getEndDateTime() < this.getStartDateTime()) {
        return false;
      }
    }
    catch { }

    return this.form.valid;
  }

  private getStartDateTime() {
    const date = this.form.controls.startingAtDate.value;
    const hours = this.startingAtTime?.split(':')[0] ?? '00';
    const minutes = this.startingAtTime?.split(':')[1] ?? '00';

    return moment(date)
      .hours(parseInt(hours))
      .minutes(parseInt(minutes))
      .second(0)
      .millisecond(0)
      .toDate()
  }

  private getEndDateTime() {
    const date = this.form.controls.endingAtDate.value;
    const hours = this.endingAtTime?.split(':')[0] ?? '00';
    const minutes = this.endingAtTime?.split(':')[1] ?? '00';

    return moment(date)
      .hours(parseInt(hours))
      .minutes(parseInt(minutes))
      .second(0)
      .millisecond(0)
      .toDate()
  }

  getDisplayNameForBesharper(besharperId: string) {
    const besharper: BeSharperModel = this.filteredBesharpers?.find(b => b.id === besharperId)!;
    return `${besharper?.name ?? ''} ${besharper?.surname ?? ''}`
  }

  getDisplayNameForType(typeId: string) {
    const oooType: OutOfOfficeTypeModel = this.filteredTypes?.find(t => t.id === typeId)!;
    return `${oooType?.name ?? ''}`
  }

  getDisplayNameForSmartWhy(id: string) {
    const why: SmartWhy = this.filteredSmartWhy?.find(sw => sw.id === id)!;
    return `${why?.name ?? ''}`
  }

  isCurrentUserHr(): boolean {
    const role = this.providerService.authService.getUser().role;
    return RoleEnum.HR === role || RoleEnum.ADMIN === role;
  }

  async getBesharpers(): Promise<BeSharperModel[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.besharperApiPath,
      new BeSharperListMapper(),
      { limit: '999999', orderBy: 'surname' }
    )
    response.elements = (this.userRole === RoleEnum.EMPLOYEE) ?
      response.elements.filter((user: BeSharperModel) => user.id === this.currentUser.id) : response.elements;
    return response.elements;
  }

  async getOutOfOfficeTypes(): Promise<OutOfOfficeTypeModel[]> {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.outOfOfficeTypeApiPath,
      new OutOfOfficeTypeListMapper(),
      {
        limit: '9999'
      }
    )
    return response.elements;
  }

  async getSettings(): Promise<SettingModel[]> {
    const result = await this.providerService.networkService.get(this.settingsListUrl, new SettingsListMapper());
    return result.elements;
  }

  async submit() {
    if (this.formValid()) {
      this.editingExistingItem ? await this.modifyOutOfOffice() : await this.createOutOfOffice(true)
    }
  }

  async modifyOutOfOffice(): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();
    try {
      this.fillRequestBody(requestMapper);
      await this.providerService.networkService
        .put(`${this.backendUrl}/${this.form.controls['id'].value}`, requestMapper, responseMapper)
        .then((response) => {
          if (response.body.googleCalendar) {
            if (this.warningOnConsecutiveDays) {
              this.providerService.utilService.showMessage("Attenzione hai selezionato un numero di giorni di remote working maggiore del valore massimo consentito in una singola richiesta. La richesta è stata elaborata, ma ti consigliamo di parlare direttamante col tuo responsabile o con HR per verificare un'opzione più strutturata.", LogLevel.warning)
            } else {
              this.providerService.utilService.showMessage('Permesso modificato, ma qualcosa è andato storto nella sincronizzazione con Google Calendar, avvisa il tuo responsabile: ' + response.body.warning, LogLevel.warning);
            }
          } else {
            if (this.warningOnConsecutiveDays) {
              this.providerService.utilService.showMessage("Attenzione hai selezionato un numero di giorni di remote working maggiore del valore massimo consentito in una singola richiesta. La richesta è stata elaborata, ma ti consigliamo di parlare direttamante col tuo responsabile o con HR per verificare un'opzione più strutturata.", LogLevel.warning)
            } else {
              this.providerService.utilService.showMessage('Permesso modificato con successo', LogLevel.success);
            }
          }
          this.loading = false;
          this.warningOnConsecutiveDays = false;
          this.dialogRef.close();
        })
        .catch((e) => {
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  async createOutOfOffice(continueEditing?: boolean): Promise<void> {
    this.loading = true;
    const requestMapper = new OutOfOfficeRequestMapper();
    const responseMapper = new SimpleMapper();
    try {
      this.fillRequestBody(requestMapper);
      console.log("RequestMapper: ", requestMapper);
      this.providerService.networkService
        .post(`${this.backendUrl}`, requestMapper, responseMapper)
        .then((response) => {
          if (response.body.googleCalendar) {
            if (this.warningOnConsecutiveDays) {
              this.providerService.utilService.showMessage(TextStrings.WARNING_TOO_MANY_SW_DAYS, LogLevel.warning)
            } else {
              this.providerService.utilService.showMessage('Permesso creato, ma qualcosa è andato storto nella sincronizzazione di Google Calendar, contatta il tuo responsabile: ' + response.body.warning, LogLevel.warning);
            }
          } else {
            if (this.warningOnConsecutiveDays) {
              this.providerService.utilService.showMessage(TextStrings.WARNING_TOO_MANY_SW_DAYS, LogLevel.warning)
            } else {
              this.providerService.utilService.showMessage('Permesso creato con successo', LogLevel.success);
            }
          }
          this.loading = false;
          this.warningOnConsecutiveDays = false;
          this.clearForm();


          if (!continueEditing) {
            this.dialogRef.close();
          }
        })
        .catch((e) => {
          this.providerService.utilService.showMessage(e, LogLevel.error);
          this.loading = false;
        });
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
    }
  }

  fillRequestBody(mapper: OutOfOfficeRequestMapper): void {
    const startTime = this.startingAtTime
    const endTime = this.endingAtTime;

    console.log("Time 1: ", startTime);
    console.log("Time 2: ", endTime);
    console.log("Date 1: ", moment(this.form.controls.startingAtDate.value).toDate());
    console.log("Date 2: ", moment(this.form.controls.endingAtDate.value).toDate());

    const localStartingDate = moment(this.form.controls.startingAtDate.value);
    localStartingDate?.hours(parseInt(startTime!.split(':')[0]));
    localStartingDate?.minutes(parseInt(startTime!.split(':')[1]));

    const localEndingDate = moment(this.form.controls.endingAtDate.value);
    localEndingDate?.hours(parseInt(endTime!.split(':')[0]));
    localEndingDate?.minutes(parseInt(endTime!.split(':')[1]));

    localStartingDate.seconds(0);
    localStartingDate.milliseconds(0);

    localEndingDate.seconds(0);
    localEndingDate.milliseconds(0);

    // Check if dates must be inverted for single day requests
    let finalStartDate = localStartingDate.toISOString(true);
    let finalEndDate = localEndingDate.toISOString(true);

    if (this.checkIfDatesAreTheSame(finalStartDate, finalEndDate) && localStartingDate > localEndingDate) {
      const temp = finalStartDate;
      finalStartDate = finalEndDate;
      finalEndDate = temp;
    }

    if (localStartingDate && localEndingDate) {
      const differenceInTime = localEndingDate.toDate().getTime() - localStartingDate.toDate().getTime();
      const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      const maxPermittedDaysForSmart = this.settings.find(setting => setting.name === "max_consecutive_smart_working_days_for_warning");

      if (this.checkIfOOOIsSmartWorking && differenceInDays > parseInt(maxPermittedDaysForSmart!.value)) {
        this.warningOnConsecutiveDays = true;
      }
    }

    const reason = (this.form.controls.otherWhy.value !== null && this.form.controls.otherWhy.value !== "" && this.form.controls.otherWhy.value !== undefined) ?
      this.form.controls.otherWhy.value : this.smartWorkingReasonLabel(this.form.controls.smartWhy.value);

    mapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      typeId: this.form.controls.type.value,
      startDate: finalStartDate,
      endDate: finalEndDate,
      smartWorkingReason: reason,
      status: '',
      description: '',
    });
  }

  checkTimePattern(time: string): string {
    const regex = /^(?:0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    if (regex.test(time)) {
      return time;
    } else {
      return time + ':00';
    }
  }

  populateForm(): void {
    this.form.controls['id'].setValue(this.outOfOfficeData?.id ?? null);
    this.form.controls['besharper'].setValue(this.outOfOfficeData?.besharperId ?? null);
    this.form.controls['type'].setValue(this.outOfOfficeData?.typeId ?? null);
    this.setSmartWorkingFields();

    const localStartingAt = moment(this.outOfOfficeData.startDate || new Date().toISOString());
    if (localStartingAt.minutes() !== 0 && localStartingAt.minutes() !== 30) {
      localStartingAt.minutes(0);
    }
    console.log(localStartingAt.format("DD/MM/YYYY"));
    console.log(localStartingAt.format("HH:mm"));


    this.form.controls['startingAtDate'].setValue(localStartingAt?.toDate() as any);

    if (!this.isCreateMode) {
      this.startingAtTime = (localStartingAt.format("HH:mm") ?? null);
    }

    const localEndingAt = moment(this.outOfOfficeData.endDate || new Date().toISOString());
    if (localEndingAt.minutes() !== 0 && localEndingAt.minutes() !== 30) {
      localEndingAt.minutes(0);
    }
    console.log(localEndingAt.format("DD/MM/YYYY"));
    console.log(localEndingAt.format("HH:mm"));

    this.form.controls['endingAtDate'].setValue(localEndingAt?.toDate() as any);

    if (!this.isCreateMode) {
      this.endingAtTime = (localEndingAt.format("HH:mm") ?? null);
    }
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  filterType(): void {
    if (this.form.controls.type.value) {
      const typeInput = this.form.controls.type.value.toLowerCase();
      this.filteredTypes = this.outOfOfficeTypes.filter((t) => t.name.toLowerCase().includes(typeInput));
    } else {
      this.filteredTypes = this.outOfOfficeTypes;
    }
  }

  smartWhyType(): void {
    if (this.form.controls.smartWhy.value) {
      const typeInput = this.form.controls.smartWhy.value.toLowerCase();
      this.filteredSmartWhy = this.smartWhys.filter((t) => t.name.toLowerCase().includes(typeInput));
    } else {
      this.filteredSmartWhy = this.smartWhys;
    }
  }

  override ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private checkIfDatesAreTheSame(finalStartDate: string, finalEndDate: string) {
    return finalStartDate.substring(0, 10) === finalEndDate.substring(0, 10);
  }

  private clearForm() {
    if (this.isCurrentUserHr()) {
      this.form.controls.besharper.reset();
    }

    this.form.controls.type.reset();
    this.form.controls.startingAtDate.reset();
    this.startingAtTime = null;
    this.form.controls.endingAtDate.reset();
    this.endingAtTime = null;

    this.timePickerStart.setTime(null);
    this.timePickerEnd.setTime(null);
  }

  checkAndSetSingleDay() {
    console.log("CLOSE: ", (this.form.controls.endingAtDate.value as any)?.toString());
    if (
      (this.form.controls.endingAtDate.value as any)?.toString() === 'Invalid Date' ||
      !this.form.controls.endingAtDate.value
    ) {
      this.form.controls.endingAtDate.setValue(this.form.controls.startingAtDate.value);
    }
  }

  setSmartWhy($event: MatAutocompleteSelectedEvent) {
    console.log($event.option.getLabel());
    this.showOtherWhyInput = $event.option.getLabel() === "Altro";
    if (this.showOtherWhyInput) {
      this.form.controls.otherWhy.addValidators(Validators.required);
      this.form.controls.otherWhy.updateValueAndValidity();
    } else {
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
    }
  }

  smartWorkingReasonLabel(id: string | null): string {
    if (!id) return "";
    return this.smartWhys.find(sw => sw.id === this.form.controls.smartWhy.value)?.name || "";
  }

  get checkIfOOOIsSmartWorking(): boolean {
    const displayNameForType = this.getDisplayNameForType(this.form.controls.type.value || "");
    const isSmartWorking = displayNameForType === "Smart Working" || displayNameForType === "Remote Working";
    if (isSmartWorking) {
      this.form.controls.smartWhy.addValidators(Validators.required);
      this.form.controls.smartWhy.updateValueAndValidity();

      this.showOtherWhyInput = this.smartWorkingReasonLabel(this.form.controls.smartWhy.value) === "Altro";
      if (this.showOtherWhyInput) {
        this.form.controls.otherWhy.addValidators(Validators.required);
        this.form.controls.otherWhy.updateValueAndValidity();
      } else {
        this.form.controls.otherWhy.setValue(null);
        this.form.controls.otherWhy.clearValidators();
        this.form.controls.otherWhy.updateValueAndValidity();
      }
    } else {
      this.form.controls.smartWhy.setValue(null);
      this.form.controls.smartWhy.clearValidators();
      this.form.controls.smartWhy.updateValueAndValidity();

      this.form.controls.otherWhy.setValue(null);
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = false;
    }
    return isSmartWorking;
  };

  private setSmartWorkingFields() {
    const smartWorkingReason = this.outOfOfficeData.smartWorkingReason;
    const smart = this.smartWhys.find(sw => sw.name === smartWorkingReason);
    if (smart) {
      this.form.controls.smartWhy.setValue(smart.id);
      this.form.controls.otherWhy.setValue("");
      this.form.controls.otherWhy.clearValidators();
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = false;
    } else {
      this.form.controls.smartWhy.setValue(this.smartWhys.find(swl => swl.name === "Altro")?.id!);
      this.form.controls.otherWhy.setValue(smartWorkingReason);
      this.form.controls.otherWhy.addValidators(Validators.required);
      this.form.controls.otherWhy.updateValueAndValidity();
      this.showOtherWhyInput = true;
    }
  }

  async getSmartWorkingTypes() {
    let response = await this.providerService.networkService.get(
      environment.cognito.apiEndpoint + Constants.smartWorkingTypeApiPath,
      new SmartWhyListMapper(),
      {
        limit: '9999'
      }
    )
    return response.elements;
  }
}
