import { Attribute, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { time } from "console";

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss'
})
export class TimePickerComponent implements OnInit {
  @Input()
  readonly: boolean = false;

  @Input()
  label: string = 'timepicker';

  @Input()
  minuteStep?: number;

  @Input()
  set time(value: string | null) {
    this.setTime(value);
  }

  @Output()
  timeChange = new EventEmitter<string | null>();

  hours: string | null = null;
  minutes: string | null = null;

  hourArray: string[] = [];
  minuteArray: string[] = [];

  ngOnInit() {
    this.hourArray = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    this.minuteArray = Array.from({ length: 60 }, (_, i) => i)
      .filter(i => i % (this.minuteStep || 1) === 0)
      .map(i => i.toString().padStart(2, '0'));
  }

  setTime(time: string | null) {
    if (time === null) {
      this.minutes = null;
      this.hours = null;
    }
    else {
      const hours = time.split(':')[0].padStart(2, '0');
      const minutes = time.split(':')[1].padStart(2, '0');

      const hoursAreInRange = this.hourArray.includes(hours);
      const minutesAreInRange = this.minuteArray.includes(minutes);

      if (hoursAreInRange && minutesAreInRange) {
        this.hours = hours;
        this.minutes = minutes;
      }
    }
  }

  emitTime() {
    console.log("Emitting time")
    if (this.hours !== null && this.minutes !== null) {
      const value = `${this.hours}:${this.minutes}`;
      this.timeChange.emit(value)
    }
    else {
      this.timeChange.emit(null);
    }
  }

  forceChange() {
    this.emitTime();
  }
}
