export interface FilterModel {
  filter?: string;
  limit: number;
  offset: number;
  orderBy?: string;
  order: OrderEnum;
}

export enum OrderEnum {
  asc = "asc",
  desc = "desc"
}