import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { IUser } from '../../models/i-user';
import { Router } from '@angular/router';
import { ProviderService } from '../../core/provider.service';
import { Constants } from '../../core/constants/constants';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  user: IUser;
  userSubscription: Subscription | null = null;

  eConstants = Constants;

  constructor(private router: Router, private providerService: ProviderService) {
    this.user = {} as IUser;
    this.userSubscription = this.providerService.authService.userSubject.subscribe((user: IUser) => {
      this.user = user;
    });
  }

  ngAfterViewInit(): void {
    this.user = this.providerService.authService.getUser() ?? {} as IUser;
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  async signOut(): Promise<void> {
    await this.providerService.authService.signOut();
    await this.router.navigate(['/', 'auth', 'sign-in']);
  }
}
