import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, EmailValidator } from '@angular/forms'; 
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-notify-failed-integrations-args-form',
  templateUrl: './notify-failed-integrations-args-form.component.html',
  styleUrl: './notify-failed-integrations-args-form.component.scss'
})
export class NotifyFailedIntegrationsArgsFormComponent implements OnInit {
  ngOnInit(): void {
    const recipients = this.args?.to;
    
    if(Array.isArray(recipients)) {
      this.recipients = recipients.map(r => r.toString());
    }
  }

  @Input() args : any = {};
  @Output() argsChange = new EventEmitter<any | null>();
  
  public recipients : string[] = [];
  public form = new FormGroup({});

  public emitArgs() {
    if(!this.form.valid || this.recipients.length === 0) {
      this.argsChange.emit(null);
    }
    else {
      this.argsChange.emit({
        to: this.recipients
      })
    }
  }
}
