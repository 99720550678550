import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BeSharperModel } from '../../../../models/be-sharper.model';
import { ProviderService } from '../../../../core/provider.service';
import { Constants } from '../../../../core/constants/constants';
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { IFilteredTablePage } from '../../../../core/interfaces/i-filtered-table-page';
import { ContractModel } from "../../../../models/contract.model";
import { WelfareListMapper, WelfareStatusMapper } from '../../../../mappers/welfare.mapper';
import { WelfareModel } from '../../../../models/welfare.model';
import { BonusModel } from '../../../../models/bonus.model';
import { BonusMapper } from '../../../../mappers/bonus.mapper';
import { ModeEnum } from '../../../../core/constants/enums';
import { CreateModifyWelfareComponent } from '../../../dialogs/create-modify-welfare/create-modify-welfare.component';
import { CreateModifyContractComponent } from '../../../dialogs/create-modify-contract/create-modify-contract.component';
import { CreateModifyBonusComponent } from '../../../dialogs/create-modify-bonus/create-modify-bonus.component';
import { EquipmentModel } from '../../../../models/equipment.model';
import { ListEquipmentMapper } from '../../../../mappers/equipment.mapper';
import { CreateModifyBesharperEquipmentComponent } from '../../../dialogs/create-modify-besharper-equipment/create-modify-besharper-equipment.component';
import { RoleEnum } from "../../../../models/role.enum";
import { CreateModifyBesharperComponent } from '../../../dialogs/create-modify-besharper/create-modify-besharper.component';
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { SimpleListMapper } from "../../../../mappers/simple.mapper"
import { BeSharperMapper } from "../../../../mappers/besharper.mapper";
import { LogLevel } from "../../../../models/log-level";
import { CustomMaximalComponent } from "../../../dialogs/custom-maximal/custom-maximal.component";

@Component({
  selector: 'app-besharper-detail',
  templateUrl: './besharper-detail.component.html',
  styleUrl: './besharper-detail.component.scss',
})
export class BesharperDetailComponent extends IFilteredTablePage implements OnInit {
  loading = false;
  id = '';
  beSharper?: BeSharperModel;
  besharperWelfareResidualCredit: string = '...';

  beSharperMapper = new BeSharperMapper();
  basePath = environment.cognito.apiEndpoint + Constants.besharperApiPath;
  eRoleEnum = RoleEnum;

  contractBackendUrl = this.basePath;
  welfareBackendUrl = this.basePath;
  bonusBackendUrl = this.basePath;
  equipmentBackendUrl = this.basePath;

  equipmentDataSource: TableDataSource<EquipmentModel> = new TableDataSource<EquipmentModel>();
  contractDataSource: TableDataSource<ContractModel> = new TableDataSource<ContractModel>();
  welfareDataSource: TableDataSource<WelfareModel> = new TableDataSource<WelfareModel>();
  bonusDataSource: TableDataSource<BonusModel> = new TableDataSource<BonusModel>();

  contractMapper = new SimpleListMapper();
  welfareMapper = new WelfareListMapper();
  welfareStatusMapper = new WelfareStatusMapper();
  bonusMapper = new BonusMapper();
  equipmentMapper = new ListEquipmentMapper();

  equipmentSensitiveDataVisible = false;
  contractSensitiveDataVisible = false;
  welfareSensitiveDataVisible = false;
  bonusSensitiveDataVisible = false;



  contractTableDefinitions = [
    { def: 'contractType.name', title: 'Tipo Contratto', sortable: false },
    { def: 'contractLevel.name', title: 'Livello CCNL', sortable: false },
    { def: 'qualification.name', title: 'Ruolo', sortable: false },
    { def: 'isSupervisor', title: 'È Supervisore?', sortable: false },
    { def: 'isTpm', title: 'È Tpm?', sortable: false },
    { def: 'ral', title: 'RAL', sortable: true, transformer: (value: string): string => this.contractSensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
    { def: 'partTime', title: 'Ore settimanali', sortable: true },
    {
      def: 'contractStartDate',
      title: 'Data di Inizio',
      sortable: true,
      transformer: (value: string): string => this.formatDate(new Date(value), true),
    },
    {
      def: 'contractEndDate',
      title: 'Data di Fine',
      sortable: true,
      transformer: (value: string): string => {
        const isEmptyOrEpoch = new Date(value).getTime() === 0;
        if (isEmptyOrEpoch) {
          return 'Corrente'
        } else {
          return this.formatDate(new Date(value), true)
        }
      }
    },
    {
      def: 'contractLink', title: 'Link Contratto', link: true
    },
    {
      def: "active", title: "Attivo", sortable: true, virtual: (cell: any) => {
        const isActive = ((
          cell.contractEndDate.includes("1970") ||
          new Date(cell.contractEndDate).getTime() > Date.now()) &&
          new Date(cell.contractStartDate).getTime() <= Date.now());
        if (isActive) {
          return '<span class="green"><i class="fa fa-check"></i>ATTIVO</span>';
        }
        return '<span class="red"><i class="fa fa-times"></i>CESSATO</span>';
      }
    },
  ];

  equipmentTableDefinitions = [
    { def: 'equipmentName', title: 'Nome dotazione', sortable: true },
    { def: 'equipmentCode', title: 'Codice dotazione', sortable: true },
    { def: 'equipmentCategory', title: 'Categoria', sortable: true },
    { def: 'beSharperName', title: 'Nome beSharper', sortable: true },
    { def: 'besharperSurname', title: 'Cognome beSharper', sortable: true },
  ];

  welfareTableDefinitions = [
    { def: 'welfareTypeName', title: 'Tipo welfare', sortable: true },
    { def: 'supplyDate', title: 'Data', sortable: true, transformer: (value: any): string => this.formatDate(new Date(value), true) },
    { def: 'value', title: 'Valore', sortable: true, transformer: (value: string): string => this.welfareSensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
    { def: 'reason', title: 'Motivo', sortable: false, transformer: (value: any): string => value ? value.name : 'Non Presente' },
  ];

  bonusTableDefinitions = [
    { def: 'supplyDate', title: 'Data inizio', sortable: true, transformer: (value: any): string => this.formatDate(new Date(value), true) },
    { def: 'value', title: 'Valore', sortable: true, transformer: (value: string): string => this.bonusSensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
    { def: 'reason', title: 'Motivo', sortable: true },
  ];

  actionDefinitions = [];
  eTextStrings = TextStrings;

  constructor(
    private activatedRoute: ActivatedRoute,
    private providerService: ProviderService,
    protected override router: Router,
  ) {
    super(router, providerService.authService, providerService.utilService);
  }

  async ngOnInit(): Promise<void> {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        this.id = params['id'];

        this.contractBackendUrl += `/${this.id}/${Constants.contractApiPath}`;
        this.welfareBackendUrl += `/${this.id}/${Constants.welfareApiPath}`;
        this.bonusBackendUrl += `/${this.id}/${Constants.bonusApiPath}`;
        this.equipmentBackendUrl += `/${this.id}/${Constants.equipmentApiPath}`;


        await this.getBesharper();
        this.getWelfareStatus();
        this.refreshTable();
      })
      .unsubscribe();
  }

  areaRowClicked(row: any, page: string): void {
    this.router.navigate(['/', 'app', page, page === 'equipment-detail' ? row['equipmentId'] : row['id']]).then();
  }

  getWelfareStatus(): void {
    const requestUrl = this.welfareBackendUrl + `/status`;
    this.besharperWelfareResidualCredit = '...';

    this.providerService.networkService
      .get(requestUrl, this.welfareStatusMapper, {})
      .then((res) => res.welfareStatus)
      .then((welfareStatus) => {
        this.besharperWelfareResidualCredit = welfareStatus.residualCredit;
      })
      .catch((_error) => { });
  }

  createWelfare(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyWelfareComponent,
      data: { mode: ModeEnum.CREATE, beSharperId: this.id },
      closeCallback: (): void => this.getWelfareStatus()
    });
  }

  createContract(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyContractComponent,
      data: { mode: ModeEnum.CREATE, selectedBesharper: this.id, },
      closeCallback: (): void => this.getWelfareStatus()
    });
  }

  createBonus(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBonusComponent,
      data: {
        mode: ModeEnum.CREATE,
        beSharperId: this.id,
        bonus: {
          besharperId: this.id
        },
      }
    });
  }

  createEquipment(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperEquipmentComponent,
      data: { mode: ModeEnum.CREATE, selectedBesharper: this.id, },
    });
  }

  modifyContract(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyContractComponent,
      data: { mode: ModeEnum.MODIFY, beSharperId: this.id }
    });
  }

  modifyWelfare(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyWelfareComponent,
      data: { mode: ModeEnum.MODIFY, beSharperId: this.id },
      closeCallback: () => this.getWelfareStatus()
    });
  }

  modifyBonus(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBonusComponent,
      data: { mode: ModeEnum.MODIFY, beSharperId: this.id }
    });
  }

  modifyBesharper(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperComponent,
      data: { mode: ModeEnum.MODIFY, user: this.beSharper },
      closeCallback: () => this.providerService.utilService.refreshPage()
    });
  }

  deleteBesharper(): void {
    const besharperBackendUrl = this.basePath + `/${this.id}`;
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: besharperBackendUrl,
      description: "Per eliminare il beSharper, assicurarsi di eliminare prima contratti, welfare e bonus associati.",
      closeCallback: (result) => {
        if (result) {
          this.providerService.utilService.navigateBack();
        }
      }
    });
  }

  private async getBesharper(): Promise<void> {
    const getBesharperUrl = environment.cognito.apiEndpoint + Constants.besharperApiPath + `/${this.id}`;
    this.beSharper = (await this.providerService.networkService.get(getBesharperUrl, this.beSharperMapper))['beSharper'];
  }

  changeMaximal(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CustomMaximalComponent,
      data: { user: this.beSharper },
      closeCallback: () => this.providerService.utilService.refreshPage()
    });
  }

  isThereAnActiveContract(): boolean {
    const contracts = this.contractDataSource.getItems();
    console.log(contracts);
    let isActive = false;
    contracts.forEach(contract => {
      if (contract.contractEndDate) {
        isActive = isActive || (
          contract.contractEndDate?.toString().includes("1970") ||
          ((new Date(contract?.contractEndDate)?.getTime() > Date.now()) && new Date(contract.contractStartDate).getTime() <= Date.now())
        );
      } else {
        isActive = isActive || true;
      }
    });
    return !(contracts.length === 0 || !isActive);
  }

  checkMaximalValue(): string {

    if (!this.isThereAnActiveContract()) {
      return "Deve essere presente un <b>contratto in essere</b> per calcolare il Welfare!"
    }
    return this.formatMoney(this.besharperWelfareResidualCredit);
  }

  isFired() {
    return this.beSharper?.firingDate !== undefined && this.beSharper?.firingDate !== null && this.beSharper?.firingDate.toString() !== "";
  }
}
