<div>
  <div class="align-right">
    <button mat-raised-button color="primary" (click)="createConsumable();">
      <i class="fa-solid fa-cog"></i>&nbsp;&nbsp;
      <span>{{eTextStrings.CONSUMABLE_CREATE}}</span>
    </button>
  </div>
</div>

<div class="search-row">
  <h1>{{eTextStrings.CONSUMABLE}}</h1>
  <div class="search-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.CONSUMABLE_SEARCH}}</mat-label>
      <input matInput (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
    </mat-form-field>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<app-table-ui *ngIf="showTable"
              [backendUrl]="consumableBackendUrl"
              [datasource]="consumableDataSource"
              [tableDefinitions]="consumableTableDefinitions"
              [actionDefinitions]="(userRole === eRoleEnum.HR || userRole === eRoleEnum.HR_REPORTS || userRole === eRoleEnum.ADMIN) ? consumableActionDefinitions : undefined"
              [defaultActive]="'name'"
              [defaultDirection]="'asc'"
              [mapper]="consumableMapper"
              [role]="currentUser.role"
              (rowClicked)="rowClicked($event);"
              [hasRowAction]=true
              [pageSize]="10"
              [pageSizeOptions]="[10,20,30]"
              [filterObservable]="filterObservable">
</app-table-ui>


