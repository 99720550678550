import { Component, OnInit } from "@angular/core";
import { IFilteredTablePage } from "../../../core/interfaces/i-filtered-table-page";
import { TableDataSource } from "../../../core/form-utils/table/table.datasource";
import { ContractModel } from "../../../models/contract.model";
import { ProviderService } from "../../../core/provider.service";
import { CreateModifyContractComponent } from "../../dialogs/create-modify-contract/create-modify-contract.component";
import { Constants } from "../../../core/constants/constants";
import { ActivatedRoute, Router } from "@angular/router";
import { RoleEnum } from "../../../models/role.enum";
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";
import { SimpleListMapper } from "../../../../app/mappers/simple.mapper";
import { Moment } from "moment";

@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
  styleUrl: "./contracts.component.scss"
})
export class
ContractsComponent extends IFilteredTablePage implements OnInit {
  loading = false;
  dataSource?: TableDataSource<ContractModel> = new TableDataSource<ContractModel>();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;
  backendUrl = environment.cognito.apiEndpoint + Constants.contractApiPath;
  listContractMapper: SimpleListMapper = new SimpleListMapper();
  sensitiveDataVisible = false;

  tableDefinitions = [
    { def: "besharper.name", title: "Nome", sortable: false },
    { def: "besharper.surname", title: "Cognome", sortable: false },
    { def: "qualification.name", title: "Qualifica", sortable: false },
    { def: "contractType.name", title: "Tipologia", sortable: false },
    { def: "ral", title: "RAL", sortable: true, transformer: (value: string) => this.sensitiveDataVisible ? this.formatMoney(value) : "€ •••••" },
    { def: "partTime", title: "Ore S.", sortable: true },
    {
      def: "contractStartDate",
      title: "Data Inizio",
      sortable: true,
      transformer: (value: string): string => this.formatDate(new Date(value), true)
    },
    {
      def: "contractEndDate",
      title: "Data Fine",
      sortable: true,
      transformer: (value: string): string => {
        //If the date is null or equals epoch the contract has no end date, show an empty string
        if (value === null || value === undefined || new Date(value).getTime() === 0) {
          return "Corrente";
        } else {
          return this.formatDate(new Date(value), true);
        }
      }
    },
    { def: "createdAt", title: "Data di Creazione", sortable: true, transformer: (value: string | Date | Moment | undefined) => this.formatDate(value) },
    { def: "contractLink", title: "Link Contratto", sortable: false, link: true },
    {
      def: "active", title: "Attivo", sortable: true, virtual: (cell: any) => {
        const isActive = ((
          cell.contractEndDate.includes("1970") ||
          new Date(cell.contractEndDate).getTime() > Date.now()) &&
          new Date(cell.contractStartDate).getTime() <= Date.now());
        if (isActive) {
          return '<span class="green"><i class="fa fa-check"></i>ATTIVO</span>';
        }
        return '<span class="red"><i class="fa fa-times"></i>CESSATO</span>';
      }
    },
  ];

  actionDefinitions = [
    {
      icon: "fa-solid fa-pen",
      text: "Modifica contratto",
      styleClass: "table-action-modify",
      callback: (contract: any): void => {
        this.editContract(contract);
      },
    },
    {
      icon: "fa-solid fa-trash-can",
      text: "Elimina contratto",
      styleClass: "table-action-delete",
      callback: (contract: any): void => {
        this.deleteContract(contract["id"]).then();
      },
    },
  ];

  constructor(protected providerService: ProviderService, protected override router: Router, private route: ActivatedRoute) {
    super(router, providerService.authService, providerService.utilService);
    this.detailPage = "contract-detail";
  }

  addContract(): void {
    this.utilService.openCrudDialog({
      page: this,
      template: CreateModifyContractComponent,
      data: { mode: "create" }
    });
  }

  editContract(contract: any) {
    console.log("the contract: ", contract);
    this.utilService.openCrudDialog({
      page: this,
      template: CreateModifyContractComponent,
      data: { mode: "modify", contract: contract }
    });
  }

  async deleteContract(id: string): Promise<void> {
    this.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.backendUrl + "/" + id,
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
        if(params["openCreate"]) {
          this.addContract();
        }
      }
    );
  }
}
