import { Component, Inject } from "@angular/core";
import { TextStrings } from "../../../core/constants/text-strings";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProviderService } from "../../../core/provider.service";
import { SimpleMapper } from "../../../mappers/simple.mapper";
import { LogLevel } from "../../../models/log-level";
import { angularMaterialRenderers } from '@jsonforms/angular-material';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrl: './dialog-box.component.scss'
})
export class DialogBoxComponent {

  protected readonly renderers = angularMaterialRenderers;
  protected readonly eTextStrings = TextStrings;
  protected readonly uiSchema?: any = {
    type: 'VerticalLayout',
    elements: [
      {
        type: 'Control',
        label: true,
        scope: '#/properties/notes',
      }
    ]
  };

  loading: boolean;
  hasDescription = false;

  title: string = "";
  description: string | undefined = "";
  url: string = "";
  method: "get" | "post" | "put" | "delete" = "get";
  schema?: { type: 'object', properties: { notes: { type: any, [key: string]: any } }, required: string[] };
  returnPath?: string;
  formData?: any;
  errors?: any;
  callback?: any;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.loading = false;
    this.title = data.title;

    this.hasDescription = !!data.description;
    this.description = data.description;

    this.url = data.url;
    this.returnPath = data.returnPath;
    this.method = data.method;
    this.schema = data.schema;
    this.uiSchema = data.schema ? this.uiSchema : undefined;

    this.callback = data.callback;
  }

  async confirmAction(): Promise<void> {
    if ((this.schema && this.schema.required.length === 0) || (this.errors?.length === 0) || !this.schema) {
      this.loading = true;

      const mapper = new SimpleMapper();
      const params = {};
      const bodyMapper = new SimpleMapper();

      if (this.formData) {
        for (const key in this.formData) {
          console.log("TEST: ", key, this.formData[key]);
          (params as any)[key] = this.formData[key];
        }
      }
      bodyMapper.fillFromJson(params);

      let promise = null;
      switch (this.method) {
        case "get": promise = this.providerService.networkService.get(this.url, mapper, params); break;
        case "post": promise = this.providerService.networkService.post(this.url, bodyMapper, mapper); break;
        case "put": promise = this.providerService.networkService.put(this.url, bodyMapper, mapper); break;
        case "delete": promise = this.providerService.networkService.delete(this.url, mapper, params); break;
      };

      promise
        .then(() => {
          this.loading = false;
          this.providerService.utilService.showMessage(TextStrings.SUCCESS, LogLevel.success);
          this.dialogRef.close(true);

          if (this.returnPath) {
            this.providerService.utilService.navigateTo(this.returnPath);
          }

          if (this.callback) {
            this.callback();
          }
        })
        .catch((e) => {
          this.loading = false;
          if (e?.includes('409') || e?.toString()?.includes('409')) {
            this.providerService.utilService.showMessage('DELETE_DATABASE_ERROR', LogLevel.error);
          } else {
            this.providerService.utilService.showMessage(e, LogLevel.error);
          }
        });
    }
  }

  setData(data: any) {
    this.formData = data;
  }

  setErrors(errors: any) {
    this.errors = errors;
  }
}
