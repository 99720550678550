export function stringToRole(name : string) : RoleEnum {
  let role = RoleEnum.NOT_YET_ASSIGNED;

  switch(name?.toLowerCase()) {
    case "admin": role = RoleEnum.ADMIN; break;

    case "supervisor":
    case "linemanager": role = RoleEnum.LINE_MANAGER; break;

    case "tpm": role = RoleEnum.TPM; break;

    case "hr": role = RoleEnum.HR; break;
    case "hrreports": role = RoleEnum.HR_REPORTS; break;

    case "employee":
    case "besharper":
    case "user": role = RoleEnum.EMPLOYEE; break;

    default: role = RoleEnum.NOT_YET_ASSIGNED
  }

  return role;
}

export enum RoleEnum {
  ADMIN = "admin",
  HR = "hr",
  HR_REPORTS = "hrReports",
  LINE_MANAGER = "lineManager",
  TPM = "tpm",
  EMPLOYEE = "employee",
  NOT_YET_ASSIGNED = "notYetAssigned"
}

export default RoleEnum;
