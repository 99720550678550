import { IListMapper, IMapper } from "../models/i-mapper";
import { EquipmentType } from "../models/equipment-type.model";
import { PagedModel } from "../models/paged.model";

export class ListEquipmentTypeMapper implements IListMapper {
  elements: EquipmentType[] = [];
  count = 0;

  fillFromJson(json: any): PagedModel<EquipmentType> {
    this.elements = [];
    json['items'].forEach((item: any) => {
      this.elements.push(this.mapItem(item));
    });
    this.count = json['total'];
    return { elements: this.elements, count: this.count };
  }

  fillToJson(): string {
    return '';
  }

  toString(): string {
    return '';
  }

  private mapItem(item: any): EquipmentType {
    return {
      id: item['id'],
      name: item['name'],
      metadataSchema: item['metadataSchema'],
    };
  }
}

export class EquipmentTypePostMapper implements IMapper {
  body: any;

  fillFromJson(json: any): void {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}
