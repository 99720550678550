export enum ContractsEnums {
  CONTRACT_LEVEL= 'contractLevel',
  CONTRACT_TYPE= 'contractType',
  CONTRACT_ROLE = 'contract-role',
}

export enum FormTypeEnum {
  CREATE = 'CREATE',
  MODIFY = 'MODIFY'
}
export enum StatusesEnum {
  OK = 'OK',
  IN_ASSISTENZA = 'In assistenza',
  SMARRITO = 'Smarrito',
}

export enum ModeEnum {
  CREATE = 'create',
  MODIFY = 'modify',
}

export enum OutOfOfficeDateMode {
  AllDay = 'AllDay',
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  MultipleDays = 'MultipleDays',
  Custom = 'Custom'
}

export enum BesharperState {
  ALL = 'All',
  ACTIVE = 'Active',
  CEASED = 'Ceased'
}

export enum EquipmentStatus {
  OK = 'ok',
  FAULTY = 'faulty',
  LOST = 'lost',
  ROBBED = 'robbed',
  IN_REPLACEMENT = 'in replacement',
  IN_ASSISTANCE = 'in assistance',
}
