<div class="dialog-box-container">
  <h2>{{title}}</h2>
  <ng-container *ngIf="hasDescription">
    <p>{{description}}</p>
  </ng-container>

  <ng-container *ngIf="schema">
    <jsonforms
      [schema]="schema"
      [uischema]="uiSchema"
      [renderers]="renderers"
      (dataChange)="setData($event)"
      (errors)="setErrors($event)"
    ></jsonforms>
  </ng-container>

  <div class="button-container">
    <div class="align-right create-button">
      <button mat-raised-button color="secondary" (click)="dialogRef.close(false)">
        <i class="fa-solid fa-xmark"></i>
        &nbsp;
        <span>{{eTextStrings.CANCEL}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button [disabled]="loading" color="{{!loading ? 'primary' : 'disabled'}}" (click)="confirmAction();">
        <i class="fa-solid fa-check"></i>
        &nbsp;
        <span>{{eTextStrings.OK}}</span>
      </button>
    </div>
  </div>
</div>
