import { IListMapper, IMapper } from "../models/i-mapper";
import { SmartWhy } from "../models/smart-why.model";

export class SmartWhyMapper implements IMapper {
  body?: SmartWhy;

  fillFromJson(json: any): any {
    this.body = json;
  }

  fillToJson(): string {
    return JSON.stringify(this.body);
  }
}

export class SmartWhyListMapper extends IListMapper {
  elements: SmartWhy[] = [];
  count : number = 0;

  override fillFromJson(json: any): void {
    this.elements = json.items;
    this.count = json.total;
  }

  override fillToJson(): string {
    const body = {
      total: this.count,
      items: this.elements
    }

    return JSON.stringify(body);
  }
}
