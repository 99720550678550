import {Component, OnInit} from '@angular/core';
import IBasePage from "../../../../core/interfaces/i-base-page";
import {ConsumableModel} from "../../../../models/consumable.model";
import {environment} from "../../../../../environments/environment";
import {ConsumableDetailMapper} from "../../../../mappers/consumable.mapper";
import RoleEnum from "../../../../models/role.enum";
import {TextStrings} from "../../../../core/constants/text-strings";
import {ActivatedRoute, Router} from "@angular/router";
import {ProviderService} from "../../../../core/provider.service";
import {CreateModifyBesharperConsumableComponent} from "../../../dialogs/create-modify-besharper-consumable/create-modify-besharper-consumable.component";
import {ModeEnum} from "../../../../core/constants/enums";

@Component({
  selector: 'app-consumable-detail',
  templateUrl: './consumable-detail.component.html',
  styleUrl: './consumable-detail.component.scss'
})
export class ConsumableDetailComponent extends IBasePage implements OnInit {
  consumable?: ConsumableModel;
  id = '';
  consumableBackendUrl = environment.cognito.apiEndpoint;
  loading = false;
  mapper = new ConsumableDetailMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;

  constructor(private activatedRoute: ActivatedRoute, protected override router: Router, private providerService: ProviderService) {
    super(router, providerService.authService, providerService.utilService);
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        this.id = params['id'];
        this.consumableBackendUrl += `consumable/${this.id}`;
        this.getConsumable();
      })
      .unsubscribe();
  }

  modifyConsumable(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyBesharperConsumableComponent,
      data: {
        mode: ModeEnum.MODIFY,
        consumable: this.consumable,
      },
      closeCallback: () => this.getConsumable()
    });
  }

  async deleteConsumable(): Promise<void> {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.consumableBackendUrl,
      closeCallback: () => this.providerService.utilService.navigateBack(),
    });
  }

  private getConsumable() {
    this.loading = true;
    this.providerService.networkService
      .get(this.consumableBackendUrl, this.mapper)
      .then(() => {
        this.consumable = this.mapper.consumable;
      }).finally(() => {
      this.loading = false;
    });
  }

  protected readonly JSON = JSON;
}
