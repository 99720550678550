<div class="table-ui-container">
  <div *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <mat-table matSort [dataSource]="datasource!" [matSortActive]="defaultActive || tableDefinitions![0].def"
    [matSortDirection]="defaultDirection!">

    <ng-container *ngFor="let item of tableDefinitions">

      <ng-container matColumnDef="{{item.def}}">

        <ng-container *ngIf="item.sortable">
          <mat-header-cell mat-sort-header *matHeaderCellDef>{{item.title}}</mat-header-cell>
        </ng-container>

        <ng-container *ngIf="!item.sortable">
          <mat-header-cell *matHeaderCellDef>{{item.title}}</mat-header-cell>
        </ng-container>

        <ng-container *ngIf="item.def === 'photo'">
          <mat-cell *matCellDef="let cell" class="photo-container" [matTooltip]="item.tooltip ? item.tooltip(cell) : ''">
            <img alt="foto" src="{{traverseCellProperty(cell, item.def)}}">
          </mat-cell>
        </ng-container>
        <ng-container *ngIf="item.def !== 'photo'">
          <ng-container *ngIf="!item.link">
            <ng-container *ngIf="!item.virtual">
              <ng-container *ngIf="!item.json">
                <mat-cell *matCellDef="let cell"
                  [matTooltip]="item.tooltip ? item.tooltip(cell) : ''"
                  [innerHtml]="item.transformer? item.transformer(traverseCellProperty(cell, item.def)) : traverseCellProperty(cell, item.def)"></mat-cell>

              </ng-container>
              <ng-container *ngIf="item.json">
                <mat-cell *matCellDef="let cell" [matTooltip]="item.tooltip ? item.tooltip(cell) : ''">
                  <ngx-json-viewer
                    [json]="item.transformer? item.transformer(traverseCellProperty(cell, item.def)) : traverseCellProperty(cell, item.def)"
                    [expanded]="false"></ngx-json-viewer>
                </mat-cell>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="item.virtual">
              <mat-cell *matCellDef="let cell" [innerHtml]="item.virtual(cell)" [matTooltip]="item.tooltip ? item.tooltip(cell) : ''"></mat-cell>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="item.link">
            <mat-cell *matCellDef="let cell" [matTooltip]="item.tooltip ? item.tooltip(cell) : ''">
              <a *ngIf="traverseCellProperty(cell, item.def)" href="{{traverseCellProperty(cell, item.def)}}"
                (click)="$event.stopPropagation()" target="_blank">
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </a>
            </mat-cell>
          </ng-container>
        </ng-container>

      </ng-container>

    </ng-container>

    <ng-container *ngIf="actionDefinitions && actionDefinitions.length > 0" matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>{{actionTitle}}</mat-header-cell>
      <mat-cell *matCellDef="let row;">
        <ng-container *ngIf="!actionCondition || actionCondition(row)">
          <div class="action-menu" (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </div>
          <mat-menu #actionsMenu="matMenu" class="table-action-menu">
            <a mat-menu-item *ngFor="let action of actionDefinitions" [disabled]="!isActionActive(row, action)"
              (click)="isActionActive(row, action) ? action.callback(row) : {}" matTooltip="{{action?.tooltip}}">
              <i class="{{action.icon}} {{action.styleClass}}"></i>
              &nbsp;
              <span class="{{action.styleClass}}">{{action.text}}</span>
            </a>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="actionCondition && !actionCondition(row)">
          <div class="action-menu">
            <i class="fa-solid fa-ellipsis-vertical grey"></i>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableDefinitionHeaders!"></mat-header-row>

    <mat-row *matRowDef="let row; columns: tableDefinitionHeaders!" (click)="rowClickedAction(row);"
      class="{{hasRowAction ? 'table-row-action': 'table-row-no-action'}}"></mat-row>

  </mat-table>

  <mat-paginator [ngStyle]="{display: showPaginator ? 'block' : 'none'}" [length]="tableService.getTotalCount()"
    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</div>
