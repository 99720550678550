import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { FormLabels } from '../../../core/interfaces/i-form-labels';
import { ModeEnum } from '../../../core/constants/enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { QualificationPostMapper } from '../../../mappers/qualification.mapper';
import { IMapper } from '../../../models/i-mapper';
import { Constants } from '../../../core/constants/constants';
import { LogLevel } from '../../../models/log-level';
import { TextStrings } from "../../../core/constants/text-strings";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-admin-panel-create-modify-besharper',
  templateUrl: './admin-panel-create-modify-besharper.component.html',
  styleUrl: './admin-panel-create-modify-besharper.component.scss',
})
export class AdminPanelCreateModifyBesharperComponent {
  loading = false;
  formMode = '';

  formLabels: FormLabels = {
    name: TextStrings.BESHARPER_QUALIFICATION_NAME,
    description: TextStrings.BESHARPER_QUALIFICATION_DESCRIPTION,
    messages: {
      nameMessage: TextStrings.BESHARPER_QUALIFICATION_MESSAGES_NAME_PLACEHOLDER,
      descriptionMessage: TextStrings.BESHARPER_QUALIFICATION_MESSAGES_DESCRIPTION_PLACEHOLDER,
    },
  };

  qualificationCreateModificationUrl = environment.cognito.apiEndpoint + Constants.qualificationsApiPath;

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl(''),
  });

  eTextStrings = TextStrings;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private providerService: ProviderService,
    public dialogRef: MatDialogRef<AdminPanelCreateModifyBesharperComponent>
  ) {
    this.formMode = this.data.mode;
    if (this.data.mode === ModeEnum.MODIFY) {
      this.fillFormControls();
    }
  }
  isModifyMode(): boolean {
    return this.data.mode === ModeEnum.MODIFY;
  }
  fillFormControls(): void {
    this.form.controls.name.setValue(this.data.data.name);
    this.form.controls.description.setValue(this.data.data.description);
  }

  async sendRequest(): Promise<void> {
    if (this.data.mode === ModeEnum.CREATE) {
      await this.sendCreationRequest();
    } else {
      await this.sendModifyRequest();
    }
  }

  async sendCreationRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new QualificationPostMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .post(this.qualificationCreateModificationUrl, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.BESHARPER_CREATE_SUCCESS, LogLevel.success);
        this.loading = false;
        this.dialogRef.close();
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }

  async sendModifyRequest(): Promise<void> {
    this.loading = true;
    const responseMappper = new SimpleMapper();
    const requestMappper = new QualificationPostMapper();
    this.fillRequestBody(requestMappper);
    this.providerService.networkService
      .put(`${this.qualificationCreateModificationUrl}/${this.data.data.id}`, requestMappper, responseMappper)
      .then(() => {
        this.providerService.utilService.showMessage(TextStrings.BESHARPER_MODIFY_SUCCESS, LogLevel.success);
        this.loading = false;
        this.dialogRef.close();
      })
      .catch((e: any) => {
        this.providerService.utilService.showMessage(e, LogLevel.error);
        this.loading = false;
      });
  }
  fillRequestBody(mapper: IMapper): void {
    mapper.fillFromJson({
      name: this.form.controls.name.value,
      description: this.form.controls.description.value,
    });
  }
  protected readonly ModeEnum = ModeEnum;
}
