import { Component } from '@angular/core';
import {IUser} from "../../../models/i-user";
import {Subscription} from "rxjs";
import {ProviderService} from "../../../core/provider.service";
import RoleEnum from "../../../models/role.enum";

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  user: IUser;
  userSubscription: Subscription | null = null;
  eRoleEnum = RoleEnum;

  constructor(private providerService: ProviderService) {
    this.user = {} as IUser;
    this.userSubscription = this.providerService.authService.userSubject.subscribe((user: IUser) => {
      this.user = user;
    });
    this.eRoleEnum = RoleEnum;
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  protected readonly RoleEnum = RoleEnum;
}
