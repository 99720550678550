<div class="create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="create-modify-form">
    <h1 class="create-modify-title">{{(isCreateMode() ? eTextStrings.BESHARPER_CREATE : eTextStrings.BESHARPER_MODIFY )}}</h1>

    <form spellcheck="false" [formGroup]="form">

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Nome</mat-label>
            <input matInput type="text" id="name" name="name" formControlName="name" placeholder="Nome..." value="">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'name'" [icon]="'fa-regular fa-circle-exclamation'" message="Nome obbligatorio"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Cognome</mat-label>
            <input matInput type="text" id="surname" name="surname" formControlName="surname" placeholder="Cognome..." value="">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'surname'" [icon]="'fa-regular fa-circle-exclamation'" message="Cognome obbligatorio"></app-validator-ui>
        </div>
      </div>
      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput type="mail" id="email" name="email" formControlName="email" placeholder="Inserire email..." value="">
            <i class="fa fa-regular fa-envelope" matSuffix></i>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="Email obbligatoria"></app-validator-ui>
          <app-validator-ui [form]="form" [name]="'email'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Formato email non valido"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Codice Fiscale</mat-label>
            <input matInput type="text" id="cf" name="cf" maxlength="16" formControlName="cf" placeholder="Inserire Codice Fiscale..." value="">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'cf'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Codice Fiscale errato"></app-validator-ui>
        </div>
      </div>

      <div class="col-3">
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="datepicker.open()" (click)="datepicker.open()">
            <mat-label>Data di nascita</mat-label>
            <input matInput [matDatepicker]="datepicker" id="birthDate" name="birthDate" formControlName="birthDate" (focus)="datepicker.open()" (click)="datepicker.open()">
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker [lang]="'it'"></mat-datepicker>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'birthDate'" [icon]="'fa-regular fa-circle-exclamation'" message="Data di nascita obbligatoria"></app-validator-ui>
        </div>
        <div class="sided">
            <small>Per salvare il beSharper il valore deve essere Maschio o Femmina.</small>
            <mat-radio-group formControlName="sex">
              <mat-radio-button value="M">M</mat-radio-button>
              <mat-radio-button value="F">F</mat-radio-button>
              <mat-radio-button value="">N/A</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Titolo di Studio</mat-label>
            <input matInput type="text" id="school" name="school" formControlName="school" placeholder="Inserisci la scuola..." value="">
          </mat-form-field>
        </div>
      </div>


      <div class="col-50-20-30">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Indirizzo di Residenza</mat-label>
            <input matInput type="text" id="street" name="street" formControlName="street" placeholder="Inserisci l'indirizzo di residenza" value="">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>N. Civ.</mat-label>
            <input matInput type="text" id="number" name="number" formControlName="number" placeholder="" value="">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Codice Postale</mat-label>
            <input matInput type="text" id="zipCode" name="zipCode" formControlName="zipCode" maxlength="10" minlength="4" placeholder="" value="">
          </mat-form-field>
        </div>
      </div>

      <div class="col-40-30-30">
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Città</mat-label>
            <input matInput type="text" id="city" name="city" formControlName="city" placeholder="Inserisci la città" value="">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Provincia</mat-label>
            <input matInput type="text" id="province" name="province" formControlName="province" maxlength="2" placeholder="(es: PV)" value="">
          </mat-form-field>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill">
            <mat-label>Stato</mat-label>
            <mat-select [disabled]="countries.length === 0" formControlName="country">
              <mat-option [value]="c" *ngFor="let c of countries">{{c}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="col-2">
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="hiringDate.open()" (click)="hiringDate.open()">
            <mat-label>Data di assunzione</mat-label>
            <input matInput [matDatepicker]="hiringDate" id="hiringDate" name="hiringDate" formControlName="hiringDate" (focus)="hiringDate.open()" (click)="hiringDate.open()">
            <mat-datepicker-toggle matIconSuffix [for]="hiringDate"></mat-datepicker-toggle>
            <mat-datepicker #hiringDate [lang]="'it'"></mat-datepicker>
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'hiringDate'" [icon]="'fa-regular fa-circle-exclamation'" message="Data di assunzione obbligatoria"></app-validator-ui>
        </div>
        <div class="sided">
          <mat-form-field appearance="fill" (focus)="firingDate.open()" (click)="firingDate.open()">
              <mat-label>Data di cessazione</mat-label>
              <input matInput [matDatepicker]="firingDate" id="firingDate" name="firingDate" formControlName="firingDate" (focus)="firingDate.open()" (click)="firingDate.open()">
              <mat-datepicker-toggle matIconSuffix [for]="firingDate"></mat-datepicker-toggle>
              <mat-datepicker #firingDate [lang]="'it'"></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <mat-checkbox [color]="'secondary'" (change)="parentFromEnabled = !parentFromEnabled" [checked]="parentFromEnabled">È genitore?</mat-checkbox>

      <mat-form-field *ngIf="parentFromEnabled" appearance="fill" (focus)="parentFromDatePicker.open()" (click)="parentFromDatePicker.open()">
        <mat-label>Genitore dal</mat-label>
        <input matInput [matDatepicker]="parentFromDatePicker" id="parentFrom" name="parentFrom" formControlName="parentFrom" (focus)="parentFromDatePicker.open()" (click)="parentFromDatePicker.open()">
        <mat-datepicker-toggle matIconSuffix [for]="parentFromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #parentFromDatePicker></mat-datepicker>
      </mat-form-field>
      <app-validator-ui *ngIf="parentFromEnabled" [form]="form" [name]="'parentFrom'" [icon]="'fa-regular fa-circle-exclamation'" message="Data obbligatoria"></app-validator-ui>
    </form>
  </div>

  <div class="create-modify-button">
    <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
      <span>Indietro</span>
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
            (click)="addUser()" [disabled]="!formValid() || loading">
      Crea beSharper
    </button>
    <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
            (click)="addUser(true)" [disabled]="!formValid() || loading">
      Crea e continua ad inserire
    </button>
    <button *ngIf="isModifyMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
            (click)="modifyUser();" [disabled]="!formValid() || loading">
      Modifica beSharper
    </button>
  </div>
</div>

