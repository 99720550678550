<div class="align-right">
  <button mat-raised-button color="primary" (click)="createItem()" *ngIf="userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER">
    <i class="fa fa-list-alt" aria-hidden="true"></i>
    <span>{{eTextStrings.WAREHOUSE_CREATE}}</span>
  </button>
</div>


<div class="table-header-container">
  <div class="table-header-text">
    <i class="fa fa-list-alt" aria-hidden="true"></i>
    <h1>{{eTextStrings.PAGES_BESHARP_WAREHOUSE}}</h1>
  </div>
  <div class="table-search-form-container">
    <mat-form-field appearance="fill">
      <mat-label>{{eTextStrings.WAREHOUSE_SEARCH}}</mat-label>
      <input matInput placeholder="{{eTextStrings.WAREHOUSE_SEARCH_PLACEHOLDER}}" (keyup)="sendFilterQuery($event);">
      <i class="fa-regular fa-magnifying-glass" matSuffix></i>
    </mat-form-field>
  </div>
</div>

<app-table-ui [backendUrl]="besharpItemsBackendUrl"
              [datasource]="dataSource"
              [tableDefinitions]="tableDefinitions"
              [actionDefinitions]="(userRole !== eRoleEnum.EMPLOYEE && userRole !== eRoleEnum.LINE_MANAGER) ? actionDefinitions : undefined"
              [mapper]="tableMapper"
              [pageSize]="10"
              [pageSizeOptions]="[10,20,30]"
              [filterObservable]="filterObservable">
</app-table-ui>
