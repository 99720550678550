<form [formGroup]="form">
    <mat-form-field appearance="fill">
        <mat-label>Oggetto</mat-label>
        <input matInput type="text" id="subject" name="subject" formControlName="subject" (input)="emitArgs()" placeholder="Oggetto" value="">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'subject'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire oggetto"></app-validator-ui>

   
    <app-email-chips label="Destinatari" [(addresses)]="recipients" (addressesChange)="emitArgs()"/>
      
    <mat-form-field appearance="fill">
        <mat-label>Titolo</mat-label>
        <input matInput type="text" id="title" name="title" formControlName="title" (input)="emitArgs()" placeholder="Titolo" value="">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Contenuto</mat-label>
        <input matInput type="text" id="body" name="body" formControlName="body" (input)="emitArgs()" placeholder="Contenuto" value="">
    </mat-form-field>
    <app-validator-ui [form]="form" [name]="'body'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="Inserire contenuto"></app-validator-ui>
</form>