<div class="container create-modify-container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <div class="header">
    <h1>{{editingExistingItem ? eTextStrings.OUT_OF_OFFICE_MODIFY_RECURRENT :
      eTextStrings.OUT_OF_OFFICE_CREATE_RECURRENT}}</h1>
  </div>
  <div class="form-row">
    <form spellcheck="false" [formGroup]="form">
      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>BeSharper</mat-label>
          <input type="text" placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}" aria-label="name"
            matInput formControlName="besharper" [matAutocomplete]="besharperAuto" (input)="filter()"
            [readonly]="!isCurrentUserHr() || editingExistingItem" />

          <mat-autocomplete #besharperAuto="matAutocomplete" [displayWith]="getDisplayNameForBesharper.bind(this)">
            <mat-option *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' +
              besharper.surname }}</mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'"
          message="{{eTextStrings.REQUIRED}}"></app-validator-ui>
      </ng-container>

      <div>
        <ng-container>
          <mat-form-field appearance="fill">
            <mat-label>Seleziona il periodo in cui far rientrare la ricorrenza (di default parte da oggi fino a fine
              anno)</mat-label>
            <mat-date-range-input [rangePicker]="picker" (focus)="picker.open()" (click)="picker.open()">
              <input matStartDate id="startingAtDate" name="startingAtDate" formControlName="startingAtDate" readonly>
              <input matEndDate id="endingAtDate" name="endingAtDate" formControlName="endingAtDate" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker (closed)="checkAndSetSingleDay();"></mat-date-range-picker>
          </mat-form-field>
        </ng-container>
      </div>

      <div class="days-list">
        <mat-label>Seleziona i giorni della ricorrenza</mat-label>
        <ul>
          <li><mat-checkbox (change)="toggleDay(0)" [checked]="daysOfTheWeek[0] === '1'">LUN</mat-checkbox></li>
          <li><mat-checkbox (change)="toggleDay(1)" [checked]="daysOfTheWeek[1] === '1'">MAR</mat-checkbox></li>
          <li><mat-checkbox (change)="toggleDay(2)" [checked]="daysOfTheWeek[2] === '1'">MER</mat-checkbox></li>
          <li><mat-checkbox (change)="toggleDay(3)" [checked]="daysOfTheWeek[3] === '1'">GIO</mat-checkbox></li>
          <li><mat-checkbox (change)="toggleDay(4)" [checked]="daysOfTheWeek[4] === '1'">VEN</mat-checkbox></li>
        </ul>
      </div>

      <ng-container>
        <div class="col-2">
          <div class="sided">
            <app-time-picker #timePickerStart [(time)]="this.startingAtTime" [minuteStep]="30"
              [label]="eTextStrings.OUT_OF_OFFICE_CREATE_STARTTIME" />
          </div>

          <div class="sided">
            <app-time-picker #timePickerEnd [(time)]="this.endingAtTime" [minuteStep]="30"
              [label]="eTextStrings.OUT_OF_OFFICE_CREATE_ENDTIME" />
          </div>
        </div>
        <div>
          <mat-form-field appearance="fill">
            <mat-label>Motivazione remote working strutturato</mat-label>
            <input matInput type="text" id="reason" name="reason" formControlName="reason">
          </mat-form-field>
          <app-validator-ui [form]="form" [name]="'reason'" [icon]="'fa-regular fa-circle-exclamation'"
            message="Campo obbligatorio"></app-validator-ui>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="button-row">
    <button color="primary" (click)="dialogRef.close()" mat-raised-button>{{eTextStrings.BUTTONS_BACK}}</button>
    <button mat-raised-button (click)="submit()" [disabled]="!formValid() || loading"
      color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
      *ngIf="!dialogWasOpenedFromDetailsPage">{{eTextStrings.CONFIRM_AND_CONTINUE}}</button>
    <button mat-raised-button (click)="editingExistingItem ? modifyOutOfOffice() : createOutOfOffice()"
      [disabled]="!formValid() || loading" color="{{formValid() && !loading ? 'primary': 'disabled'}}"
      type="button">{{eTextStrings.CONFIRM}}</button>
  </div>
</div>