import { Component } from '@angular/core';
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { environment } from "../../../../../environments/environment";
import { Constants } from "../../../../core/constants/constants";
import { TextStrings } from "../../../../core/constants/text-strings";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { ModeEnum } from "../../../../core/constants/enums";
import { EquipmentType } from "../../../../models/equipment-type.model";
import { ListEquipmentTypeMapper } from "../../../../mappers/equipment-type-mapper";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { AdminPanelCreateModifyEquipmentTypeComponent } from "../../../dialogs/admin-panel-create-modify-equipmen-type/admin-panel-create-modify-equipment-type.component";

@Component({
  selector: 'app-set-equipment-types-dropdown-menus',
  templateUrl: './set-equipment-types-dropdown-menus.component.html',
  styleUrl: './set-equipment-types-dropdown-menus.component.scss'
})
export class SetEquipmentTypesDropdownMenusComponent extends IFilteredTablePage {
  equipmentTypeCollapsed = true;

  equipmentTypeDataSource: TableDataSource<EquipmentType> = new TableDataSource<EquipmentType>();
  equipmentTypeBackendUrl = environment.cognito.apiEndpoint + Constants.equipmentTypesApiPath;
  equipmentTypeTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
    { def: 'metadataSchema', title: 'Schema MetadatiJson', sortable: false, json: true, transformer: (row: any) => JSON.parse(row) },
  ];
  equipmentTypeMapper = new ListEquipmentTypeMapper();
  equipmentTypeActionDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: EquipmentType) => {
        this.deleteItem(item.id);
      },
    },
  ];

  eTextStrings = TextStrings;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.equipmentTypeCollapsed = !this.equipmentTypeCollapsed;
  }

  deleteItem(id: string): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.equipmentTypeBackendUrl}/${id}`,
    });
  }

  openCreateDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyEquipmentTypeComponent,
      data: { mode: ModeEnum.CREATE }
    });
  }

  openModifyDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyEquipmentTypeComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
      }
    });
  }
}
