import { Component, OnInit } from '@angular/core';
import { ContractModel } from "../../../../models/contract.model";
import { ActivatedRoute, Router } from "@angular/router";
import { ProviderService } from '../../../../core/provider.service';
import { Constants } from '../../../../core/constants/constants';
import { RoleEnum } from "../../../../models/role.enum";
import { ModeEnum } from '../../../../core/constants/enums';
import { CreateModifyContractComponent } from '../../../dialogs/create-modify-contract/create-modify-contract.component';
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import IBasePage from "../../../../core/interfaces/i-base-page";
import { SimpleMapper } from '../../../../mappers/simple.mapper';
import { BeSharperModel } from "../../../../models/be-sharper.model";
import { CoreModule } from "../../../../core/core.module";
import { EyeToggleComponent } from "../../../../core/form-utils/eye-toggle/eye-toggle.component";

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrl: './contract-detail.component.scss',
})
export class ContractDetailComponent extends IBasePage implements OnInit {
  loading = false;
  id = null;
  contract?: ContractModel;
  contractMapper = new SimpleMapper();
  eRoleEnum = RoleEnum;
  eTextStrings = TextStrings;
  isTpmRequired = false;
  isLineManagerRequired = false;
  isSupervisorRequired = false;
  sensitiveDataVisible = false;
  protected readonly JSON = JSON;

  get backendUrl() {
    return environment.cognito.apiEndpoint + Constants.contractApiPath + `/${this.id}`;
  }

  constructor(protected override router: Router, private activatedRoute: ActivatedRoute, private providerService: ProviderService) {
    super(router, providerService.authService, providerService.utilService);
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe(async (params: any) => {
        this.id = params['id'];
        this.getContract();
      })
      .unsubscribe();
  }

  modifyContractModalDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: CreateModifyContractComponent,
      data: {
        mode: ModeEnum.MODIFY,
        selectedBesharper: this.contract?.besharper?.id,
        contract: this.contract,
      },
      closeCallback: () => this.getContract()
    });
  }

  deleteContractModalDialog(): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: this.backendUrl,
      closeCallback: () => this.providerService.utilService.navigateBack()
    });
  }

  async getContract(): Promise<void> {
    const getContractUrl = this.backendUrl;
    this.loading = true;

    const response = await this.providerService.networkService.get(getContractUrl, this.contractMapper)
    this.contract = response.body;
    console.log(this.contract);

    if (this.contract) {
      if (this.contract.isTpm) {
        this.isTpmRequired = false;
        if (this.contract.isLineManager) {
          this.isLineManagerRequired = false;
          this.isSupervisorRequired = true;
        } else {
          this.isLineManagerRequired = true;
          this.isSupervisorRequired = false;
        }
      } else {
        if (this.contract.isLineManager) {
          this.isTpmRequired = false;
          this.isLineManagerRequired = false;
          this.isSupervisorRequired = true;
        } else {
          this.isTpmRequired = true;
          this.isLineManagerRequired = true;
          this.isSupervisorRequired = false;
        }
      }
    }

    this.loading = false;
  }

  getContractEndLabel(): string | undefined {
    const contractEnd = new Date(this.contract?.contractEndDate?.toString() || '');
    if (
      contractEnd === undefined ||
      contractEnd === null ||
      contractEnd.toString() === '' ||
      contractEnd.toString().indexOf('1970') > -1
    ) {
      return 'Corrente';
    }

    const day = contractEnd.getDate();
    const month = contractEnd.getMonth();
    const year = contractEnd.getFullYear();
    if (day && month && year) {
      return `${year}-${month + 1}-${day}`;
    }
    return undefined;
  }

  getContractStartLabel(): string | undefined {
    return this.formatDate(this.contract?.contractStartDate?.toString(), true) || undefined;
  }

  getFullname(model: BeSharperModel | undefined): string {
    if (model) {
      return model.name + " " + model.surname;
    }
    return "";
  }
}
