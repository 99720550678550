import { Component } from '@angular/core';
import { TextStrings } from "../../../../core/constants/text-strings";
import { environment } from "../../../../../environments/environment";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { Constants } from "../../../../core/constants/constants";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { ModeEnum } from "../../../../core/constants/enums";
import { SmartWhy } from "../../../../models/smart-why.model";
import { SmartWhyListMapper } from "../../../../mappers/smart-why.mapper";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { AdminPanelCreateModifySmartWorkingTypeComponent } from "../../../dialogs/admin-panel-create-modify-smart-working-type/admin-panel-create-modify-smart-working-type.component";

@Component({
  selector: 'app-set-smart-working-types-dropdown-menu',
  templateUrl: './set-smart-working-types-dropdown-menu.component.html',
  styleUrl: './set-smart-working-types-dropdown-menu.component.scss'
})
export class SetSmartWorkingTypesDropdownMenuComponent extends IFilteredTablePage {
  eTextStrings = TextStrings;
  loading = false;
  basePath = environment.cognito.apiEndpoint;

  smartWorkingTypesDataModel: TableDataSource<SmartWhy> = new TableDataSource<SmartWhy>();
  smartWorkingTypesCollapsed = true;
  smartWorkingTypesBackendUrl = this.basePath + Constants.smartWorkingTypeApiPath;
  smartWorkingTypesMapper: SmartWhyListMapper = new SmartWhyListMapper();

  smartWorkingTypesTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
  ];
  smartWorkingTypesActionsDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: SmartWhy) => {
        this.deleteItem(item.id);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.smartWorkingTypesCollapsed = !this.smartWorkingTypesCollapsed;
  }

  openCreatesmartWorkingTypesDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifySmartWorkingTypeComponent,
      data: {
        mode: ModeEnum.CREATE
      }
    });
  }

  deleteItem(id: string): void {
    const apiUrl = `${this.smartWorkingTypesBackendUrl}/${id}`;

    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: apiUrl!,
    });
  }

  openModifyDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifySmartWorkingTypeComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row
      }
    });
  }
}
