import { Router } from "@angular/router";
import { IAuthService } from "./i-auth-service";
import { UtilsService } from "../app-utils/utils.service";
import { Component } from "@angular/core";
import IBasePage from "./i-base-page";

@Component({ template: '' })
export abstract class IFilteredTablePage extends IBasePage {
  detailPage?: string;

  protected constructor(router: Router, authService: IAuthService, utilService: UtilsService) {
    super(router, authService, utilService);
  }

  rowClicked(row: any): void {
    if (this.detailPage) {
      this.router.navigate(['/', 'app', this.detailPage, row['id']]).then();
    }
  }

  sendFilterQuery(event: any): void {
    this.lastDebounceValue = event.target.value;
    this.debounceSubject.next(event.target.value);
  }

  sendDaysOfWeekQuery(daysOfWeek: any): void {
    this.lastDebounceWeekValue = daysOfWeek;
    this.debounceDaysSubject.next(daysOfWeek);
  }

  sendMobileWindowQuery(mobileWindow: boolean): void {
    this.lastDebounceMobileWindow = mobileWindow;
    this.debounceMobileWindowSubject.next(mobileWindow);
  }
}
