import { Component } from "@angular/core";
import { TextStrings } from "../../../../core/constants/text-strings";
import { IFilteredTablePage } from "../../../../core/interfaces/i-filtered-table-page";
import { ProviderService } from "../../../../core/provider.service";
import { Router } from "@angular/router";
import { WelfareMaximalListMapper } from "../../../../mappers/welfare.mapper";
import { TableDataSource } from "../../../../core/form-utils/table/table.datasource";
import { environment } from "../../../../../environments/environment";
import { Constants } from "../../../../core/constants/constants";
import { WelfareMaximalModel } from "../../../../models/welfare-maximal.model";
import { ModeEnum } from "../../../../core/constants/enums";
import { AdminPanelCreateModifyWelfareMaximalsComponent } from "../../../dialogs/admin-panel-create-modify-welfare-maximals/admin-panel-create-modify-welfare-maximals.component";
import { LogLevel } from "../../../../models/log-level";

@Component({
  selector: 'app-set-welfare-maximals-menus',
  templateUrl: './set-welfare-maximals-menus.component.html',
  styleUrl: './set-welfare-maximals-menus.component.scss'
})
export class SetWelfareMaximalsMenusComponent extends IFilteredTablePage {
  eTextStrings = TextStrings;

  welfareMaximalsCollapsed = false;

  welfareMaximalsMapper: WelfareMaximalListMapper = new WelfareMaximalListMapper();
  welfareMaximalsDataSource: TableDataSource<WelfareMaximalModel> = new TableDataSource<WelfareMaximalModel>();
  welfareMaximalsBackendUrl = environment.cognito.apiEndpoint + Constants.welfareMaximalsApiPath;

  welfareMaximalsTableDefinitions = [
    { def: 'id', title: 'Identificativo', sortable: true },
    { def: 'value', title: 'Valore Mensile', sortable: true },
    { def: 'family', title: 'Bonus Famiglia?', sortable: true },
  ];
  welfareMaximalsActionDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: WelfareMaximalModel) => {
        this.deleteItem(item.id);
      },
    },
  ];

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.welfareMaximalsCollapsed = !this.welfareMaximalsCollapsed;
  }

  deleteItem(id: string): void {
    if(this.welfareMaximalsDataSource.getItems().length > 2) {
      this.providerService.utilService.deleteCrudDialog({
        page: this,
        apiUrl: `${this.welfareMaximalsBackendUrl}/${id}`,
      });
    } else {
      this.providerService.utilService.showMessage("I Massimali devono essere 2, uno con family a TRUE e uno con family a FALSE, usa modifica invece di elimina", LogLevel.info);
    }
  }

  openModifyWelfareMaximalDialog(row: any) {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyWelfareMaximalsComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
      }
    });
  }

  openCreateWelfareMaximalDialog() {
    if(this.welfareMaximalsDataSource.getItems().length < 2) {
      this.providerService.utilService.openCrudDialog({
        page: this,
        template: AdminPanelCreateModifyWelfareMaximalsComponent,
        data: { mode: ModeEnum.CREATE }
      });
    } else {
      this.providerService.utilService.showMessage("Al momento non serve creare più di 2 tipi di massimali, verificare solo che 1 dei due sia con family a FALSE e 1 con family a TRUE", LogLevel.info)
    }
  }
}
