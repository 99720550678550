<div class="create-modify-container">
    <div *ngIf="loading">
        <app-loader></app-loader>
    </div>
    <div class="create-modify-form">
        <h1 class="create-modify-title">{{isCreateMode() ? eTextStrings.WELFARE_CREATE : eTextStrings.WELFARE_MODIFY}}</h1>

        <form spellcheck="false" [formGroup]="form">

            <ng-container *ngIf="listMode">
              <mat-form-field appearance="fill">
                <input type="text"
                       placeholder="{{eTextStrings.EQUIPMENT_MESSAGES_SELECT_BESHARPER}}"
                       aria-label="name"
                       matInput
                       (input)="filter()"
                       formControlName="besharper"
                       [matAutocomplete]="auto"
                />

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectedBesharper.bind(this)">
                  <mat-option (onSelectionChange)="onBesharperSelected(besharper)" *ngFor="let besharper of filteredBesharpers" [value]="besharper.id">{{besharper.name + ' ' + besharper.surname }}</mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <app-validator-ui [form]="form" [name]="'besharper'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.CONTRACT_MESSAGES_SELECT}}"></app-validator-ui>

            </ng-container>

            <mat-form-field appearance="fill">
                <mat-label>Tipologia di Welfare</mat-label>
                <mat-select [disabled]="welfareTypes.length === 0" formControlName="welfareType">
                    <mat-option [value]="w.id" *ngFor="let w of welfareTypes">{{w.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'welfareType'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.WELFARE_MESSAGES_TYPE}}"></app-validator-ui>

            <mat-form-field appearance="fill">
              <mat-label>{{eTextStrings.VALUE}}</mat-label>
              <input matInput id="value" name="value" formControlName="value" placeholder="{{eTextStrings.VALUE}}..." value="">
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'value'" [errorCode]="'required'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.WELFARE_MESSAGES_VALUE}}"></app-validator-ui>
            <app-validator-ui [form]="form" [name]="'value'" [errorCode]="'pattern'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.WELFARE_MESSAGES_NUMERIC}}"></app-validator-ui>

            <mat-form-field appearance="fill">
              <mat-label>{{eTextStrings.WELFARE_REASON}}</mat-label>
              <mat-select [disabled]="welfareReasons.length === 0" formControlName="reason">
                <mat-option [value]="r.id" *ngFor="let r of welfareReasons">{{r.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'reason'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.WELFARE_MESSAGES_REASON}}"></app-validator-ui>

            <mat-form-field appearance="fill" (focus)="datepicker.open()" (click)="datepicker.open()">
              <mat-label>Data Inserimento</mat-label>
              <input matInput [matDatepicker]="datepicker" id="supplyDate" name="supplyDate" formControlName="supplyDate" (focus)="datepicker.open()" (click)="datepicker.open()">
                <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker></mat-datepicker>
            </mat-form-field>
            <app-validator-ui [form]="form" [name]="'supplyDate'" [icon]="'fa-regular fa-circle-exclamation'" message="{{eTextStrings.WELFARE_MESSAGES_STARTDATE_VALIDATOR}}"></app-validator-ui>

        </form>
    </div>

    <div class="create-modify-button">
        <button mat-raised-button color="primary" (click)="this.dialogRef.close()">
            <span>{{eTextStrings.BUTTONS_BACK}}</span>
        </button>
        <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
                (click)="createWelfare()" [disabled]="!formValid() || loading">
            {{eTextStrings.WELFARE_CREATE}}
        </button>
        <button *ngIf="isCreateMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
                (click)="createWelfare(true)" [disabled]="!formValid() || loading">
          {{eTextStrings.WELFARE_CREATE_AND_ADD_ANOTHER}}
        </button>
        <button *ngIf="isModifyMode()" mat-raised-button color="{{formValid() && !loading ? 'primary': 'disabled'}}" type="button"
                (click)="modifyWelfare();" [disabled]="!formValid() || loading">
            {{eTextStrings.WELFARE_MODIFY}}
        </button>
    </div>
</div>

