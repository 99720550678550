import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { IAuthService } from '../interfaces/i-auth-service';
import { IUser } from '../../models/i-user';
import { Constants } from '../constants/constants';
import { RoleEnum } from "../../models/role.enum";
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends IAuthService {
  private user: IUser = {
    name: '...',
    surname: '',
    email: '...',
    role: RoleEnum.EMPLOYEE,
    id: '...',
    photoUrl: ''
  };

  private userBehaviorSubject: BehaviorSubject<IUser> = new BehaviorSubject(this.user);

  constructor(
    private cookieService: CookieService,
    private router: Router,
  ) {
    super();
  }

  public get userSubject() {
    return this.userBehaviorSubject;
  }

  confirmSignUp(_user: IUser): Promise<any> {
    return Promise.resolve(undefined);
  }

  getUser(): IUser {
    return this.user;
  }

  setUser(user: IUser): void {
    this.user = user;
    this.userBehaviorSubject.next(user);
  }

  async isAuthenticated(): Promise<boolean> {
    if (
      this.cookieService.get(Constants.accessKeyCookie) &&
      this.cookieService.get(Constants.secretKeyCookie) &&
      this.cookieService.get(Constants.sessionTokenCookie) &&
      this.cookieService.get(Constants.expirationCookie)
    ) {
      const expirationDate = new Date(this.cookieService.get(Constants.expirationCookie));
      const expired = expirationDate.getTime() < new Date().getTime();

      if (expired) {
        this.cookieService.delete(Constants.accessKeyCookie);
        this.cookieService.delete(Constants.secretKeyCookie);
        this.cookieService.delete(Constants.sessionTokenCookie);
        this.cookieService.delete(Constants.expirationCookie);

        localStorage.removeItem(Constants.userInfo);
        localStorage.removeItem(Constants.samlResponseItem);

        this.router.navigate(["/", "auth", "sign-in"]).then();
        return false;
      }
      return true;
    } else {
      localStorage.removeItem(Constants.userInfo);
      localStorage.removeItem(Constants.samlResponseItem);
      localStorage.setItem(Constants.currentNavigation, window.location.pathname);

      this.router.navigate(["/", "auth", "sign-in"]).then();
      return false;
    }
  }

  signIn(_user: IUser): Promise<any> {
    return Promise.resolve(undefined);
  }

  signOut(): Promise<any> {
    this.cookieService.deleteAll();
    localStorage.clear();
    return Promise.resolve();
  }

  signUp(_user: IUser): Promise<any> {
    return Promise.resolve(undefined);
  }

  updateUser(_user: IUser): Promise<any> {
    return Promise.resolve(undefined);
  }

  private changeHref(href: string) {
    window.location.href = href;
  }
}
