import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModeEnum } from '../../../core/constants/enums';
import { Constants } from '../../../core/constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProviderService } from '../../../core/provider.service';
import { SimpleMapper } from '../../../mappers/simple.mapper';
import { LogLevel } from '../../../models/log-level';
import { IListMapper, IMapper } from '../../../models/i-mapper';
import { CreateModifyWelfareComponent } from '../create-modify-welfare/create-modify-welfare.component';
import { BonusPostMapper } from '../../../mappers/bonus.mapper';
import { BeSharperModel } from '../../../models/be-sharper.model';
import { BeSharperListMapper } from '../../../mappers/besharper.mapper';
import { BonusModel } from '../../../models/bonus.model';
import { environment } from "../../../../environments/environment";
import { TextStrings } from "../../../core/constants/text-strings";

@Component({
  selector: 'app-create-modify-bonus',
  templateUrl: './create-modify-bonus.component.html',
  styleUrl: './create-modify-bonus.component.scss',
})
export class CreateModifyBonusComponent implements OnInit {
  loading = false;
  mode: ModeEnum = ModeEnum.CREATE;
  listMode = false;
  bonus?: any;
  backendUrlBonus = environment.cognito.apiEndpoint + Constants.bonusApiPath;
  besharperLocked : boolean = false;

  besharpers : BeSharperModel[] = [];
  filteredBesharpers: BeSharperModel[] = [];
  selectedBesharper?: BeSharperModel;

  backendUrlBesharper = environment.cognito.apiEndpoint + Constants.besharperApiPath;

  eTextStrings = TextStrings;

  public form = new FormGroup({
    besharper: new FormControl('', []),
    value: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
    supplyDate: new FormControl(new Date(), [Validators.required]),
    reason: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<CreateModifyBonusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public providerService: ProviderService
  ) {
    this.bootstrap(data);
  }

  private bootstrap(data: any) {
    if (data["mode"] === "modify") {
      this.mode = ModeEnum.MODIFY;
    } else if (data["mode"] === "create") {
      this.mode = ModeEnum.CREATE;
      this.listMode = data["listMode"];
      if (this.listMode) {
        this.form.controls.besharper.addValidators(Validators.required);
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    await this.getBesharpers();

    if (this.data['mode'] == ModeEnum.MODIFY && this.data.bonus !== undefined) {
      this.mode = ModeEnum.MODIFY;
      this.bonus = this.data.bonus;
      this.populateFormForModify(this.bonus);
      this.besharperLocked = true;
    }
    else if(this.data['mode'] == ModeEnum.CREATE) {
      this.mode = ModeEnum.CREATE;

      if(this.data.bonus?.besharperId !== undefined) {
        this.form.controls.besharper.setValue(this.data?.bonus?.besharperId)
        this.besharperLocked = true;
      }
    }

    this.loading = false;
  }

  onBesharperSelected(besharper: BeSharperModel): void {
    this.selectedBesharper = besharper;
  }

  displaySelectedBesharper(id: string): string {
    const selectedBesharper = this.besharpers.find((_) => _.id === id);
    return selectedBesharper ? selectedBesharper?.name + ' ' + selectedBesharper?.surname : '';
  }

  filter(): void {
    if (this.form.controls.besharper.value) {
      const userInput = this.form.controls.besharper.value.toLowerCase();
      this.filteredBesharpers = this.besharpers.filter(
        (b) => b.name.toLowerCase().includes(userInput) || b.surname.toLowerCase().includes(userInput)
      );
    } else {
      this.filteredBesharpers = this.besharpers;
    }
  }

  formValid(): boolean {
    return this.form.valid;
  }

  isCreateMode(): boolean {
    return this.mode === ModeEnum.CREATE;
  }

  isModifyMode(): boolean {
    return this.mode === ModeEnum.MODIFY;
  }

  async createBonus(addAnother?: boolean): Promise<void> {
    this.loading = true;

    const url = environment.cognito.apiEndpoint + Constants.bonusApiPath;
    const postMapper = new BonusPostMapper();
    const responseMapper = new SimpleMapper();

    try {
      this.fillMapper(postMapper);

      await this.providerService.networkService.post(url, postMapper, responseMapper);

      this.providerService.utilService.showMessage(TextStrings.BONUS_CREATE_SUCCESS, LogLevel.success);
      this.loading = false;
      this.form.reset();
      this.form.controls.besharper.setValue(this.data?.bonus?.besharperId);
      if(!addAnother) {
        this.dialogRef.close();
      }
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  async modifyBonus(): Promise<void> {
    this.loading = true;
    const putMapper = new BonusPostMapper();
    const mapper = new SimpleMapper();
    try {
      this.fillMapper(putMapper);
      await this.providerService.networkService.put(this.backendUrlBonus + '/' + this.bonus['id'], putMapper, mapper)
      this.providerService.utilService.showMessage(TextStrings.BONUS_MODIFY_SUCCESS, LogLevel.success);
      this.dialogRef.close();
      this.loading = false;
    } catch (e: any) {
      this.providerService.utilService.showMessage(e, LogLevel.error);
      this.loading = false;
    }
  }

  async getBesharpers(): Promise<void> {
    const mapper: IListMapper = new BeSharperListMapper();
    try {
      await this.providerService.networkService.get(this.backendUrlBesharper, mapper, { limit: '99999', offset: '0', orderBy: 'surname' });
      this.besharpers = mapper.elements;
    }
    catch(e: any) {
      this.providerService.utilService.showMessage(e.toString(), LogLevel.error);
      this.dialogRef.close();
    }
  }

  private populateFormForModify(data: BonusModel) {
    this.form.controls.besharper.setValue(data.besharperId)
    this.form.controls.value.setValue(data.value?.toString() ?? '');
    this.form.controls.supplyDate.setValue(new Date(data.supplyDate));
    this.form.controls.reason.setValue(data.reason ?? '');
  }

  fillMapper(mapper: IMapper): void {
    mapper.fillFromJson({
      besharperId: this.form.controls.besharper.value,
      value: this.form.controls.value.value,
      supplyDate: this.providerService.utilService.formatDate(this.form.controls.supplyDate.value ?? new Date()),
      reason: this.form.controls.reason.value,
    });
  }
}
