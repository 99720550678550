import { Injectable } from '@angular/core';
import { IAuthService } from './interfaces/i-auth-service';
import { UtilsService } from './app-utils/utils.service';
import { NetworkService } from './net-utils/network.service';
import { IamAuthenticatorService } from './net-utils/iam-authenticator.service';
import { AuthService } from './auth-utils/auth.service';
import { AuthUtilsService } from "./auth-utils/auth-utils.service";

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  constructor(
    private _utilsService: UtilsService,
    private _authServiceInstance: AuthService,
    private _authUtilsServiceInstance: AuthUtilsService,
    private _netService: NetworkService,
    private _iamAuthenticatorServiceInstance: IamAuthenticatorService,
  ) {}

  get iamAuthenticatorService(): IamAuthenticatorService {
    return this._iamAuthenticatorServiceInstance;
  }

  get authService(): IAuthService {
    return this._authServiceInstance;
  }

  get authUtilsService(): AuthUtilsService {
    return this._authUtilsServiceInstance;
  }

  get utilService(): UtilsService {
    return this._utilsService;
  }

  get networkService(): NetworkService {
    return this._netService;
  }
}
