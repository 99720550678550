<div class="container">
  <div *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="button-container">
    <div class="align-right create-button">
      <button mat-raised-button [color]="canPerformAction('edit') ? 'primary' : 'disabled'"
        [disabled]="!canPerformAction('edit')" (click)="openModifyDialog()" *ngIf="!(outOfOffice?.isRecurrent)">
        <i class="fa-solid fa-edit"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>

      <button mat-raised-button color="'primary'" (click)="openModifyDialogRecurrent()"
        *ngIf="outOfOffice?.isRecurrent">
        <i class="fa-solid fa-edit"></i>
        &nbsp;
        <span>{{eTextStrings.MODIFY}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button [color]="canPerformAction('delete') ? 'primary' : 'disabled'"
        [disabled]="!canPerformAction('delete')" (click)="openDeleteDialog()">
        <i class="fa-solid fa-trash"></i>
        &nbsp;
        <span>{{eTextStrings.DELETE}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button
        [matTooltip]="canPerformAction('approve') ? '' : eTextStrings.OUT_OF_OFFICE_PERFORM_ACTION_DISABLED"
        [color]="canPerformAction('approve') ? 'primary' : 'disabled'" [disabled]="!canPerformAction('approve')"
        (click)="approveRequest()" *ngIf="!(outOfOffice?.isRecurrent)">
        <i class="fa-solid fa-check"></i>
        &nbsp;
        <span>{{eTextStrings.APPROVE}}</span>
      </button>
    </div>
    <div class="align-right create-button">
      <button mat-raised-button
        [matTooltip]="canPerformAction('deny') ? '' : eTextStrings.OUT_OF_OFFICE_PERFORM_ACTION_DISABLED"
        [color]="canPerformAction('deny') ? 'primary' : 'disabled'" [disabled]="!canPerformAction('deny')"
        (click)="denyRequest()" *ngIf="!(outOfOffice?.isRecurrent)">
        <i class="fa-solid fa-times"></i>
        &nbsp;
        <span>{{eTextStrings.DENY}}</span>
      </button>
    </div>
  </div>
  <div class="card-detail">
    <mat-card>
      <mat-card-content>
        <p><i class="fa-solid fa-id-card"></i>beSharper: <b>{{outOfOffice?.besharperName}}
            {{outOfOffice?.besharperSurname}}</b></p>
        <p><i class="fa-solid fa-clock"></i>Periodo: <b> {{dateRange}}</b></p>
        <p><i class="fas fa-tasks"></i>Stato richiesta: <b>{{requestStatus}}</b></p>
        <p><i class="fas fa-note"></i>Motivazione: <b>{{outOfOffice?.approverNotes}}</b></p>
        <p><i class="fas fa-ear-listen"></i>Tipologia di richiesta: <b>{{(outOfOffice?.typeName)}}</b></p>
        <p *ngIf="outOfOffice?.smartWorkingReason"><i class="fas fa-block-question"></i> Ragione dello Smart:
          <b>{{(outOfOffice.smartWorkingReason)}}</b>
        </p>
        <p *ngIf="outOfOffice?.isRecurrent"><i class="far fa-repeat"></i>E' un evento ricorrente?
          <b>{{outOfOffice?.isRecurrent ? 'SI' : 'NO'}}</b>
        </p>
        <p *ngIf="outOfOffice?.daysOfTheWeek"><i class="far fa-calendar"></i>Giorni: <b
            [innerHtml]="formatDaysOfTheWeek(outOfOffice.daysOfTheWeek)"></b></p>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-container *ngIf="outOfOffice?.isRecurrent === true">
    <mat-card>
      <mat-card-content>
        <div class="detail-card-header">
          <h2>Esploso dei Giorni di Remote Working</h2>
          <br>
          <div class="days-query-filter">
            <mat-label>Cerca per giorni della ricorrenza</mat-label>
            <ul>
              <li><mat-checkbox (change)="toggleDay(0)" [checked]="daysOfTheWeek[0] === '1'">LUN</mat-checkbox></li>
              <li><mat-checkbox (change)="toggleDay(1)" [checked]="daysOfTheWeek[1] === '1'">MAR</mat-checkbox></li>
              <li><mat-checkbox (change)="toggleDay(2)" [checked]="daysOfTheWeek[2] === '1'">MER</mat-checkbox></li>
              <li><mat-checkbox (change)="toggleDay(3)" [checked]="daysOfTheWeek[3] === '1'">GIO</mat-checkbox></li>
              <li><mat-checkbox (change)="toggleDay(4)" [checked]="daysOfTheWeek[4] === '1'">VEN</mat-checkbox></li>
            </ul>
          </div>
          <div class="mobile-window-filter">
            <mat-label>Usa finestra mobile per filtrare i risultati</mat-label>
            <mat-checkbox (change)="toggleMobileWindow()" [checked]="mobileWindowFilter"></mat-checkbox>
          </div>
        </div>
        <app-table-ui [datasource]="childrenDataSource" [backendUrl]="childrenUrl" [defaultActive]="'startDate'"
          [defaultDirection]="'asc'" [tableDefinitions]="tableDefinitions" [actionDefinitions]="actionDefinitions"
          [pageSize]="10" [pageSizeOptions]="[10,20,30]" [daysOfTheWeekObservable]="daysOfTheWeekObservable"
          [mobileWindowObservable]="mobileWindowObservable"
          [filterObservable]="filterObservable" [mapper]="mapper" [showPaginator]=true>
        </app-table-ui>
      </mat-card-content>
    </mat-card>
  </ng-container>

</div>
