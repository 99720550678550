import { IListMapper, IMapper } from '../models/i-mapper';
import { OutOfOfficeModel } from '../models/out-of-office.model';
import { OutOfOfficeTypeModel } from "../models/out-of-office-type.model";

export class OutOfOfficeListMapper implements IListMapper {
  count: number = 0;
  elements: OutOfOfficeModel[] = [];
  permission: any = {};
  response: OutOfOfficeModel = {} as OutOfOfficeModel;

  fillFromJson(json: any): void {
    const items = json.items;
    this.count = json.total;

    this.elements = [];
    let itemMapper = new OutOfOfficeDetailsMapper();

    for (let item of items) {
      const model = itemMapper.fillFromJson(item)
      this.elements.push(model);
    }
  }

  fillToJson(): string {
    return JSON.stringify(this.permission);
  }
}

export class OutOfOfficeDetailsMapper implements IMapper {
  count: number = 0;
  elements: OutOfOfficeModel[] = [];
  response: OutOfOfficeModel = {} as OutOfOfficeModel;

  fillFromJson(json: any): OutOfOfficeModel {
    this.response = {
      id: json.id,
      startDate: json.startDate,
      endDate: json.endDate,
      status: json.status,
      smartWorkingReason: json.smartWorkingReason,
      description: json.description,
      createdAt: new Date(json.createdAt),
      updatedAt: new Date(json.updatedAt),
      besharperId: json.besharper.id,
      besharperName: json.besharper.name,
      besharperSurname: json.besharper.surname,
      typeId: json.type.id,
      typeName: json.type.name,
      isRecurrent: json.isRecurrent,
      correlationId: json.correlationId,
      daysOfTheWeek: json.daysOfTheWeek,
      approvers: json.approvers,
      allowedActions: json.allowedActions,
      approverNotes: json.approverNotes,
    }

    return this.response;
  }

  fillToJson(): string {
    return JSON.stringify(this.response);
  }
}

export class OutOfOfficeRequestMapper implements IMapper {
  count: number = 0;
  elements: OutOfOfficeModel[] = [];
  response: OutOfOfficeModel = {} as OutOfOfficeModel;

  fillFromJson(json: any): OutOfOfficeModel {
    this.response = {
      ...json
    }

    return this.response;
  }

  fillToJson(): string {
    return JSON.stringify(this.response);
  }
}

export class OutOfOfficeTypeListMapper implements IListMapper {
  count: number = 0;
  elements: OutOfOfficeTypeModel[] = [];
  permission: any = {};
  response: OutOfOfficeTypeModel = {} as OutOfOfficeTypeModel;

  fillFromJson(json: any): void {
    const items = json.items;

    this.elements = [];
    let itemMapper = new OutOfOfficeTypeDetailsMapper();

    for (let item of items) {
      const model = itemMapper.fillFromJson(item)
      this.elements.push(model);
    }
  }

  fillToJson(): string {
    return JSON.stringify(this.permission);
  }
}

export class OutOfOfficeTypeDetailsMapper implements IMapper {
  count: number = 0;
  elements: OutOfOfficeTypeModel[] = [];
  response: OutOfOfficeTypeModel = {} as OutOfOfficeTypeModel;

  fillFromJson(json: any): OutOfOfficeTypeModel {
    console.log("OO TYPER: ", json);
    this.response = {
      ...json,
      id: json.id,
      name: json.name,
      code: json.code,
      offsetDays: json.offsetDays,
    }

    return this.response;
  }

  fillToJson(): string {
    return JSON.stringify(this.response);
  }
}
