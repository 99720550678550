import { Component } from '@angular/core';
import { IFilteredTablePage } from '../../../../core/interfaces/i-filtered-table-page';
import { ProviderService } from '../../../../core/provider.service';
import { AdminPanelCreateModifyWelfareComponent } from '../../../dialogs/admin-panel-create-modify-welfare/admin-panel-create-modify-welfare.component';
import { ModeEnum } from '../../../../core/constants/enums';
import { WelfareReasonModel } from '../../../../models/welfare.model';
import { Constants } from '../../../../core/constants/constants';
import { TableDataSource } from '../../../../core/form-utils/table/table.datasource';
import { environment } from "../../../../../environments/environment";
import { TextStrings } from "../../../../core/constants/text-strings";
import { Router } from "@angular/router";
import { WelfareReasonListMapper } from "../../../../mappers/welfare.mapper";
import { AdminPanelCreateModifyWelfareReasonComponent } from "../../../dialogs/admin-panel-create-modify-welfare-reason/admin-panel-create-modify-welfare-reason.component";

@Component({
  selector: 'app-set-welfare-reason-menus',
  templateUrl: './set-welfare-reason-menus.component.html',
  styleUrl: './set-welfare-reason-menus.component.scss',
})
export class SetWelfareReasonMenusComponent extends IFilteredTablePage {
  welfareReasonCollapsed = true;

  welfareReasonDataSource: TableDataSource<WelfareReasonModel> = new TableDataSource<WelfareReasonModel>();
  welfareReasonBackendUrl = environment.cognito.apiEndpoint + Constants.welfareReasonApiPath;
  welfareReasonTableDefinitions = [
    { def: 'name', title: 'Nome', sortable: true },
  ];
  welfareReasonMapper = new WelfareReasonListMapper();
  welfareActionDefinitions = [
    {
      icon: 'fa-solid fa-check',
      text: 'Cancella',
      styleClass: 'table-action-delete',
      callback: (item: WelfareReasonModel) => {
        this.deleteItem(item.id);
      },
    },
  ];

  eTextStrings = TextStrings;

  constructor(private providerService: ProviderService, protected override router: Router) {
    super(router, providerService.authService, providerService.utilService);
  }

  toggleCollapse(): void {
    this.welfareReasonCollapsed = !this.welfareReasonCollapsed;
  }

  deleteItem(id: string): void {
    this.providerService.utilService.deleteCrudDialog({
      page: this,
      apiUrl: `${this.welfareReasonBackendUrl}/${id}`,
    });
  }

  openCreateWelfareDialog(): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyWelfareReasonComponent,
      data: { mode: ModeEnum.CREATE }
    });
  }

  openModifyWelfareReasonDialog(row: any): void {
    this.providerService.utilService.openCrudDialog({
      page: this,
      template: AdminPanelCreateModifyWelfareReasonComponent,
      data: {
        mode: ModeEnum.MODIFY,
        data: row,
      }
    });
  }
}
